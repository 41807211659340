import EnglishAdm from "./adm/english.json";
import EnglishCore from "./core/english.json";
import EnglishCrm from "./crm/english.json";
import EnglishIor from "./ior/english.json";
import EnglishOrg from "./organization/english.json";
import EnglishQam from "./qam/english.json";
import EnglishRsm from "./rsm/english.json";
import EnglishTrg from "./trg/english.json";
import FrenchAdm from "./adm/french.json";
import FrenchCore from "./core/french.json";
import FrenchCrm from "./crm/french.json";
import FrenchIor from "./ior/french.json";
import FrenchOrg from "./organization/french.json";
import FrenchQam from "./qam/french.json";
import FrenchRsm from "./rsm/french.json";
import FrenchTrg from "./trg/french.json";
import GermanAdm from "./adm/german.json";
import GermanCore from "./core/german.json";
import GermanCrm from "./crm/german.json";
import GermanIor from "./ior/german.json";
import GermanOrg from "./organization/german.json";
import GermanQam from "./qam/german.json";
import GermanRsm from "./rsm/german.json";
import GermanTrg from "./trg/german.json";
import ItalianAdm from "./adm/italian.json";
import ItalianCore from "./core/italian.json";
import ItalianCrm from "./crm/italian.json";
import ItalianIor from "./ior/italian.json";
import ItalianOrg from "./organization/italian.json";
import ItalianQam from "./qam/italian.json";
import ItalianRsm from "./rsm/italian.json";
import ItalianTrg from "./trg/italian.json";
import SpanishAdm from "./adm/spanish.json";
import SpanishCore from "./core/spanish.json";
import SpanishCrm from "./crm/spanish.json";
import SpanishIor from "./ior/spanish.json";
import SpanishOrg from "./organization/spanish.json";
import SpanishQam from "./qam/spanish.json";
import SpanishRsm from "./rsm/spanish.json";
import SpanishTrg from "./trg/spanish.json";
import EnglishDom from "./dom/english.json";
import FrenchDom from "./dom/french.json";
import GermanDom from "./dom/german.json";
import ItalianDom from "./dom/italian.json";
import SpanishDom from "./dom/spanish.json";

// the translations
export const translations = {
  "English": {
    translation: {
      ...EnglishAdm,
      ...EnglishCore,
      ...EnglishCrm,
      ...EnglishIor,
      ...EnglishOrg,
      ...EnglishQam,
      ...EnglishRsm,
      ...EnglishTrg,
      ...EnglishDom
    }
  },
  "French": {
    translation: {
      ...FrenchAdm,
      ...FrenchCore,
      ...FrenchCrm,
      ...FrenchIor,
      ...FrenchOrg,
      ...FrenchQam,
      ...FrenchRsm,
      ...FrenchTrg,
      ...FrenchDom
    }
  },
  "German": {
    translation: {
      ...GermanAdm,
      ...GermanCore,
      ...GermanCrm,
      ...GermanIor,
      ...GermanOrg,
      ...GermanQam,
      ...GermanRsm,
      ...GermanTrg,
      ...GermanDom
    }
  },
  "Italian": {
    translation: {
      ...ItalianAdm,
      ...ItalianCore,
      ...ItalianCrm,
      ...ItalianIor,
      ...ItalianOrg,
      ...ItalianQam,
      ...ItalianRsm,
      ...ItalianTrg,
      ...ItalianDom
    }
  },
  "Spanish": {
    translation: {
      ...SpanishAdm,
      ...SpanishCore,
      ...SpanishCrm,
      ...SpanishIor,
      ...SpanishOrg,
      ...SpanishQam,
      ...SpanishRsm,
      ...SpanishTrg,
      ...SpanishDom
    }
  }
};
