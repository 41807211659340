import { createAction, createReducer } from "@reduxjs/toolkit";

const initialState = {
  collapsed: [],
  query: {
    columns: [],
    model: "",
    joins: []
  }
};

export const updateAdmReport = createAction("adm/report/update");
export const resetAdmReport = createAction("adm/report/reset");

export default createReducer(initialState, (builder) => {
  builder
    .addCase(updateAdmReport, (state, action) => {
      return {
        ...state,
        ...action.payload
      };
    })
    .addCase(resetAdmReport, () => {
      return {
        ...initialState
      };
    });
});
