import { useCallback, useEffect, useState } from "react";
import isEqual from "lodash/isEqual";
import isEmpty from "lodash/isEmpty";
import isBoolean from "lodash/isBoolean";
import qs from "qs";
import { useTranslation } from "react-i18next";
import axios from "../../axios";
import { openGeneralErrorNotification } from "../../components/Notification";
import { usePrevious } from "./usePrevious";

const prepareParams = (params, method) => {
  if (isEmpty(params)) {
    return {};
  }

  if (method === "get") {
    return { params: qs.parse(params) };
  }

  return params;
};

export const useAxiosRequest = (props) => {
  const {
    route,
    disabled,
    refetch,
    params = {},
    method = "get",
    onSuccess = () => {},
    transformer = (res) => res,
    onFailed = () => {},
    config = {}
  } = props;
  const previousParams = usePrevious(params);
  const previousRefetch = usePrevious(refetch);
  const previousRoute = usePrevious(route);
  const { t } = useTranslation();

  const [requestState, setRequestState] = useState({
    loading: !disabled,
    error: null,
    data: null
  });

  const source = axios.CancelToken.source();
  const request = useCallback(() => {
    if (disabled) {
      return;
    }

    setRequestState({ ...requestState, loading: true });
    axios[method](route, { ...prepareParams(params, method), ...config }, { cancelToken: source.token }).then(
      (res) => {
        onSuccess && onSuccess(res);
        setRequestState({ ...requestState, loading: false, data: transformer(res) });
      },
      (error) => {
        onFailed && onFailed(error.response);
        setRequestState({ ...requestState, loading: false, error: error.response });
        if (!onFailed) {
          openGeneralErrorNotification({ error, defaultError: t("container.form_errors.internal_server_error") });
        }
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, requestState, route, source.token, disabled, method]);

  useEffect(() => {
    request();

    return () => {
      source?.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isEqual(previousParams, params)) {
      request();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    if (isBoolean(refetch) && !isEqual(previousRefetch, refetch)) {
      request();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);

  useEffect(() => {
    if (route !== previousRoute) {
      request();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route]);

  return requestState;
};
