import React from "react";
import classNames from "classnames";
import Preloader from "../Preloader";
import { useImage } from "./useImage";

const Image = (props) => {
  const { alt = "img", src, retina, className, auth = true, style } = props;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const img = auth ? useImage({ src, retina }) : { src };

  return (
    <div className={classNames("image-container flex flex-justify-center flex-items-center", className)} style={style}>
      {img.src ? (
        <img alt={alt} src={img.src} srcSet={`${img.src} 1x${img.retina ? `, ${img.retina} 2x` : ""}`} />
      ) : (
        <span className="inline-flex">
          <Preloader />
        </span>
      )}
    </div>
  );
};

export default Image;
