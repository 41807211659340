import React, { useState } from "react";
import { Dropdown } from "antd";
import ProtectedComponent from "../_auth/ProtectedComponent";
import {
  PERMISSION_MANAGE_CORE_GROUPS,
  PERMISSION_MANAGE_CORE_USERS,
  PERMISSION_MANAGE_DOM_TEMPLATES,
  PERMISSION_MANAGE_ORG_SETTINGS,
  PERMISSION_MANAGE_ORG_STAFF,
  PERMISSION_MANAGE_QAM_AUDITORS,
  PERMISSION_MANAGE_QAM_AUDITS,
  PERMISSION_MANAGE_QAM_ENTITIES,
  PERMISSION_MANAGE_QAM_NORMS
} from "../_auth/permissions/permissions";
import ActivityForm from "../../pages/Qam/Activities/ActivityForm";
import Modal from "../Modal";
import EntityForm from "../../pages/Qam/Entities/EntityForm";
import { MODAL_ORG_SETTINGS_FUNCTIONS } from "../../pages/Organization/OrgSettings/Functions";
import FunctionForm from "../../pages/Organization/OrgSettings/Functions/FunctionForm";
import NormForm, { MODAL_NORM_FORM } from "../../pages/Qam/Catalogues/Norms/NormForm";
import ModalTrainingForm from "../../pages/Trg/Trainings/Form";
import { MODAL_TRG_TRAINING } from "../../pages/Trg/Trainings";
import { MODAL_QUALIFICATION } from "../../pages/Trg/TrgQualifications";
import ModalQualificationForm from "../../pages/Trg/TrgQualifications/Form";
import TemplateForm, { MODAL_TEMPLATE_FORM } from "../../pages/Dom/DocumentTemplates/Templates/TemplateForm";
import DropdownNewItemOverlay from "./Overlay";
import { MODAL_QAM_ACTIVITY_FORM_HEADER_NEW_ITEM, MODAL_QAM_ENTITY_FORM_HEADER_NEW_ITEM } from "./config";

const DropdownNewItem = () => {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <Modal name={MODAL_QAM_ACTIVITY_FORM_HEADER_NEW_ITEM}>
        <ActivityForm name={MODAL_QAM_ACTIVITY_FORM_HEADER_NEW_ITEM} />
      </Modal>
      <Modal name={MODAL_QAM_ENTITY_FORM_HEADER_NEW_ITEM}>
        <EntityForm name={MODAL_QAM_ENTITY_FORM_HEADER_NEW_ITEM} />
      </Modal>
      <Modal name={MODAL_ORG_SETTINGS_FUNCTIONS}>
        <FunctionForm />
      </Modal>
      <Modal name={MODAL_NORM_FORM}>
        <NormForm />
      </Modal>
      <Modal name={MODAL_TEMPLATE_FORM}>
        <TemplateForm />
      </Modal>
      <Modal name={MODAL_TRG_TRAINING}>
        <ModalTrainingForm />
      </Modal>
      <Modal name={MODAL_QUALIFICATION}>
        <ModalQualificationForm />
      </Modal>
      <ProtectedComponent
        gates={[
          PERMISSION_MANAGE_CORE_USERS,
          PERMISSION_MANAGE_CORE_GROUPS,
          PERMISSION_MANAGE_ORG_STAFF,
          PERMISSION_MANAGE_ORG_SETTINGS,
          PERMISSION_MANAGE_QAM_ENTITIES,
          PERMISSION_MANAGE_QAM_AUDITORS,
          PERMISSION_MANAGE_QAM_AUDITS,
          PERMISSION_MANAGE_QAM_NORMS,
          PERMISSION_MANAGE_DOM_TEMPLATES
        ]}
      >
        <Dropdown
          overlay={<DropdownNewItemOverlay setVisible={setVisible} />}
          visible={visible}
          onVisibleChange={(vis) => setVisible(vis)}
          trigger={["click"]}
          placement="bottomRight"
          overlayClassName="dropdown-new-item-overlay"
          getPopupContainer={() => document.getElementsByClassName("layout-header")[0]}
        >
          <div className="link icon-link white">
            <i className="ti-plus" />
          </div>
        </Dropdown>
      </ProtectedComponent>
    </>
  );
};

export default DropdownNewItem;
