import React from "react";
import { useTranslation } from "react-i18next";
import { fieldRules } from "../methods";
import Form from "../index";
import Signature from "../../Signature";

export const FORM_ITEM_SIGNATURE = "form-signature";

const FormItemSignature = (props) => {
  const { name, label, required, rules = [], fieldProps = {}, placeholder, initialValue, ...rest } = props;
  const { t } = useTranslation();

  return (
    <Form.Item
      name={name}
      label={t(label)}
      rules={fieldRules(
        {
          required: required
            ? {
                message: t("container.form_errors.required")
              }
            : false
        },
        rules
      )}
      {...rest}
    >
      <Signature initialValue={initialValue} placeholder={placeholder} {...fieldProps} />
    </Form.Item>
  );
};

export default FormItemSignature;
