import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { Select } from "antd";
import qs from "qs";
import { useTranslation } from "react-i18next";
import isEqual from "lodash/isEqual";
import Preloader from "../Preloader";
import axios from "../../axios";
import { antdSelectFilterOption } from "../ResourceSelectLazyLoading/methods";
import { openGeneralErrorNotification } from "../Notification";
import { usePrevious } from "../../utils/hooks/usePrevious";

const ResourceSelect = (props) => {
  const {
    className,
    apiRoute,
    showSearch = true,
    data = [],
    params = {},
    textAccessor = (item) => item.text,
    valueAccessor = (item) => item.id || item.value,
    ...rest
  } = props;
  const [loading, setLoading] = useState(false);
  const [selectData, setSelectData] = useState(data);
  const { t } = useTranslation();
  const fetch = () => {
    setLoading(true);
    axios
      .get(apiRoute, {
        params,
        paramsSerializer: qs.stringify
      })
      .then((response) => {
        setSelectData(
          response.data.data.map((item) => ({
            text: textAccessor(item),
            value: valueAccessor(item)
          }))
        );
        setLoading(false);
      })
      .catch((error) => {
        openGeneralErrorNotification({ error, defaultError: t("container.form_errors.internal_server_error") });
        setLoading(false);
      });
  };

  const previousData = usePrevious(data);
  useEffect(() => {
    if (!isEqual(data, previousData)) {
      setSelectData(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Select
      size="large"
      className={classNames("resource-select", className, { "is-searchable": showSearch })}
      onFocus={apiRoute ? fetch : null}
      showSearch={showSearch}
      showArrow={false}
      optionFilterProp="children"
      getPopupContainer={(el) => el}
      dropdownMatchSelectWidth={false}
      filterOption={antdSelectFilterOption}
      notFoundContent={
        loading ? (
          <Preloader style={{ width: "100%", textAlign: "center" }} width={20} />
        ) : (
          <p className="m-0">{t("container.general_words.no_data_message")}</p>
        )
      }
      {...rest}
    >
      {selectData.map((option) => (
        <Select.Option key={option.value} value={option.value} disabled={option.disabled}>
          {option.text}
        </Select.Option>
      ))}
    </Select>
  );
};
export default ResourceSelect;
