import { createAction, createReducer } from "@reduxjs/toolkit";

const initialState = {
  triggerHeader: null
};

export const updateNotifications = createAction("notifications/update");

export default createReducer(initialState, (builder) => {
  builder.addCase(updateNotifications, (state, action) => {
    return {
      ...state,
      ...action.payload
    };
  });
});
