import React from "react";
import { Editor as TinymceReact } from "@tinymce/tinymce-react";
import { defaultMenu, defaultOptions, defaultPlugins, defaultToolbar, tinyMceApiKey } from "./config";

const TinyMCE = (props) => {
  const { value, onChange, options = {}, showMenuBar, ...rest } = props;

  return (
    <TinymceReact
      {...rest}
      apiKey={tinyMceApiKey}
      value={value}
      init={{
        plugins: defaultPlugins,
        menu: defaultMenu,
        toolbar: defaultToolbar,
        menubar: showMenuBar,
        elementpath: showMenuBar,
        ...defaultOptions,
        ...options
      }}
      onEditorChange={onChange}
    />
  );
};

export default TinyMCE;
