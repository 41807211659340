import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import useFormSubmit from "../../../components/Form/useFormSubmit";
import Form, { FORM_HORIZONTAL_LAYOUT } from "../../../components/Form";
import { reloadTable } from "../../../components/SmartTable/reducer";
import { closeModalAction } from "../../../components/Modal/reducer";
import Button from "../../../components/Button";
import { renderDynamicallyFields } from "../../../components/Form/methods";
import { FORM_ITEM_TEXT } from "../../../components/Form/FormItems/FormItemText";
import { FORM_ITEM_TINY_MCE } from "../../../components/Form/FormItems/FormItemTinyMCE";
import { FORM_ITEM_DATE_PICKER } from "../../../components/Form/FormItems/FormItemDatePicker";
import { FORM_ITEM_RESOURCE_SELECT_LAZY_LOADING } from "../../../components/Form/FormItems/FormItemResourceSelectLazyLoading";
import { formatMomentDateForAPI } from "../../../components/DatePicker";
import { TABLE_QAM_CORRECTIVE_ACTIONS } from "./methods";
import { MODAL_QAM_CORRECTIVE_ACTION } from "./index";

const CorrectiveActionForm = ({ name }) => {
  const data = useSelector((state) => state?.modal[name || MODAL_QAM_CORRECTIVE_ACTION]?.data) || {};
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isNewRecord = !data.id;
  const { loading, submit, generalError } = useFormSubmit({
    route: isNewRecord ? "qam/correctiveactions" : `qam/correctiveactions/${data.id}`,
    method: isNewRecord ? "post" : "put",
    form,
    transformer: (values) => {
      return {
        ...data,
        ...values,
        due_date: formatMomentDateForAPI(values.due_date)
      };
    },
    onSuccess: (res) => {
      data.onSuccess && data.onSuccess(res);
      dispatch(reloadTable({ name: TABLE_QAM_CORRECTIVE_ACTIONS, resetFilters: isNewRecord }));
      dispatch(closeModalAction({ name: name || MODAL_QAM_CORRECTIVE_ACTION }));
    }
  });

  return (
    <Form
      onSubmit={submit}
      form={form}
      errors={generalError}
      loading={loading}
      initialValues={data}
      layout={FORM_HORIZONTAL_LAYOUT}
      header={
        <>
          <div className="h3">
            {t(isNewRecord ? "qam.new_corrective_action.form_title" : "qam.edit_corrective_action.form_title")}
          </div>
          <div className="mb-30 mt-10 h6 font-weight-300 text-muted">{t("container.general_words.form_subtitle")}</div>
        </>
      }
    >
      {renderDynamicallyFields([
        {
          required: true,
          type: FORM_ITEM_TEXT,
          max: 200,
          name: "title",
          label: "container.general_words.title",
          placeholder: "container.general_words.title"
        },
        ...(data.hideObservation
          ? []
          : [
              {
                required: true,
                type: FORM_ITEM_TINY_MCE,
                name: "observation",
                label: "qam.activity_checklist.criteria_table.observation",
                placeholder: "qam.activity_checklist.criteria_table.observation"
              }
            ]),
        {
          required: true,
          type: FORM_ITEM_TINY_MCE,
          name: "details",
          label: "qam.new_corrective_action.details",
          placeholder: "qam.new_corrective_action.details"
        },
        {
          required: true,
          type: FORM_ITEM_DATE_PICKER,
          name: "due_date",
          label: "qam.new_corrective_action.due_date",
          placeholder: "qam.new_corrective_action.due_date"
        },
        {
          required: true,
          type: FORM_ITEM_RESOURCE_SELECT_LAZY_LOADING,
          name: "responsible_id",
          label: "qam.new_corrective_action.responsibility",
          placeholder: "qam.new_corrective_action.responsibility",
          initialValue: data?.responsible_id
            ? {
                id: data.responsible_id,
                text: data.responsible_name || data.responsible?.name
              }
            : undefined,
          apiRoute: `qam/auditees?field%5B%5D=audit_id&op%5B%5D=eq&order_by_column%5B%5D=name&order_by_type%5B%5D=asc&term%5B%5D=${data.audit_id}`
        }
      ])}
      <div className="flex mt-25 flex-justify-end">
        <Button
          className="min-w-150"
          type="primary"
          htmlType="submit"
          disabled={loading}
          loading={loading}
          icon={<i className="ti-check" />}
        >
          {t("container.general_words.save")}
        </Button>
      </div>
    </Form>
  );
};

export default CorrectiveActionForm;
