import { message } from "antd";

export const imageExtensions = ["JPG", "JPEG", "PNG"];
export const allowedFileTypes = [
  "jpeg",
  "png",
  "jpg",
  "pdf",
  "doc",
  "ppt",
  "xls",
  "docx",
  "pptx",
  "xlsx",
  "csv",
  "ods",
  "txt",
  "msg",
  "eml"
];
export const imageMaxSize = 20; // MB

export const getFileExtension = (fileName) => {
  if (fileName) {
    return fileName.toUpperCase().split(".").pop();
  }
  return null;
};

export const validateImage = (file, t) => {
  if (imageExtensions.indexOf(getFileExtension(file.name)) === -1) {
    message.error(t("container.form_errors.allowedFileTypes", { value: imageExtensions.join(", ") }));
    return false;
  }
  if (file.size / 1024 / 1024 > imageMaxSize) {
    message.error(t("container.form_errors.uploadMaxLimit", { value: imageMaxSize }));
    return false;
  }
  return true;
};

export const validateFile = (file, t, extensions = []) => {
  const fileExtension = getFileExtension(file.name);
  const allowedExtensions = extensions.length !== 0 ? extensions : allowedFileTypes;
  if (
    allowedExtensions.indexOf(fileExtension) === -1 &&
    allowedExtensions.indexOf(fileExtension.toLowerCase()) === -1
  ) {
    message.error(t("container.form_errors.allowedFileTypes", { value: allowedExtensions.join(", ") }));
    return false;
  }
  return true;
};
