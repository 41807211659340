export const PAGE_DASHBOARD = "/";
export const PAGE_NOT_FOUND = "/404";

export const PAGE_LOGIN = "/login";
export const PAGE_FORGOT_PASSWORD = "/forgot-password";
export const PAGE_RESET_PASSWORD = "/password/reset/:token";
export const PAGE_TWO_FACTOR = "/two-factor";
export const PAGE_TWO_FACTOR_QR = "/qr-confirmation/:token";
export const PAGE_ANONYMOUS_REPORT = "/anonymous-report";

export const PAGE_USERS = "/users";
export const PAGE_NEW_USER = `${PAGE_USERS}/new`;
export const PAGE_EDIT_USER = `${PAGE_USERS}/edit`;
export const PAGE_VIEW_USER = "/user";

export const PAGE_GROUPS = "/groups";
export const PAGE_NEW_GROUP = `${PAGE_GROUPS}/new`;
export const PAGE_EDIT_GROUP = `${PAGE_GROUPS}/edit`;

export const PAGE_MAIN_SETTINGS = "/main-settings";
export const PAGE_NOTIFICATIONS = "/notifications";
export const PAGE_AUDIT_LOG = "/audit-log";
