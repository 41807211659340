export const getFileType = (src) => {
  if (!src) {
    return "";
  }

  if (src.indexOf(";base64") > -1) {
    return src.substring(src.indexOf("/") + 1, src.indexOf(";base64"));
  }

  return src.toLowerCase().split(".").pop();
};

export const getBase64 = async (image) => {
  return new Promise((resolve) => {
    const reader = new FileReader();

    reader.readAsDataURL(image);

    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = () => {
      resolve("");
    };
  });
};
