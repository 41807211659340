export const defaultPlugins = "fullscreen lists link wordcount lists advlist";
export const defaultMenu = {
  file: { title: "File", items: "newdocument" },
  edit: { title: "Edit", items: "undo redo | cut copy paste pastetext | selectall" },
  view: {
    title: "",
    items: ""
  },
  insert: {
    title: "",
    items: ""
  },
  tools: {
    title: "",
    items: ""
  },
  format: {
    title: "Format",
    items: "bold italic underline strikethrough superscript subscript | formats | removeformat"
  }
};
export const defaultToolbar =
  "undo redo | styles | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link | fullscreen";

export const defaultOptions = {
  visual: false,
  branding: false,
  height: 240
};

// apiKey for TinyMCE
export const tinyMceApiKey = process.env.REACT_APP_TINY_KEY_LICENCE;

export const fullPlugins = "fullscreen lists link wordcount lists advlist image table textcolor paste imagetools media";
export const fullToolbar = [
  "undo redo | styleselect | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | bullist numlist | outdent indent | blockquote | subscript superscript | removeformat | code",
  "fontsizeselect | forecolor backcolor | link unlink | image media | hr | charmap | searchreplace | table | | fullscreen"
];
