import { notification } from "antd";
import uniqueId from "lodash/uniqueId";
import classNames from "classnames";

export const openNotification = (params = {}) => {
  const {
    message,
    description,
    type = "open",
    placement = "topRight",
    key = uniqueId("_notification_key_"),
    duration = 3,
    closeIcon = <i className="ti-close" />,
    className,
    ...rest
  } = params;
  notification[type]({
    key,
    placement,
    message,
    description,
    duration,
    closeIcon,
    className: classNames("antd-custom-notification", className, { "no-description": !description }),
    ...rest
  });
};

export const openGeneralErrorNotification = (params) => {
  const {
    className,
    error,
    placement = "topRight",
    key = uniqueId("_notification_key_"),
    duration = 3,
    closeIcon = <i className="ti-close" />,
    defaultError = "",
    ...rest
  } = params;

  const message =
    error?.response?.data?.errors?.general?.length > 0 ? error.response.data.errors.general[0] : defaultError;

  if (message) {
    notification.error({
      key,
      placement,
      message,
      duration,
      closeIcon,
      className: classNames("antd-custom-notification no-description", className),
      ...rest
    });
  }
};
