import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { Tooltip } from "antd";
import { ClearOutlined } from "@ant-design/icons";
import TableService from "../../../components/SmartTable/TableService";
import SmartTable from "../../../components/SmartTable";
import { useLoggedUser } from "../../../utils/hooks/useLoggedUser";
import PageMetaTagTitle from "../../../components/PageMetaTagTitle";
import { useTableFullHeight } from "../../../components/SmartTable/useTableFullHeight";
import { openModalAction } from "../../../components/Modal/reducer";
import ResourceSelectLazyLoading from "../../../components/ResourceSelectLazyLoading";
import { reloadTable } from "../../../components/SmartTable/reducer";
import DatePicker from "../../../components/DatePicker";
import moment from "../../../moment";
import config from "../../../config";
import Button from "../../../components/Button";
import { useDateFormat } from "../../../utils/hooks/useDateFormat";
import LoadingModal, { MODAL_EXPORT_LOADING } from "../../../components/SmartTable/ExportDropdown/LoadingModal";
import { PAGE_TRG_MASS_ASSIGNMENT } from "../routes";
import { generateColumns, prepareQualificationsData, TABLE_QUALIFICATIONS } from "./methods";
import { contractTypeOptions, qualificationStatusOptions } from "./config";

export const MODAL_QUALIFICATION = "MODAL_QUALIFICATION";

const Qualifications = () => {
  const location = useLocation();
  const locationStatus = location.state?.status;
  const history = useHistory();
  const { format } = useDateFormat();
  const dispatch = useDispatch();
  const tableHeight = useTableFullHeight();
  const loggedUser = useLoggedUser();
  const { t } = useTranslation();
  const tableKeyName = `${TABLE_QUALIFICATIONS}_${loggedUser.id}`;
  const localStorageTableFilters = locationStatus
    ? {
        anotherFilters: [
          { nameKey: "qualificationStatus", value: locationStatus },
          { nameKey: "validContract", value: 1 }
        ]
      }
    : {
        ...JSON.parse(localStorage.getItem(tableKeyName) || "{}"),
        anotherFilters: [
          { nameKey: "validContract", value: 1 },
          { nameKey: "qualificationStatus", value: "all_without_previous_function" }
        ]
      };
  const [tableService] = useState(
    new TableService({
      defaultWith: [
        "qualifications.training.validity",
        "qualifications.training.uncompletedCourses.participants",
        "userDetail",
        "activeStaffJobFunctions.staffFunctions.trainingPrograms.activeJobTitleTrainings.training.uncompletedCourses.participants"
      ],
      defaultOrderBy: [
        { column: "last_name", type: "asc" },
        { column: "first_name", type: "asc" }
      ],
      searchColumns: ["name"],
      searchValue: "",
      ...localStorageTableFilters
    })
  );

  const contractFilter = localStorageTableFilters?.anotherFilters?.filter(
    (item) => item.nameKey === "validContract"
  )[0];

  const statusFilter = localStorageTableFilters?.anotherFilters?.filter(
    (item) => item.nameKey === "qualificationStatus"
  )[0];

  const employeeStatusFilter = localStorageTableFilters?.anotherFilters?.filter(
    (item) => item.nameKey === "employeeStatusIds"
  )[0];

  const dateFilter = localStorageTableFilters?.anotherFilters?.filter((item) => item.nameKey === "dateFilter")[0];

  const customHandleTableChange = ({ pagination, filters, sorter, tableService, getTableData, columns }) => {
    tableService.setPage(pagination.current);
    tableService.removeAllOrderBy().manageOrderBy({ column: sorter.field, type: sorter.order });
    tableService.manageColumnFilters(filters, columns);
    getTableData(tableService.getFilters());
  };

  const [totalItems, setTotalItems] = useState();

  useEffect(() => {
    if (locationStatus) {
      history.replace({ ...location, state: undefined });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [shouldReset, setShouldReset] = useState();
  const handleClearFilters = () => {
    dispatch(reloadTable({ name: TABLE_QUALIFICATIONS, resetFilters: true }));
    setShouldReset(new Date().getTime());
  };

  return (
    <>
      <PageMetaTagTitle title="TRG qualifications" />
      <LoadingModal name={MODAL_EXPORT_LOADING} message={t("qam.loading_pdf_report_message")} />
      <SmartTable
        className="table-vertical-top"
        containerClassName="qualifications-table"
        externalActions={
          <>
            <NavLink to={PAGE_TRG_MASS_ASSIGNMENT} className="action-btn">
              <Button type="primary" icon={<i className="ti-check-box" />}>
                {t("trg.qualifications.mass_assignment")}
              </Button>
            </NavLink>
          </>
        }
        clearFiltersActions={
          <Tooltip placement="topLeft" title={t("container.general_words.clear_filters")}>
            <Button onClick={handleClearFilters} icon={<ClearOutlined />}>
              {t("container.general_words.clear_filters")}
            </Button>
          </Tooltip>
        }
        externalFilters={
          <div className="flex qualification-external-filters">
            <ResourceSelectLazyLoading
              shouldReset={shouldReset}
              showArrow
              showSearch
              allowClear
              className="mr-10 mb-10"
              initialValue={contractFilter}
              value={contractFilter?.value}
              placeholder={t("trg.qualifications.filter_by_contract")}
              staticOptions={contractTypeOptions}
              onChange={(value) => {
                tableService.removeAnotherFilter("validContract");
                if (value !== undefined) {
                  tableService.addAnotherFilter({ nameKey: "validContract", value });
                }
                dispatch(reloadTable({ name: TABLE_QUALIFICATIONS }));
              }}
            />
            <ResourceSelectLazyLoading
              shouldReset={shouldReset}
              showArrow
              showSearch
              allowClear
              className="mr-10 mb-10"
              initialValue={statusFilter}
              value={statusFilter?.value}
              placeholder={t("trg.qualifications.filter_by_qualification_status")}
              staticOptions={qualificationStatusOptions}
              onChange={(value) => {
                tableService.removeAnotherFilter("qualificationStatus");
                if (value !== undefined) {
                  tableService.addAnotherFilter({ nameKey: "qualificationStatus", value });
                }
                dispatch(reloadTable({ name: TABLE_QUALIFICATIONS }));
              }}
            />
            <ResourceSelectLazyLoading
              shouldReset={shouldReset}
              showArrow
              showSearch
              allowClear
              className="mr-10 mb-10"
              mode="multiple"
              initialValue={employeeStatusFilter}
              value={employeeStatusFilter?.value}
              placeholder={t("trg.qualifications.filter_by_employee_status")}
              apiRoute="employee-status"
              maxTagCount="responsive"
              staticOptions={[
                { value: "all_employee_status", text: t("trg.qualifications.all_employee_status") },
                { value: "no_employee_status", text: t("trg.qualifications.no_employee_status") }
              ]}
              onChange={(value) => {
                tableService.removeAnotherFilter("employeeStatusIds");
                if (value !== undefined) {
                  tableService.addAnotherFilter({ nameKey: "employeeStatusIds", value });
                }
                dispatch(reloadTable({ name: TABLE_QUALIFICATIONS }));
              }}
            />
            <DatePicker
              className="mb-10 mr-10"
              style={{ width: 200, minWidth: 200, maxHeight: 40 }}
              placeholder={t("trg.qualifications.filter_by_date")}
              defaultValue={dateFilter?.value ? moment(dateFilter.value) : undefined}
              onChange={(value) => {
                tableService.removeAnotherFilter("dateFilter");
                if (value) {
                  tableService.addAnotherFilter({
                    nameKey: "dateFilter",
                    value: moment(value).format(config.defaultDateFormat)
                  });
                }
                dispatch(reloadTable({ name: TABLE_QUALIFICATIONS }));
              }}
            />
          </div>
        }
        customHandleTableChange={customHandleTableChange}
        pagination={{
          hideOnSinglePage: true,
          showSizeChanger: false,
          pageSize:
            totalItems > tableService.getFilters().defaultPageSize
              ? totalItems
              : tableService.getFilters().defaultPageSize,
          total:
            totalItems > tableService.getFilters().defaultPageSize
              ? totalItems * Math.ceil(tableService.getTotal() / tableService.defaultPageSize)
              : tableService.getTotal()
        }}
        rowSelection={false}
        scroll={{ x: 800, y: tableHeight - 47 }}
        name={TABLE_QUALIFICATIONS}
        tableService={tableService}
        columns={generateColumns(t, dispatch)}
        route="trg/qualifications"
        tableLocalStorageKeyName={tableKeyName}
        transformer={(data) => {
          const preparedData = prepareQualificationsData(data, t, format, tableService.getFilters());
          setTotalItems(preparedData.length);
          return preparedData;
        }}
        newItemProps={{
          access: true,
          text: t("trg.qualifications.add_qualification"),
          onClick: () => dispatch(openModalAction({ name: MODAL_QUALIFICATION }))
        }}
      />
    </>
  );
};

export default Qualifications;
