import React from "react";
import { DatePicker as AntdDatepicker } from "antd";
import moment from "../../moment";
import { useDateFormat } from "../../utils/hooks/useDateFormat";
import useLocale from "./useLocale";

export const DATE_SHORT_FORMAT = "YYYY-MM-DD";

export const formatMomentDateForAPI = (date, format = DATE_SHORT_FORMAT) => {
  return date ? moment(date).format(format) : date;
};

const DatePicker = (props) => {
  const { disabledDate, children, value, ...rest } = props;
  const locale = useLocale();
  const { appFormat } = useDateFormat();
  const pickerProps = {
    size: "large",
    disabledDate: disabledDate,
    format: appFormat,
    value: value ? moment(value) : value,
    locale,
    ...rest
  };

  if (children) {
    return (
      <div className="form-field-with-children">
        <AntdDatepicker {...pickerProps} />
        {children}
      </div>
    );
  }

  return <AntdDatepicker {...pickerProps} />;
};

DatePicker.RangePicker = AntdDatepicker.RangePicker;

export default DatePicker;
