import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import axios from "../../../../axios";
import { openGeneralErrorNotification } from "../../../../components/Notification";
import Tooltip from "../../../../components/Tooltip";
import Preloader from "../../../../components/Preloader";
import { openConfirm } from "../../../../components/Modal/Confirm";
import { reloadTable } from "../../../../components/SmartTable/reducer";
import { usePrevious } from "../../../../utils/hooks/usePrevious";

const ColumnActiveSwitch = ({
  isActive,
  route,
  tooltip = {},
  className,
  tableName,
  disabled,
  reqParams = {},
  stopPropagation,
  onSuccess,
  activeIcon,
  inactiveIcon,
  popupContent
}) => {
  const [active, setActive] = useState(!!isActive);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const previousIsActive = usePrevious(isActive);

  useEffect(() => {
    if (isActive !== previousIsActive) {
      setActive(!!isActive);
    }
  }, [isActive, previousIsActive]);

  const activeSwitch = () => {
    if (loading || !route) {
      return;
    }

    setLoading(true);
    return axios.put(route, { ...reqParams }).then(
      () => {
        if (tableName) {
          dispatch(reloadTable({ name: tableName }));
        }
        setActive(!active);
        setLoading(false);
        onSuccess && onSuccess();
      },
      (error) => {
        openGeneralErrorNotification({ error, defaultError: t("container.form_errors.internal_server_error") });
        setLoading(false);
      }
    );
  };

  const handleClick = (e) => {
    e.preventDefault();
    if (stopPropagation) {
      e.stopPropagation();
    }
    if (disabled) return;
    openConfirm({ onOk: activeSwitch, content: popupContent });
  };

  return (
    <Tooltip title={t(active ? tooltip.active : tooltip.inactive)}>
      <span
        className={classNames(
          active ? "active-switch-btn custom-icon ghost circle active" : "active-switch-btn custom-icon ghost circle",
          className,
          { disabled }
        )}
        style={{ cursor: "pointer" }}
        onClick={handleClick}
      >
        {loading && <Preloader size={14} />}
        {active && !loading && (activeIcon || <i className="ti-check" />)}
        {!active && !loading && (inactiveIcon || <i className="ti-close" />)}
      </span>
    </Tooltip>
  );
};

export default ColumnActiveSwitch;
