import { useRef, useEffect, useState } from "react";

export const useCountdown = (val = 100) => {
  const [num, setNum] = useState(val);

  let intervalRef = useRef();
  const decreaseNum = () => setNum((prev) => prev - 1);

  useEffect(() => {
    intervalRef.current = setInterval(decreaseNum, 1000);

    return () => clearInterval(intervalRef.current);
  }, []);

  useEffect(() => {
    if (num === 0) {
      clearInterval(intervalRef.current);
    }
  }, [num]);

  return num;
};
