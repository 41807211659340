import React from "react";
import { Modal as AntModal } from "antd";
import { connect } from "react-redux";
import classNames from "classnames";
import { closeModalAction } from "./reducer";

export const MODAL_SIZE_MEDIUM = "medium";
export const MODAL_SIZE_LARGE = "large";
export const MODAL_SIZE_SMALL = "small";

const Modal = (props) => {
  const {
    onClose,
    visible,
    className,
    title,
    children,
    size = MODAL_SIZE_MEDIUM,
    rounded,
    closable = true,
    ...rest
  } = props;

  return (
    <AntModal
      footer={null}
      onCancel={onClose}
      closable={false}
      maskClosable={false}
      visible={visible}
      wrapClassName={classNames("modal-wrapper", className, size, {
        "modal-no-title": !title,
        rounded,
        closable
      })}
      destroyOnClose
      {...rest}
    >
      <div className="modal-header">
        {closable && (
          <button type="button" className="modal-close" onClick={onClose}>
            <i className="ti-close" />
          </button>
        )}
        {title && <div className="modal-title">{title}</div>}
      </div>
      {children}
    </AntModal>
  );
};

const mapDispatchToProps = (dispatch, { name }) => ({
  onClose: () => dispatch(closeModalAction({ name }))
});

const mapStateToProps = (state, { name }) => {
  const modalState = state.modal ? state.modal[name] : null;

  return {
    visible: modalState ? !!modalState.visible : false
  };
};

Modal.useModal = AntModal.useModal;

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
