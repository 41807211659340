import isArray from "lodash/isArray";
import isString from "lodash/isString";
import isObject from "lodash/isObject";
import isEmpty from "lodash/isEmpty";
import uniqBy from "lodash/uniqBy";

export const antdSelectFilterOption = (input, option) => {
  let children = option.children || [];

  if (isObject(children)) {
    children = option.title || [];
  }

  if (isArray(children) && children.length) {
    return children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0;
  }

  return children && isString(children) ? children.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false;
};

export const customAntdSelectFilterOption =
  (searchFields = []) =>
  (input, option = {}) => {
    const { data = {} } = option.props || {};
    let result = false;

    if (!isEmpty(data)) {
      searchFields.forEach((field) => {
        const fieldValue = data[field];
        if (fieldValue && fieldValue.toLowerCase().indexOf(input.toLowerCase()) !== -1) {
          result = true;
        }
      });
    }

    return result;
  };

export const prepareInitialOptions = (initialValue, textAccessor, valueAccessor, options = []) => {
  let result = [...options];

  if (isEmpty(initialValue)) {
    return uniqBy(result, (item) => valueAccessor(item));
  }

  if (isArray(initialValue)) {
    const filteredInitialValue = initialValue
      .map((item) => ({ ...item, value: valueAccessor(item), text: textAccessor(item) }))
      .filter((item) => {
        return !options.filter((option) => valueAccessor(option) === valueAccessor(item))[0];
      });
    result = [...filteredInitialValue, ...options];
  } else {
    if (isObject(initialValue)) {
      const initialValueIsInOptions = result.filter((item) => valueAccessor(item) === valueAccessor(initialValue))[0];
      if (!initialValueIsInOptions) {
        result = [
          { ...initialValue, value: valueAccessor(initialValue), text: textAccessor(initialValue) },
          ...options
        ];
      }
    }
  }

  return uniqBy(result, (item) => valueAccessor(item));
};
