import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Form, { FORM_HORIZONTAL_LAYOUT } from "../../../../components/Form";
import useFormSubmit from "../../../../components/Form/useFormSubmit";
import { useDateFormat } from "../../../../utils/hooks/useDateFormat";
import { useAxiosRequest } from "../../../../utils/hooks/useAxios";
import { REPORT_FORM_TYPE } from "../index";
import { useLoggedUser } from "../../../../utils/hooks/useLoggedUser";
import Modal from "../../../../components/Modal";
import { openNotification } from "../../../../components/Notification";
import { PAGE_IOR_MY_REPORTS, PAGE_IOR_REPORTS } from "../../routes";
import { AddCorrectiveActionForm } from "../../CorrectiveActions/AddCorrectiveActionForm";
import { MODAL_IOR_CORRECTIVE_ACTION } from "../../CorrectiveActions";
import { ReportBasicData } from "./ReportBasicData";
import { getFormInitialValues, prepareFormData, showCollapsedBoxErrors } from "./methods";
import { ReportFirstRiskAnalysis } from "./ReportFirstRiskAnalysis";
import { ReportInvestigation } from "./ReportInvestigation";
import { ReportRootCause } from "./ReportRootCause";
import { ReportRiskMatrix } from "./ReportRiskMatrix";
import { ReportManagementSummary } from "./ReportManagementSummary";
import { ReportSecondRiskAnalysis } from "./ReportSecondRiskAnalysis";
import { DocumentForm } from "./ReportBasicData/DocumentForm";
import { MODAL_IOR_DOCUMENT_FORM } from "./ReportBasicData/DocumentsTable";
import { ReportButtons } from "./ReportButtons";
import ReportInvestigators from "./ReportInvestigators";
import { ReportCorrectiveActions } from "./ReportCorrectiveActions";
import { ReportInternalNotes } from "./ReportInternalNotes";

export const IOR_REPORT_PAGE = {
  REPORTS: "REPORTS",
  MY_REPORTS: "MY_REPORTS"
};

export const Report = ({ generalSettings = {}, data = {}, type, editOnlyBasicSection }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const history = useHistory();
  const loggedUser = useLoggedUser();
  const [success, setSuccess] = useState(false);
  const { formatLocale, formatLocaleUTC, format } = useDateFormat();
  const [collapseErrors, setCollapseErrors] = useState({
    basic_data: false,
    investigation: false,
    first_risk_analysis: false,
    second_risk_analysis: false,
    management_summary: false
  });

  const formWatch = Form.useWatch(["riskAnalysis", "riskLevel"], form);
  const [hasSecondRiskAnalysis, setHasSecondRiskAnalysis] = useState(
    data?.riskAnalysis?.riskLevel?.corrective_action_is_required
  );
  const [isRootCause, setIsRootCause] = useState(!!data.rootCause?.length);
  const [isRootCauseCollapsed, setIsRootCauseCollapsed] = useState(false);
  const [basicDataSaved, setBasicDataSaved] = useState(data);
  const [rootCauses, setRootCauses] = useState(null);
  const [hasRootCauseError, setHasRootCauseError] = useState(false);
  const { loading, submit, generalError } = useFormSubmit({
    route: basicDataSaved?.basic_data?.id ? `ior/report/${basicDataSaved?.basic_data?.id}` : "ior/report",
    method: basicDataSaved?.basic_data?.id ? "put" : "post",
    form,
    transformer: (values) => {
      return prepareFormData(values, formatLocaleUTC, format, rootCauses);
    },
    onSuccess: (response) => {
      openNotification({
        message: t("ior.new_report.notifications.report_data_saved"),
        type: "success",
        placement: "bottom"
      });
      if (setBasicDataSaved) {
        setBasicDataSaved(response.data);
      }
    },
    onFailed: (err, generalErrors, formErrors) => {
      openNotification({
        message: t("ior.new_report.notifications.report_data_not_saved"),
        type: "error",
        placement: "bottom"
      });
      showCollapsedBoxErrors(form, formErrors, collapseErrors, setCollapseErrors);
    }
  });

  useEffect(() => {
    if (formWatch) {
      setHasSecondRiskAnalysis(formWatch.corrective_action_is_required);
    }
  }, [formWatch]);

  const riskMatrix = useAxiosRequest({
    route: `ior/risk-matrices/active-matrix?${
      data?.basic_data?.id && `report_id=${data.basic_data.id}`
    }&with%5B%5D=image`
  });

  const onClose = () => {
    history.push({
      pathname: history?.location?.state?.page === "REPORTS" ? PAGE_IOR_REPORTS : PAGE_IOR_MY_REPORTS
    });
  };

  const updateRootCauses = (res) => setRootCauses(res);
  const submitForm = () => {
    if (isRootCause || !data?.basic_data?.id) {
      setHasRootCauseError(false);
      submit();
    } else {
      setIsRootCauseCollapsed(true);
      setHasRootCauseError(true);
      const errorFieldElement = document.getElementsByClassName("report-root-cause")[0];
      errorFieldElement && window.scrollTo({ top: errorFieldElement.offsetTop - 80, behavior: "smooth" });
    }
  };

  return (
    <div className="box">
      <Modal name={MODAL_IOR_DOCUMENT_FORM}>
        <DocumentForm />
      </Modal>
      <Modal name={MODAL_IOR_CORRECTIVE_ACTION}>
        <AddCorrectiveActionForm name={MODAL_IOR_CORRECTIVE_ACTION} reportForm={form} basicDataSaved={basicDataSaved} />
      </Modal>
      {!success && (
        <Form
          onSubmit={submitForm}
          form={form}
          errors={generalError}
          loading={loading}
          layout={FORM_HORIZONTAL_LAYOUT}
          initialValues={getFormInitialValues(data, formatLocale, loggedUser, type, t)}
        >
          <div className="flex flex-justify-between flex-items-center mb-30">
            <div className="h4 mb-10">
              {t(`ior.new_report.page_title${type === REPORT_FORM_TYPE.NEW ? "" : `_${type}`}`)}
            </div>
            {!editOnlyBasicSection && (
              <ReportButtons
                submitLoading={loading}
                onClose={onClose}
                submit={submitForm}
                type={type}
                data={data}
                setSuccess={setSuccess}
                form={form}
                collapseErrors={collapseErrors}
                setCollapseErrors={setCollapseErrors}
                canBeReopened={generalSettings?.can_be_reopened}
                basicDataSaved={basicDataSaved}
              />
            )}
          </div>
          <ReportBasicData
            form={form}
            data={data}
            type={type}
            hasError={collapseErrors.basic_data}
            setBasicDataSaved={setBasicDataSaved}
            basicDataSaved={basicDataSaved}
            editOnlyBasicSection={editOnlyBasicSection}
          />
          {!editOnlyBasicSection && (
            <>
              <ReportInvestigation
                form={form}
                data={data}
                type={type}
                hasError={collapseErrors.investigation}
                initialValues={data?.investigation}
                basicDataSaved={basicDataSaved}
              />

              {!data?.basic_data ||
              (data.basic_data.stage !== "archived" &&
                !(data.basic_data.stage === "closed" && data.basic_data.root_causes_old_format === 1)) ? (
                <ReportRootCause
                  form={form}
                  data={data}
                  type={type}
                  loading={loading}
                  setIsRootCause={setIsRootCause}
                  isCollapsed={isRootCauseCollapsed}
                  hasError={hasRootCauseError}
                  onUpdateRootCauses={updateRootCauses}
                  basicDataSaved={basicDataSaved}
                  isClosedReport={data.basic_data?.stage === "closed"}
                />
              ) : (
                <></>
              )}
              <ReportInvestigators type={type} basicDataSaved={basicDataSaved} />
              <ReportFirstRiskAnalysis
                form={form}
                data={data}
                hasSecondRiskAnalysis={hasSecondRiskAnalysis}
                type={type}
                hasError={collapseErrors.first_risk_analysis}
                basicDataSaved={basicDataSaved}
              />
              <ReportCorrectiveActions form={form} data={data} type={type} basicDataSaved={basicDataSaved} />
              {hasSecondRiskAnalysis && (
                <ReportSecondRiskAnalysis
                  form={form}
                  data={data}
                  type={type}
                  hasError={collapseErrors.second_risk_analysis}
                  basicDataSaved={basicDataSaved}
                />
              )}
              <ReportManagementSummary
                form={form}
                data={data}
                type={type}
                hasError={collapseErrors.management_summary}
                basicDataSaved={basicDataSaved}
              />
              <ReportRiskMatrix
                data={riskMatrix.data?.data?.data}
                loading={riskMatrix.loading}
                isSectionDisabled={!basicDataSaved}
              />
              <ReportInternalNotes data={data} basicDataSaved={basicDataSaved} />
            </>
          )}
          {!editOnlyBasicSection && (
            <div className="flex flex-justify-end flex-items-center mt-20 mb-10">
              <ReportButtons
                submitLoading={loading}
                onClose={onClose}
                submit={submitForm}
                type={type}
                data={data}
                setSuccess={setSuccess}
                form={form}
                collapseErrors={collapseErrors}
                setCollapseErrors={setCollapseErrors}
                canBeReopened={generalSettings?.data?.data?.data?.can_be_reopened}
                basicDataSaved={basicDataSaved}
              />
            </div>
          )}
        </Form>
      )}
    </div>
  );
};
