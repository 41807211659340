import forEach from "lodash/forEach";
import isArray from "lodash/isArray";
import isObject from "lodash/isObject";
import isEmpty from "lodash/isEmpty";
import moment from "../../../../moment";
import config from "../../../../config";
import { REPORT_FORM_TYPE } from "../index";
import axios from "../../../../axios";
import { openNotification } from "../../../../components/Notification";

export const getFormInitialValues = (values = {}, format, loggedUser, type, t) => {
  const { basic_data, investigation } = values;

  const event_date = basic_data?.event_date ? basic_data.event_date : "";
  const event_time = basic_data?.event_time ? basic_data.event_time : "00:00";

  const eventFullDate = event_date + " " + event_time;

  return {
    ...values,
    basic_data: {
      ...basic_data,
      reporter_id: basic_data?.reporter_id || t("ior.new_report.anonymous"),
      ...(type === REPORT_FORM_TYPE.NEW && { reporter_id: loggedUser.id, stage: "new" }),
      event_date: event_date
        ? format({ date: eventFullDate, dateFormat: config.defaultDateFormat, defaultReturn: null })
        : null,
      event_time: basic_data?.event_time ? moment(basic_data.event_time, "HH:mm") : undefined
    },
    investigation: {
      ...investigation,
      tags: values.tags?.map((item) => item.id) || undefined
    }
  };
};

export const prepareFormData = (values = {}, formatUTC, format, rootCauses) => {
  const { basic_data, investigation, first_risk_analysis, second_risk_analysis, management_summary } = values;

  const event_date = basic_data?.event_date ? moment(basic_data.event_date).format(config.defaultDateFormat) : "";
  const event_time = basic_data?.event_time ? moment(basic_data.event_time).format("HH:mm") : "";

  const eventFullDate = event_date + " " + event_time;

  let prepareDataForAnonymousReport = {};
  if (basic_data?.reporter_id === "Anonymous") {
    prepareDataForAnonymousReport = {
      reporter_id: null,
      reporter_name: "Anonymous",
      reporter_type: "anonymous"
    };
  }
  const formData = {
    basic_data: {
      ...basic_data,
      ...prepareDataForAnonymousReport,
      files: values?.basic_files?.map((item) => item.id) || [],
      event_date: basic_data?.event_date
        ? event_time
          ? formatUTC({ date: eventFullDate, dateFormat: config.defaultDateFormat, defaultReturn: null })
          : format({ date: eventFullDate, dateFormat: config.defaultDateFormat, defaultReturn: null })
        : null,
      event_time: basic_data?.event_time ? moment(basic_data.event_time).format("HH:mm") : null,
      report_date: basic_data?.report_date
        ? format({ date: basic_data?.report_date, dateFormat: config.defaultDateFormat, defaultReturn: null })
        : null
    },
    investigation: {
      ...investigation,
      deadline: format({
        date: investigation.deadline,
        dateFormat: config.defaultDateFormat,
        defaultReturn: null
      }),
      tag_ids: investigation.tags
    },
    root_causes: rootCauses ? rootCauses.map((item) => item.id) : [],
    first_risk_analysis: {
      ...first_risk_analysis,
      first_analysis_deadline: format({
        date: first_risk_analysis?.first_analysis_deadline,
        dateFormat: config.defaultDateFormat,
        defaultReturn: null
      }),
      first_analysis_files: values?.first_files?.map((item) => item.id)
    },
    management_summary,
    ...(values?.riskAnalysis?.riskLevel?.corrective_action_is_required && {
      second_risk_analysis: {
        ...second_risk_analysis
      }
    })
  };

  Object.keys(formData).forEach((item) => {
    if (
      Object.values(formData[item] || {}).every(
        (field) => field === null || field === undefined || ((isArray(field) || isObject(field)) && isEmpty(field))
      )
    ) {
      delete formData[item];
    }
  });

  return formData;
};

export const prepareFields = (fields = [], type) => {
  return fields.map((item) => {
    if (type === REPORT_FORM_TYPE.VIEW) {
      item.required = false;
      item.placeholder = "";
      item.fieldProps = { ...item.fieldProps, disabled: true };
    }
    return item;
  });
};

export const showCollapsedBoxErrors = (form, errors, collapseErrors, setCollapseErrors) => {
  const collapseItems = { ...collapseErrors };
  forEach(collapseItems, (value, key) => {
    collapseItems[key] = false;
  });
  let errorFieldElement;
  const formErrors = errors.map((err) => {
    const namesArray = err.name.split(".");
    collapseItems[namesArray[0]] = true;

    if (!errorFieldElement) {
      errorFieldElement = document.getElementById(namesArray.join("_"));
    }

    err.name = namesArray;
    return err;
  });

  if (formErrors?.length > 0) {
    setCollapseErrors(collapseItems);
    form.setFields(formErrors);
    errorFieldElement &&
      window.scrollTo({
        top: errorFieldElement.closest(".ant-collapse").getBoundingClientRect().top - 80,
        behavior: "smooth"
      });
  }
};

export const saveSectionData = (
  form,
  data,
  setLoading,
  formatLocaleUTC,
  format,
  successMessage,
  errorMessage,
  setBasicDataSaved = null
) => {
  const values = form.getFieldsValue([
    "basic_data",
    "basic_files",
    "investigation",
    "first_risk_analysis",
    "second_risk_analysis",
    "management_summary"
  ]);
  setLoading(true);
  if (data?.basic_data?.id) {
    axios.put("ior/report/" + data.basic_data.id, prepareFormData(values, formatLocaleUTC, format, [])).then(
      (response) => {
        setLoading(false);
        openNotification({
          message: successMessage,
          type: "success",
          placement: "bottom"
        });
        if (setBasicDataSaved) {
          setBasicDataSaved(response.data.data);
        }
      },
      () => {
        setLoading(false);
        openNotification({
          message: errorMessage,
          type: "error",
          placement: "bottom"
        });
      }
    );
  } else {
    axios.post("ior/report", prepareFormData(values, formatLocaleUTC, format, [])).then(
      (response) => {
        setLoading(false);
        openNotification({
          message: successMessage,
          type: "success",
          placement: "bottom"
        });
        if (setBasicDataSaved) {
          setBasicDataSaved(response.data.data);
        }
      },
      () => {
        setLoading(false);
        openNotification({
          message: errorMessage,
          type: "error",
          placement: "bottom"
        });
      }
    );
  }
};
