import { createAction, createReducer } from "@reduxjs/toolkit";

const initialState = {
  reload: null
};

export const updateReportData = createAction("report/update");
export const reloadReportData = createAction("report/reload");
export const cleanUpData = createAction("report/cleanUpData");

export default createReducer(initialState, (builder) => {
  builder
    .addCase(updateReportData, (state, { payload = {} }) => {
      if (payload.rewrite) {
        return { ...payload, rewrite: null, ...(state.keepOnReload || {}), keepOnReload: null };
      }
      return {
        ...state,
        ...payload,
        ...(state.keepOnReload ? { ...state.keepOnReload, keepOnReload: null } : {})
      };
    })
    .addCase(reloadReportData, (state, { payload }) => {
      return {
        ...state,
        keepOnReload: payload,
        reload: !state.reload
      };
    })
    .addCase(cleanUpData, () => {
      return {
        ...initialState
      };
    });
});
