import localeEn from "antd/es/date-picker/locale/en_US";
import localeFr from "antd/es/date-picker/locale/fr_FR";
import localeIt from "antd/es/date-picker/locale/it_IT";
import localeDe from "antd/es/date-picker/locale/de_DE";

export const getLocaleByLanguage = (language) => {
  let locale = localeEn;

  if (language === "French") {
    locale = localeFr;
  }

  if (language === "Italian") {
    locale = localeIt;
  }

  if (language === "German") {
    locale = localeDe;
  }

  return locale;
};
