import React, { useEffect, useState } from "react";
import { Spin, Upload } from "antd";
import { CloseCircleFilled } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import { Cropper } from "../Cropper";
import { closeModalAction, openModalAction } from "../Modal/reducer";
import Modal from "../Modal";
import axios from "../../axios";
import { openGeneralErrorNotification } from "../Notification";
import Image from "../Image";
import { usePrevious } from "../../utils/hooks/usePrevious";
import { validateImage } from "./config";

const InputUploadImage = (props) => {
  const {
    id,
    placeholder,
    cardImageStyle,
    purpose,
    entity,
    type,
    value = {},
    disabled = false,
    name = "cropper-image-modal",
    onChange = (e) => e,
    onUpload
  } = props;
  const [image, setImage] = useState({
    src: value.src,
    name: value.name
  });
  const [file, setFile] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const previousValue = usePrevious(value);
  useEffect(() => {
    if (value?.id && isEqual(previousValue, value)) {
      onChange([value.id]);
    }

    if (!isEqual(previousValue, value)) {
      if (value?.id) {
        onChange([value.id]);
      }

      if (isEmpty(value)) {
        onChange([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const beforeUpload = (file) => {
    if (validateImage(file, t)) {
      setFile(file);
      dispatch(openModalAction({ name }));
    }

    return false;
  };

  const onCrop = (res) => {
    setLoading(true);

    const fd = new FormData();
    purpose && fd.append("purpose", purpose);
    entity && fd.append("entity", entity);
    type && fd.append("type", type);
    fd.append("file", res.blob, file.name);

    axios.post("files", fd, { headers: { "Content-Type": "multipart/form-data" } }).then(
      (response) => {
        setImage({ src: res.base64, name: file.name });
        onChange([response.data.data.id]);
        onUpload && onUpload(response.data.data);
        setLoading(false);
        dispatch(closeModalAction({ name }));
      },
      (error) => {
        openGeneralErrorNotification({ error, defaultError: t("container.form_errors.internal_server_error") });
        setLoading(false);
        dispatch(closeModalAction({ name }));
      }
    );
  };

  const clearUpload = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onChange([]);
    setFile(null);
    setImage({});
  };

  return (
    <>
      <Modal name={name} className="custom-cropper-modal">
        <Cropper
          loadingUpload={loading}
          image={file}
          preloader={<Spin size="large" />}
          onCancel={() => dispatch(closeModalAction({ name }))}
          onCrop={onCrop}
        />
      </Modal>
      <div className="input-upload">
        <Upload
          name="avatar"
          className="image-uploader"
          listType="picture-card"
          showUploadList={false}
          beforeUpload={beforeUpload}
          disabled={disabled}
          accept="image/*"
          id={id}
        >
          {image.name ? (
            <>
              <div className="file-name">
                <i className="ti-file mr-5" />
                {image.name}
              </div>
              <div className="clear-upload" onClick={clearUpload}>
                <CloseCircleFilled />
              </div>
            </>
          ) : (
            <div className="placeholder">{placeholder}</div>
          )}
        </Upload>
      </div>
      {image.src && <Image className="mt-10" src={image.src} style={cardImageStyle} />}
    </>
  );
};

export default InputUploadImage;
