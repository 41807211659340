import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { renderDynamicallyFields } from "../../../../../components/Form/methods";
import { FORM_ITEM_TEXT_AREA } from "../../../../../components/Form/FormItems/FormItemTextArea";
import { prepareFields, saveSectionData } from "../methods";
import { ReportCollapse } from "../ReportCollapse";
import Tooltip from "../../../../../components/Tooltip";
import { useDateFormat } from "../../../../../utils/hooks/useDateFormat";
import Button from "../../../../../components/Button";

export const ReportManagementSummary = ({ form, type, hasError, data, basicDataSaved }) => {
  const { t } = useTranslation();
  const { formatLocaleUTC, format } = useDateFormat();
  const [loading, setLoading] = useState(false);

  const fields = [
    {
      type: FORM_ITEM_TEXT_AREA,
      max: 4000,
      name: "management_summary",
      customLabel: (
        <div className="flex flex-items-center">
          <span className="mr-10">{t("ior.new_report.management_summary")}</span>
          <Tooltip title={t("ior.new_report.management_summary_tooltip")}>
            <i className="ti-info-alt fs-14" />
          </Tooltip>
        </div>
      ),
      placeholder: "ior.new_report.management_summary_placeholder"
    }
  ];

  const saveData = () => {
    const usedData = data?.basic_data?.id ? data : basicDataSaved;
    saveSectionData(
      form,
      usedData,
      setLoading,
      formatLocaleUTC,
      format,
      t("ior.new_report.notifications.management_summary_saved"),
      t("ior.new_report.notifications.management_summary_not_saved")
    );
  };

  return (
    <div className={classNames("activity-view-content mb-20", { error: hasError })}>
      <ReportCollapse
        collapsed={false}
        isSectionDisabled={!basicDataSaved?.basic_data}
        header={{
          title: t("ior.new_report.section_management_summary"),
          tooltip: !data?.basic_data?.id
            ? t("ior.new_report.disabled_feedback")
            : t("ior.new_report.section_management_summary_popover")
        }}
      >
        {renderDynamicallyFields(prepareFields(fields, type))}
        <div className="flex flex-justify-end mt-15">
          <Button
            className="min-w-110 ant-btn-sm"
            type="primary"
            icon={<i className="ti-check" />}
            onClick={saveData}
            disabled={loading || type === "view"}
            loading={loading}
          >
            {t("container.general_words.save")}
          </Button>
        </div>
      </ReportCollapse>
    </div>
  );
};
