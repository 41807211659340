import { Spin } from "antd";
import React from "react";

const GlobalPreloader = ({ state }) => (
  <div className={`global-preloader ${state}`}>
    <Spin className="preloader" size="large" />
  </div>
);

export default GlobalPreloader;
