import React from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { NavLink } from "react-router-dom";
import Tooltip from "../Tooltip";

export const EditButton = (props) => {
  const { t } = useTranslation();
  const { disabled = false, onClick, className, stopPropagation, href, ...rest } = props;

  const onBtnClick = (e) => {
    e.preventDefault();

    if (stopPropagation) {
      e.stopPropagation();
    }

    if (disabled) {
      return;
    }

    onClick && onClick();
  };

  const onNavLinkClick = (e) => {
    if (disabled) {
      e.preventDefault();
    }
  };

  return (
    <Tooltip
      title={t("container.general_words.edit")}
      placement="top"
      className={classNames("text-primary", className)}
      {...rest}
    >
      {href ? (
        <NavLink to={href} disabled={disabled} onClick={onNavLinkClick}>
          <i className="ti-pencil-alt" />
        </NavLink>
      ) : (
        <a disabled={disabled} href="/" onClick={onBtnClick}>
          <i className="ti-pencil-alt" />
        </a>
      )}
    </Tooltip>
  );
};
