export const correctiveActionStatuses = [
  {
    name: "qam.general_words.qam_corrective_actions_stages.draft",
    value: "Draft",
    color: "#707c82"
  },
  {
    name: "qam.general_words.qam_corrective_actions_stages.assigned",
    value: "Assigned",
    color: "#ffbc34"
  },
  {
    name: "qam.general_words.qam_corrective_actions_stages.overdue",
    value: "Overdue",
    color: "#f62d51"
  },
  {
    name: "qam.general_words.qam_corrective_actions_stages.closed",
    value: "Closed",
    color: "#289441"
  }
];
