import React, { useEffect, useRef, useState } from "react";
import { Input as AntdInput, InputNumber as AntdInputNumber, Tooltip } from "antd";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";

const Input = (props) => {
  return <AntdInput size="large" autoComplete="off" {...props} />;
};

const PasswordInput = (props) => {
  const { t } = useTranslation();
  const timerRef = useRef(null);
  const [visible, setVisible] = useState(false);
  const [key, setKey] = useState(1);

  // Reset visibility and force re-render
  const resetVisibility = () => {
    setVisible(false);
    // Increment key to force re-rendering
    setKey((prev) => prev + 1);
  };

  // Set a timer to hide the password after 15 seconds
  const setHideTimer = () => {
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      resetVisibility();
    }, 15000);
  };

  useEffect(() => {
    return () => {
      clearTimeout(timerRef.current);
    };
  }, []);

  const toggleVisibility = () => {
    setVisible((v) => !v);
    setHideTimer();
  };

  const renderVisibilityIcon = (visible) => (
    <Tooltip
      title={
        visible
          ? t("container.unauthenticated_pages.hide_password")
          : t("container.unauthenticated_pages.show_password")
      }
    >
      <span onClick={toggleVisibility}>{visible ? <EyeInvisibleOutlined /> : <EyeOutlined />}</span>
    </Tooltip>
  );

  return (
    <div style={{ position: "relative", alignItems: "center" }}>
      <AntdInput.Password
        key={key}
        visibilityToggle={true}
        className="password-input"
        size="large"
        autoComplete="off"
        {...props}
        onBlur={resetVisibility}
        onClick={() => {
          setVisible((v) => !v);
          setHideTimer(); // Reset the timer each time the visibility is toggled
        }}
        type={visible ? "text" : "password"}
        iconRender={renderVisibilityIcon}
      />
    </div>
  );
};

const InputNumber = (props) => {
  return <AntdInputNumber size="large" autoComplete="off" {...props} />;
};

const SearchInput = (props) => {
  return (
    <AntdInput.Search
      allowClear
      addonAfter={false}
      size="large"
      autoComplete="off"
      {...props}
      className={classNames("search-field", props.className)}
    />
  );
};

export { Input, PasswordInput, SearchInput, InputNumber };
