import React from "react";
import { useTranslation } from "react-i18next";
import { fieldRules } from "../methods";
import Form from "../index";
import { PasswordInput } from "../../Input";

export const FORM_ITEM_PASSWORD = "form-password";

const FormItemPassword = (props) => {
  const {
    name,
    label,
    required,
    max,
    email,
    placeholder,
    rules = [],
    fieldProps = {},
    visible = true,
    ...rest
  } = props;
  const { t } = useTranslation();

  if (!visible) {
    return null;
  }

  return (
    <Form.Item
      name={name}
      label={t(label)}
      rules={fieldRules(
        {
          required: required
            ? {
                message: t("container.form_errors.required")
              }
            : false,
          max: max
            ? {
                value: max,
                message: t("container.form_errors.maxLength", { value: max })
              }
            : false,
          email: email
            ? {
                message: t("container.form_errors.email")
              }
            : false
        },
        rules
      )}
      {...rest}
    >
      <PasswordInput placeholder={t(placeholder)} {...fieldProps} />
    </Form.Item>
  );
};

export default FormItemPassword;
