import React from "react";
import { Empty } from "antd";
import classNames from "classnames";

const NoData = ({ children, className, description = "" }) => {
  return (
    <div className={classNames("no-data-to-show flex flex-justify-center flex-column flex-items-center", className)}>
      <Empty description={description} image={Empty.PRESENTED_IMAGE_SIMPLE} />
      {children}
    </div>
  );
};

export default NoData;
