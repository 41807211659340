import React from "react";
import { connect } from "react-redux";
import isEmpty from "lodash/isEmpty";
import { Redirect, Route } from "react-router-dom";
import { PAGE_LOGIN, PAGE_NOT_FOUND } from "../../Core/routes";
import { usePermission } from "../../../components/_auth/permissions/usePermission";

const AuthenticatedRoute = ({ children, user, gates, module, ...props }) => {
  const permission = usePermission();
  if (isEmpty(user)) {
    return <Redirect push to={PAGE_LOGIN} {...props} />;
  }

  if (module && !permission.hasModule(module)) {
    return <Redirect push to={PAGE_NOT_FOUND} {...props} />;
  }

  if (gates && !permission.canDo(gates)) {
    return <Redirect push to={PAGE_NOT_FOUND} {...props} />;
  }

  return <Route {...props}>{children}</Route>;
};

const mapStateToProps = (state) => ({
  user: state.auth.user
});

export default connect(mapStateToProps)(AuthenticatedRoute);
