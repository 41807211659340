import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import isEmpty from "lodash/isEmpty";
import Tooltip from "../../../components/Tooltip";
import { formatString } from "../../../utils/common";
import DeleteItemButton from "../../../components/SmartTable/TableFiltersActions/DeleteItemButton";
import Modal from "../../../components/Modal";
import { openModalAction } from "../../../components/Modal/reducer";
import BackBtn from "./BackBtn";
import AddRootCauseForm from "./AddRootCauseForm";

export const MODAL_QAM_CORRECTIVE_ACTION_ROOT_CAUSE = "MODAL_QAM_CORRECTIVE_ACTION_ROOT_CAUSE";

const CorrectiveActionRootCause = ({ data = {}, canManage, setIsRootCause }) => {
  const dispatch = useDispatch();
  const [localRootCauses, setLocalRootCauses] = useState(data.rootCause || []);
  const location = useLocation();
  const { t } = useTranslation();

  const openRootCauseModal = (e, editData = {}) => {
    e.preventDefault();
    dispatch(
      openModalAction({
        name: MODAL_QAM_CORRECTIVE_ACTION_ROOT_CAUSE,
        data: {
          ...editData,
          corrective_action_id: data.id,
          existentRootCauses: localRootCauses,
          onSuccess: (res) => {
            const updatedRootCause = isEmpty(editData)
              ? [...localRootCauses, res.data]
              : localRootCauses.map((rootCauseItem) => {
                  if (rootCauseItem.id === res.data.id) {
                    return res.data;
                  }
                  return rootCauseItem;
                });
            setLocalRootCauses(updatedRootCause);
            setIsRootCause(!!updatedRootCause.length);
          }
        }
      })
    );
  };

  return (
    <>
      <Modal name={MODAL_QAM_CORRECTIVE_ACTION_ROOT_CAUSE}>
        <AddRootCauseForm routeUrl={"qam/correctiveActionRootCause"} />
      </Modal>
      <div className="flex flex-justify-between flex-items-center mb-10">
        <div className="flex flex-items-center">
          <h6 className="font-weight-300 text-muted mb-0">{t("qam.corrective_action_view.root_cause")}</h6>
          {canManage && (
            <Tooltip title={t("qam.corrective_action_view.add_root_cause")}>
              <a href="#/" className="text-secondary ml-10" onClick={(e) => openRootCauseModal(e)}>
                <i className="ti-plus" />
              </a>
            </Tooltip>
          )}
        </div>
        {location.key && <BackBtn />}
      </div>
      {localRootCauses.length > 0 ? (
        <div className="root-causes-list">
          {localRootCauses.map((rootCauseItem) => (
            <div key={rootCauseItem.id} className="root-cause-item">
              <div className="left">
                <div className="text-muted fs-12">
                  {t("qam.corrective_action_view.root_cause_area_category_sub_category")}
                </div>
                <div className="fs-14">
                  {formatString(rootCauseItem.area.name, "-")} / {formatString(rootCauseItem.category.name, "-")} /{" "}
                  {formatString(rootCauseItem.subcategory.name, "-")}
                </div>
              </div>
              {canManage && (
                <div className="right">
                  <Tooltip title={t("container.general_words.edit")} className="text-primary">
                    <a href="/" onClick={(e) => openRootCauseModal(e, rootCauseItem)}>
                      <i className="ti-pencil-alt" />
                    </a>
                  </Tooltip>
                  <DeleteItemButton
                    onDelete={(res) => {
                      const updatedRootCause = localRootCauses.filter((item) => item.id !== res.data.data.id);
                      setLocalRootCauses(updatedRootCause);
                      setIsRootCause(!!updatedRootCause.length);
                    }}
                    route={`qam/correctiveActionRootCause/${rootCauseItem.id}`}
                    content={
                      <div>
                        <div>{t("container.confirm_modal.form_subtitle")}</div>
                      </div>
                    }
                  />
                </div>
              )}
            </div>
          ))}
        </div>
      ) : (
        <div className="fs-14">{t("qam.corrective_action_view.root_cause_no_data_message")}</div>
      )}
    </>
  );
};

export default CorrectiveActionRootCause;
