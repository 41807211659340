import React from "react";
import { reduceStringLength } from "../../../utils/common";
import UserAvatarOrLetter from "./UserAvatarOrLetter";

const UserTableCard = ({ name, image, title, desc }) => {
  return (
    <div className="user-table-card">
      <div className="container-avatar mr-15">
        <UserAvatarOrLetter name={name} imageSrc={image} />
      </div>
      <div className="right-part">
        {name && (
          <h6>
            {title ? `${title} ` : ""}
            {name}
          </h6>
        )}
        {desc && (
          <div className="text-muted" title={desc}>
            {reduceStringLength(desc)}
          </div>
        )}
      </div>
    </div>
  );
};

export default UserTableCard;
