import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import classNames from "classnames";
import SmartTable from "../../../../../components/SmartTable";
import TableService from "../../../../../components/SmartTable/TableService";
import { useDateFormat } from "../../../../../utils/hooks/useDateFormat";
import { ReportCollapse } from "../ReportCollapse";
import PageMetaTagTitle from "../../../../../components/PageMetaTagTitle";
import { usePermission } from "../../../../../components/_auth/permissions/usePermission";
import Button from "../../../../../components/Button";
import { openModalAction } from "../../../../../components/Modal/reducer";
import {
  PERMISSION_CREATE_IOR_REPORTS,
  PERMISSION_MANAGE_IOR_REPORTS
} from "../../../../../components/_auth/permissions/permissions";
import Modal from "../../../../../components/Modal";
import { InternalNoteForm, MODAL_IOR_INTERNAL_NOTE } from "./InternalNoteForm";
import { generateColumns, TABLE_IOR_INTERNAL_NOTES } from "./methods";

export const ReportInternalNotes = ({ data, basicDataSaved }) => {
  const { t } = useTranslation();
  const permission = usePermission();
  const dispatch = useDispatch();
  const { format } = useDateFormat();
  const tableKeyName = `${TABLE_IOR_INTERNAL_NOTES}`;
  const [tableService] = useState(
    new TableService({
      defaultOrderBy: [{ column: "created_at", type: "desc" }]
    })
  );
  return (
    <div className={classNames("activity-view-content mb-20")}>
      <ReportCollapse
        className="mb-15 mt-10"
        isSectionDisabled={!basicDataSaved?.basic_data?.report_id}
        collapsed={false}
        header={{
          title: t("ior.internal_notes.title"),
          tooltip:
            permission.canDo(PERMISSION_CREATE_IOR_REPORTS) || permission.canDo(PERMISSION_MANAGE_IOR_REPORTS)
              ? t("ior.internal_notes.title")
              : t("qam.general_words.block_can_not_be_changed_here")
        }}
      >
        <>
          <PageMetaTagTitle title="IOR internal notes" />
          <Modal name={MODAL_IOR_INTERNAL_NOTE}>
            <InternalNoteForm />
          </Modal>
          <SmartTable
            scroll={{ x: 1600 }}
            name={TABLE_IOR_INTERNAL_NOTES}
            tableService={tableService}
            columns={generateColumns(permission, dispatch, t, format, data)}
            route={
              basicDataSaved?.basic_data?.id
                ? "ior/internal-notes?filters[reportId]=" + basicDataSaved?.basic_data?.id
                : ""
            }
            tableLocalStorageKeyName={tableKeyName}
            rowSelection={false}
            externalActions={
              <Button
                type="primary"
                icon={<i className="ti-plus" />}
                className="action-btn"
                onClick={() => {
                  dispatch(
                    openModalAction({
                      name: MODAL_IOR_INTERNAL_NOTE,
                      data: {
                        report_id: basicDataSaved.basic_data?.id
                      }
                    })
                  );
                }}
              >
                {t("ior.internal_notes.new_internal_note")}
              </Button>
            }
          />
        </>
      </ReportCollapse>
    </div>
  );
};
