import authReducer from "../components/_auth/reducer";
import modalReducer from "../components/Modal/reducer";
import globalReducer from "../pages/App/reducer";
import tableReducer from "../components/SmartTable/reducer";
import stepsReducer from "../components/Steps/reducer";
import calendarReducer from "../pages/Qam/ActivitiesCalendar/reducer";
import notificationsReducer from "../components/Notification/reducer";
import activityViewReducer from "../pages/Qam/ActivityView/reducer";
import activityCriteriaFiltersReducer from "../pages/Qam/ActivityView/ActivityViewContent/ActivityCriteria/ActivityCriteriaFilters/reducer";
import trainingProgramReducer from "../pages/Trg/TrainingProgram/reducer";
import cataloguesReducer from "../pages/Qam/Catalogues/reducer";
import templatesReducer from "../pages/Dom/DocumentTemplates/reducer";
import templateReducer from "../pages/Dom/DocumentTemplate/reducer";
import admReportReducer from "../pages/Adm/ReportForm/reducer";
import reportReducer from "../pages/Ior/ReportPage/reducer";
import rootCausesReducer from "../pages/Qam/QamSettings/RootCauses/reducer";
import documentViewReducer from "../pages/Dom/DocumentView/reducer";
import documentCriteriaFiltersReducer from "../pages/Dom/DocumentView/DocumentViewContent/DocumentCriteria/DocumentCriteriaFilters/reducer";

const rootReducer = {
  auth: authReducer,
  modal: modalReducer,
  global: globalReducer,
  table: tableReducer,
  steps: stepsReducer,
  calendar: calendarReducer,
  notifications: notificationsReducer,
  activity: activityViewReducer,
  report: reportReducer,
  activityCriteriaFilters: activityCriteriaFiltersReducer,
  trainingProgram: trainingProgramReducer,
  catalogues: cataloguesReducer,
  templates: templatesReducer,
  template: templateReducer,
  admReport: admReportReducer,
  rootCauses: rootCausesReducer,
  document: documentViewReducer,
  documentCriteriaFilters: documentCriteriaFiltersReducer
};

export default rootReducer;
