import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Button, Tooltip } from "antd";
import { ClearOutlined } from "@ant-design/icons";
import { usePermission } from "../../../../components/_auth/permissions/usePermission";
import { useLoggedUser } from "../../../../utils/hooks/useLoggedUser";
import TableService from "../../../../components/SmartTable/TableService";
import SmartTable from "../../../../components/SmartTable";
import { PERMISSION_MANAGE_ORG_SETTINGS } from "../../../../components/_auth/permissions/permissions";
import { openModalAction } from "../../../../components/Modal/reducer";
import { useTableFullHeight } from "../../../../components/SmartTable/useTableFullHeight";
import { reloadTable } from "../../../../components/SmartTable/reducer";
import { generateColumns, TABLE_ORG_SETTINGS_FUNCTIONS_NAME } from "./methods";

export const MODAL_ORG_SETTINGS_FUNCTIONS = "MODAL_ORG_SETTINGS_FUNCTIONS";

const FunctionsTab = () => {
  const tableHeight = useTableFullHeight();
  const dispatch = useDispatch();
  const permission = usePermission();
  const loggedUser = useLoggedUser();
  const { t } = useTranslation();
  const tableKeyName = `${TABLE_ORG_SETTINGS_FUNCTIONS_NAME}_${loggedUser.id}`;
  const canManage = permission.canDo(PERMISSION_MANAGE_ORG_SETTINGS);
  const [tableService] = useState(
    new TableService({
      defaultOrderBy: [{ column: "name", type: "asc" }],
      ...JSON.parse(localStorage.getItem(tableKeyName) || "{}")
    })
  );

  const handleClearFilters = () => {
    dispatch(reloadTable({ name: TABLE_ORG_SETTINGS_FUNCTIONS_NAME, resetFilters: true }));
  };

  return (
    <>
      <SmartTable
        name={TABLE_ORG_SETTINGS_FUNCTIONS_NAME}
        tableService={tableService}
        columns={generateColumns(permission, t, dispatch)}
        route="job-titles"
        tableLocalStorageKeyName={tableKeyName}
        scroll={{ x: 700, y: canManage ? tableHeight - 70 : tableHeight - 20 }}
        rowSelection={false}
        newItemProps={{
          access: canManage,
          text: t("container.org_settings.functions_tab.new_function_button"),
          onClick: () => dispatch(openModalAction({ name: MODAL_ORG_SETTINGS_FUNCTIONS }))
        }}
        clearFiltersActions={
          <Tooltip placement="topLeft" title={t("container.general_words.clear_filters")}>
            <Button onClick={handleClearFilters} icon={<ClearOutlined />}>
              {t("container.general_words.clear_filters")}
            </Button>
          </Tooltip>
        }
      />
    </>
  );
};

export default FunctionsTab;
