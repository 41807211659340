import { Tooltip } from "antd";
import { NavLink } from "react-router-dom";
import React from "react";
import TableCell from "../../../../../components/SmartTable/TableCell";
import Tag from "../../../../../components/Tag";
import { PERMISSION_MANAGE_IOR_CORRECTIVE_ACTIONS } from "../../../../../components/_auth/permissions/permissions";
import { MODAL_IOR_CORRECTIVE_ACTION } from "../../../CorrectiveActions/AddCorrectiveActionForm";
import {
  FILTER_TYPE_DATE_RANGE,
  FILTER_TYPE_SELECT_MODE_1,
  FILTER_TYPE_STRING
} from "../../../../../components/SmartTable/methods";
import { getCorrectiveActionStatusLangKey } from "../../../../Qam/CorrectiveActions/methods";
import { correctiveActionStatuses } from "../../../../Qam/CorrectiveActions/config";
import { openModalAction } from "../../../../../components/Modal/reducer";
import { PAGE_IOR_CORRECTIVE_ACTION } from "../../../routes";

export const TABLE_IOR_CORRECTIVE_ACTIONS = "table-ior-corrective-actions";

export const generateColumns = (permission, dispatch, trans, format) => {
  return [
    {
      title: "qam.corrective_actions.status",
      dataIndex: "status",
      sorter: true,
      filterType: FILTER_TYPE_SELECT_MODE_1,
      filterNameKey: "status",
      filterProps: {
        staticOptions: [
          ...correctiveActionStatuses.map((item) => ({ ...item, text: trans(item.name) })),
          { value: "assigned_overdue", text: trans("qam.general_words.qam_corrective_actions_stages.assigned_overdue") }
        ]
      },
      width: 130,
      render: (text, row) => {
        const status = getCorrectiveActionStatusLangKey(row.status, true);
        return (
          <TableCell>
            <Tag style={{ backgroundColor: status.color, color: "#fff", borderColor: "transparent" }}>
              {trans(status.name)}
            </Tag>
          </TableCell>
        );
      }
    },
    {
      title: "container.general_words.title",
      dataIndex: "title",
      filterType: FILTER_TYPE_STRING,
      sorter: true,
      render: (text, row) => {
        return <TableCell minWidth={130}>{row.title}</TableCell>;
      }
    },
    {
      title: "qam.corrective_actions.due_date",
      dataIndex: "due_date",
      sorter: true,
      filterType: FILTER_TYPE_DATE_RANGE,
      render: (text, row) => <TableCell>{format({ date: row.due_date })}</TableCell>
    },
    {
      title: "qam.corrective_actions.responsibility",
      dataIndex: "responsible_name",
      filterType: FILTER_TYPE_STRING,
      sorter: true,
      render: (text, row) => {
        return (
          <TableCell>
            {row.responsible_name}
            {row.responsible_type === "external" && (
              <Tooltip title={trans("ior.corrective_actions.heart-broken-icon-popover")}>
                <i className="ti-heart-broken text-darken text-left-margin" />
              </Tooltip>
            )}
          </TableCell>
        );
      }
    },
    ...(permission.canDo(PERMISSION_MANAGE_IOR_CORRECTIVE_ACTIONS)
      ? [
          {
            title: trans("container.general_words.actions"),
            fixed: "right",
            width: 80,
            render: (row = {}) => (
              <div className="table-actions-column">
                {permission.canDo(PERMISSION_MANAGE_IOR_CORRECTIVE_ACTIONS) && (
                  <Tooltip title={trans("container.general_words.view")}>
                    <NavLink to={`${PAGE_IOR_CORRECTIVE_ACTION}/${row.id}`} className="text-secondary">
                      <i className="ti-eye" />
                    </NavLink>
                  </Tooltip>
                )}
                {true && (
                  <Tooltip title={trans("container.general_words.edit")} placement="top" className="text-primary">
                    <a
                      href="/"
                      disabled={row.status === correctiveActionStatuses[3].value}
                      onClick={(e) => {
                        e.preventDefault();
                        if (row.status === correctiveActionStatuses[3].value) return;

                        dispatch(
                          openModalAction({
                            name: MODAL_IOR_CORRECTIVE_ACTION,
                            data: {
                              ...row,
                              hideObservation: true
                            }
                          })
                        );
                      }}
                    >
                      <i className="ti-pencil-alt" />
                    </a>
                  </Tooltip>
                )}
              </div>
            )
          }
        ]
      : [])
  ];
};
