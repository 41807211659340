import forEach from "lodash/forEach";
import forOwn from "lodash/forOwn";
import isArray from "lodash/isArray";
import config from "../../config";
import FormItemDatePicker, { FORM_ITEM_DATE_PICKER } from "./FormItems/FormItemDatePicker";
import FormItemResourceSelect, { FORM_ITEM_RESOURCE_SELECT } from "./FormItems/FormItemResourceSelect";
import FormItemResourceSelectLazyLoading, {
  FORM_ITEM_RESOURCE_SELECT_LAZY_LOADING
} from "./FormItems/FormItemResourceSelectLazyLoading";
import FormItemText, { FORM_ITEM_TEXT } from "./FormItems/FormItemText";
import FormItemPassword, { FORM_ITEM_PASSWORD } from "./FormItems/FormItemPassword";
import FormItemUploadImage, { FORM_TYPE_UPLOAD_IMAGE } from "./FormItems/FormItemUploadImage";
import FormItemUploadFile, { FORM_TYPE_UPLOAD_FILE } from "./FormItems/FormItemUploadFile";
import FormItemTextArea, { FORM_ITEM_TEXT_AREA } from "./FormItems/FormItemTextArea";
import FormItemCheckbox, { FORM_ITEM_CHECKBOX } from "./FormItems/FormItemCheckbox";
import FormItemTinyMCE, { FORM_ITEM_TINY_MCE } from "./FormItems/FormItemTinyMCE";
import FormItemSignature, { FORM_ITEM_SIGNATURE } from "./FormItems/FormItemSignature";
import FormItemColorPicker, { FORM_ITEM_COLOR_PICKER } from "./FormItems/FormItemColorPicker";
import FormItemRadioGroup, { FORM_ITEM_RADIO_GROUP } from "./FormItems/FormItemRadioGroup";
import FormItemTimePicker, { FORM_ITEM_TIME_PICKER } from "./FormItems/FormItemTimePicker";
import FormItemNumber, { FORM_ITEM_NUMBER } from "./FormItems/FormItemNumber";

export const renderDynamicallyFields = (fields = []) => {
  return fields.map((item) => {
    switch (item.type) {
      case FORM_ITEM_DATE_PICKER:
        return <FormItemDatePicker key={item.name} {...item} />;
      case FORM_ITEM_RESOURCE_SELECT:
        return <FormItemResourceSelect key={item.name} {...item} />;
      case FORM_ITEM_RESOURCE_SELECT_LAZY_LOADING:
        return <FormItemResourceSelectLazyLoading key={item.name} {...item} />;
      case FORM_ITEM_TEXT:
        return <FormItemText key={item.name} {...item} />;
      case FORM_ITEM_PASSWORD:
        return <FormItemPassword key={item.name} {...item} />;
      case FORM_TYPE_UPLOAD_IMAGE:
        return <FormItemUploadImage key={item.name} {...item} />;
      case FORM_TYPE_UPLOAD_FILE:
        return <FormItemUploadFile key={item.name} {...item} />;
      case FORM_ITEM_TEXT_AREA:
        return <FormItemTextArea key={item.name} {...item} />;
      case FORM_ITEM_CHECKBOX:
        return <FormItemCheckbox key={item.name} {...item} />;
      case FORM_ITEM_TINY_MCE:
        return <FormItemTinyMCE key={item.name} {...item} />;
      case FORM_ITEM_SIGNATURE:
        return <FormItemSignature key={item.name} {...item} />;
      case FORM_ITEM_COLOR_PICKER:
        return <FormItemColorPicker key={item.name} {...item} />;
      case FORM_ITEM_RADIO_GROUP:
        return <FormItemRadioGroup key={item.name} {...item} />;
      case FORM_ITEM_TIME_PICKER:
        return <FormItemTimePicker key={item.name} {...item} />;
      case FORM_ITEM_NUMBER:
        return <FormItemNumber key={item.name} {...item} />;
      default:
        if (item.customField) {
          return <div key={item.name}>{item.customField}</div>;
        }
        return null;
    }
  });
};

/**
 * Create field rules
 */
export const fieldRules = ({ required, email, max, min }, additionalRules = []) => {
  const result = [];

  if (required) {
    result.push({
      required: true,
      message: required.message
    });
  }

  if (email) {
    result.push({
      type: "email",
      message: email.message
    });
  }

  if (max) {
    result.push({
      max: max.value,
      message: max.message
    });
  }

  if (min) {
    result.push({
      min: min.value,
      message: min.message
    });
  }

  return [...result, ...additionalRules];
};

/**
 * @param {object} errorResponse
 * @param {object} formValues
 * @return {object}
 * ex: {
 *   formErrors: [{ name: fieldName, value: fieldValue, errors: [error1, error2, ...] }],
 *   generalErrors: ['Error 1', ...]
 * }
 */
export const prepareErrors = (errorResponse, formValues = {}) => {
  const formErrors = [];
  let generalErrors = [];

  forOwn(errorResponse, (errItem, key) => {
    if (key !== "general") {
      formErrors.push({
        name: key,
        value: formValues[key] || undefined,
        errors: errItem
      });
    }

    if (key === "general") {
      generalErrors = [...generalErrors, ...errItem];
    }
  });

  if (formErrors.length === 0 && generalErrors.length === 0) {
    generalErrors = [config.generalError];
  }

  return {
    formErrors,
    generalErrors
  };
};

/**
 * @description This method is called when an ajax request from a form is failed
 * @param {object} errors
 * @param {boolean} showFieldError
 * @param {object} form
 */
export const catchFormErrors = (errors = {}, form, showFieldError = true) => {
  const { formErrors, generalErrors } = prepareErrors(errors, form.getFieldsValue());
  if (showFieldError) {
    form.setFields(formErrors);
    if (formErrors?.length > 0) {
      form.scrollToField(formErrors[0].name, { scrollMode: "if-needed", behavior: "smooth", block: "center" });
    }
  }
  return { formErrors, generalErrors };
};

/**
 * @description:
 * Create a form data object using form fields value collection
 * Exclude all undefined fields value
 */
export const prepareFormData = (data) => {
  const formData = new FormData();

  forEach(data, (value, key) => {
    if (value !== undefined) {
      if (isArray(value)) {
        forEach(value, (val) => {
          formData.append(`${key}[]`, val);
        });
      } else {
        formData.append(key, value);
      }
    }
  });

  return formData;
};

export const getAllErrorsAsArray = (errors = {}, t) => {
  let result = [];
  forEach(errors, (value) => {
    result = [...result, ...value];
  });
  return result.length ? result : [t("container.form_errors.internal_server_error")];
};

export const translateFormErrors = (form) => {
  const fieldsError = form.getFieldsError();
  const fieldsWithErrors = fieldsError.filter((field) => {
    return field?.errors?.length > 0;
  });
  const listOfFieldsWithErrors = fieldsWithErrors.map((item) => item.name[0]);

  if (listOfFieldsWithErrors?.length > 0) {
    form.validateFields(listOfFieldsWithErrors);
  }
};
