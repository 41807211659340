import moment from "../../moment";
import "moment-timezone";
import config from "../../config";
import { useLoggedUser } from "./useLoggedUser";

export const useDateFormat = () => {
  const user = useLoggedUser();
  const defaultFormat = user?.company?.date_format || config.defaultDateFormat;
  const timezone = user?.company?.timezone;

  const format = ({ date, dateFormat = defaultFormat, defaultReturn = null, formatIn }) => {
    if (!date) {
      return defaultReturn;
    }

    return moment(date, formatIn).format(dateFormat);
  };

  const formatLocale = ({ date, dateFormat = defaultFormat, defaultReturn = null, formatIn }) => {
    if (!date) {
      return defaultReturn;
    }

    const utcDate = moment.utc(date, formatIn).tz(timezone);

    if (dateFormat === null) {
      return utcDate;
    }

    return utcDate.format(dateFormat);
  };

  const formatLocaleUTC = ({ date, dateFormat = defaultFormat, defaultReturn = null }) => {
    if (!date) {
      return defaultReturn;
    }

    const utcDate = moment.tz(date, config.defaultDateLongFormat, timezone).utc();

    if (dateFormat === null) {
      return utcDate;
    }

    return utcDate.format(dateFormat);
  };

  return {
    format,
    formatLocale,
    formatLocaleUTC,
    appFormat: defaultFormat
  };
};
