import isEmpty from "lodash/isEmpty";
import config from "../../config";

export const DURATION_TO_BE_IDLE = process.env.REACT_APP_DURATION_TO_BE_IDLE_MILLISECONDS || 900000; // 900000ms = 15min

export const deleteStorageAuth = () => {
  localStorage.removeItem(config.localStorageKeys.auth);
};

export const saveStorageAuth = (data = {}) => {
  localStorage.setItem(config.localStorageKeys.auth, JSON.stringify(data));
};

export const getStorageAuth = () => {
  const authLocalStorageData = localStorage.getItem(config.localStorageKeys.auth);
  return authLocalStorageData ? JSON.parse(authLocalStorageData) : {};
};

export const checkIsLogged = (user) => !isEmpty(user);

export const checkProbablyLogged = (authStorage = {}) => {
  const tokenReceivedDate = authStorage.token_timestamp;
  if (tokenReceivedDate && authStorage.expires_in) {
    const currentDate = new Date();
    const difference = currentDate.getTime() - new Date(tokenReceivedDate).getTime();
    const resultInMinutes = Math.round(difference / 60000);
    if (resultInMinutes > Math.floor(authStorage.expires_in / 60)) {
      return false;
    }
  }

  if (authStorage.refresh_token && !authStorage.has2Fa) {
    return true;
  }

  if (authStorage.has2Fa) {
    return false;
  }

  return !!authStorage.refresh_token;
};

export const eventsIdleDetection = [
  "mousemove",
  "keydown",
  "wheel",
  "DOMMouseScroll",
  "mousewheel",
  "mousedown",
  "touchstart",
  "touchmove",
  "MSPointerDown",
  "MSPointerMove",
  "visibilitychange"
];
