import { useTranslation } from "react-i18next";
import React from "react";
import { useDispatch } from "react-redux";
import SmartTable from "../../../../../components/SmartTable";
import Button from "../../../../../components/Button";
import { openModalAction } from "../../../../../components/Modal/reducer";
import LoadingModal, { MODAL_EXPORT_LOADING } from "../../../../../components/SmartTable/ExportDropdown/LoadingModal";
import { REPORT_FORM_TYPE } from "../../index";
import Form from "../../../../../components/Form";
import { generateColumns } from "./methods";

export const IOR_REPORT_DOCUMENTS_TABLE_NAME = "ior-report";
export const MODAL_IOR_DOCUMENT_FORM = "modal-ior-document-form";

export const DocumentsTable = ({ attachments, setAttachments, type }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <>
      <LoadingModal name={MODAL_EXPORT_LOADING} message={t("qam.loading_pdf_report_message")} />
      <Form.Item label={t("crm.actual_company.documents")}>
        <SmartTable
          staticData={attachments}
          columns={generateColumns(dispatch, t, type, attachments, setAttachments)}
          rowSelection={false}
          name={IOR_REPORT_DOCUMENTS_TABLE_NAME}
          scroll={{ x: 400 }}
          pagination={false}
          emptyText={t("container.general_words.no_attachments_message")}
        />
        {type !== REPORT_FORM_TYPE.VIEW && (
          <div className="flex flex-justify-end">
            <Button
              onClick={() =>
                dispatch(openModalAction({ name: MODAL_IOR_DOCUMENT_FORM, data: { attachments, setAttachments } }))
              }
              className="min-w-150 mt-10"
              type="primary"
              icon={<i className="ti-plus" />}
            >
              {t("qam.activity_program.new_attachment.form_title")}
            </Button>
          </div>
        )}
      </Form.Item>
    </>
  );
};
