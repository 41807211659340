import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Button, Tooltip } from "antd";
import { ClearOutlined } from "@ant-design/icons";
import { usePermission } from "../../../components/_auth/permissions/usePermission";
import TableService from "../../../components/SmartTable/TableService";
import SmartTable from "../../../components/SmartTable";
import { PERMISSION_MANAGE_TRG } from "../../../components/_auth/permissions/permissions";
import Modal from "../../../components/Modal";
import { useLoggedUser } from "../../../utils/hooks/useLoggedUser";
import PageMetaTagTitle from "../../../components/PageMetaTagTitle";
import { useTableFullHeight } from "../../../components/SmartTable/useTableFullHeight";
import { openModalAction } from "../../../components/Modal/reducer";
import { EXPORT_TYPE_XLS } from "../../../components/SmartTable/ExportDropdown/ExportOverlay";
import { useDateFormat } from "../../../utils/hooks/useDateFormat";
import ResourceSelectLazyLoading from "../../../components/ResourceSelectLazyLoading";
import { reloadTable } from "../../../components/SmartTable/reducer";
import { generateColumns, TABLE_TRG_TRAININGS } from "./methods";
import ShareTrainingForm, { MODAL_SHARE_TRAINING } from "./ShareTrainingForm";

export const MODAL_TRG_TRAINING = "MODAL_TRG_TRAINING";

const Trainings = () => {
  const { format } = useDateFormat();
  const dispatch = useDispatch();
  const tableHeight = useTableFullHeight();
  const permission = usePermission();
  const loggedUser = useLoggedUser();
  const { t } = useTranslation();
  const canManage = permission.canDo(PERMISSION_MANAGE_TRG);
  const tableKeyName = `${TABLE_TRG_TRAININGS}_${loggedUser.id}`;
  const localStorageTableFilters = JSON.parse(localStorage.getItem(tableKeyName) || "{}");
  const [tableService] = useState(
    new TableService({
      filterKeyTransformer: {
        transformer: (item) => {
          return `filters[${item.nameKey}][]`;
        },
        keys: ["tags"]
      },
      filters: [{ column: "active", op: "eq", value: "1" }],
      defaultWith: ["validity"],
      defaultOrderBy: [{ column: "code", type: "asc" }],
      searchColumns: ["code", "name"],
      searchValue: "",
      ...localStorageTableFilters
    })
  );

  const externalFilterTags = localStorageTableFilters?.anotherFilters?.filter((item) => item.nameKey === "tags")[0];
  const externalFilterProvider = localStorageTableFilters?.anotherFilters?.filter(
    (item) => item.nameKey === "provider"
  )[0];

  const [shouldReset, setShouldReset] = useState();
  const handleClearFilters = () => {
    dispatch(reloadTable({ name: TABLE_TRG_TRAININGS, resetFilters: true }));
    setShouldReset(new Date().getTime());
  };

  return (
    <>
      <PageMetaTagTitle title="TRG trainings" />
      <Modal name={MODAL_SHARE_TRAINING} className="pre-large">
        <ShareTrainingForm />
      </Modal>
      <SmartTable
        externalFilters={
          <div className="flex">
            <ResourceSelectLazyLoading
              shouldReset={shouldReset}
              className="mr-10"
              showArrow
              showSearch
              allowClear
              initialValue={externalFilterTags}
              value={externalFilterTags?.value}
              placeholder={t("trg.trainings.filter_by_tags")}
              apiRoute="trg/tags?order_by_column%5B%5D=name&order_by_type%5B%5D=asc"
              onChange={(value, objValue) => {
                tableService.removeAnotherFilter("tags");
                if (value) {
                  tableService.addAnotherFilter({ nameKey: "tags", ...objValue });
                }
                dispatch(reloadTable({ name: TABLE_TRG_TRAININGS }));
              }}
            />
            <ResourceSelectLazyLoading
              shouldReset={shouldReset}
              className="mr-10"
              showArrow
              showSearch
              allowClear
              initialValue={externalFilterProvider}
              value={externalFilterProvider?.value}
              placeholder={t("trg.trainings.filter_by_provider")}
              apiRoute="trg/external-providers?order_by_column%5B%5D=name&order_by_type%5B%5D=asc"
              onChange={(value, objValue) => {
                tableService.removeAnotherFilter("provider");
                if (value) {
                  tableService.addAnotherFilter({ nameKey: "provider", ...objValue });
                }
                dispatch(reloadTable({ name: TABLE_TRG_TRAININGS }));
              }}
            />
          </div>
        }
        rowSelection={false}
        scroll={{ x: 1100, y: tableHeight }}
        name={TABLE_TRG_TRAININGS}
        tableService={tableService}
        columns={generateColumns(loggedUser.company?.is_linked, canManage, t, dispatch, format)}
        route="trg/trainings"
        tableLocalStorageKeyName={tableKeyName}
        exportProps={{
          params: { "with": ["validity", "area", "tags", "prerequisites", "branches", "linkedTraining"] },
          exportTypes: [EXPORT_TYPE_XLS],
          access: canManage,
          route: "trg/trainings/export",
          xlsColumns: [
            { column: "code", trans: t("trg.trainings.code") },
            { column: "area_id", trans: t("trg.new_training.area") },
            { column: "name", trans: t("trg.trainings.name") },
            { column: "version", trans: t("trg.new_training.version") },
            { column: "description", trans: t("trg.new_training.description") },
            { column: "training_label_id", trans: t("trg.training_program.training_label") },
            { column: "category", trans: t("trg.new_training.category") },
            { column: "duration", trans: t("trg.new_training.duration") },
            { column: "language", trans: t("trg.new_training.language") },
            { column: "tags", trans: t("trg.new_training.tags"), onlyLang: true },
            { column: "branch_names", trans: t("trg.new_training.range_of_validity"), onlyLang: true },
            { column: "prerequisites", trans: t("trg.new_training.prerequisites"), onlyLang: true },
            { column: "validity_id", trans: t("trg.trainings.validity_id") },
            { column: "notification_period", trans: t("trg.new_training.notification_period") },
            { column: "linked_training_id", trans: t("trg.new_training.link_this_training_to") },
            { column: "linked_training_notification", trans: t("trg.new_training.number_of_days_for_linked_training") },
            { column: "provider_name", trans: t("trg.new_training.provider") },
            { column: "internal_remarks", trans: t("trg.new_training.internal_remarks") },
            { column: "method_name", trans: t("qam.activity_program.method") },
            { column: "responsible_person", trans: t("trg.new_training.responsible_person") },
            { column: "alternative_languages", trans: t("trg.new_training.alternative_languages") },
            { column: "specific_date", trans: t("trg.new_training.specific_date") },
            { column: "active", trans: t("trg.trainings.active") },
            { column: "id", trans: t("trg.trainings.id") }
          ]
        }}
        newItemProps={{
          access: canManage,
          text: t("trg.trainings.new_training"),
          onClick: () => dispatch(openModalAction({ name: MODAL_TRG_TRAINING }))
        }}
        clearFiltersActions={
          <Tooltip placement="topLeft" title={t("container.general_words.clear_filters")}>
            <Button onClick={handleClearFilters} icon={<ClearOutlined />}>
              {t("container.general_words.clear_filters")}
            </Button>
          </Tooltip>
        }
      />
    </>
  );
};

export default Trainings;
