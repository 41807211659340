import { createAction, createReducer } from "@reduxjs/toolkit";

export const userAuthenticatedAction = createAction("user/authenticated");
export const userUnauthenticatedAction = createAction("user/unauthenticated");
export const userUpdatedAction = createAction("user/updated");

const initialState = {
  user: {}
};

export default createReducer(initialState, (builder) => {
  builder
    .addCase(userAuthenticatedAction, (state, action) => {
      state.user = { ...action.payload };
    })
    .addCase(userUpdatedAction, (state, action) => {
      state.user = { ...action.payload };
    })
    .addCase(userUnauthenticatedAction, (state) => {
      state.user = {};
    });
});
