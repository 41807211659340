export const typeOptions = [
  { value: "internal", text: "ior.new_report.responsible.internal" },
  { value: "external", text: "ior.new_report.responsible.external" }
];
export const correctiveActionStatuses = [
  {
    name: "ior.ior_corrective_actions_stages.draft",
    value: "Draft",
    color: "#707c82"
  },
  {
    name: "ior.ior_corrective_actions_stages.assigned",
    value: "Assigned",
    color: "#ffbc34"
  },
  {
    name: "ior.ior_corrective_actions_stages.overdue",
    value: "Overdue",
    color: "#f62d51"
  },
  {
    name: "ior.ior_corrective_actions_stages.closed",
    value: "Closed",
    color: "#289441"
  }
];
