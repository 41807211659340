import React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { UserOutlined } from "@ant-design/icons";
import { preventDefaultWithCallback } from "../../utils/common";
import useLogout from "../_auth/useLogout";
import Avatar from "../Avatar";
import { useImage } from "../Image/useImage";
import Preloader from "../Preloader";
import ProtectedComponent from "../_auth/ProtectedComponent";
import { PERMISSION_MANAGE_CORE_USERS } from "../_auth/permissions/permissions";
import { PAGE_EDIT_USER } from "../../pages/Core/routes";

const DropdownUserOverlay = ({ user, setVisible }) => {
  const { t } = useTranslation();
  const { submit, loading } = useLogout();
  const userFiles = user?.files || [];
  const avatar = useImage({ src: userFiles[0]?.sizes?.users?.md });

  return (
    <>
      <div className="duo-profile">
        <div className="duo-avatar">
          <Avatar
            shape={avatar.src ? "square" : "circle"}
            src={avatar.src}
            size={80}
            icon={<UserOutlined />}
            title={user.name}
          />
        </div>
        <div className="duo-right">
          <h5>{user.name}</h5>
          <p className="text-muted mb-5">{user.username}</p>
          <ProtectedComponent gates={PERMISSION_MANAGE_CORE_USERS}>
            <NavLink
              to={`${PAGE_EDIT_USER}/${user.id}`}
              className="ant-btn ant-btn-sm ant-btn-secondary"
              onClick={() => setVisible(false)}
            >
              {t("container.sidebar.my_profile")}
            </NavLink>
          </ProtectedComponent>
        </div>
      </div>
      <div className="duo-logout">
        <a href="/" onClick={preventDefaultWithCallback(submit)}>
          {loading ? <Preloader /> : <i className="ti-power-off" />}
          <span>{t("container.sidebar.logout")}</span>
        </a>
      </div>
    </>
  );
};

export default DropdownUserOverlay;
