import { createAction, createReducer } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  resetFilters: false,
  reload: undefined,
  filterLabel: undefined,
  filterArea: undefined,
  filterTag: undefined,
  filterFunction: undefined
};

export const updateTrainingProgramState = createAction("training-program/update");

export default createReducer(initialState, (builder) => {
  builder.addCase(updateTrainingProgramState, (state, { payload = {} }) => {
    return {
      ...state,
      ...payload
    };
  });
});
