import React from "react";
import { useTranslation } from "react-i18next";
import { fieldRules } from "../methods";
import Form from "../index";
import TextArea from "../../TextArea";

export const FORM_ITEM_TEXT_AREA = "form-textarea";

const FormItemTextArea = (props) => {
  const {
    name,
    label,
    required,
    max,
    placeholder,
    rules = [],
    fieldProps = {},
    autoSize,
    visible = true,
    customLabel,
    ...rest
  } = props;
  const { t } = useTranslation();

  if (!visible) return null;

  return (
    <Form.Item
      name={name}
      label={customLabel ? customLabel : t(label)}
      rules={fieldRules(
        {
          required: required
            ? {
                message: t("container.form_errors.required")
              }
            : false,
          max: max
            ? {
                value: max,
                message: t("container.form_errors.maxLength", { value: max })
              }
            : false
        },
        rules
      )}
      {...rest}
    >
      <TextArea placeholder={t(placeholder)} autoSize={autoSize} {...fieldProps} />
    </Form.Item>
  );
};

export default FormItemTextArea;
