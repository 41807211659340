import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { FORM_ITEM_TEXT } from "../../../../../components/Form/FormItems/FormItemText";
import { renderDynamicallyFields } from "../../../../../components/Form/methods";
import { FORM_ITEM_RESOURCE_SELECT_LAZY_LOADING } from "../../../../../components/Form/FormItems/FormItemResourceSelectLazyLoading";
import { FORM_ITEM_DATE_PICKER } from "../../../../../components/Form/FormItems/FormItemDatePicker";
import { FORM_ITEM_TIME_PICKER } from "../../../../../components/Form/FormItems/FormItemTimePicker";
import { FORM_ITEM_TEXT_AREA } from "../../../../../components/Form/FormItems/FormItemTextArea";
import { prepareFields, saveSectionData } from "../methods";
import moment from "../../../../../moment";
import { DATE_SHORT_FORMAT } from "../../../../../components/DatePicker";
import config from "../../../../../config";
import { REPORT_FORM_TYPE } from "../../index";
import Form from "../../../../../components/Form";
import { Input } from "../../../../../components/Input";
import { ReportCollapse } from "../ReportCollapse";
import Button from "../../../../../components/Button";
import { useDateFormat } from "../../../../../utils/hooks/useDateFormat";
import { DocumentsTable } from "./DocumentsTable";

const stageOptions = (data, t) => {
  return [
    ...(data.basic_data?.stage === "closed"
      ? [
          {
            text: t("ior.stages.closed"),
            value: "closed"
          }
        ]
      : []),
    {
      text: t("ior.stages.new"),
      value: "new"
    },
    { text: t("ior.stages.in_progress"), value: "in_progress" }
  ];
};

export const ReportBasicData = ({
  form,
  data,
  type,
  hasError,
  setBasicDataSaved,
  basicDataSaved,
  editOnlyBasicSection
}) => {
  const { t } = useTranslation();
  const [attachments, setAttachments] = useState(data.basicReportFiles || []);
  const { formatLocaleUTC, format } = useDateFormat();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    form.setFieldsValue({ basic_files: attachments });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attachments]);

  const saveData = () => {
    const usedData = data?.basic_data?.id ? data : basicDataSaved;
    saveSectionData(
      form,
      usedData,
      setLoading,
      formatLocaleUTC,
      format,
      t("ior.new_report.notifications.basic_data_saved"),
      t("ior.new_report.notifications.basic_data_not_saved"),
      setBasicDataSaved
    );
  };

  const fields = [
    ...(type !== REPORT_FORM_TYPE.NEW
      ? [
          {
            type: FORM_ITEM_TEXT,
            max: 200,
            name: ["basic_data", "report_id"],
            label: "qam.activities.id",
            placeholder: "qam.activities.id",
            fieldProps: {
              disabled: true
            }
          }
        ]
      : []),
    {
      type: FORM_ITEM_RESOURCE_SELECT_LAZY_LOADING,
      fieldProps: {
        requestOnMount: true,
        disabled: true,
        searchColumns: ["name"]
      },
      apiRoute:
        "users/all?field%5B%5D=user_is_in_organisation&order_by_column%5B%5D=name&order_by_type%5B%5D=asc&term%5B%5D=1",
      name: ["basic_data", "reporter_id"],
      label: "ior.new_report.reporter",
      placeholder: "ior.new_report.reporter"
    },
    {
      type: FORM_ITEM_RESOURCE_SELECT_LAZY_LOADING,
      fieldProps: {
        staticOptions: stageOptions(data, t),
        disabled: data.basic_data?.stage === "closed"
      },
      name: ["basic_data", "stage"],
      label: "container.tasks.new_task.stage",
      placeholder: "container.tasks.new_task.stage"
    },
    {
      type: FORM_ITEM_TEXT,
      max: 200,
      required: true,
      name: ["basic_data", "title"],
      label: "ior.new_report.title",
      placeholder: "ior.new_report.title"
    },
    {
      type: FORM_ITEM_DATE_PICKER,
      required: true,
      name: ["basic_data", "event_date"],
      label: "ior.new_report.event_date",
      placeholder: "ior.new_report.event_date",
      disabledDate: (current) => {
        if (!form.getFieldValue(["basic_data", "report_date"])) {
          return false;
        }
        return (
          moment(current, DATE_SHORT_FORMAT).startOf("day") > moment(form.getFieldValue(["basic_data", "report_date"]))
        );
      }
    },
    {
      type: FORM_ITEM_TIME_PICKER,
      name: ["basic_data", "event_time"],
      label: "ior.new_report.event_time",
      placeholder: "ior.new_report.event_time"
    },
    {
      type: FORM_ITEM_DATE_PICKER,
      required: true,
      name: ["basic_data", "report_date"],
      label: "ior.new_report.report_date",
      placeholder: "ior.new_report.report_date",
      disabledDate: (current) => {
        if (!form.getFieldValue(["basic_data", "event_date"])) {
          return false;
        }
        const fullDate = `${moment(form.getFieldValue(["basic_data", "event_date"])).format(
          config.defaultDateFormat
        )} ${moment(form.getFieldValue(["basic_data", "event_time"])).format("HH:mm")}`;

        return moment(current, DATE_SHORT_FORMAT).endOf("day") < moment(fullDate);
      }
    },
    {
      type: FORM_ITEM_TEXT,
      max: 200,
      name: ["basic_data", "flight_number"],
      label: "ior.new_report.flight_number",
      placeholder: "ior.new_report.flight_number"
    },
    {
      type: FORM_ITEM_TEXT_AREA,
      max: 4000,
      required: true,
      name: ["basic_data", "description"],
      label: "ior.new_report.description",
      placeholder: "ior.new_report.description_placeholder"
    },
    {
      type: FORM_ITEM_TEXT_AREA,
      max: 4000,
      name: ["basic_data", "immediate_action"],
      label: "ior.new_report.immediate_action",
      placeholder: "ior.new_report.immediate_action_plceholder"
    },
    {
      customField: <DocumentsTable attachments={attachments} setAttachments={setAttachments} type={type} />,
      name: "documents_first_table"
    },
    {
      customField: (
        <Form.Item name="basic_files" className="d-none">
          <Input />
        </Form.Item>
      ),
      name: ["basic_data", "basic_files"]
    }
  ];

  return (
    <div className={classNames("activity-view-content mb-20", { error: hasError })}>
      <ReportCollapse
        collapsed={true}
        header={{
          title: t("ior.new_report.section_basic_data"),
          tooltip: t("ior.new_report.section_basic_data_popover")
        }}
      >
        {renderDynamicallyFields(prepareFields(fields, type))}
        {!editOnlyBasicSection && (
          <div className="flex flex-justify-end mt-15">
            <Button
              className="min-w-110 ant-btn-sm"
              type="primary"
              icon={<i className="ti-check" />}
              onClick={saveData}
              disabled={loading || type === "view"}
              loading={loading}
            >
              {t("container.general_words.save")}
            </Button>
          </div>
        )}
      </ReportCollapse>
    </div>
  );
};
