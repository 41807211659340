import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Button from "../../../components/Button";

const BackBtn = ({ onClick, btnText, ...rest }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const onClickBack = () => {
    onClick ? onClick() : history.goBack();
  };

  return (
    <Button type="secondary" icon={<i className="ti-back-left" />} onClick={onClickBack} {...rest}>
      {btnText ?? t("container.general_words.back")}
    </Button>
  );
};

export default BackBtn;
