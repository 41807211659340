import { Modal } from "antd";
import i18n from "../../i18n";

export const openConfirm = (params = {}) => {
  const {
    title = i18n.t("container.confirm_modal.form_title"),
    content = i18n.t("container.confirm_modal.form_subtitle"),
    okText = i18n.t("container.general_words.proceed"),
    cancelText = i18n.t("container.confirm_modal.cancel"),
    closable = true,
    closeIcon = <i className="ti-close" />,
    ...rest
  } = params;
  return Modal.confirm({
    title,
    content,
    okText,
    cancelText,
    closable,
    closeIcon,
    ...rest
  });
};
