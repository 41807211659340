import React from "react";
import moment from "../../moment";
import config from "../../config";

const AppFooter = ({ company = {} }) => {
  return (
    <div className="app-footer">
      <div>
        {company.footer === "teampulse" ? `© ${moment().format("YYYY")} teampulse` : company.footer} / v{config.version}
      </div>
    </div>
  );
};

export default AppFooter;
