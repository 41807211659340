import { createAction, createReducer } from "@reduxjs/toolkit";

const initialState = {
  reload: undefined,
  searchValue: "",
  templates: [],
  filters: {
    "filters[template_active]": 1
  },
  pagination: {
    page: 1
  }
};

export const updateTemplatesState = createAction("templates/update");

export default createReducer(initialState, (builder) => {
  builder.addCase(updateTemplatesState, (state, { payload = {} }) => {
    return {
      ...state,
      ...payload
    };
  });
});
