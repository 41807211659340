import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useModalData } from "../../../../components/Modal/useModalData";
import Form, { FORM_HORIZONTAL_LAYOUT } from "../../../../components/Form";
import useFormSubmit from "../../../../components/Form/useFormSubmit";
import { closeModalAction } from "../../../../components/Modal/reducer";
import Button from "../../../../components/Button";
import { FORM_ITEM_TEXT } from "../../../../components/Form/FormItems/FormItemText";
import { renderDynamicallyFields } from "../../../../components/Form/methods";
import { FORM_ITEM_RESOURCE_SELECT_LAZY_LOADING } from "../../../../components/Form/FormItems/FormItemResourceSelectLazyLoading";
import { FORM_ITEM_CHECKBOX } from "../../../../components/Form/FormItems/FormItemCheckbox";
import { updateCataloguesState } from "../reducer";
import axios from "../../../../axios";

export const MODAL_NORM_FORM = "MODAL_NORM_FORM";

const NormForm = () => {
  const data = useModalData(MODAL_NORM_FORM);
  const isEdit = !!data?.id;
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { loading, submit, generalError } = useFormSubmit({
    form,
    route: isEdit ? `qam/norms/${data.id}` : "qam/norms",
    method: isEdit ? "put" : "post",
    onSuccess: () => {
      dispatch(closeModalAction({ name: MODAL_NORM_FORM }));
      dispatch(updateCataloguesState({ reload: true, keepFilters: isEdit }));
    }
  });

  useEffect(() => {
    if (data.id) {
      axios.get(`qam/norms/${data.id}`).then((res) => {
        form.setFieldsValue({
          classification_id: res.data.data.classification_id,
          standard: res.data.data.standard,
          version: res.data.data.version
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.id]);

  return (
    <Form
      onSubmit={submit}
      form={form}
      errors={generalError}
      loading={loading}
      initialValues={{ ...data, active: isEdit ? data?.active : true }}
      layout={FORM_HORIZONTAL_LAYOUT}
      header={
        <>
          <div className="h3">{t(isEdit ? "qam.edit_norm.form_title" : "qam.new_norm.form_title")}</div>
          <div className="mb-30 mt-10 h6 font-weight-300 text-muted">{t("container.general_words.form_subtitle")}</div>
        </>
      }
    >
      {renderDynamicallyFields([
        {
          required: true,
          type: FORM_ITEM_RESOURCE_SELECT_LAZY_LOADING,
          name: "classification_id",
          label: "qam.new_norm.classification",
          placeholder: "qam.new_norm.classification",
          apiRoute: "qam/classifications?field%5B%5D=active&op%5B%5D=eq&term%5B%5D=1",
          fieldProps: {
            requestOnMount: isEdit
          }
        },
        {
          required: true,
          type: FORM_ITEM_TEXT,
          name: "standard",
          label: "qam.new_norm.norm_standard",
          placeholder: "qam.new_norm.norm_standard"
        },
        {
          required: true,
          type: FORM_ITEM_TEXT,
          name: "version",
          label: "container.general_words.version",
          placeholder: "container.general_words.version"
        },
        {
          type: FORM_ITEM_CHECKBOX,
          visible: !isEdit,
          name: "active",
          label: "container.general_words.active"
        }
      ])}
      <div className="flex mt-25 flex-justify-end">
        <Button
          className="min-w-150"
          type="primary"
          htmlType="submit"
          disabled={loading}
          loading={loading}
          icon={<i className="ti-check" />}
        >
          {t("container.general_words.save")}
        </Button>
      </div>
    </Form>
  );
};

export default NormForm;
