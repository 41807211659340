import EnglishFlagImage from "../../images/flags/english.svg";
import ItalianFlagImage from "../../images/flags/italian.svg";
import GermanFlagImage from "../../images/flags/german.svg";
import FrenchFlagImage from "../../images/flags/french.svg";
import SpanishFlagImage from "../../images/flags/spanish.svg";

export const languages = [
  {
    name: "container.languages.english",
    icon: EnglishFlagImage,
    code: "English",
    title: "English"
  },
  {
    name: "container.languages.german",
    icon: GermanFlagImage,
    code: "German",
    title: "Deutsch"
  },
  {
    name: "container.languages.french",
    icon: FrenchFlagImage,
    code: "French",
    title: "Français"
  },
  {
    name: "container.languages.italian",
    icon: ItalianFlagImage,
    code: "Italian",
    title: "Italiano"
  },
  {
    name: "container.languages.spanish",
    icon: SpanishFlagImage,
    code: "Spanish",
    title: "Español"
  }
];

export const generateAvailableLanguage = (company = {}) => {
  const companyAvailableLanguages = [
    company.default_language,
    ...(company.additionalLanguages || []).map((item) => item.language)
  ].filter((item) => !!item);

  if (companyAvailableLanguages.length > 0) {
    return languages.filter((item) => companyAvailableLanguages.indexOf(item.code) > -1);
  }

  return languages;
};

export const convertFromShortLanguageCode = (lang) => {
  switch (lang) {
    case "en":
      return "English";
    case "de":
      return "German";
    case "fr":
      return "French";
    case "it":
      return "Italian";
    case "es":
      return "Spanish";
    default:
      return lang || "English";
  }
};

export const convertFromLongLanguageToCode = (language) => {
  switch (language) {
    case "English":
      return "en";
    case "German":
      return "de";
    case "French":
      return "fr";
    case "Italian":
      return "it";
    case "Spanish":
      return "es";
    default:
      return language || "en";
  }
};
