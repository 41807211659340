import React from "react";
import { useImage } from "../../../components/Image/useImage";
import Preloader from "../../../components/Preloader";
import Avatar from "../../../components/Avatar";

const UserAvatarOrLetter = ({ imageSrc, name, size = 40 }) => {
  const { src, loading } = useImage({ src: imageSrc });

  if (loading) {
    return (
      <div style={{ minHeight: size }} className="flex flex-justify-center flex-items-center">
        <Preloader size={size / 2} />
      </div>
    );
  }

  return <Avatar style={{ textTransform: "uppercase" }} size={size} title={name} src={src} />;
};

export default UserAvatarOrLetter;
