import axiosBase from "axios";
import debounce from "lodash/debounce";
import config from "./config";
import { getStorageAuth, deleteStorageAuth } from "./components/_auth/methods";
import AuthService from "./components/_auth/AuthService";

const bouncedRequest = debounce(() => {
  AuthService.trackTokenValidityTime();
}, 2000);

const axios = axiosBase.create({
  baseURL: config.apiBaseUrl,
  //withCredentials: true,
  headers: { Accept: "application/json" },
  transformRequest: [
    function addCredentialsToRequest(data, headers) {
      const storageAuth = getStorageAuth();
      const accessToken = storageAuth.access_token;
      const google2faToken = storageAuth.google2fa;

      if (accessToken) {
        headers.Authorization = `Bearer ${accessToken}`;
      }

      if (google2faToken) {
        headers.google2fa = google2faToken;
      }

      if (headers.Authorization && !headers.notTracking) {
        bouncedRequest();
      }

      return data;
    },
    ...axiosBase.defaults.transformRequest
  ],
  transformResponse: [
    function transformResponse(data) {
      return JSON.parse(data);
    }
  ]
});

axios.CancelToken = axiosBase.CancelToken;
axios.all = axiosBase.all;

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.status === 401) {
      if (getStorageAuth().access_token) {
        deleteStorageAuth();
        window.location.reload();
      }
    }
    return Promise.reject(error);
  }
);

export default axios;
