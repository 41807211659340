import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import Form, { FORM_HORIZONTAL_LAYOUT } from "../../../components/Form";
import useFormSubmit from "../../../components/Form/useFormSubmit";
import { reloadTable } from "../../../components/SmartTable/reducer";
import { closeModalAction } from "../../../components/Modal/reducer";
import { FORM_ITEM_TEXT } from "../../../components/Form/FormItems/FormItemText";
import Button from "../../../components/Button";
import { FORM_ITEM_CHECKBOX } from "../../../components/Form/FormItems/FormItemCheckbox";
import { renderDynamicallyFields } from "../../../components/Form/methods";
import { useModalData } from "../../../components/Modal/useModalData";
import { FORM_ITEM_RESOURCE_SELECT_LAZY_LOADING } from "../../../components/Form/FormItems/FormItemResourceSelectLazyLoading";
import { FORM_ITEM_TEXT_AREA } from "../../../components/Form/FormItems/FormItemTextArea";
import { FORM_ITEM_DATE_PICKER } from "../../../components/Form/FormItems/FormItemDatePicker";
import { FORM_TYPE_UPLOAD_FILE } from "../../../components/Form/FormItems/FormItemUploadFile";
import moment from "../../../moment";
import { DATE_SHORT_FORMAT } from "../../../components/DatePicker";
import { formTransformer } from "../../Core/Users/UserView/UserViewTrgQualifications/methods";
import { useDateFormat } from "../../../utils/hooks/useDateFormat";
import { TABLE_TRG_PLANNING } from "./methods";

export const MODAL_TRG_PLANNING_MARK = "MODAL_TRG_PLANNING_MARK";

const PlanningMarkForm = () => {
  const data = useModalData(MODAL_TRG_PLANNING_MARK);
  const { format } = useDateFormat();
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [instructorType, setInstructorType] = useState(data.instructor_id ? 1 : 0);
  const { loading, submit, generalError } = useFormSubmit({
    form,
    route: `trg/courses/complete/${data.id}`,
    transformer: (values) => formTransformer(values, format),
    onSuccess: () => {
      dispatch(reloadTable({ name: TABLE_TRG_PLANNING }));
      dispatch(closeModalAction({ name: MODAL_TRG_PLANNING_MARK }));
      data.onSuccess && data.onSuccess();
    }
  });

  return (
    <Form
      onSubmit={submit}
      form={form}
      errors={generalError}
      loading={loading}
      initialValues={{
        ...data,
        expiry_notification: true,
        completed_date: data.end_date,
        instructor_type: instructorType
      }}
      layout={FORM_HORIZONTAL_LAYOUT}
      header={
        <>
          <div className="h3">{t("trg.complete_course.form_title")}</div>
          <div className="mb-30 mt-10 h6 font-weight-300 text-muted">{t("trg.complete_course.form_subtitle")}</div>
        </>
      }
    >
      {renderDynamicallyFields([
        {
          type: FORM_ITEM_CHECKBOX,
          name: "expiry_notification",
          label: "trg.add_qualification_modal.expiry_notification"
        },
        {
          type: FORM_ITEM_TEXT_AREA,
          name: "internal_remarks",
          label: "trg.add_qualification_modal.internal_remarks",
          placeholder: "trg.add_qualification_modal.internal_remarks",
          autoSize: { minRows: 2, maxRows: 6 }
        },
        {
          type: FORM_ITEM_DATE_PICKER,
          required: true,
          dependencies: ["validity_start_date"],
          name: "completed_date",
          label: "trg.add_qualification_modal.completed_date",
          placeholder: "trg.add_qualification_modal.completed_date",
          disabledDate: (current) => {
            const activeTo = form.getFieldValue("validity_start_date");
            return activeTo
              ? moment(current, DATE_SHORT_FORMAT).startOf("day") > moment(activeTo, DATE_SHORT_FORMAT).startOf("day")
              : false;
          }
        },
        {
          type: FORM_ITEM_DATE_PICKER,
          required: true,
          name: "validity_start_date",
          dependencies: ["completed_date"],
          label: "trg.add_qualification_modal.validity_start_date",
          placeholder: "trg.add_qualification_modal.validity_start_date",
          disabledDate: (current) => {
            const activeFrom = form.getFieldValue("completed_date");
            return activeFrom
              ? moment(current, DATE_SHORT_FORMAT).startOf("day") < moment(activeFrom, DATE_SHORT_FORMAT).startOf("day")
              : false;
          }
        },
        {
          required: true,
          type: FORM_ITEM_RESOURCE_SELECT_LAZY_LOADING,
          name: "instructor_type",
          label: "trg.add_qualification_modal.instructor_type",
          placeholder: "trg.add_qualification_modal.instructor_type",
          fieldProps: {
            onChange: (value) => setInstructorType(value),
            staticOptions: [
              {
                transKey: "trg.add_qualification_modal.internal",
                value: 1
              },
              {
                transKey: "trg.add_qualification_modal.external",
                value: 0
              }
            ]
          }
        },
        {
          required: true,
          type: FORM_ITEM_RESOURCE_SELECT_LAZY_LOADING,
          name: "instructor_id",
          label: "trg.add_qualification_modal.instructor",
          placeholder: "trg.add_qualification_modal.instructor",
          visible: instructorType === 1,
          apiRoute: "users/all",
          initialValue: data.instructor,
          fieldProps: {
            searchColumns: ["name"],
            customParams: {
              "filters[validContract]": 1,
              order_by_column: ["name"],
              order_by_type: ["asc"],
              field: ["user_is_in_organisation"],
              op: ["eq"],
              term: [1]
            }
          }
        },
        {
          required: true,
          type: FORM_ITEM_RESOURCE_SELECT_LAZY_LOADING,
          name: "external_provider_id",
          label: "trg.add_qualification_modal.provider",
          placeholder: "trg.add_qualification_modal.provider",
          visible: instructorType === 0,
          apiRoute: "trg/external-providers",
          fieldProps: {
            customParams: {
              order_by_column: ["name"],
              order_by_type: ["asc"],
              field: ["active"],
              op: ["eq"],
              term: [1]
            }
          }
        },
        {
          required: true,
          type: FORM_ITEM_TEXT,
          max: 200,
          name: "instructor_name",
          label: "trg.add_qualification_modal.instructor",
          placeholder: "trg.add_qualification_modal.instructor",
          visible: instructorType === 0
        },
        {
          type: FORM_TYPE_UPLOAD_FILE,
          name: "evidence",
          label: "trg.add_qualification_modal.evidence",
          placeholder: "ior.new_report.new_attachment.attachment_button",
          fieldProps: {
            purpose: "qualificationHistory",
            entity: "qualificationHistory",
            type: "document"
          }
        },
        {
          type: FORM_TYPE_UPLOAD_FILE,
          name: "certificate",
          label: "trg.add_qualification_modal.certificate",
          placeholder: "ior.new_report.new_attachment.attachment_button",
          fieldProps: {
            purpose: "certificate",
            entity: "certificate",
            type: "document"
          }
        }
      ])}
      <div className="flex mt-25 flex-justify-end">
        <Button
          className="min-w-150"
          type="primary"
          htmlType="submit"
          disabled={loading}
          loading={loading}
          icon={<i className="ti-check" />}
        >
          {t("trg.complete_course.save_button")}
        </Button>
      </div>
    </Form>
  );
};

export default PlanningMarkForm;
