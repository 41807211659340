import React from "react";
import { Checkbox as AntdCheckbox } from "antd";

const Checkbox = (props) => {
  return <AntdCheckbox {...props} />;
};

Checkbox.Group = AntdCheckbox.Group;

export default Checkbox;
