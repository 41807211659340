import React from "react";
import { useTranslation } from "react-i18next";
import { fieldRules } from "../methods";
import Form from "../index";
import InputUploadFile from "../../Upload/InputUploadFile";

export const FORM_TYPE_UPLOAD_FILE = "form-upload-file";

const FormItemUploadFile = (props) => {
  const { name, label, required, placeholder, rules = [], fieldProps = {}, ...rest } = props;
  const { t } = useTranslation();

  return (
    <Form.Item
      name={name}
      label={t(label)}
      rules={fieldRules(
        {
          required: required
            ? {
                message: t("container.form_errors.required")
              }
            : false
        },
        rules
      )}
      {...rest}
    >
      <InputUploadFile placeholder={t(placeholder)} {...fieldProps} />
    </Form.Item>
  );
};

export default FormItemUploadFile;
