import React from "react";
import { useTranslation } from "react-i18next";
import Preloader from "../../../../../components/Preloader";
import { useImage } from "../../../../../components/Image/useImage";
import { ReportCollapse } from "../ReportCollapse";

export const ReportRiskMatrix = ({ data, loading, isSectionDisabled }) => {
  const { t } = useTranslation();
  const { src, imgLoading } = useImage({ src: data?.image.fullPath });

  return (
    <div className="activity-view-content mb-20">
      <ReportCollapse
        collapsed={false}
        isSectionDisabled={isSectionDisabled}
        header={{
          title: t("ior.new_report.section_risk_matrix"),
          tooltip: t("ior.new_report.section_risk_matrix_popover")
        }}
      >
        {(loading || imgLoading) && <Preloader className="text-center mt-50" size={30} />}
        {src && (
          <div className="flex flex-justify-center">
            <img src={src} alt="" style={{ maxWidth: "100%", height: "auto" }} />
          </div>
        )}
      </ReportCollapse>
    </div>
  );
};
