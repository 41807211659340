import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import classNames from "classnames";
import NoData from "../NoData";
import Preloader from "../Preloader";
import Icon from "../Icon";
import { PAGE_NOTIFICATIONS } from "../../pages/Core/routes";

const DropdownOverlayNotifications = (props) => {
  const { t } = useTranslation();
  const { data = [], loading, markAsSeen, notificationIdLoading, setVisible } = props;

  const onNotificationClick = (notification) => () => {
    markAsSeen(notification);
  };

  return (
    <div className="notification">
      <header>
        <span className="notification-title">{t("container.notifications_page.header_bell.notifications")}</span>
      </header>
      <div className="content">
        {loading && <Preloader size={30} />}
        <Scrollbars
          autoHide
          style={{ height: 250 }}
          autoHideTimeout={1000}
          hideTracksWhenNotNeeded={true}
          renderTrackVertical={(props) => <div className="scrollbar-track-vertical right" {...props} />}
        >
          {data.length === 0 && !loading && (
            <NoData description={t("container.notifications_page.empty_notifications")} />
          )}
          {data.length > 0 && (
            <ul>
              {data.map((notification) => {
                const staffNumber = notification?.employee?.userDetail?.staff_number;
                return (
                  <li key={notification.id}>
                    <div
                      onClick={onNotificationClick(notification)}
                      className={classNames("notification-dropdown-item", {
                        disabled: notificationIdLoading === notification.id
                      })}
                    >
                      <Icon circle className="danger mr-10">
                        <i className="ti-medall-alt" />
                      </Icon>
                      <div className="notification-dropdown-item-content">
                        <h6 className="mt-5 mb-5">{t(notification.title)}</h6>
                        {notification.employee && (
                          <div className="text-inline-small">
                            {`${notification.employee.last_name} ${notification.employee.first_name}${
                              staffNumber ? ` [${staffNumber}]` : ""
                            }`}
                          </div>
                        )}
                        {notification.report && <div className="text-inline-small">{notification.report.name}</div>}
                        {notification.date && <div className="text-inline-small">{notification.date}</div>}
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          )}
        </Scrollbars>
      </div>
      <footer>
        <NavLink to={PAGE_NOTIFICATIONS} className="link-text-icon" onClick={() => setVisible(false)}>
          {t("container.notifications_page.header_bell.check_all_notifications")}
          <i className="ti-angle-right" />
        </NavLink>
      </footer>
    </div>
  );
};

export default DropdownOverlayNotifications;
