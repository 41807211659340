export const PAGE_DOM_DASHBOARD = "/dom/dashboard";
export const PAGE_DOM_DOCUMENTS = "/dom/documents";
export const PAGE_DOM_ENTITIES = "/dom/entities";
export const PAGE_DOM_SETTINGS = "/dom-settings";

export const PAGE_DOM_DOCUMENT = "/dom/document";
export const PAGE_DOM_HISTORY_DOCUMENT = "/dom/history-document";
export const PAGE_DOM_DOCUMENTS_MASS_DUPLICATION = "/dom/documents-mass-duplication";

export const PAGE_DOM_RESPONSIBLE_PERSONS = "/dom/responsible-persons";
export const PAGE_DOM_NEW_RESPONSIBLE_PERSON = "/dom/responsible-persons/new";
export const PAGE_DOM_EDIT_RESPONSIBLE_PERSON = "/dom/responsible-persons/edit";

export const PAGE_DOM_APPROVERS = "/dom/approvers";
export const PAGE_DOM_NEW_APPROVER = "/dom/approvers/new";
export const PAGE_DOM_EDIT_APPROVER = "/dom/approvers/edit";

export const PAGE_DOM_TEMPLATES = "/dom/templates";
export const PAGE_DOM_VIEW_TEMPLATE_HISTORY = "/dom/history-template";
export const PAGE_DOM_EDIT_HISTORY_TEMPLATE = "/dom/history-template/edit";

export const PAGE_DOM_VIEW_TASK = "/dom/task";
export const PAGE_DOM_VIEW_KEYWORD_TASK = "/dom/keyword-task";
