import { useEffect, useRef } from "react";

export const usePrevious = (value, initializeValueOnMount = true) => {
  const ref = useRef(initializeValueOnMount ? value : undefined);

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};
