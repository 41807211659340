import { useState } from "react";
import axios from "../../axios";
import { catchFormErrors } from "./methods";

const useFormSubmit = (params) => {
  const { form, route, onSuccess, onFailed, values, method, transformer = (v) => v, showFieldError = true } = params;
  const [requestState, setRequestState] = useState({
    loading: false,
    generalError: [],
    formErrors: []
  });

  const sendData = (dataToSend) => {
    setRequestState({ ...requestState, loading: true, generalError: [], formErrors: [] });
    axios[method || "post"](route, dataToSend).then(
      (res) => {
        setRequestState({ ...requestState, loading: false, generalError: [] });
        onSuccess && onSuccess(res.data);
      },
      (err) => {
        const { generalErrors, formErrors } = catchFormErrors(err.response?.data?.errors, form, showFieldError);
        setRequestState({
          ...requestState,
          loading: false,
          generalError: generalErrors,
          formErrors: formErrors
        });
        onFailed && onFailed(err, generalErrors, formErrors);
      }
    );
  };

  const submit = () => {
    const dataToSend = transformer({ ...form.getFieldsValue(), ...values });
    sendData(dataToSend);
  };

  const submitCustomValues = (dataToSend) => {
    sendData(dataToSend);
  };

  return {
    loading: requestState.loading,
    generalError: requestState.generalError,
    formErrors: requestState.formErrors,
    axios,
    submit,
    submitCustomValues
  };
};

export default useFormSubmit;
