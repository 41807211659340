import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import Collapse from "../../../../components/Collapse";
import Tooltip from "../../../../components/Tooltip";
import { usePrevious } from "../../../../utils/hooks/usePrevious";
import { openNotification } from "../../../../components/Notification";

export const ReportCollapse = (props) => {
  const { className, completed, isSectionDisabled, header = {}, collapsed = true, children } = props;
  const { title, tooltip, content } = header;
  const [collapse, setCollapse] = useState(collapsed);
  const collapsedPrev = usePrevious(collapsed);
  const { t } = useTranslation();

  useEffect(() => {
    if (collapsed !== collapsedPrev && collapsed !== collapse) {
      setCollapse(collapsed);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collapsed]);

  return (
    <Collapse
      onChange={() => {
        if (isSectionDisabled) {
          openNotification({
            message: t("ior.new_report.basic_data_not_completed"),
            type: "error",
            placement: "bottom"
          });
          return;
        }
        return setCollapse(!collapse);
      }}
      activeKey={collapse ? ["1"] : []}
      className={classNames("activity-collapse-item", className, { completed })}
    >
      <Collapse.Panel
        forceRender
        header={
          content || (
            <div className="activity-collapse-header">
              <div className="status">
                <i className="ti-check" />
              </div>
              <div className="title">{title}</div>
              <div className="info">
                <Tooltip title={tooltip}>
                  <i className="ti-info-alt" />
                </Tooltip>
              </div>
            </div>
          )
        }
        key="1"
      >
        {children}
      </Collapse.Panel>
    </Collapse>
  );
};
