import React from "react";
import { Radio as AntdRadio } from "antd";

const Radio = (props) => {
  return <AntdRadio {...props} />;
};

Radio.Group = AntdRadio.Group;

export default Radio;
