import forEach from "lodash/forEach";
import { Link } from "react-router-dom";
import LogoImg from "../../images/logo.png";
import SmallLogoImg from "../../images/small-logo.png";
import { PAGE_DASHBOARD } from "../../pages/Core/routes";
import {
  NAV_ADM,
  NAV_AUDIT_LOGS,
  NAV_CORE_DASHBOARD,
  NAV_DOM,
  NAV_IOR,
  NAV_NOTIFICATIONS,
  NAV_ORG,
  NAV_QAM,
  NAV_RSM,
  NAV_SETTINGS,
  NAV_TRG,
  NAV_USERS_GROUPS
} from "../_auth/config";
import {
  MODULE_ADM,
  MODULE_CORE,
  MODULE_DOM,
  MODULE_IOR,
  MODULE_ORGANIZATION,
  MODULE_QAM,
  MODULE_RSM,
  MODULE_TRG
} from "../_auth/permissions/permissions";

export const composeNavigationMenuItems = (permission) => {
  const result = [NAV_CORE_DASHBOARD];

  if (permission.hasModule(MODULE_ORGANIZATION) && permission.canDo(NAV_ORG.gates)) {
    result.push(NAV_ORG);
  }

  if (permission.hasModule(MODULE_RSM) && permission.canDo(NAV_RSM.gates)) {
    result.push(NAV_RSM);
  }

  if (permission.hasModule(MODULE_IOR) && permission.canDo(NAV_IOR.gates)) {
    result.push(NAV_IOR);
  }

  if (permission.hasModule(MODULE_DOM) && permission.canDo(NAV_DOM.gates)) {
    result.push(NAV_DOM);
  }

  if (permission.hasModule(MODULE_QAM) && permission.canDo(NAV_QAM.gates)) {
    result.push(NAV_QAM);
  }

  if (permission.hasModule(MODULE_TRG) && permission.canDo(NAV_TRG.gates)) {
    result.push(NAV_TRG);
  }

  if (permission.hasModule(MODULE_ADM) && permission.canDo(NAV_ADM.gates)) {
    result.push(NAV_ADM);
  }

  if (permission.hasModule(MODULE_CORE) && permission.canDo(NAV_USERS_GROUPS.gates)) {
    result.push(NAV_USERS_GROUPS);
  }

  if (permission.hasModule(MODULE_CORE) && permission.canDo(NAV_SETTINGS.gates)) {
    result.push(NAV_SETTINGS);
  }

  if (permission.hasModule(MODULE_CORE) && permission.canDo(NAV_NOTIFICATIONS.gates)) {
    result.push(NAV_NOTIFICATIONS);
  }

  if (permission.hasModule(MODULE_CORE) && permission.canDo(NAV_AUDIT_LOGS.gates)) {
    result.push(NAV_AUDIT_LOGS);
  }

  return result;
};

const isActiveMenuLink = (pathname, menuItem) => {
  if (pathname === menuItem.path) {
    return true;
  }

  let result = false;

  if (menuItem.activeRoutes) {
    menuItem.activeRoutes.forEach((item) => {
      if (pathname.indexOf(item) > -1) {
        result = true;
      }
    });
  }

  return result;
};

export const getSelectedMenuKey = (location, options) => {
  let selectedKey;
  let parentSelectedKey;

  forEach(options, (item) => {
    if (item.submenu) {
      forEach(item.submenu, (submenuItem) => {
        if (isActiveMenuLink(location.pathname, submenuItem)) {
          selectedKey = submenuItem.key;
          parentSelectedKey = item.key;
        }
      });
    } else {
      if (isActiveMenuLink(location.pathname, item)) {
        selectedKey = item.key;
      }
    }
  });

  if (location.pathname === PAGE_DASHBOARD) {
    selectedKey = "dashboard";
  }

  return parentSelectedKey ? [parentSelectedKey, selectedKey] : selectedKey;
};

export const getCompanyLogo = (loggedUser) => {
  let logo = LogoImg;
  let smallLogo = SmallLogoImg;
  let fullLogo = LogoImg;

  if (loggedUser?.company?.files?.length > 0) {
    loggedUser.company.files.forEach((file) => {
      if (file.purpose === "logo") {
        logo = file.sizes.headerLogo.size200w;
        fullLogo = file.fullPath;
      }

      if (file.purpose === "thumbnail") {
        smallLogo = file.sizes.thumbnail.size35w;
      }
    });
  }

  return {
    logo,
    smallLogo,
    fullLogo
  };
};

export const generateNavigationMenuItems = (options = [], permission, t) => {
  return options.map((menuItem) => {
    if (permission.canDo(menuItem.gates)) {
      if (!menuItem.submenu) {
        return {
          label: (
            <>
              {t(menuItem.name)}
              {menuItem.path && <Link to={menuItem.path} />}
            </>
          ),
          key: menuItem.key,
          icon: <i className={menuItem.iconClassName} />
        };
      } else {
        return {
          label: (
            <>
              {t(menuItem.name)}
              {menuItem.path && <Link to={menuItem.path} />}
            </>
          ),
          children: menuItem.submenu.map((menuSubItem) => {
            if (permission.canDo(menuSubItem.gates)) {
              return {
                label: (
                  <>
                    {t(menuSubItem.name)}
                    {menuSubItem.path && <Link to={menuSubItem.path} />}
                  </>
                ),
                key: menuSubItem.key,
                icon: <i className={menuSubItem.iconClassName} />
              };
            }
            return null;
          }),
          key: menuItem.key,
          title: t(menuItem.name),
          icon: <i className={menuItem.iconClassName} />
        };
      }
    }
    return null;
  });
};
