import React from "react";
import TableCell from "../../../../../components/SmartTable/TableCell";
import { formatString } from "../../../../../utils/common";
import DownloadFile from "../../../../../components/DownloadFile";
import { FILTER_TYPE_SELECT_MODE_2 } from "../../../../../components/SmartTable/methods";
import Tooltip from "../../../../../components/Tooltip";
import DeleteItemButton from "../../../../../components/SmartTable/TableFiltersActions/DeleteItemButton";
import { languages } from "../../../../../components/DropdownLanguages/methods";
import { openModalAction } from "../../../../../components/Modal/reducer";
import { FORM_ITEM_RESOURCE_SELECT_LAZY_LOADING } from "../../../../../components/Form/FormItems/FormItemResourceSelectLazyLoading";
import { FORM_ITEM_DATE_PICKER } from "../../../../../components/Form/FormItems/FormItemDatePicker";
import moment from "../../../../../moment";
import { DATE_SHORT_FORMAT } from "../../../../../components/DatePicker";
import { FORM_ITEM_RESOURCE_SELECT } from "../../../../../components/Form/FormItems/FormItemResourceSelect";
import { FORM_ITEM_TEXT } from "../../../../../components/Form/FormItems/FormItemText";
import { FORM_TYPE_UPLOAD_FILE } from "../../../../../components/Form/FormItems/FormItemUploadFile";
import config from "../../../../../config";
import { MODAL_USER_VIEW_QUALIFICATION } from "./index";

export const TABLE_USER_VIEW_TRG_QUALIFICATIONS_NAME = "table-user-view-trg-qualifications";

export const generateColumns = (formatDate, trans, userId, language, dispatch) => {
  return [
    {
      title: "container.user_profile.training_history.training",
      dataIndex: "qualification_id",
      filterType: FILTER_TYPE_SELECT_MODE_2,
      filterProps: {
        apiRoute: `trg/qualifications?field%5B%5D=id&op%5B%5D=eq&term%5B%5D=${userId}&with%5B%5D=qualifications.training`,
        transformer: (res = []) => {
          return (
            res[0]?.qualifications?.map((qualification) => ({
              ...qualification,
              value: qualification?.id,
              text: `${qualification?.training?.name} (${qualification?.training?.code})`
            })) || []
          );
        }
      },
      render: (text, row) => (
        <TableCell minWidth={130} maxWidth={160}>
          {formatString(row.qualification?.training?.name, "-")}
        </TableCell>
      )
    },
    {
      title: "container.user_profile.training_history.completed_date",
      dataIndex: "completed_date",
      sorter: true,
      render: (text, row) => (
        <TableCell width={language === languages[2].code ? 140 : 120}>
          {formatDate({ date: row.completed_date, defaultReturn: "-" })}
        </TableCell>
      )
    },
    {
      title: "container.user_profile.training_history.validity_start_date",
      dataIndex: "validity_start_date",
      sorter: true,
      render: (text, row) => (
        <TableCell width={language === languages[2].code ? 170 : 120}>
          {formatDate({ date: row.validity_start_date, defaultReturn: "-" })}
        </TableCell>
      )
    },
    {
      title: "container.user_profile.training_history.expiry_date",
      dataIndex: "expiry_date",
      render: (text, row) => (
        <TableCell width={110}>
          {formatDate({ date: row.expiryDate, defaultReturn: trans("trg.validity_field.no_expiry") })}
        </TableCell>
      )
    },
    {
      title: "container.user_profile.training_history.instructor_name",
      dataIndex: "instructor_name",
      render: (text, row) => (
        <TableCell minWidth={140} maxWidth={160}>
          {formatString(row.instructor?.name || row.instructor_name, "-")}
        </TableCell>
      )
    },
    {
      title: "container.user_profile.training_history.evidence",
      dataIndex: "evidence",
      render: (text, row) => {
        const evidence = row.files.filter((file = {}) => file.purpose !== "certificate")[0];
        return (
          <TableCell width={70} className="flex flex-justify-center">
            <DownloadFile
              file={evidence}
              title={trans(
                evidence
                  ? "container.user_profile.training_history.download_evidence"
                  : "container.user_profile.training_history.missing_evidence"
              )}
            />
          </TableCell>
        );
      }
    },
    {
      title: "container.user_profile.training_history.certificate",
      dataIndex: "certificate",
      render: (text, row) => {
        const certificate = row.files.filter((file = {}) => file.purpose === "certificate")[0];
        return (
          <TableCell width={70} className="flex flex-justify-center">
            <DownloadFile
              file={certificate}
              title={trans(
                certificate
                  ? "container.user_profile.training_history.download_certificate"
                  : "container.user_profile.training_history.missing_certificate"
              )}
            />
          </TableCell>
        );
      }
    },
    {
      title: trans("container.general_words.actions"),
      dataIndex: "actions",
      fixed: "right",
      render: (text, row) => (
        <TableCell minWidth={55} className="table-actions-column">
          <Tooltip title={trans("container.general_words.edit")} placement="top" className="text-primary">
            <a
              href="/"
              onClick={(e) => {
                e.preventDefault();
                dispatch(openModalAction({ name: MODAL_USER_VIEW_QUALIFICATION, data: row }));
              }}
            >
              <i className="ti-pencil-alt" />
            </a>
          </Tooltip>
          <DeleteItemButton
            tableName={TABLE_USER_VIEW_TRG_QUALIFICATIONS_NAME}
            route={`trg/qualification-histories/${row.id}`}
          />
        </TableCell>
      )
    }
  ];
};

export const generateFormFields = (data = {}, userId, form, t) => {
  return [
    {
      required: true,
      type: FORM_ITEM_RESOURCE_SELECT_LAZY_LOADING,
      name: "qualification_id",
      label: "trg.add_qualification_modal.training",
      placeholder: "trg.add_qualification_modal.training",
      initialValue: data.qualification
        ? {
            id: data.qualification.id,
            text: `${data.qualification.training.name} (${data.qualification.training.code})`
          }
        : undefined,
      apiRoute: `trg/qualifications?field%5B%5D=id&op%5B%5D=eq&term%5B%5D=${userId}&with%5B%5D=qualifications.training.provider`,
      fieldProps: {
        disabled: data?.id,
        transformer: (res) => {
          return (
            res[0]?.qualifications?.map((item) => ({
              ...item,
              value: item.id,
              text: `${item.training.name} (${item.training.code})`
            })) || []
          );
        }
      }
    },
    {
      type: FORM_ITEM_DATE_PICKER,
      required: true,
      dependencies: ["validity_start_date"],
      name: "completed_date",
      label: "trg.add_qualification_modal.completed_date",
      placeholder: "trg.add_qualification_modal.completed_date",
      disabledDate: (current) => {
        const activeTo = form.getFieldValue("validity_start_date");
        return activeTo
          ? moment(current, DATE_SHORT_FORMAT).startOf("day") > moment(activeTo, DATE_SHORT_FORMAT).startOf("day")
          : false;
      }
    },
    {
      type: FORM_ITEM_DATE_PICKER,
      required: true,
      dependencies: ["completed_date"],
      name: "validity_start_date",
      label: "trg.add_qualification_modal.validity_start_date",
      placeholder: "trg.add_qualification_modal.validity_start_date",
      disabledDate: (current) => {
        const activeFrom = form.getFieldValue("completed_date");
        return activeFrom
          ? moment(current, DATE_SHORT_FORMAT).startOf("day") < moment(activeFrom, DATE_SHORT_FORMAT).startOf("day")
          : false;
      }
    },
    {
      type: FORM_ITEM_RESOURCE_SELECT,
      required: true,
      name: "instructor_type",
      label: "trg.add_qualification_modal.instructor_type",
      placeholder: "trg.add_qualification_modal.instructor_type",
      options: [
        {
          value: 1,
          text: t("trg.add_qualification_modal.internal")
        },
        {
          value: 0,
          text: t("trg.add_qualification_modal.external")
        }
      ]
    },
    {
      required: true,
      type: FORM_ITEM_RESOURCE_SELECT_LAZY_LOADING,
      visible: (formInstance) => formInstance.getFieldValue("instructor_type") === 1,
      name: "instructor_id",
      label: "trg.add_qualification_modal.instructor",
      placeholder: "trg.add_qualification_modal.instructor",
      initialValue: data.instructor,
      apiRoute: "users/all?order_by_column%5B%5D=name&order_by_type%5B%5D=asc",
      fieldProps: {
        searchColumns: ["name"]
      }
    },
    {
      required: true,
      type: FORM_ITEM_RESOURCE_SELECT_LAZY_LOADING,
      visible: (formInstance) => formInstance.getFieldValue("instructor_type") === 0,
      name: "external_provider_id",
      label: "trg.add_qualification_modal.provider",
      placeholder: "trg.add_qualification_modal.provider",
      apiRoute:
        "trg/external-providers?field%5B%5D=active&op%5B%5D=eq&order_by_column%5B%5D=name&order_by_type%5B%5D=asc&term%5B%5D=1",
      fieldProps: {
        requestOnMount: !!data.external_provider_id
      }
    },
    {
      required: true,
      type: FORM_ITEM_TEXT,
      visible: (formInstance) => formInstance.getFieldValue("instructor_type") === 0,
      max: 200,
      name: "instructor_name",
      label: "trg.add_qualification_modal.instructor",
      placeholder: "trg.add_qualification_modal.instructor"
    },
    {
      type: FORM_TYPE_UPLOAD_FILE,
      name: "evidence",
      label: "trg.add_qualification_modal.evidence",
      placeholder: "ior.new_report.new_attachment.attachment_button",
      fieldProps: {
        purpose: "qualificationHistory",
        entity: "qualificationHistory",
        type: "document"
      }
    },
    {
      type: FORM_TYPE_UPLOAD_FILE,
      name: "certificate",
      label: "trg.add_qualification_modal.certificate",
      placeholder: "ior.new_report.new_attachment.attachment_button",
      fieldProps: {
        purpose: "certificate",
        entity: "certificate",
        type: "document"
      }
    }
  ];
};

export const formTransformer = (values = {}, format) => {
  return {
    ...values,
    completed_date: format({
      date: values.completed_date,
      dateFormat: config.defaultDateFormat,
      defaultReturn: null
    }),
    validity_start_date: format({
      date: values.validity_start_date,
      dateFormat: config.defaultDateFormat,
      defaultReturn: null
    }),
    evidences: [(values.evidence || [])[0], (values.certificate || [])[0]].filter((item) => !!item)
  };
};
