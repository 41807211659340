import React from "react";
import { NavLink } from "react-router-dom";
import TableCell from "../../../components/SmartTable/TableCell";
import DeleteItemButton from "../../../components/SmartTable/TableFiltersActions/DeleteItemButton";
import {
  FILTER_TYPE_DATE_RANGE,
  FILTER_TYPE_SELECT_MODE_2,
  FILTER_TYPE_STRING
} from "../../../components/SmartTable/methods";
import { formatString } from "../../../utils/common";
import { openModalAction } from "../../../components/Modal/reducer";
import { EditButton } from "../../../components/EditButton";
import { trainingLanguages } from "../Trainings/config";
import axios from "../../../axios";
import { downloadFile } from "../../../utils/downloadFile";
import Tooltip from "../../../components/Tooltip";
import { PAGE_TRG_EDIT_PLANNING, PAGE_TRG_VIEW_PLANNING } from "../routes";
import { MODAL_TRG_PLANNING_MARK } from "./PlanningMarkForm";
import DownloadPlanning from "./DownloadPlanning";

export const TABLE_TRG_PLANNING = "TABLE_TRG_PLANNING";

export const generateColumns = (canManage, trans, dispatch, format, appFormat) => {
  return [
    {
      title: "trg.planning.number",
      dataIndex: "number",
      sorter: true,
      filterType: FILTER_TYPE_STRING,
      width: 100,
      render: (text, row) => <TableCell minWidth={90}>{formatString(row.number, "-")}</TableCell>
    },
    {
      title: "trg.planning.title",
      dataIndex: "title",
      sorter: true,
      filterType: FILTER_TYPE_STRING,
      width: 120,
      render: (text, row) => <TableCell minWidth={120}>{formatString(row.title, "-")}</TableCell>
    },
    {
      title: "trg.planning.training_name",
      dataIndex: "training_name",
      sorter: true,
      filterType: FILTER_TYPE_SELECT_MODE_2,
      filterProps: {
        apiRoute: "trg/trainings?order_by_column%5B%5D=name&order_by_type%5B%5D=asc",
        searchColumns: ["name", "code"],
        transformer: (res = []) => {
          return res?.map((item) => ({ ...item, text: `${item.name} (${item.code})` })) || [];
        }
      },
      width: 200,
      render: (text, row) => (
        <TableCell minWidth={170}>
          {formatString(row.training ? `${row.training.name} (${row.training.code})` : null, "-")}
        </TableCell>
      )
    },
    {
      title: "trg.planning.course_start_date",
      dataIndex: "start_date",
      sorter: true,
      filterType: FILTER_TYPE_DATE_RANGE,
      width: 150,
      render: (text, row) => (
        <TableCell minWidth={130}>{format({ date: row.start_date, dateFormat: `${appFormat} HH:mm` })}</TableCell>
      )
    },
    {
      title: "trg.planning.course_end_date",
      dataIndex: "end_date",
      sorter: true,
      filterType: FILTER_TYPE_DATE_RANGE,
      width: 150,
      render: (text, row) => (
        <TableCell minWidth={130}>{format({ date: row.end_date, dateFormat: `${appFormat} HH:mm` })}</TableCell>
      )
    },
    {
      title: "trg.planning.instructor_name",
      dataIndex: "instructor_name",
      sorter: true,
      filterType: FILTER_TYPE_STRING,
      width: 200,
      render: (text, row) => <TableCell minWidth={150}>{formatString(row.instructor_name, "-")}</TableCell>
    },
    {
      title: "trg.planning.language",
      dataIndex: "language",
      sorter: true,
      filterType: FILTER_TYPE_SELECT_MODE_2,
      filterProps: {
        staticOptions: trainingLanguages
      },
      width: 110,
      render: (text, row) => {
        const languageOption = trainingLanguages.filter(
          (item) => item.value === (row.language ? row.language.toLowerCase() : null)
        )[0];
        const languageTranslated = languageOption ? trans(languageOption.transKey) : null;
        return <TableCell width={110}>{formatString(languageTranslated, "-")}</TableCell>;
      }
    },
    {
      title: "trg.course_export.participants",
      dataIndex: "participantsCount",
      width: 120,
      align: "center",
      render: (text, row) => (
        <TableCell minWidth={120} className="text-center">
          {formatString(row.participantsCount, "0")}
        </TableCell>
      )
    },
    {
      title: trans("container.general_words.actions"),
      fixed: "right",
      width: 100,
      render: (row = {}) => {
        let statusTooltipTransKey;
        if (!row.canBeCompleted && !row.completed) {
          statusTooltipTransKey = "trg.planning.course_can_not_be_completed";

          if (row.participantsCount === 0) {
            statusTooltipTransKey = "trg.planning.course_without_participants";
          }
        } else if (row.completed) {
          statusTooltipTransKey = "trg.planning.course_is_completed";
        } else {
          statusTooltipTransKey = "trg.planning.complete_course";
        }

        return (
          <div className="table-actions-column">
            <DownloadPlanning row={row} route={`trg/courses/export/${row.id}?type=courses`} />
            <Tooltip title={trans("container.general_words.view")}>
              <NavLink to={`${PAGE_TRG_VIEW_PLANNING}/${row.id}`} className="text-primary">
                <i className="ti-eye" />
              </NavLink>
            </Tooltip>
            {canManage && (
              <>
                <Tooltip title={trans(statusTooltipTransKey)}>
                  <a
                    disabled={row.completed || !row.canBeCompleted}
                    href="/"
                    className="fs-16"
                    onClick={(e) => {
                      e.preventDefault();

                      if (row.completed || !row.canBeCompleted) {
                        return;
                      }

                      dispatch(openModalAction({ name: MODAL_TRG_PLANNING_MARK, data: row }));
                    }}
                  >
                    <i className="ti-check" />
                  </a>
                </Tooltip>
                <EditButton
                  stopPropagation={row.completed}
                  disabled={row.completed}
                  href={`${PAGE_TRG_EDIT_PLANNING}/${row.id}`}
                />
                <DeleteItemButton
                  disabled={row.completed}
                  tableName={TABLE_TRG_PLANNING}
                  route={`trg/courses/${row.id}`}
                  content={
                    <div>
                      <div>{trans("container.confirm_modal.form_subtitle")}</div>
                    </div>
                  }
                />
              </>
            )}
          </div>
        );
      }
    }
  ];
};

export const downloadTableRow = ({
  route,
  onStart = () => {},
  onSuccess = () => {},
  onFailed = () => {},
  params = {}
}) => {
  onStart();
  axios
    .get(route, {
      params
    })
    .then(
      (res) => {
        const fileName = res.data.data.url.substring(res.data.data.url.lastIndexOf("/") + 1, res.data.data.url.length);

        downloadFile({
          fileUrl: res.data.data.url,
          fileName: fileName,
          onSuccess: () => onSuccess(),
          onFailed: (error) => onFailed(error)
        });
      },
      (error) => onFailed(error)
    );
};
