import React from "react";
import Modal, { MODAL_SIZE_SMALL } from "../../Modal";
import Preloader from "../../Preloader";

export const MODAL_EXPORT_LOADING = "modalExportLoading";

const LoadingModal = ({ name = MODAL_EXPORT_LOADING, message }) => {
  return (
    <Modal name={name} size={MODAL_SIZE_SMALL} closable={false} zIndex={99999}>
      <div className="pl-30 pr-30 pb-30 text-center">
        <div className="text-center mb-20 mt-30">
          <Preloader size={40} />
        </div>
        {message && <h5 className="font-weight-300">{message}</h5>}
      </div>
    </Modal>
  );
};

export default LoadingModal;
