import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import Button from "../../Button";
import { openConfirm } from "../../Modal/Confirm";
import axios from "../../../axios";
import { openNotification } from "../../Notification";
import { reloadTable } from "../reducer";

const MassDeleteButton = ({ massDeleteProps = {}, selectedRows = [], tableName }) => {
  const { t } = useTranslation();
  const { onSuccess } = massDeleteProps;

  const dispatch = useDispatch();

  const reloadTableData = () => {
    if (tableName) {
      dispatch(reloadTable({ name: tableName, deleted: true, deletedItems: selectedRows.length }));
    }
  };

  const onDeleteClick = () => {
    openConfirm({
      onOk: () => {
        return deleteItems();
      }
    });
  };

  const deleteItems = () => {
    return axios.delete(massDeleteProps.route, { params: { identifiers: selectedRows.map((item) => item.id) } }).then(
      () => {
        reloadTableData();
        onSuccess && onSuccess();
      },
      () => {
        openNotification({ type: "error", message: t("container.form_errors.internal_server_error") });
      }
    );
  };

  return (
    <Button type="danger" icon={<i className="ti-close" />} className="action-btn" onClick={onDeleteClick}>
      {t("container.general_words.delete")}
    </Button>
  );
};

export default MassDeleteButton;
