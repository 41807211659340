import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { translations } from "./lang";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: translations,
    fallbackLng: "English",
    //debug: process.env.NODE_ENV === "development",
    debug: false, //process.env.NODE_ENV === "development",
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
