import React from "react";
import { useTranslation } from "react-i18next";
import Tooltip from "../Tooltip";

export const DownloadButton = (props) => {
  const { title = "container.general_words.download", onClick, disabled = false, stopPropagation } = props;
  const { t } = useTranslation();

  const onBtnClick = (e) => {
    e.preventDefault();

    if (stopPropagation) {
      e.stopPropagation();
    }

    if (disabled) {
      return;
    }

    onClick && onClick();
  };

  return (
    <Tooltip title={t(title)} placement="top" className="text-muted">
      <a disabled={disabled} href="/" onClick={onBtnClick}>
        <i className="ti-download" />
      </a>
    </Tooltip>
  );
};
