import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Button, Tooltip } from "antd";
import { ClearOutlined } from "@ant-design/icons";
import PageMetaTagTitle from "../../../components/PageMetaTagTitle";
import SmartTable from "../../../components/SmartTable";
import TableService from "../../../components/SmartTable/TableService";
import { usePermission } from "../../../components/_auth/permissions/usePermission";
import {
  PERMISSION_EXPORT_QAM_ENTITIES,
  PERMISSION_MANAGE_QAM_ENTITIES
} from "../../../components/_auth/permissions/permissions";
import { useLoggedUser } from "../../../utils/hooks/useLoggedUser";
import Modal from "../../../components/Modal";
import { openModalAction } from "../../../components/Modal/reducer";
import { reloadTable } from "../../../components/SmartTable/reducer";
import { generateColumns, TABLE_QAM_ENTITIES } from "./methods";
import EntityForm from "./EntityForm";

export const MODAL_QAM_ENTITY = "MODAL_QAM_ENTITY";

const QamEntitiesPage = () => {
  const dispatch = useDispatch();
  const permission = usePermission();
  const loggedUser = useLoggedUser();
  const { t } = useTranslation();
  const tableKeyName = `${TABLE_QAM_ENTITIES}_${loggedUser.id}`;
  const localStorageTableFilters = JSON.parse(localStorage.getItem(tableKeyName) || "{}");
  const [tableService] = useState(
    new TableService({
      defaultWith: ["branches", "audits", "files"],
      defaultOrderBy: [{ column: "name", type: "asc" }],
      searchColumns: ["name", "number", "branches_list", "city", "address", "email", "address2", "url", "phone"],
      searchValue: "",
      ...localStorageTableFilters
    })
  );
  const handleClearFilters = () => {
    dispatch(reloadTable({ name: TABLE_QAM_ENTITIES, resetFilters: true }));
  };

  return (
    <>
      <PageMetaTagTitle title="QAM entities" />
      <Modal name={MODAL_QAM_ENTITY}>
        <EntityForm />
      </Modal>
      <SmartTable
        scroll={{ x: 1200 }}
        name={TABLE_QAM_ENTITIES}
        tableService={tableService}
        columns={generateColumns(permission, dispatch, t)}
        route="qam/entities"
        tableLocalStorageKeyName={tableKeyName}
        exportProps={{
          access: permission.canDo(PERMISSION_EXPORT_QAM_ENTITIES),
          route: "qam/entities/export",
          columns: [
            { column: "number", trans: t("qam.new_entity.number") },
            { column: "name", trans: t("container.general_words.name") },
            { column: "city", trans: t("qam.entities.city") },
            { column: "address", trans: t("qam.entities.address") },
            { column: "email", trans: t("container.general_words.email") },
            { column: "phone", trans: t("container.users.phone") }
          ]
        }}
        newItemProps={{
          access: permission.canDo(PERMISSION_MANAGE_QAM_ENTITIES),
          text: t("qam.entities.new_entity"),
          onClick: () => dispatch(openModalAction({ name: MODAL_QAM_ENTITY }))
        }}
        massDeleteProps={{
          access: permission.canDo(PERMISSION_MANAGE_QAM_ENTITIES),
          route: "qam/entities/mass-delete"
        }}
        disableSelectionRow={(row = {}) => !row.canBeEdited}
        clearFiltersActions={
          <Tooltip placement="topLeft" title={t("container.general_words.clear_filters")}>
            <Button onClick={handleClearFilters} icon={<ClearOutlined />}>
              {t("container.general_words.clear_filters")}
            </Button>
          </Tooltip>
        }
      />
    </>
  );
};

export default QamEntitiesPage;
