import React, { useState, useEffect } from "react";
import Transition from "react-transition-group/Transition";
import { connect, useDispatch } from "react-redux";
import isEmpty from "lodash/isEmpty";
import { useIdleTimer } from "react-idle-timer";
import axios from "../../axios";
import i18n from "../../i18n";
import { closeModalAction, openModalAction } from "../Modal/reducer";
import moment from "../../moment";
import { convertFromLongLanguageToCode } from "../DropdownLanguages/methods";
import config from "../../config";
import { userAuthenticatedAction, userUnauthenticatedAction } from "./reducer";
import {
  checkProbablyLogged,
  deleteStorageAuth,
  DURATION_TO_BE_IDLE,
  eventsIdleDetection,
  getStorageAuth,
  saveStorageAuth
} from "./methods";
import AuthService from "./AuthService";
import useLogout from "./useLogout";
import ModalSession, { MODAL_SESSION_NAME } from "./ModalSessions";
import GlobalPreloader from "./GlobalPreloader";

/**
 * @description This component will be shown while token is checked
 */
const CheckToken = ({ children, user }) => {
  const authStorage = getStorageAuth();
  const probablyLogged = checkProbablyLogged(authStorage);
  const [loading, setLoading] = useState(probablyLogged);
  const dispatch = useDispatch();
  const logout = useLogout();
  const { start, pause, resume, message } = useIdleTimer({
    onIdle: () => {
      dispatch(openModalAction({ name: MODAL_SESSION_NAME }));
    },
    onMessage: (data) => {
      dispatch(closeModalAction({ name: MODAL_SESSION_NAME }));
      if (data === "logout") {
        deleteStorageAuth();
        dispatch(userUnauthenticatedAction());
      }
    },
    timeout: DURATION_TO_BE_IDLE,
    events: eventsIdleDetection,
    debounce: 200,
    element: document,
    crossTab: true
  });

  const onModalClose = (action) => {
    message(action);
  };

  useEffect(() => {
    if (probablyLogged) {
      setLoading(true);
      axios.post("auth/refresh", { token: authStorage.refresh_token }).then(
        (res) => {
          saveStorageAuth({ ...res.data, google2fa: authStorage.google2fa, token_timestamp: new Date().toString() });

          axios.get("users/me", { params: { with: ["company.files", "groups", "files"] } }).then(
            (res) => {
              setLoading(false);
              dispatch(userAuthenticatedAction(res.data.data));
              const appLanguage = res.data.data.language || res.data.data.company.default_language || "English";
              axios.defaults.headers.locale = appLanguage;
              i18n.changeLanguage(appLanguage);
              moment.locale(convertFromLongLanguageToCode(appLanguage));
            },
            () => {
              setLoading(false);
            }
          );
        },
        () => {
          setLoading(false);
          deleteStorageAuth();
          dispatch(userUnauthenticatedAction());
        }
      );
    } else {
      if (!authStorage.has2Fa) {
        deleteStorageAuth();
      }
      dispatch(userUnauthenticatedAction());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    AuthService.initBroadCast((event = {}) => {
      if (event.data?.name === "logout") {
        deleteStorageAuth();
        dispatch(userUnauthenticatedAction());
      }

      if (event.data?.name === "login") {
        dispatch(userAuthenticatedAction(event.data.data));
      }
    });

    if (isEmpty(user)) {
      pause();
      AuthService.destroy();
    } else {
      start();
      resume();
      AuthService.init({
        beforeExpiry: () => {
          const authStorage = getStorageAuth();
          axios.post("auth/refresh", { token: authStorage.refresh_token }).then((res) => {
            saveStorageAuth({ ...res.data, google2fa: authStorage.google2fa, token_timestamp: new Date().toString() });
          });
        },
        afterExpiry: () => {
          logout.submit();
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    const head = document.head;
    const link = document.createElement("link");
    link.type = "text/css";
    link.rel = "stylesheet";
    link.href = `${config.apiBaseUrl}colors.css`;

    head.appendChild(link);
  }, []);

  return (
    <div className="app-global-container">
      <ModalSession onModalClose={onModalClose} />
      <Transition in={loading} enter={false} timeout={300} mountOnEnter unmountOnExit>
        {(state) => <GlobalPreloader state={state} />}
      </Transition>
      {!loading ? children : null}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state?.auth?.user
  };
};

export default connect(mapStateToProps)(CheckToken);
