import React from "react";
import { useTranslation } from "react-i18next";
import {
  PERMISSION_MANAGE_TRG,
  PERMISSION_VIEW_CORE_USERS,
  PERMISSION_VIEW_QAM_AUDITS,
  PERMISSION_VIEW_QAM_CORRECTIVE_ACTIONS,
  PERMISSION_VIEW_TRG_PLANNING
} from "../_auth/permissions/permissions";
import { usePermission } from "../_auth/permissions/usePermission";
import { PAGE_USERS } from "../../pages/Core/routes";
import { PAGE_QAM_ACTIVITIES, PAGE_QAM_CORRECTIVE_ACTIONS } from "../../pages/Qam/routes";
import { PAGE_TRG_PLANNING, PAGE_TRG_TRAININGS } from "../../pages/Trg/routes";

export const entitiesSearch = [
  {
    title: "container.sidebar.users",
    gates: PERMISSION_VIEW_CORE_USERS,
    path: PAGE_USERS
  },
  {
    title: "container.sidebar.activities",
    gates: PERMISSION_VIEW_QAM_AUDITS,
    path: PAGE_QAM_ACTIVITIES
  },
  {
    title: "container.sidebar.corrective_actions",
    gates: PERMISSION_VIEW_QAM_CORRECTIVE_ACTIONS,
    path: PAGE_QAM_CORRECTIVE_ACTIONS
  },
  {
    title: "container.sidebar.trainings",
    gates: PERMISSION_MANAGE_TRG,
    path: PAGE_TRG_TRAININGS
  },
  {
    title: "trg.planning.courses",
    gates: PERMISSION_VIEW_TRG_PLANNING,
    path: PAGE_TRG_PLANNING
  }
];

const GeneralSearchOverlay = (props) => {
  const { t } = useTranslation();
  const { onSelect } = props;
  const permission = usePermission();

  return (
    <ul>
      {entitiesSearch.map(
        (item) =>
          permission.canDo(item.gates) && (
            <li key={item.title} onClick={() => onSelect(item)}>
              {t(item.title)}
            </li>
          )
      )}
    </ul>
  );
};

export default GeneralSearchOverlay;
