import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import useFormSubmit from "../../../components/Form/useFormSubmit";
import Form, { FORM_HORIZONTAL_LAYOUT } from "../../../components/Form";
import { reloadTable } from "../../../components/SmartTable/reducer";
import { closeModalAction } from "../../../components/Modal/reducer";
import Button from "../../../components/Button";
import { renderDynamicallyFields } from "../../../components/Form/methods";
import { FORM_ITEM_TEXT } from "../../../components/Form/FormItems/FormItemText";
import { FORM_ITEM_RESOURCE_SELECT } from "../../../components/Form/FormItems/FormItemResourceSelect";
import { countries } from "../../Organization/OrgSettings/Branches/config";
import { FORM_ITEM_TEXT_AREA } from "../../../components/Form/FormItems/FormItemTextArea";
import { FORM_TYPE_UPLOAD_IMAGE } from "../../../components/Form/FormItems/FormItemUploadImage";
import { FORM_ITEM_CHECKBOX } from "../../../components/Form/FormItems/FormItemCheckbox";
import { formatPhoneNumber } from "../../../utils/common";
import { TABLE_QAM_ENTITIES } from "./methods";
import { MODAL_QAM_ENTITY } from "./index";

const EntityForm = ({ name }) => {
  const data = useSelector((state) => state?.modal[name || MODAL_QAM_ENTITY]?.data) || {};
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isNewRecord = !data.id;
  const { loading, submit, generalError } = useFormSubmit({
    route: isNewRecord ? "qam/entities" : `qam/entities/${data.id}`,
    method: isNewRecord ? "post" : "put",
    form,
    onSuccess: () => {
      data.onSuccess && data.onSuccess();
      dispatch(reloadTable({ name: TABLE_QAM_ENTITIES, resetFilters: isNewRecord }));
      dispatch(closeModalAction({ name: name || MODAL_QAM_ENTITY }));
    }
  });

  return (
    <Form
      onSubmit={submit}
      form={form}
      errors={generalError}
      loading={loading}
      initialValues={{
        active: true,
        ...data,
        images:
          data.files?.length > 0
            ? {
                src: data.files[0].sizes.users.md,
                name: data.files[0].name,
                id: data.files[0].id
              }
            : {}
      }}
      layout={FORM_HORIZONTAL_LAYOUT}
      header={
        <>
          <div className="h3">{t(isNewRecord ? "qam.new_entity.form_title" : "qam.edit_entity.form_title")}</div>
          <div className="mb-30 mt-10 h6 font-weight-300 text-muted">{t("container.general_words.form_subtitle")}</div>
        </>
      }
    >
      {renderDynamicallyFields([
        {
          type: FORM_ITEM_TEXT,
          max: 200,
          name: "number",
          label: "qam.new_entity.number",
          placeholder: "qam.new_entity.number"
        },
        {
          required: true,
          type: FORM_ITEM_TEXT,
          max: 200,
          name: "name",
          label: "qam.new_entity.company_name",
          placeholder: "qam.new_entity.company_name"
        },
        {
          type: FORM_ITEM_TEXT,
          max: 200,
          name: "address",
          label: "qam.new_entity.address1",
          placeholder: "qam.new_entity.address1"
        },
        {
          type: FORM_ITEM_TEXT,
          max: 200,
          name: "address2",
          label: "qam.new_entity.address2",
          placeholder: "qam.new_entity.address2"
        },
        {
          type: FORM_ITEM_TEXT,
          max: 200,
          name: "zip",
          label: "qam.new_entity.zip",
          placeholder: "qam.new_entity.zip"
        },
        {
          type: FORM_ITEM_TEXT,
          max: 200,
          name: "city",
          label: "qam.new_entity.city",
          placeholder: "qam.new_entity.city"
        },
        {
          type: FORM_ITEM_RESOURCE_SELECT,
          name: "country",
          label: "qam.new_entity.country",
          placeholder: "qam.new_entity.country_placeholder",
          options: countries.map((item) => ({
            value: item.name,
            text: item.name
          }))
        },
        {
          type: FORM_ITEM_TEXT,
          max: 200,
          name: "phone",
          label: "qam.new_entity.phone",
          placeholder: "qam.new_entity.phone",
          normalize: (value) => formatPhoneNumber(value)
        },
        {
          type: FORM_ITEM_TEXT,
          email: true,
          max: 200,
          name: "email",
          label: "container.general_words.email",
          placeholder: "container.general_words.email"
        },
        {
          type: FORM_ITEM_TEXT,
          max: 200,
          name: "url",
          label: "qam.new_entity.website_url",
          placeholder: "qam.new_entity.website_url"
        },
        {
          type: FORM_ITEM_TEXT_AREA,
          name: "remarks",
          label: "qam.new_entity.internal_remarks",
          placeholder: "qam.new_entity.internal_remarks",
          autoSize: { minRows: 2, maxRows: 6 }
        },
        {
          type: FORM_TYPE_UPLOAD_IMAGE,
          name: "images",
          label: "qam.new_entity.picture",
          placeholder: "qam.new_entity.picture",
          fieldProps: {
            purpose: "picture",
            entity: "entity",
            type: "image",
            cardImageStyle: { width: 80, height: 80 }
          }
        },
        {
          type: FORM_ITEM_CHECKBOX,
          visible: isNewRecord,
          name: "active",
          label: "container.qam_settings.scopes_tab.active"
        }
      ])}
      <div className="flex mt-25 flex-justify-end">
        <Button
          className="min-w-150"
          type="primary"
          htmlType="submit"
          disabled={loading}
          loading={loading}
          icon={<i className="ti-check" />}
        >
          {t("container.general_words.save")}
        </Button>
      </div>
    </Form>
  );
};

export default EntityForm;
