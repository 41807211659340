import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { useDateFormat } from "../../../utils/hooks/useDateFormat";
import moment from "../../../moment";
import { PAGE_VIEW_USER } from "../routes";
import { PAGE_DOM_DOCUMENT } from "../../Dom/routes";

const prepareMessage = (data = {}, dateFormat, trans) => {
  const { translation_key, qualification, employee, report, document } = data;
  const langKeyPart1 = `container.notifications_page.${translation_key}.message_part1`;
  const langKeyPart2 = `container.notifications_page.${translation_key}.message_part2`;
  const langKeyPart3 = `container.notifications_page.${translation_key}.message_part3`;
  const date = data ? moment(data.expiring_soon_date).format(dateFormat) : null;
  const staffNumber = employee?.userDetail ? `[${data.employee.userDetail.staff_number}]` : "";

  switch (translation_key) {
    case "qualification_missing": {
      return (
        <>
          <span className="mr-5">{trans(langKeyPart1)}</span>
          <NavLink to={`${PAGE_VIEW_USER}/${data.employee.id}`} target="_blank" className="mr-5">
            {employee.last_name} {employee.first_name} {staffNumber}
          </NavLink>
          <span className="mr-5">{trans(langKeyPart2)}</span>
          <span className="text-muted">{data.training.name}</span>
        </>
      );
    }
    case "qualification_expired": {
      return (
        <>
          <span className="mr-5">{trans(langKeyPart1)}</span>
          <span className="text-muted mr-5">{qualification.training.name}</span>
          <span className="mr-5">{trans(langKeyPart2)}</span>
          <NavLink to={`${PAGE_VIEW_USER}/${data.employee.id}`} target="_blank" className="mr-5">
            {employee.last_name} {employee.first_name} {staffNumber}
          </NavLink>
          <span className="mr-5">{trans(langKeyPart3)}</span>
        </>
      );
    }
    case "qualification_expiring_soon": {
      return (
        <>
          <span className="mr-5">{trans(langKeyPart1)}</span>
          <span className="text-muted mr-5">{qualification.training.name}</span>
          <span className="mr-5">{trans(langKeyPart2)}</span>
          <NavLink to={`${PAGE_VIEW_USER}/${data.employee.id}`} target="_blank" className="mr-5">
            {employee.last_name} {employee.first_name} {staffNumber}
          </NavLink>
          <span className="mr-5">{trans(langKeyPart3)}</span>
          <span className="text-muted">{date}</span>
        </>
      );
    }
    case "export_ready": {
      return (
        <>
          <span className="mr-5">{trans(langKeyPart1)}</span>
          <NavLink to="/adm/exports-in-queue" target="_blank" className="mr-5">
            {report.name}
          </NavLink>
          <span>{trans(langKeyPart2)}</span>
        </>
      );
    }
    case "notify_approver": {
      return (
        <>
          <span className="mr-5">{trans(langKeyPart1)}</span>
          <NavLink to={`${PAGE_DOM_DOCUMENT}/${document.id}`} target="_blank" className="mr-5">
            {document.title}
          </NavLink>
          <span className="mr-5">{trans(langKeyPart2)}</span>
          <NavLink to={`${PAGE_VIEW_USER}/${data.employee.id}`} target="_blank" className="mr-5">
            {employee.last_name} {employee.first_name}
          </NavLink>
        </>
      );
    }
    default: {
      return null;
    }
  }
};

const NotificationsMessageColumn = ({ data }) => {
  const { appFormat } = useDateFormat();
  const { t } = useTranslation();
  return prepareMessage(data, appFormat, t);
};

export default NotificationsMessageColumn;
