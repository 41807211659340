import React, { useCallback, useEffect, useState } from "react";
import { Table as AntdTable } from "antd";
import { useTranslation } from "react-i18next";
import uniqueId from "lodash/uniqueId";
import qs from "qs";
import classNames from "classnames";
import debounce from "lodash/debounce";
import { connect } from "react-redux";
import isEqual from "lodash/isEqual";
import isBoolean from "lodash/isBoolean";
import { useHistory, useLocation } from "react-router-dom";
import isArray from "lodash/isArray";
import axios from "../../axios";
import { usePrevious } from "../../utils/hooks/usePrevious";
import { useLoggedUser } from "../../utils/hooks/useLoggedUser";
import Preloader from "../Preloader";
import { applyColumnsFilterSort, saveTableParams, translateColumnsTitles } from "./methods";
import TableFiltersActions from "./TableFiltersActions";
import { useTableFullHeight } from "./useTableFullHeight";
import TableService from "./TableService";

let axiosController = {};
const tableGeneratedName = uniqueId("table_unique_key_name_");
const SmartTable = (props) => {
  const {
    route,
    tableService = new TableService(),
    disableSelectionRow,
    tableLocalStorageKeyName,
    name = tableGeneratedName,
    externalFilters,
    onGetData,
    columns = [],
    exportProps = {},
    newItemProps = {},
    tableState = {},
    massDeleteProps = {},
    scroll = {},
    transformer = (data) => data,
    transformerParams = (params) => params,
    containerClassName,
    onSelectRows,
    pagination,
    externalActions,
    clearFiltersActions,
    customHandleTableChange,
    staticData,
    rowsPerPage,
    footer,
    onError,
    emptyText = "container.general_words.no_data_message",
    ...rest
  } = props;
  const tableHeight = useTableFullHeight();
  const location = useLocation();
  const history = useHistory();
  const { shouldReload, resetFilters } = tableState;
  const previousShouldReload = usePrevious(shouldReload);
  const loggedUser = useLoggedUser();
  const itemsPerPage = rowsPerPage || loggedUser?.company?.items_per_page;
  const [tableStateParams, setTableStateParams] = useState(
    tableService.setPerPage(itemsPerPage, true).setDefaultPerPage(itemsPerPage).getFilters()
  );
  const [selectedRows, setSelectedRows] = useState({
    rows: [],
    keys: []
  });
  const { t } = useTranslation();
  const [data, setData] = useState(staticData || []);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState(tableService.getSearchValue());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceGetData = useCallback(
    debounce(() => getTableData(tableService.getFilters()), 1000),
    []
  );

  const getTableData = useCallback(
    (params) => {
      if (axiosController[name]) {
        axiosController[name].abort();
      }

      if (!route) return;

      // eslint-disable-next-line react-hooks/exhaustive-deps
      axiosController[name] = new AbortController();
      setLoading(true);
      setSelectedRows({ keys: [], rows: [] });
      setSearch(tableService.getSearchValue());

      axios
        .get(route, {
          signal: axiosController[name].signal,
          params: params,
          paramsSerializer: (params) => {
            return qs.stringify(transformerParams(params), { arrayFormat: "brackets" });
          }
        })
        .then(
          (res) => {
            const responseData = res.data.data?.data || (isArray(res.data.data) ? res.data.data : []);
            const preparedData = transformer(responseData);
            setData(preparedData);
            tableService
              .setPerPage(params.per_page)
              .setPage(res.data.data?.pager?.current || 1)
              .setTotal(res.data.data?.pager?.totalItems || preparedData.length);
            setTableStateParams(tableService.getFilters());
            setLoading(false);
            onGetData && onGetData(preparedData, res.data.data.pager);

            if (tableLocalStorageKeyName) {
              saveTableParams(tableService, tableLocalStorageKeyName);
            }
          },
          (err) => {
            setLoading(false);
            onError && onError(err);
          }
        );
    },
    [route, tableService, tableLocalStorageKeyName, name, transformer, transformerParams, onGetData, onError]
  );

  const handleTableChange = (pagination, filters, sorter) => {
    if (customHandleTableChange) {
      customHandleTableChange({ pagination, filters, sorter, tableService, getTableData, columns });
      return;
    }

    tableService.setPage(pagination.current);
    tableService.setPerPage(pagination.pageSize);
    tableService.removeAllOrderBy().manageOrderBy({ column: sorter.field, type: sorter.order });
    tableService.manageColumnFilters(filters, columns);
    getTableData(tableService.getFilters());
  };

  const handleSearchChange = useCallback(
    (e) => {
      history.replace({
        search: ""
      });
      tableService.setSearchValue(e.target.value);
      tableService.setPage(1);
      setSearch(e.target.value);
      debounceGetData();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debounceGetData, tableService]
  );

  useEffect(() => {
    getTableData(tableService.getFilters());

    return () => {
      !!axiosController[name] && axiosController[name].abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isBoolean(shouldReload) && !isEqual(shouldReload, previousShouldReload)) {
      const currentPage = tableService.getPage();
      const total = tableService.getTotal();
      const perPage = tableService.getPerPage();
      const lastPage = Math.ceil(total / perPage);

      if (
        (data.length === 1 || tableState.deletedItems === data.length) &&
        tableState.deleted &&
        lastPage === currentPage
      ) {
        tableService.setPage(currentPage - 1 || 1);
      }

      let params = tableService.getFilters();
      if (resetFilters) {
        params = tableService.removeAllFilters().getFilters();
        setSearch("");
      }

      getTableData(params);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldReload]);

  const prevStaticData = usePrevious(staticData);
  useEffect(() => {
    if (!isEqual(staticData, prevStaticData)) {
      setData(staticData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [staticData]);

  // General search functionality
  useEffect(() => {
    if (location.state?.generalSearch && location.search) {
      const searchValue = location.search.split("?search=")[1] || "";

      if (searchValue !== tableService.getSearchValue()) {
        tableService.setSearchValue(searchValue);
        tableService.setPage(1);
        getTableData(tableService.getFilters());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const searchColumns = tableService.getSearchColumns();
  const translatedColumns = translateColumnsTitles(columns, t);

  return (
    <div
      className={classNames("outer-table-container", containerClassName, {
        "global-search": searchColumns?.length > 0,
        loading
      })}
      style={{ minHeight: "100%" }}
    >
      <TableFiltersActions
        newItemProps={newItemProps}
        exportProps={exportProps}
        tableService={tableService}
        columns={translatedColumns}
        search={search}
        searchColumns={searchColumns}
        handleSearchChange={handleSearchChange}
        selectedRows={selectedRows.rows}
        massDeleteProps={massDeleteProps}
        tableName={name}
        externalFilters={externalFilters}
        externalActions={externalActions}
        clearFiltersActions={clearFiltersActions}
      />
      <AntdTable
        showSorterTooltip={false}
        scroll={isBoolean(scroll) ? scroll : { x: scroll.x || 1300, y: scroll.y || tableHeight }}
        tableLayout="auto"
        rowKey={(row = {}) => row.id || uniqueId("_table_unique_id_")}
        columns={applyColumnsFilterSort(translatedColumns, tableService)}
        dataSource={data}
        locale={{ emptyText: t(emptyText) }}
        pagination={
          isBoolean(pagination)
            ? pagination
            : {
                current: tableStateParams.page,
                defaultPageSize: tableStateParams.defaultPageSize,
                pageSize: tableStateParams.per_page,
                total: tableStateParams.total,
                ...pagination
              }
        }
        loading={{ spinning: loading, indicator: <Preloader /> }}
        onChange={handleTableChange}
        rowSelection={{
          type: "checkbox",
          selectedRowKeys: selectedRows.keys,
          onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRows({ rows: selectedRows, keys: selectedRowKeys });
            onSelectRows && onSelectRows({ rows: selectedRows, keys: selectedRowKeys });
          },
          getCheckboxProps: (record) => ({
            disabled: disableSelectionRow ? disableSelectionRow(record) : false
          })
        }}
        {...rest}
      />
      {!!footer && footer}
    </div>
  );
};

const mapStateToProps = (state, { name = tableGeneratedName }) => {
  const tableState = state.table ? state.table[name] || {} : {};

  return {
    tableState
  };
};

export default connect(mapStateToProps)(SmartTable);
