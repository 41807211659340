import React from "react";
import { useTranslation } from "react-i18next";
import { fieldRules } from "../methods";
import Form from "../index";
import Checkbox from "../../Checkbox";

export const FORM_ITEM_CHECKBOX = "form-checkbox";

const FormItemCheckbox = (props) => {
  const { name, label, required, rules = [], fieldProps = {}, visible = true, ...rest } = props;
  const { t } = useTranslation();

  if (!visible) {
    return null;
  }

  return (
    <Form.Item
      name={name}
      label={t(label)}
      valuePropName="checked"
      rules={fieldRules(
        {
          required: required
            ? {
                message: t("container.form_errors.required")
              }
            : false
        },
        rules
      )}
      {...rest}
    >
      <Checkbox {...fieldProps} />
    </Form.Item>
  );
};

export default FormItemCheckbox;
