import { APP_LOCAL_STORAGE_BUILD_VERSION_KEY } from "../config";

export const preventDefault = (e) => e.preventDefault();
export const preventDefaultWithCallback = (func) => (e) => {
  e.preventDefault();
  func();
};

export const formatString = (value, defaultReturn = "") => {
  return value || defaultReturn;
};

export const reduceStringLength = (value, size = 45) => {
  return value.length >= size ? `${value.substr(0, size)}...` : value;
};

export const formatURL = (link) => {
  const pattern = /^((http|https|ftp):\/\/)/;
  if (!pattern.test(link)) {
    link = `http://${link}`;
  }
  return link;
};

export const clearTablesFiltersLocalStorage = () => {
  const envBuildVersion = process.env.REACT_APP_BUILD_VERSION;
  const localStorageSavedBuildVersion = localStorage.getItem(APP_LOCAL_STORAGE_BUILD_VERSION_KEY);
  const envBuildVersionToString = envBuildVersion ? envBuildVersion.toString() : "";

  if (envBuildVersionToString && envBuildVersionToString !== localStorageSavedBuildVersion) {
    localStorage.setItem(APP_LOCAL_STORAGE_BUILD_VERSION_KEY, envBuildVersion);
    Object.keys(localStorage || {}).forEach((key) => {
      const keyLowerCase = key ? key.toLowerCase() : "";
      if (keyLowerCase && keyLowerCase.includes("table")) {
        localStorage.removeItem(key);
      }
    });
  }
};

export const formatPhoneNumber = (value) => {
  return value ? value.toString().replace(/([^+0-9 ]+)/, "") : value;
};
