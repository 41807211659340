import { createAction, createReducer } from "@reduxjs/toolkit";

const initialState = {
  historyTemplate: {},
  collapsedSections: []
};

export const updateTemplateState = createAction("history-template/update");
export const resetTemplateState = createAction("history-template/reset");
export const reloadTemplateState = createAction("history-template/reload");

export default createReducer(initialState, (builder) => {
  builder
    .addCase(updateTemplateState, (state, { payload = {} }) => {
      return {
        ...state,
        ...payload
      };
    })
    .addCase(resetTemplateState, () => {
      return initialState;
    })
    .addCase(reloadTemplateState, (state) => {
      return { ...state, reload: new Date().getTime() };
    });
});
