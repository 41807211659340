import React, { useState } from "react";
import { Dropdown } from "antd";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import debounce from "lodash/debounce";
import { useHistory } from "react-router-dom";
import { SearchInput } from "../Input";
import ProtectedComponent from "../_auth/ProtectedComponent";
import {
  PERMISSION_MANAGE_TRG,
  PERMISSION_VIEW_CORE_USERS,
  PERMISSION_VIEW_QAM_AUDITS,
  PERMISSION_VIEW_QAM_CORRECTIVE_ACTIONS,
  PERMISSION_VIEW_TRG_PLANNING
} from "../_auth/permissions/permissions";
import { usePermission } from "../_auth/permissions/usePermission";
import GeneralSearchOverlay, { entitiesSearch } from "./GeneralSearchOverlay";

const GeneralSearch = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const permission = usePermission();
  const [selectedEntity, setSelectedEntity] = useState(
    entitiesSearch.filter((item) => permission.canDo(item.gates))[0]
  );
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [open, setOpen] = useState(false);

  const onSelect = (selected) => {
    setSelectedEntity(selected);
    setDropdownVisible(false);
  };

  const onSearch = (value) => {
    if (!selectedEntity?.path) {
      return;
    }

    history.push({
      pathname: selectedEntity.path,
      search: value ? `search=${value}` : "",
      state: { generalSearch: true }
    });
  };

  return (
    <ProtectedComponent
      gates={[
        PERMISSION_VIEW_CORE_USERS,
        PERMISSION_VIEW_QAM_AUDITS,
        PERMISSION_VIEW_QAM_CORRECTIVE_ACTIONS,
        PERMISSION_MANAGE_TRG,
        PERMISSION_VIEW_TRG_PLANNING
      ]}
    >
      <div className={classNames("general-search", { open })}>
        {open && <i className="hide-search ti-close" onClick={() => setOpen(false)} />}
        <a
          href="/#"
          className="icon-link white"
          onClick={(e) => {
            e.preventDefault();
            setOpen(true);
          }}
        >
          <i className="ti-search" />
        </a>
        <div className="search-field-group">
          <SearchInput
            placeholder={t("container.general_words.search_for")}
            onChange={debounce((e) => onSearch(e.target.value), 1000)}
            onSearch={onSearch}
          />
          <Dropdown
            overlay={<GeneralSearchOverlay onSelect={onSelect} />}
            trigger={["click"]}
            visible={dropdownVisible}
            getPopupContainer={(el) => el}
            onVisibleChange={(vis) => setDropdownVisible(vis)}
            overlayClassName="general-search-overlay"
            placement="bottomRight"
          >
            <div>
              <i className="ti-angle-down" />
              <span>{t(selectedEntity?.title)}</span>
            </div>
          </Dropdown>
        </div>
      </div>
    </ProtectedComponent>
  );
};

export default GeneralSearch;
