import React, { useState } from "react";
import { Dropdown } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import Avatar from "../Avatar";
import { useImage } from "../Image/useImage";
import DropdownUserOverlay from "./DropdownUserOverlay";

const DropdownUser = ({ user }) => {
  const userFiles = user?.files || [];
  const avatar = useImage({ src: userFiles[0]?.sizes?.users?.xs });
  const [visible, setVisible] = useState(false);

  return (
    <Dropdown
      overlay={<DropdownUserOverlay user={user} setVisible={setVisible} />}
      trigger={["click"]}
      overlayClassName="dropdown-user-overlay"
      placement="bottomRight"
      getPopupContainer={(el) => el}
      visible={visible}
      onVisibleChange={(vis) => setVisible(vis)}
    >
      <div className="link user-name-avatar">
        <Avatar src={avatar.src} size={45} icon={<UserOutlined />} title={user?.name} />
        <i className="ti-angle-down" />
      </div>
    </Dropdown>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user
  };
};

export default connect(mapStateToProps)(DropdownUser);
