import moment from "../../moment";

export const headerNotificationsParams = {
  per_page: 5,
  "with[]": ["qualification.training", "training", "user.userDetail", "employee.userDetail", "report", "document"],
  "field[]": "is_seen",
  "op[]": "eq",
  "term[]": "0"
};

export const notificationsTransformer = (data = [], dateFormat) => {
  return data.map((item) => {
    if (item.qualification) {
      item.date = moment(item.qualification.expiry_date).format(dateFormat);
    }
    switch (item.translation_key) {
      case "qualification_missing": {
        item.title = "container.header.notifications.missing_qualification";
        break;
      }
      case "qualification_expired": {
        item.title = "container.header.notifications.expired_qualification";
        break;
      }
      case "qualification_expiring_soon": {
        item.title = "container.header.notifications.expiring_soon_qualification";
        break;
      }
      case "export_ready": {
        item.title = "container.header.notifications.export_ready";
        break;
      }
      case "notify_approver": {
        item.title = "container.header.notifications.notify_approver";
        break;
      }
      default: {
        item.title = null;
        break;
      }
    }

    return item;
  });
};
