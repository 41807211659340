export const contractTypeOptions = [
  {
    transKey: "trg.reporting.contract_status_filter.invalid",
    value: 0
  },
  {
    transKey: "trg.reporting.contract_status_filter.valid",
    value: 1
  }
];

export const qualificationStatusOptions = [
  {
    transKey: "trg.qualifications.qualification_status_filter.valid",
    value: "valid"
  },
  {
    transKey: "trg.qualifications.qualification_status_filter.planned",
    value: "planned"
  },
  {
    transKey: "trg.qualifications.training_not_done",
    value: "pending"
  },
  {
    transKey: "trg.qualifications.qualification_status_filter.expiring_soon",
    value: "expiring_soon"
  },
  {
    transKey: "trg.qualifications.qualification_status_filter.expired",
    value: "expired"
  },
  {
    transKey: "trg.qualifications.qualification_status_filter.expired_expiring",
    value: "expired_expiring"
  },
  {
    transKey: "trg.qualifications.qualification_status_filter.from_previous_function",
    value: "from_previous_function"
  },
  {
    transKey: "trg.qualifications.qualification_status_filter.all_without_previous_function",
    value: "all_without_previous_function"
  }
];
