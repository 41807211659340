import React from "react";
import TableCell from "../../../../../components/SmartTable/TableCell";
import {
  PERMISSION_CREATE_IOR_REPORTS,
  PERMISSION_MANAGE_IOR_REPORTS
} from "../../../../../components/_auth/permissions/permissions";
import { FILTER_TYPE_DATE_RANGE, FILTER_TYPE_STRING } from "../../../../../components/SmartTable/methods";
import DeleteItemButton from "../../../../../components/SmartTable/TableFiltersActions/DeleteItemButton";

export const TABLE_IOR_INTERNAL_NOTES = "table-ior-internal-notes";

export const generateColumns = (permission, dispatch, trans, format) => {
  return [
    {
      title: "ior.internal_notes.note",
      dataIndex: "note",
      filterType: FILTER_TYPE_STRING,
      sorter: true,
      render: (text, row) => {
        return (
          <TableCell minWidth={130} style={{ whiteSpace: "break-spaces" }}>
            {row.text}
          </TableCell>
        );
      }
    },
    {
      title: "ior.internal_notes.user",
      dataIndex: "user",
      sorter: true,
      filterType: FILTER_TYPE_STRING,
      render: (text, row) => {
        return <TableCell minWidth={130}>{row.user.name}</TableCell>;
      }
    },
    {
      title: "ior.new_report.date",
      dataIndex: "date",
      sorter: true,
      filterType: FILTER_TYPE_DATE_RANGE,
      render: (text, row) => <TableCell>{format({ date: row.date })}</TableCell>
    },
    ...(permission.canDo(PERMISSION_MANAGE_IOR_REPORTS) || permission.canDo(PERMISSION_CREATE_IOR_REPORTS)
      ? [
          {
            title: trans("container.general_words.actions"),
            fixed: "right",
            width: 80,
            render: (row = {}) => (
              <div className="table-actions-column flex flex-justify-center">
                <DeleteItemButton
                  tableName={TABLE_IOR_INTERNAL_NOTES}
                  route={`ior/internal-notes/${row.id}`}
                  content={
                    <div>
                      <div>{trans("ior.internal_notes.delete_note_modal_body")}</div>
                    </div>
                  }
                />
              </div>
            )
          }
        ]
      : [])
  ];
};
