import React from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "antd";
import classNames from "classnames";
import { useAxiosRequest } from "../../../../../utils/hooks/useAxios";
import { ReportCollapse } from "../ReportCollapse";
import { PERMISSION_MANAGE_IOR_CORRECTIVE_ACTIONS } from "../../../../../components/_auth/permissions/permissions";
import { usePermission } from "../../../../../components/_auth/permissions/usePermission";
import useReportData from "../../useReportData";
import ReportLeadAuditor from "./ReportLeadAuditor";
import ReportCoAuditors from "./ReportCoAuditors";
import { checkActiveAuditorPeriod, getAvailableInvestigators, prepareUserProperties } from "./methods";

const ReportInvestigators = ({ type, basicDataSaved }) => {
  const { t } = useTranslation();
  const permission = usePermission();
  const report = useReportData();

  const usersRes = useAxiosRequest({
    route: "users",
    params: {
      "with[]": ["auditor", "company"],
      "order_by_column[]": "name",
      "order_by_type[]": "asc",
      "filters[]": "hasIorInvestigatorPermission"
    },
    transformer: (res) => {
      return res.data.data
        .filter((user) => {
          if (user.user_is_in_auditors && user.auditor) {
            return checkActiveAuditorPeriod(user.auditor.active_period_from, user.auditor.active_period_to);
          }
          return true;
        })
        .map((item) => {
          return prepareUserProperties(item, t);
        });
    }
  });
  const availableInvestigators = getAvailableInvestigators(usersRes.data, report);

  const auditorsAvailable = availableInvestigators.filter((item) => item.user_is_in_auditors);

  return (
    <div className={classNames("activity-view-content mb-20")}>
      <ReportCollapse
        className={"mb-15"}
        collapsed={false}
        isSectionDisabled={!basicDataSaved?.basic_data}
        header={{
          title: t("ior.new_report.section_investigators"),
          tooltip: !report?.basic_data?.id
            ? t("ior.new_report.disabled_feedback")
            : permission.canDo(PERMISSION_MANAGE_IOR_CORRECTIVE_ACTIONS)
            ? t("ior.new_report.participants_popover")
            : t("qam.general_words.block_can_not_be_changed_here")
        }}
      >
        <Row className="columns-bordered" gutter={[15, { xs: 15, sm: 15, md: 0 }]} style={{ width: "100%" }}>
          <Col span={12} md={6}>
            <>
              <div className="font-weight-400 mb-5">{t("ior.reports.lead_investigator")}</div>
              <ReportLeadAuditor
                type={type}
                investigators={auditorsAvailable}
                loading={usersRes.loading}
                basicDataSaved={basicDataSaved}
              />
            </>
          </Col>
          <Col span={12} md={6}>
            <div className="font-weight-400 mb-5">{t("ior.reports.lead_coInvestigator")}</div>
            <ReportCoAuditors
              investigators={auditorsAvailable}
              loading={usersRes.loading}
              type={type}
              basicDataSaved={basicDataSaved}
            />
          </Col>
        </Row>
      </ReportCollapse>
    </div>
  );
};

export default ReportInvestigators;
