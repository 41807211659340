import React, { useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import { useTranslation } from "react-i18next";
import { Spin, Upload } from "antd";
import { useDispatch } from "react-redux";
import { preventDefaultWithCallback } from "../../utils/common";
import Tooltip from "../Tooltip";
import { validateImage } from "../Upload/config";
import { closeModalAction, openModalAction } from "../Modal/reducer";
import { Cropper, Shape } from "../Cropper";
import Modal from "../Modal";
import { EditButton } from "../EditButton";

export const MODAL_SIGNATURE_UPLOAD_IMAGE = "MODAL_SIGNATURE_UPLOAD_IMAGE";

const Signature = (props) => {
  const { t } = useTranslation();
  const { onChange, placeholder, initialValue } = props;
  const [editMode, setEditMode] = useState(!initialValue);
  const signatureRef = useRef();
  const dispatch = useDispatch();
  const [imgFile, setImgFile] = useState();
  const [imageSignature, setImageSignature] = useState();

  const onReset = () => {
    signatureRef.current.clear();
    onChange && onChange(null);
  };

  const onCancel = () => {
    setEditMode(false);
    onChange && onChange(initialValue);
  };

  const onEdit = () => {
    setEditMode(true);
    onChange && onChange(null);
  };

  const onSignatureEnd = () => {
    const signaturePng = signatureRef.current.getTrimmedCanvas().toDataURL("image/png");
    onChange && onChange(signaturePng);
  };

  const onCrop = (res) => {
    onChange && onChange(res.base64);
    setEditMode(false);
    setImageSignature(res.base64);
    dispatch(closeModalAction({ name: MODAL_SIGNATURE_UPLOAD_IMAGE }));
  };

  const beforeUpload = (file) => {
    if (validateImage(file, t)) {
      setImgFile(file);
      dispatch(openModalAction({ name: MODAL_SIGNATURE_UPLOAD_IMAGE }));
    }

    return false;
  };

  return (
    <div className="signature-container">
      <Modal name={MODAL_SIGNATURE_UPLOAD_IMAGE} className="custom-cropper-modal">
        <Cropper
          cropperJsOptions={{
            aspectRatio: 5 / 2
          }}
          image={imgFile}
          shape={Shape.RECT}
          preloader={<Spin size="large" />}
          onCancel={() => dispatch(closeModalAction({ name: MODAL_SIGNATURE_UPLOAD_IMAGE }))}
          onCrop={onCrop}
        />
      </Modal>
      <div className="signature-input">
        <div className="actions">
          {editMode && (
            <>
              <Tooltip title={t("container.general_words.reset")}>
                <a href="/" className="mr-10" onClick={preventDefaultWithCallback(onReset)}>
                  <i className="ti-reload" />
                </a>
              </Tooltip>
              <Tooltip title={t("qam.new_auditor.upload_signature")}>
                <Upload listType="picture-card" showUploadList={false} beforeUpload={beforeUpload} accept="image/*">
                  <i className="ti-upload" />
                </Upload>
              </Tooltip>
            </>
          )}
          {!!initialValue && editMode && (
            <Tooltip title={t("container.general_words.cancel")}>
              <a href="/" className="text-danger ml-10" onClick={preventDefaultWithCallback(onCancel)}>
                <i className="ti-close" />
              </a>
            </Tooltip>
          )}
          {(!!initialValue || imageSignature) && !editMode && <EditButton onClick={onEdit} />}
        </div>
        {(initialValue || imageSignature) && !editMode && <img src={imageSignature || initialValue} alt="signature" />}
        {editMode && (
          <SignatureCanvas
            ref={signatureRef}
            penColor="#000"
            canvasProps={{ width: 500, height: 200 }}
            onEnd={onSignatureEnd}
          />
        )}
      </div>
      {placeholder && <div className="signature-placeholder">{t(placeholder)}</div>}
    </div>
  );
};

export default Signature;
