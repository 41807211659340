import React from "react";
import { Alert as AntdAlert } from "antd";
import classNames from "classnames";

const Alert = (props) => {
  const { className, ...rest } = props;
  return <AntdAlert className={classNames("custom-alert", className)} {...rest} />;
};

export default Alert;
