export const PAGE_QAM_ACTIVITIES = "/qam/activities";
export const PAGE_QAM_ACTIVITIES_CALENDAR = "/qam/activities/calendar";
export const PAGE_QAM_ACTIVITY_PROGRAM = "/qam/activity-program";
export const PAGE_QAM_ACTIVITY_CHECKLIST = "/qam/activity-checklist";
export const PAGE_QAM_ACTIVITY_ACTIONS = "/qam/activity-actions";

export const PAGE_QAM_AUDITORS = "/qam/auditors";
export const PAGE_QAM_NEW_AUDITOR = "/qam/auditors/new";
export const PAGE_QAM_EDIT_AUDITOR = "/qam/auditors/edit";

export const PAGE_QAM_AUDITEES = "/qam/auditees";
export const PAGE_QAM_NEW_AUDITEE = "/qam/auditees/new";
export const PAGE_QAM_EDIT_AUDITEE = "/qam/auditees/edit";

export const PAGE_QAM_DASHBOARD = "/qam/dashboard";
export const PAGE_QAM_SETTINGS = "/qam-settings";

export const PAGE_QAM_CORRECTIVE_ACTIONS = "/qam/corrective-actions";
export const PAGE_QAM_CORRECTIVE_ACTION = "/qam/corrective-action";

export const PAGE_QAM_ENTITIES = "/qam/entities";

export const PAGE_QAM_CATALOGUES = "/qam/catalogues";
