import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import isEmpty from "lodash/isEmpty";
import isArray from "lodash/isArray";
import isObject from "lodash/isObject";
import { renderDynamicallyFields } from "../../../../../components/Form/methods";
import { FORM_ITEM_RESOURCE_SELECT_LAZY_LOADING } from "../../../../../components/Form/FormItems/FormItemResourceSelectLazyLoading";
import { FORM_ITEM_DATE_PICKER } from "../../../../../components/Form/FormItems/FormItemDatePicker";
import moment from "../../../../../moment";
import { DATE_SHORT_FORMAT } from "../../../../../components/DatePicker";
import { prepareFields, saveSectionData } from "../methods";
import { FORM_ITEM_TEXT } from "../../../../../components/Form/FormItems/FormItemText";
import { ReportCollapse } from "../ReportCollapse";
import Form from "../../../../../components/Form";
import config from "../../../../../config";
import Button from "../../../../../components/Button";
import { useDateFormat } from "../../../../../utils/hooks/useDateFormat";

const estimationOptions = (t) => [
  { value: "positive", text: t("ior.new_report.estimation.positive") },
  { value: "negative", text: t("ior.new_report.estimation.negative") },
  { value: "neutral", text: t("ior.new_report.estimation.neutral") }
];

export const typeOptions = (t) => [
  { value: "internal", text: t("ior.new_report.responsible.internal") },
  { value: "external", text: t("ior.new_report.responsible.external") }
];

export const ReportInvestigation = ({ form, type, hasError, initialValues, data, basicDataSaved }) => {
  const { t } = useTranslation();
  const { formatLocaleUTC, format } = useDateFormat();
  const [loading, setLoading] = useState(false);
  const investigation = Form.useWatch("investigation", form) || {};
  let hasData = Object.values(investigation)?.some((item) => {
    if (isArray(item) || isObject(item)) {
      return !isEmpty(item);
    } else {
      return !!item;
    }
  });

  const fields = [
    {
      type: FORM_ITEM_RESOURCE_SELECT_LAZY_LOADING,
      required: hasData,
      fieldProps: {
        requestOnMount: true
      },
      apiRoute:
        "ior/types?field%5B%5D=active&op%5B%5D=eq&order_by_column%5B%5D=name&order_by_type%5B%5D=asc&term%5B%5D=1" +
        (data.investigation?.type_id ? "&selected_item_id=" + data.investigation.type_id : ""),
      name: ["investigation", "type_id"],
      label: "ior.new_report.type",
      placeholder: "ior.new_report.type"
    },
    {
      type: FORM_ITEM_RESOURCE_SELECT_LAZY_LOADING,
      fieldProps: {
        requestOnMount: true,
        mode: "multiple",
        transformer: (options) =>
          options.map((option) => ({ ...option, className: !option.active && "option-select-hidden" }))
      },
      apiRoute: "ior/tags?order_by_column%5B%5D=name&order_by_type%5B%5D=asc&term%5B%5D=1",
      name: ["investigation", "tags"],
      label: "ior.new_report.tags",
      placeholder: "ior.new_report.tags"
    },
    {
      type: FORM_ITEM_RESOURCE_SELECT_LAZY_LOADING,
      required: hasData,
      fieldProps: {
        requestOnMount: true
      },
      apiRoute:
        "ior/carriers?field%5B%5D=active&op%5B%5D=eq&order_by_column%5B%5D=name&order_by_type%5B%5D=asc&term%5B%5D=1" +
        (data.investigation?.carrier_id ? "&selected_item_id=" + data.investigation.carrier_id : ""),
      name: ["investigation", "carrier_id"],
      label: "ior.new_report.carrier",
      placeholder: "ior.new_report.carrier"
    },
    {
      type: FORM_ITEM_RESOURCE_SELECT_LAZY_LOADING,
      required: hasData,
      fieldProps: {
        requestOnMount: true
      },
      apiRoute:
        "ior/categories?field%5B%5D=active&op%5B%5D=eq&order_by_column%5B%5D=name&order_by_type%5B%5D=asc&term%5B%5D=1" +
        (data.investigation?.category_id ? "&selected_item_id=" + data.investigation.category_id : ""),
      name: ["investigation", "category_id"],
      label: "ior.new_report.category",
      placeholder: "ior.new_report.category"
    },
    {
      type: FORM_ITEM_RESOURCE_SELECT_LAZY_LOADING,
      required: hasData,
      fieldProps: {
        requestOnMount: true
      },
      apiRoute:
        "ior/issues?field%5B%5D=active&op%5B%5D=eq&order_by_column%5B%5D=name&order_by_type%5B%5D=asc&term%5B%5D=1" +
        (data.investigation?.issue_id ? "&selected_item_id=" + data.investigation.issue_id : ""),
      name: ["investigation", "issue_id"],
      label: "ior.new_report.issue",
      placeholder: "ior.new_report.issue"
    },
    {
      type: FORM_ITEM_RESOURCE_SELECT_LAZY_LOADING,
      required: hasData,
      fieldProps: {
        requestOnMount: true
      },
      apiRoute:
        "ior/liabilities?field%5B%5D=active&op%5B%5D=eq&order_by_column%5B%5D=name&order_by_type%5B%5D=asc&term%5B%5D=1" +
        (data.investigation?.liability_id ? "&selected_item_id=" + data.investigation.liability_id : ""),
      name: ["investigation", "liability_id"],
      label: "ior.new_report.liability",
      placeholder: "ior.new_report.liability"
    },
    {
      type: FORM_ITEM_RESOURCE_SELECT_LAZY_LOADING,
      required: hasData,
      fieldProps: {
        requestOnMount: true
      },
      visible:
        type !== "new" &&
        (data.basic_data.stage === "archived" || data.basic_data.stage === "closed") &&
        data.basic_data?.root_causes_old_format === 1,
      apiRoute:
        "ior/root-causes?field%5B%5D=active&op%5B%5D=eq&order_by_column%5B%5D=name&order_by_type%5B%5D=asc&term%5B%5D=1",
      name: ["investigation", "root_cause_id"],
      label: "ior.new_report.root_cause",
      placeholder: "ior.new_report.root_cause"
    },
    {
      type: FORM_ITEM_RESOURCE_SELECT_LAZY_LOADING,
      required: hasData,
      fieldProps: {
        staticOptions: estimationOptions(t)
      },
      name: ["investigation", "estimation"],
      label: "ior.new_report.estimation.label",
      placeholder: "ior.new_report.estimation.label"
    },
    {
      type: FORM_ITEM_RESOURCE_SELECT_LAZY_LOADING,
      required: hasData,
      fieldProps: {
        staticOptions: typeOptions(t),
        onChange: () => {
          const investigation = form.getFieldValue("investigation");
          form.setFieldsValue({
            investigation: {
              ...investigation,
              responsible_name: undefined,
              responsible_id: undefined
            }
          });
        }
      },
      name: ["investigation", "responsible_type"],
      label: "ior.new_report.responsible.caused_by_type",
      placeholder: "ior.new_report.responsible.caused_by_type"
    },
    {
      type: FORM_ITEM_TEXT,
      max: 200,
      visible: (formInstance) => formInstance.getFieldValue(["investigation", "responsible_type"]) === "external",
      name: ["investigation", "responsible_name"],
      label: "ior.new_report.responsible.caused_by_name",
      placeholder: "ior.new_report.responsible.caused_by_name"
    },
    {
      type: FORM_ITEM_RESOURCE_SELECT_LAZY_LOADING,
      fieldProps: {
        searchColumns: ["name"]
      },
      initialValue: initialValues?.responsible_id
        ? {
            id: initialValues.responsible_id,
            text: initialValues.responsible_internal_name
          }
        : undefined,
      required: true,
      visible: (formInstance) => formInstance.getFieldValue(["investigation", "responsible_type"]) === "internal",
      name: ["investigation", "responsible_id"],
      apiRoute:
        "users/all?field%5B%5D=user_is_in_organisation&filters%5BvalidContract%5D=1&op%5B%5D=eq&order_by_column%5B%5D=name&order_by_type%5B%5D=asc&term%5B%5D=1",
      label: "ior.new_report.responsible.caused_by_name",
      placeholder: "ior.new_report.responsible.caused_by_name"
    },
    {
      type: FORM_ITEM_DATE_PICKER,
      required: hasData,
      name: ["investigation", "deadline"],
      label: "ior.new_report.deadline",
      placeholder: "ior.new_report.deadline",
      disabledDate: (current) => {
        if (!form.getFieldValue(["basic_data", "event_date"])) {
          return false;
        }
        const fullDate = `${moment(form.getFieldValue(["basic_data", "event_date"])).format(
          config.defaultDateFormat
        )} ${moment(form.getFieldValue(["basic_data", "event_time"])).format("HH:mm")}`;

        return moment(current, DATE_SHORT_FORMAT).startOf("day") < moment(fullDate);
      }
    }
  ];

  const saveData = () => {
    const usedData = data?.basic_data?.id ? data : basicDataSaved;
    saveSectionData(
      form,
      usedData,
      setLoading,
      formatLocaleUTC,
      format,
      t("ior.new_report.notifications.investigation_saved"),
      t("ior.new_report.notifications.investigation_not_saved")
    );
  };

  return (
    <div className={classNames("activity-view-content mb-20", { error: hasError })}>
      <ReportCollapse
        collapsed={false}
        isSectionDisabled={!basicDataSaved?.basic_data}
        header={{
          title: t("ior.new_report.section_investigation"),
          tooltip: !data?.basic_data?.id
            ? t("ior.new_report.disabled_feedback")
            : t("ior.new_report.section_investigation_popover")
        }}
      >
        {renderDynamicallyFields(prepareFields(fields, type))}
        <div className="flex flex-justify-end mt-15">
          <Button
            className="min-w-110 ant-btn-sm"
            type="primary"
            icon={<i className="ti-check" />}
            onClick={saveData}
            disabled={loading || type === "view"}
            loading={loading}
          >
            {t("container.general_words.save")}
          </Button>
        </div>
      </ReportCollapse>
    </div>
  );
};
