import React from "react";
import { Helmet } from "react-helmet";
import config from "../../config";

const PageMetaTagTitle = ({ title, appName = config.appName, ...rest }) => {
  return (
    <Helmet {...rest}>
      <title>{title ? `${appName ? `${appName} - ` : ""}${title}` : appName}</title>
    </Helmet>
  );
};

export default PageMetaTagTitle;
