export const getHTMLWords = (html, limit) => {
  const result = {
    html: html,
    wordsNumber: 0,
    charactersLength: 0,
    words: "",
    limitedCharacters: ""
  };

  if (html) {
    const extractedHtmlText = new DOMParser().parseFromString(html, "text/html").documentElement.textContent;
    if (extractedHtmlText.length > 0) {
      result.wordsNumber = extractedHtmlText.split(" ").length;
      result.words = extractedHtmlText;
      result.charactersLength = extractedHtmlText.length;
      if (limit) {
        result.limitedCharacters = extractedHtmlText.slice(0, limit);
      }
    }
  }

  return result;
};
