import i18next from "i18next";
import { useEffect, useState } from "react";
import { usePrevious } from "../../utils/hooks/usePrevious";
import { getLocaleByLanguage } from "./methods";

const useLocale = () => {
  const { language } = i18next;
  const previousLanguage = usePrevious(language);
  const [locale, setLocale] = useState(getLocaleByLanguage(language));

  useEffect(() => {
    if (language && language !== previousLanguage) {
      setLocale(getLocaleByLanguage(language));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  return locale;
};

export default useLocale;
