import { createAction, createReducer } from "@reduxjs/toolkit";

const initialState = {};

export const reloadTable = createAction("table/reload");

export default createReducer(initialState, (builder) => {
  builder.addCase(reloadTable, (state, action) => {
    return {
      ...state,
      [action.payload.name]: {
        ...(action.payload || {}),
        shouldReload: !state[action.payload.name]?.shouldReload,
        resetFilters: action.payload.resetFilters
      }
    };
  });
});
