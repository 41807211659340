import React from "react";
import { useTranslation } from "react-i18next";
import isFunction from "lodash/isFunction";
import { fieldRules } from "../methods";
import Form from "../index";
import TimePicker from "../../TimePicker";

export const FORM_ITEM_TIME_PICKER = "form-timepicker";

const FormItemTimePicker = (props) => {
  const { name, label, required, placeholder, rules = [], fieldProps = {}, visible = true, ...rest } = props;
  const { t } = useTranslation();

  if (!visible) {
    return null;
  }

  const formField = (
    <Form.Item
      name={name}
      label={t(label)}
      rules={fieldRules(
        {
          required: required
            ? {
                message: t("container.form_errors.required")
              }
            : false
        },
        rules
      )}
      {...rest}
    >
      <TimePicker placeholder={t(placeholder)} {...fieldProps} />
    </Form.Item>
  );

  if (isFunction(visible)) {
    return (
      <Form.Item noStyle shouldUpdate>
        {(formInstance) => {
          const isVisible = visible(formInstance);
          if (isVisible) {
            return isVisible ? formField : null;
          }
        }}
      </Form.Item>
    );
  }

  return formField;
};

export default FormItemTimePicker;
