import React, { useCallback, useState, useEffect } from "react";
import classNames from "classnames";
import { Select } from "antd";
import debounce from "lodash/debounce";
import qs from "qs";
import uniqBy from "lodash/uniqBy";
import { useTranslation } from "react-i18next";
import isEqual from "lodash/isEqual";
import isString from "lodash/isString";
import Preloader from "../Preloader";
import axios from "../../axios";
import { usePrevious } from "../../utils/hooks/usePrevious";
import i18n from "../../i18n";
import { antdSelectFilterOption, customAntdSelectFilterOption, prepareInitialOptions } from "./methods";

const ResourceSelectLazyLoading = (props) => {
  const {
    className,
    apiRoute,
    onSelect,
    value,
    searchIn,
    mode,
    searchColumns,
    requestOnMount,
    onGetOptions,
    onLanguageChange,
    onDropdownVisibleChange,
    customParams,
    allowClear = true,
    perPage = 100,
    page = 1,
    initialValue = {},
    showSearch = true,
    showArrow = true,
    staticOptions = [],
    overwritePreviousOptions = false,
    refetchOptions = false,
    textAccessor = (option = {}) => option.text || option.name,
    valueAccessor = (option = {}) => option.id || option.value,
    onChange = (val) => val,
    transformer = (options) => options,
    beforeOptionRender = (option) => option,
    shouldReset,
    ...rest
  } = props;

  const { language } = i18n;
  const { t } = useTranslation();
  const [options, setOptions] = useState(
    prepareInitialOptions(initialValue, textAccessor, valueAccessor, staticOptions)
  );
  const [params, setParams] = useState({ per_page: perPage, page });
  const [localValue, setLocalValue] = useState(value || undefined);
  const [firstRequestMade, setFirstRequestMade] = useState(false);
  const [loading, setLoading] = useState({
    initial: false,
    lazy: false,
    onMount: false
  });

  // Refetch options
  const prevRefetchOptions = usePrevious(refetchOptions);
  useEffect(() => {
    if (refetchOptions && refetchOptions !== prevRefetchOptions) {
      getOptions({
        onStart: () => setLoading({ ...loading, onMount: true }),
        onSuccess: () => setLoading({ ...loading, onMount: false }),
        onFailed: () => setLoading({ ...loading, onMount: false }),
        removeExistingOptions: true
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetchOptions]);

  // reset filters when clear filters
  const prevShouldReset = usePrevious(shouldReset);
  useEffect(() => {
    if (shouldReset && shouldReset !== prevShouldReset) {
      if (mode === "multiple") {
        setLocalValue([]);
      } else {
        setLocalValue(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldReset]);

  // update options translations after language change
  const prevLanguage = usePrevious(language);
  useEffect(() => {
    if (onLanguageChange && language !== prevLanguage) {
      setOptions(onLanguageChange(options));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  // Insert initialValue in options list
  const previousInitialValue = usePrevious(initialValue);
  useEffect(() => {
    if (!isEqual(initialValue, previousInitialValue)) {
      setOptions(prepareInitialOptions(initialValue, textAccessor, valueAccessor, options));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue]);

  // Update options with new static options
  const previousStaticOptions = usePrevious(staticOptions);

  useEffect(() => {
    if (!isEqual(staticOptions, previousStaticOptions)) {
      setOptions(
        prepareInitialOptions(
          initialValue,
          textAccessor,
          valueAccessor,
          overwritePreviousOptions ? staticOptions : [...staticOptions, ...options]
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [staticOptions]);

  // Update localValue when value from props is changed
  useEffect(() => {
    if (value !== localValue) {
      setLocalValue(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (requestOnMount) {
      getOptions({
        onStart: () => setLoading({ ...loading, onMount: true }),
        onSuccess: () => setLoading({ ...loading, onMount: false }),
        onFailed: () => setLoading({ ...loading, onMount: false })
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestOnMount]);

  // method to get data from request
  const getOptions = useCallback(
    (p = {}) => {
      if (!apiRoute) {
        return;
      }

      const {
        reqParams = {},
        onStart = () => {},
        onSuccess = () => {},
        onFailed = () => {},
        removeExistingOptions
      } = p;
      const preparedParams = { ...reqParams, ...customParams };

      onStart();

      axios
        .get(apiRoute, {
          params: preparedParams,
          paramsSerializer: (preparedParams) => {
            return qs.stringify(preparedParams, { arrayFormat: "brackets" });
          }
        })
        .then(
          (res) => {
            let pager = res.data.data.pager;
            let data = res.data.data.data;

            if (!pager && !data) {
              data = res.data.data;
            }

            const preparedOptions = [...(removeExistingOptions ? [] : options), ...transformer([...data])].map(
              (option) => ({
                ...option,
                text: textAccessor(option),
                value: valueAccessor(option)
              })
            );
            const preparedUniqueOptions = uniqBy(preparedOptions, (option) => valueAccessor(option));
            setOptions(preparedUniqueOptions);
            onSuccess(data || [], pager || {});
            onGetOptions && onGetOptions(preparedUniqueOptions, value);

            if (!firstRequestMade) {
              setFirstRequestMade(true);
            }
          },
          (error) => {
            onFailed(error);
          }
        );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [options, apiRoute, firstRequestMade, customParams]
  );

  // call get method when dropdown is visible
  const onVisibleChange = useCallback(
    (visible) => {
      onDropdownVisibleChange && onDropdownVisibleChange(visible);
      if ((visible && options.length === 0) || !firstRequestMade) {
        getOptions({
          reqParams: params,
          onStart: () => {
            setLoading({
              ...loading,
              initial: true
            });
          },
          onSuccess: (data, pager) => {
            setLoading({
              ...loading,
              initial: false
            });
            setParams({
              ...params,
              page: pager.current,
              total: pager.totalItems
            });
          },
          onFailed: () => {
            setLoading({
              ...loading,
              initial: false
            });
          }
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [options, params, getOptions, firstRequestMade]
  );

  // call get method when dropdown is scrolled
  const onScroll = useCallback(
    (event) => {
      const { target } = event;
      const isLastPage = params.per_page * params.page > params.total;

      if (isLastPage || !params.page) {
        return;
      }

      if (!loading.initial && !loading.lazy && target.scrollTop + target.offsetHeight === target.scrollHeight) {
        getOptions({
          reqParams: {
            ...params,
            page: params.page + 1
          },
          onStart: () => {
            setLoading({
              ...loading,
              lazy: true
            });
          },
          onSuccess: (data, pager) => {
            setLoading({
              ...loading,
              lazy: false
            });
            setParams({
              ...params,
              page: pager.current,
              total: pager.totalItems
            });
          },
          onFailed: () => {
            setLoading({
              ...loading,
              lazy: false
            });
          }
        });
      }
    },
    [loading, params, getOptions]
  );

  // call get method when search input exists
  const onSearch = useCallback(
    (searchVal) => {
      if (!searchColumns) {
        return;
      }
      getOptions({
        reqParams: {
          ...params,
          page: 1,
          searchValue: searchVal,
          searchColumns: searchColumns
        },
        onStart: () => {
          setLoading({
            ...loading,
            lazy: false,
            initial: true
          });
        },
        onSuccess: (data, pager) => {
          setLoading({
            ...loading,
            initial: false
          });
          setParams({
            ...params,
            page: pager.current,
            total: pager.totalItems
          });
        },
        onFailed: () => {
          setLoading({
            ...loading,
            initial: false
          });
        }
      });
    },
    [params, loading, getOptions, searchColumns]
  );

  const onChangeValue = useCallback(
    (val) => {
      onChange(val, val ? options.filter((option) => valueAccessor(option) === val)[0] : null);
      setLocalValue(val);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [options]
  );

  const handleOnSelect = useCallback(
    (val) => {
      if (onSelect) {
        onSelect(val, options.filter((option) => valueAccessor(option) === val)[0]);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onSelect, options]
  );

  return (
    <Select
      size="large"
      allowClear={allowClear}
      mode={mode}
      showSearch={showSearch}
      showArrow={showArrow}
      dropdownMatchSelectWidth={false}
      value={loading.onMount ? undefined : localValue}
      disabled={loading.onMount}
      optionFilterProp="children"
      className={classNames("resource-select", className, { "is-searchable": showSearch })}
      getPopupContainer={(el) => el}
      filterOption={searchIn && searchIn.length > 0 ? customAntdSelectFilterOption(searchIn) : antdSelectFilterOption}
      onDropdownVisibleChange={onVisibleChange}
      onPopupScroll={onScroll}
      onSearch={showSearch ? debounce(onSearch, 300) : null}
      notFoundContent={
        loading.initial ? (
          <Preloader style={{ width: "100%", textAlign: "center" }} width={20} />
        ) : (
          <p className="m-0">{t("container.general_words.no_data_message")}</p>
        )
      }
      {...rest}
      onChange={onChangeValue}
      onSelect={handleOnSelect}
    >
      {options.map((option) => {
        const preparedOption = beforeOptionRender(option);
        const text = preparedOption.transKey ? t(preparedOption.transKey) : preparedOption.text;
        return (
          <Select.Option
            key={preparedOption.value}
            value={preparedOption.value}
            title={isString(text) ? text : preparedOption.title}
            disabled={preparedOption.disabled}
            data={preparedOption}
            className={preparedOption.className}
          >
            {text}
            {preparedOption.subText && <div>{preparedOption.subText}</div>}
          </Select.Option>
        );
      })}
      {(loading.lazy || (options.length > 0 && loading.initial)) && (
        <Select.Option key="loading" disabled>
          <Preloader />
        </Select.Option>
      )}
    </Select>
  );
};

export default ResourceSelectLazyLoading;
