import React from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import classNames from "classnames";

/**
 * Preloader
 * @function Preloader
 * @description Preloader component
 */
const Preloader = ({ className, size, style = {} }) => (
  <div className={classNames("preloader", className)}>
    <Spin indicator={<LoadingOutlined style={{ fontSize: size || 24, ...style }} spin />} />
  </div>
);

export default Preloader;
