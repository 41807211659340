import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Tooltip from "../../../components/Tooltip";
import axios from "../../../axios";
import { openGeneralErrorNotification } from "../../../components/Notification";
import Preloader from "../../../components/Preloader";

const MarkAsSeen = ({ notification = {}, onSuccess }) => {
  const [seen, setSeen] = useState(null);
  const { is_seen } = notification;
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const markAsSeen = () => {
    if (loading) {
      return;
    }

    setLoading(true);
    axios.put(`notifications/mark-as-seen/${notification.id}`).then(
      () => {
        setSeen(true);
        setLoading(false);
        onSuccess && onSuccess();
      },
      (error) => {
        openGeneralErrorNotification({ error, defaultError: t("container.form_errors.internal_server_error") });
        setLoading(false);
      }
    );
  };

  return (
    <span className="mr-15">
      {seen || is_seen ? (
        <i className="ti-check text-primary" />
      ) : (
        <Tooltip title={t("container.notifications_page.mark_seen")}>
          {loading ? (
            <Preloader size={14} />
          ) : (
            <i onClick={markAsSeen} className="ti-check" style={{ cursor: "pointer" }} />
          )}
        </Tooltip>
      )}
    </span>
  );
};

export default MarkAsSeen;
