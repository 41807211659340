import React from "react";
import { Tag as AntdTag } from "antd";
import classNames from "classnames";

const Tag = (props) => {
  const { className, ...rest } = props;
  return <AntdTag {...rest} className={classNames("custom-tag", className)} />;
};

export default Tag;
