import { Layout } from "antd";

/**
 * Main Container
 * @param  {object} props: { children, className, style }
 * @description Container page component
 */
const MainContainer = ({ children }) => <Layout.Content className="layout-content-inner">{children}</Layout.Content>;

export default MainContainer;
