import React from "react";
import { PhoneOutlined } from "@ant-design/icons";
import { formatURL } from "../../../utils/common";

const EntitiesContactColumn = ({ row = {} }) => {
  const email = row.email ? (
    <div className="flex flex-items-center">
      <i className="ti-email fs-12 mr-5 text-muted" />
      <a href={`mailto:${row.email}`} target="_blank" rel="noreferrer">
        {row.email}
      </a>
    </div>
  ) : null;
  const phone = row.phone ? (
    <div className="flex flex-items-center">
      <PhoneOutlined className="fs-12 mr-5 text-muted" />
      <a href={`tel:${row.phone}`} rel="noreferrer">
        {row.phone}
      </a>
    </div>
  ) : null;
  const website = row.url ? (
    <div className="flex flex-items-center">
      <i className="ti-link fs-12 mr-5 text-muted" />
      <a href={formatURL(row.url)} rel="noreferrer" target="_blank">
        {row.url}
      </a>
    </div>
  ) : null;

  return (
    <div>
      {!email && !phone && !website && "-"}
      {email}
      {phone}
      {website}
    </div>
  );
};

export default EntitiesContactColumn;
