import { createAction, createReducer } from "@reduxjs/toolkit";

const initialState = {
  reload: null
};

export const reloadCalendar = createAction("calendar/reload");

export default createReducer(initialState, (builder) => {
  builder.addCase(reloadCalendar, (state) => {
    return {
      ...state,
      reload: !state.reload
    };
  });
});
