import { createAction, createReducer } from "@reduxjs/toolkit";

export const documentCriteriaInitialState = {
  classification: undefined,
  searchParagraphHeader: undefined,
  searchAll: undefined
};

export const updateCriteriaFilters = createAction("document/criteria-filters");

export default createReducer(documentCriteriaInitialState, (builder) => {
  builder.addCase(updateCriteriaFilters, (state, { payload = {} }) => {
    return {
      ...state,
      ...payload
    };
  });
});
