import { createAction, createReducer } from "@reduxjs/toolkit";

const initialState = {
  classified: undefined,
  classifiedRemarks: undefined,
  attachment: undefined,
  classification: undefined,
  searchParagraphHeader: undefined,
  searchNormStandard: undefined,
  search: undefined,
  withCorrectiveActions: undefined
};

export const updateCriteriaFilters = createAction("activity/criteria-filters");

export default createReducer(initialState, (builder) => {
  builder.addCase(updateCriteriaFilters, (state, { payload = {} }) => {
    return {
      ...state,
      ...payload
    };
  });
});
