import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { renderDynamicallyFields } from "../../../../../components/Form/methods";
import { FORM_ITEM_TEXT_AREA } from "../../../../../components/Form/FormItems/FormItemTextArea";
import { FORM_ITEM_DATE_PICKER } from "../../../../../components/Form/FormItems/FormItemDatePicker";
import moment from "../../../../../moment";
import { DATE_SHORT_FORMAT } from "../../../../../components/DatePicker";
import { FORM_ITEM_RESOURCE_SELECT_LAZY_LOADING } from "../../../../../components/Form/FormItems/FormItemResourceSelectLazyLoading";
import { FORM_ITEM_NUMBER } from "../../../../../components/Form/FormItems/FormItemNumber";
import { FORM_ITEM_TEXT } from "../../../../../components/Form/FormItems/FormItemText";
import { prepareFields, saveSectionData } from "../methods";
import { ReportCollapse } from "../ReportCollapse";
import Form from "../../../../../components/Form";
import { typeOptions } from "../ReportInvestigation";
import { severityOptions } from "../ReportFirstRiskAnalysis";
import config from "../../../../../config";
import Button from "../../../../../components/Button";
import { useDateFormat } from "../../../../../utils/hooks/useDateFormat";
import { REPORT_ARCHIVED } from "../../../config";

export const ReportSecondRiskAnalysis = ({ form, data, type, hasError, basicDataSaved }) => {
  const { t } = useTranslation();
  const secondAnalysis = Form.useWatch("second_risk_analysis", form) || {};
  let hasData = Object.values(secondAnalysis)?.some((item) => !!item);
  const { formatLocaleUTC, format } = useDateFormat();
  const [loading, setLoading] = useState(false);
  const reportStage = data?.basic_data?.stage === REPORT_ARCHIVED;

  const fields = [
    {
      visible: reportStage,
      type: FORM_ITEM_TEXT_AREA,
      max: 4000,
      required: hasData,
      name: ["second_risk_analysis", "second_analysis_corrective_action"],
      label: "ior.new_report.corrective_action_taken",
      placeholder: "ior.new_report.corrective_action_taken_placeholder"
    },
    {
      visible: reportStage,
      type: FORM_ITEM_DATE_PICKER,
      required: hasData,
      name: ["second_risk_analysis", "second_analysis_date"],
      label: "ior.new_report.date",
      placeholder: "ior.new_report.date",
      disabledDate: (current) => {
        if (!form.getFieldValue(["basic_data", "event_date"])) {
          return false;
        }
        const fullDate = `${moment(form.getFieldValue(["basic_data", "event_date"])).format(
          config.defaultDateFormat
        )} ${moment(form.getFieldValue(["basic_data", "event_time"])).format("HH:mm")}`;

        return moment(current, DATE_SHORT_FORMAT).startOf("day") < moment(fullDate);
      }
    },
    {
      visible: reportStage,
      type: FORM_ITEM_RESOURCE_SELECT_LAZY_LOADING,
      required: hasData,
      fieldProps: {
        staticOptions: typeOptions(t),
        onChange: () => {
          const secondRiskAnalysis = form.getFieldValue("second_risk_analysis");
          form.setFieldsValue({
            second_risk_analysis: {
              ...secondRiskAnalysis,
              second_analysis_responsible_name: undefined,
              second_analysis_responsible_id: undefined
            }
          });
        }
      },
      name: ["second_risk_analysis", "second_analysis_responsible_type"],
      label: "ior.new_report.responsible.type",
      placeholder: "ior.new_report.responsible.type"
    },
    {
      type: FORM_ITEM_TEXT,
      max: 200,
      required: hasData,
      visible: (formInstance) =>
        formInstance.getFieldValue(["second_risk_analysis", "second_analysis_responsible_type"]) === "external",
      name: ["second_risk_analysis", "second_analysis_responsible_name"],
      label: "ior.new_report.responsible.name",
      placeholder: "ior.new_report.responsible.name"
    },
    {
      required: hasData,
      type: FORM_ITEM_RESOURCE_SELECT_LAZY_LOADING,
      visible: (formInstance) =>
        formInstance.getFieldValue(["second_risk_analysis", "second_analysis_responsible_type"]) === "internal",
      name: ["second_risk_analysis", "second_analysis_responsible_id"],
      label: "ior.new_report.responsible.name",
      fieldProps: {
        apiRoute:
          "users/all?field%5B%5D=user_is_in_organisation&filters%5BvalidContract%5D=1&op%5B%5D=eq&order_by_column%5B%5D=name&order_by_type%5B%5D=asc&term%5B%5D=1",
        requestOnMount: true,
        searchColumns: ["name"]
      },
      placeholder: "ior.new_report.responsible.name"
    },
    {
      type: FORM_ITEM_RESOURCE_SELECT_LAZY_LOADING,
      required: hasData,
      fieldProps: {
        staticOptions: severityOptions,
        onChange: (value) => {
          const secondRiskAnalysis = form.getFieldValue("second_risk_analysis");
          const probability = secondRiskAnalysis.second_analysis_probability;
          probability &&
            secondRiskAnalysis &&
            form.setFieldsValue({
              second_risk_analysis: { ...secondRiskAnalysis, second_analysis_conclusion: probability * value }
            });
        }
      },
      name: ["second_risk_analysis", "second_analysis_severity"],
      label: "ior.new_report.severity",
      placeholder: "ior.new_report.severity"
    },
    {
      required: hasData,
      type: FORM_ITEM_NUMBER,
      name: ["second_risk_analysis", "second_analysis_probability"],
      label: "ior.new_report.probability",
      placeholder: "ior.new_report.probability",
      fieldProps: {
        min: 0,
        max: 999,
        onChange: (value) => {
          const secondRiskAnalysis = form.getFieldValue("second_risk_analysis");
          const severity = secondRiskAnalysis.second_analysis_severity;
          secondRiskAnalysis &&
            severity &&
            form.setFieldsValue({
              second_risk_analysis: { ...secondRiskAnalysis, second_analysis_conclusion: severity * value }
            });
        }
      }
    },
    {
      type: FORM_ITEM_TEXT,
      fieldProps: {
        disabled: true
      },
      name: ["second_risk_analysis", "second_analysis_conclusion"],
      label: "ior.new_report.conclusion",
      placeholder: "ior.new_report.conclusion"
    }
  ];

  const saveData = () => {
    saveSectionData(
      form,
      basicDataSaved,
      setLoading,
      formatLocaleUTC,
      format,
      t("ior.new_report.notifications.second_risk_analysis_saved"),
      t("ior.new_report.notifications.second_risk_analysis_not_saved")
    );
  };

  return (
    <div className={classNames("activity-view-content mb-20", { error: hasError })}>
      <ReportCollapse
        collapsed={false}
        header={{
          title: t("ior.new_report.section_second_risk_analysis"),
          tooltip: t("ior.new_report.section_second_risk_analysis_popover")
        }}
      >
        {renderDynamicallyFields(prepareFields(fields, type))}
        <div className="flex flex-justify-end mt-15">
          <Button
            className="min-w-110 ant-btn-sm"
            type="primary"
            icon={<i className="ti-check" />}
            onClick={saveData}
            disabled={loading || type === "view"}
            loading={loading}
          >
            {t("container.general_words.save")}
          </Button>
        </div>
      </ReportCollapse>
    </div>
  );
};
