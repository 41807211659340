import { createAction, createReducer } from "@reduxjs/toolkit";

const initialState = {
  reload: undefined,
  loadingSections: {
    keywords: undefined,
    criteria: undefined,
    tasks: undefined,
    additionalDocuments: undefined,
    keywordsTasks: undefined
  }
};

export const updateDocumentData = createAction("document/update");
export const reloadDocumentData = createAction("document/reload");
export const resetDocumentData = createAction("document/reset");
export const updateDocumentLoadingSections = createAction("document/loading-sections");

export default createReducer(initialState, (builder) => {
  builder
    .addCase(updateDocumentData, (state, { payload = {} }) => {
      const { onMount, isReload, ...payloadRest } = payload;
      return {
        ...(onMount ? { missingFields: [] } : state),
        ...(isReload ? { reloadTime: new Date().getTime() } : {}),
        ...payloadRest
      };
    })
    .addCase(reloadDocumentData, (state, { payload = {} }) => {
      return {
        ...state,
        ...payload,
        reload: new Date().getTime()
      };
    })
    .addCase(resetDocumentData, () => {
      return {
        reload: undefined
      };
    })
    .addCase(updateDocumentLoadingSections, (state, action) => {
      return {
        ...state,
        loadingSections: {
          ...state.loadingSections,
          ...action.payload.loadingSections
        }
      };
    });
});
