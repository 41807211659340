import React from "react";
import { TimePicker as AntdTimePicker } from "antd";
import moment from "../../moment";
import useLocale from "../DatePicker/useLocale";

export const TIME_SHORT_FORMAT = "HH:mm";

export const formatDateToTime = (date, format = TIME_SHORT_FORMAT) => {
  return date ? moment(date).format(format) : date;
};

const TimePicker = (props) => {
  const locale = useLocale();
  const { format = TIME_SHORT_FORMAT, ...rest } = props;
  return <AntdTimePicker format={format} size="large" locale={locale} {...rest} />;
};

export default TimePicker;
