import {
  PAGE_AUDIT_LOG,
  PAGE_DASHBOARD,
  PAGE_EDIT_GROUP,
  PAGE_EDIT_USER,
  PAGE_GROUPS,
  PAGE_MAIN_SETTINGS,
  PAGE_NEW_GROUP,
  PAGE_NEW_USER,
  PAGE_NOTIFICATIONS,
  PAGE_USERS,
  PAGE_VIEW_USER
} from "../../pages/Core/routes";
import {
  PAGE_EDIT_ORGANIZATION_STAFF,
  PAGE_NEW_ORGANIZATION_STAFF,
  PAGE_ORGANIZATION_CHART,
  PAGE_ORGANIZATION_SETTINGS,
  PAGE_ORGANIZATION_STAFF
} from "../../pages/Organization/routes";
import {
  PAGE_QAM_ACTIVITIES,
  PAGE_QAM_ACTIVITIES_CALENDAR,
  PAGE_QAM_ACTIVITY_ACTIONS,
  PAGE_QAM_ACTIVITY_CHECKLIST,
  PAGE_QAM_ACTIVITY_PROGRAM,
  PAGE_QAM_AUDITEES,
  PAGE_QAM_AUDITORS,
  PAGE_QAM_CATALOGUES,
  PAGE_QAM_CORRECTIVE_ACTION,
  PAGE_QAM_CORRECTIVE_ACTIONS,
  PAGE_QAM_DASHBOARD,
  PAGE_QAM_EDIT_AUDITEE,
  PAGE_QAM_EDIT_AUDITOR,
  PAGE_QAM_ENTITIES,
  PAGE_QAM_NEW_AUDITEE,
  PAGE_QAM_NEW_AUDITOR,
  PAGE_QAM_SETTINGS
} from "../../pages/Qam/routes";
import {
  PAGE_RSM_REPORTING,
  PAGE_RSM_PUBLICATIONS,
  PAGE_RSM_SETTINGS,
  PAGE_RSM_MY_PUBLICATIONS,
  PAGE_RSM_DASHBOARD,
  PAGE_RSM_NEW_PUBLICATION,
  PAGE_RSM_EDIT_PUBLICATION,
  PAGE_RSM_VIEW_PUBLICATION,
  PAGE_RSM_VIEW_MY_PUBLICATION
} from "../../pages/Rsm/routes";
import {
  PAGE_IOR_MY_REPORTS,
  PAGE_IOR_REPORTS,
  PAGE_IOR_SETTINGS,
  PAGE_IOR_DASHBOARD,
  PAGE_IOR_VIEW_REPORT,
  PAGE_IOR_EDIT_REPORT,
  PAGE_IOR_NEW_REPORT,
  PAGE_IOR_ASSIGNEES,
  PAGE_IOR_INVESTIGATORS,
  PAGE_IOR_CORRECTIVE_ACTIONS,
  PAGE_IOR_CORRECTIVE_ACTION,
  PAGE_IOR_EDIT_INVESTIGATOR,
  PAGE_IOR_NEW_INVESTIGATOR,
  PAGE_IOR_NEW_ASSIGNEE,
  PAGE_IOR_EDIT_ASSIGNEE
} from "../../pages/Ior/routes";
import {
  PAGE_TRG_DASHBOARD,
  PAGE_TRG_EDIT_PLANNING,
  PAGE_TRG_EXTERNAL_PROVIDERS,
  PAGE_TRG_MASS_ASSIGNMENT,
  PAGE_TRG_NEW_PLANNING,
  PAGE_TRG_PLANNING,
  PAGE_TRG_PLANNING_CALENDAR,
  PAGE_TRG_QUALIFICATIONS,
  PAGE_TRG_REPORTING,
  PAGE_TRG_SETTINGS,
  PAGE_TRG_TRAINING_MATRIX,
  PAGE_TRG_TRAINING_PROGRAM,
  PAGE_TRG_TRAININGS,
  PAGE_TRG_VIEW_PLANNING
} from "../../pages/Trg/routes";
import {
  PAGE_ADM_DRAFTS,
  PAGE_ADM_EDIT_REPORT,
  PAGE_ADM_EXPORTS,
  PAGE_ADM_NEW_REPORT,
  PAGE_ADM_REPORT,
  PAGE_ADM_REPORTS
} from "../../pages/Adm/routes";
import {
  PAGE_DOM_APPROVERS,
  PAGE_DOM_DASHBOARD,
  PAGE_DOM_TEMPLATES,
  PAGE_DOM_DOCUMENTS,
  PAGE_DOM_EDIT_APPROVER,
  PAGE_DOM_EDIT_RESPONSIBLE_PERSON,
  PAGE_DOM_ENTITIES,
  PAGE_DOM_NEW_APPROVER,
  PAGE_DOM_NEW_RESPONSIBLE_PERSON,
  PAGE_DOM_RESPONSIBLE_PERSONS,
  PAGE_DOM_SETTINGS,
  PAGE_DOM_VIEW_TEMPLATE_HISTORY,
  PAGE_DOM_EDIT_HISTORY_TEMPLATE,
  PAGE_DOM_DOCUMENT,
  PAGE_DOM_HISTORY_DOCUMENT,
  PAGE_DOM_DOCUMENTS_MASS_DUPLICATION
} from "../../pages/Dom/routes";
import {
  PERMISSION_CREATE_IOR_REPORTS,
  PERMISSION_MANAGE_ADM,
  PERMISSION_MANAGE_CORE_SETTINGS,
  PERMISSION_MANAGE_IOR_REPORTS,
  PERMISSION_VIEW_IOR_ALL_REPORTS,
  PERMISSION_MANAGE_IOR_SETTINGS,
  PERMISSION_MANAGE_ORG_SETTINGS,
  PERMISSION_MANAGE_QAM_SETTINGS,
  PERMISSION_MANAGE_RSM_PUBLICATIONS,
  PERMISSION_MANAGE_RSM_SETTINGS,
  PERMISSION_MANAGE_TRG,
  PERMISSION_MANAGE_TRG_SETTINGS,
  PERMISSION_VIEW_ADM,
  PERMISSION_VIEW_CORE_AUDIT_LOG,
  PERMISSION_VIEW_CORE_GROUPS,
  PERMISSION_VIEW_CORE_NOTIFICATIONS,
  PERMISSION_VIEW_CORE_USERS,
  PERMISSION_VIEW_IOR_REPORTS,
  PERMISSION_VIEW_IOR_SETTINGS,
  PERMISSION_VIEW_ORG_CHART,
  PERMISSION_VIEW_ORG_SETTINGS,
  PERMISSION_VIEW_ORG_STAFF,
  PERMISSION_VIEW_QAM_AUDITEES,
  PERMISSION_VIEW_QAM_AUDITORS,
  PERMISSION_VIEW_QAM_AUDITS,
  PERMISSION_VIEW_QAM_CORRECTIVE_ACTIONS,
  PERMISSION_VIEW_QAM_DASHBOARD,
  PERMISSION_VIEW_QAM_ENTITIES,
  PERMISSION_VIEW_QAM_NORMS,
  PERMISSION_VIEW_QAM_ROOT_CAUSES,
  PERMISSION_VIEW_QAM_SETTINGS,
  PERMISSION_VIEW_RSM_PUBLICATIONS,
  PERMISSION_VIEW_RSM_REPORTING,
  PERMISSION_VIEW_RSM_SETTINGS,
  PERMISSION_VIEW_TRG_PLANNING,
  PERMISSION_VIEW_TRG_SETTINGS,
  PERMISSION_VIEW_IOR_CORRECTIVE_ACTIONS,
  PERMISSION_VIEW_IOR_ROOT_CAUSES,
  PERMISSION_VIEW_IOR_ASSIGNEES,
  PERMISSION_VIEW_IOR_INVESTIGATORS,
  PERMISSION_VIEW_DOM_DASHBOARD,
  PERMISSION_VIEW_DOM_DOCUMENTS,
  PERMISSION_VIEW_DOM_ENTITIES,
  PERMISSION_VIEW_DOM_SETTINGS,
  PERMISSION_MANAGE_DOM_SETTINGS,
  PERMISSION_VIEW_DOM_RESPONSIBLE_PERSONS,
  PERMISSION_VIEW_DOM_APPROVERS,
  PERMISSION_VIEW_DOM_TEMPLATES
} from "./permissions/permissions";

export const NAV_CORE_DASHBOARD = {
  key: "dashboard",
  name: "container.sidebar.dashboard",
  path: PAGE_DASHBOARD,
  iconClassName: "ti-dashboard"
};

export const NAV_ORG = {
  key: "org",
  name: "container.sidebar.org",
  iconClassName: "ti-pulse",
  gates: [PERMISSION_VIEW_ORG_CHART, PERMISSION_VIEW_ORG_STAFF],
  submenu: [
    {
      key: "org.organisational_chart",
      name: "container.sidebar.organisational_chart",
      path: PAGE_ORGANIZATION_CHART,
      gates: PERMISSION_VIEW_ORG_CHART
    },
    {
      key: "org.staff",
      name: "container.sidebar.staff",
      path: PAGE_ORGANIZATION_STAFF,
      gates: PERMISSION_VIEW_ORG_STAFF,
      activeRoutes: [PAGE_NEW_ORGANIZATION_STAFF, PAGE_EDIT_ORGANIZATION_STAFF]
    }
  ]
};

export const NAV_RSM = {
  key: "rsm",
  name: "container.sidebar.rsm",
  iconClassName: "ti-write",
  gates: [PERMISSION_MANAGE_RSM_PUBLICATIONS, PERMISSION_VIEW_RSM_PUBLICATIONS, PERMISSION_VIEW_RSM_REPORTING],
  submenu: [
    {
      key: "rsm.rsm_dashboard",
      name: "container.sidebar.dashboard",
      path: PAGE_RSM_DASHBOARD,
      gates: PERMISSION_VIEW_RSM_PUBLICATIONS
    },
    {
      key: "rsm.rsm_publications",
      name: "container.sidebar.rsm_publications",
      path: PAGE_RSM_PUBLICATIONS,
      gates: PERMISSION_MANAGE_RSM_PUBLICATIONS,
      activeRoutes: [PAGE_RSM_NEW_PUBLICATION, PAGE_RSM_EDIT_PUBLICATION, PAGE_RSM_VIEW_PUBLICATION]
    },
    {
      key: "rsm.rsm_my_publications",
      name: "container.sidebar.rsm_my_publications",
      path: PAGE_RSM_MY_PUBLICATIONS,
      gates: PERMISSION_VIEW_RSM_PUBLICATIONS,
      activeRoutes: [PAGE_RSM_VIEW_MY_PUBLICATION]
    },
    {
      key: "rsm.reporting",
      name: "container.sidebar.reporting",
      path: PAGE_RSM_REPORTING,
      gates: PERMISSION_VIEW_RSM_REPORTING
    }
  ]
};

export const NAV_IOR = {
  key: "ior",
  name: "container.sidebar.ior",
  iconClassName: "ti-bolt",
  gates: [
    PERMISSION_VIEW_IOR_REPORTS,
    PERMISSION_MANAGE_IOR_REPORTS,
    PERMISSION_CREATE_IOR_REPORTS,
    PERMISSION_VIEW_IOR_ALL_REPORTS,
    PERMISSION_VIEW_IOR_CORRECTIVE_ACTIONS,
    PERMISSION_VIEW_IOR_ROOT_CAUSES
  ],
  submenu: [
    {
      key: "ior.dashboard",
      name: "container.sidebar.dashboard",
      path: PAGE_IOR_DASHBOARD,
      gates: PERMISSION_VIEW_IOR_REPORTS
    },
    {
      key: "ior.ior_reports",
      name: "container.sidebar.ior_reports",
      path: PAGE_IOR_REPORTS,
      gates: [PERMISSION_MANAGE_IOR_REPORTS, PERMISSION_VIEW_IOR_ALL_REPORTS],
      activeRoutes: [PAGE_IOR_VIEW_REPORT, PAGE_IOR_NEW_REPORT, PAGE_IOR_EDIT_REPORT]
    },
    {
      key: "ior.ior_my_reports",
      name: "container.sidebar.ior_my_reports",
      path: PAGE_IOR_MY_REPORTS,
      gates: PERMISSION_CREATE_IOR_REPORTS
    },
    {
      key: "ior.ior_investigators",
      name: "container.sidebar.ior_investigators",
      path: PAGE_IOR_INVESTIGATORS,
      activeRoutes: [PAGE_IOR_NEW_INVESTIGATOR, PAGE_IOR_EDIT_INVESTIGATOR],
      gates: PERMISSION_VIEW_IOR_INVESTIGATORS
    },
    {
      key: "ior.ior_assignees",
      name: "container.sidebar.ior_assignees",
      path: PAGE_IOR_ASSIGNEES,
      activeRoutes: [PAGE_IOR_NEW_ASSIGNEE, PAGE_IOR_EDIT_ASSIGNEE],
      gates: PERMISSION_VIEW_IOR_ASSIGNEES
    },
    {
      key: "ior.corrective_actions",
      name: "container.sidebar.corrective_actions",
      path: PAGE_IOR_CORRECTIVE_ACTIONS,
      activeRoutes: [PAGE_IOR_CORRECTIVE_ACTION],
      gates: PERMISSION_VIEW_IOR_CORRECTIVE_ACTIONS
    }
  ]
};

export const NAV_DOM = {
  key: "dom",
  name: "container.sidebar.dom",
  iconClassName: "ti-folder",
  gates: [
    PERMISSION_VIEW_DOM_DASHBOARD,
    PERMISSION_VIEW_DOM_DOCUMENTS,
    PERMISSION_VIEW_DOM_ENTITIES,
    PERMISSION_VIEW_DOM_RESPONSIBLE_PERSONS,
    PERMISSION_VIEW_DOM_APPROVERS,
    PERMISSION_VIEW_DOM_TEMPLATES
  ],
  submenu: [
    {
      key: "dom.dashboard",
      name: "container.sidebar.dashboard",
      path: PAGE_DOM_DASHBOARD,
      gates: PERMISSION_VIEW_DOM_DASHBOARD
    },
    {
      key: "dom.documents",
      name: "container.sidebar.documents",
      path: PAGE_DOM_DOCUMENTS,
      activeRoutes: [PAGE_DOM_DOCUMENT, PAGE_DOM_HISTORY_DOCUMENT, PAGE_DOM_DOCUMENTS_MASS_DUPLICATION],
      gates: PERMISSION_VIEW_DOM_DOCUMENTS
    },
    {
      key: "dom.entities",
      name: "container.sidebar.entities",
      path: PAGE_DOM_ENTITIES,
      gates: PERMISSION_VIEW_DOM_ENTITIES
    },
    {
      key: "dom.approvers",
      name: "container.sidebar.approvers",
      path: PAGE_DOM_APPROVERS,
      activeRoutes: [PAGE_DOM_EDIT_APPROVER, PAGE_DOM_NEW_APPROVER],
      gates: PERMISSION_VIEW_DOM_APPROVERS
    },
    {
      key: "dom.responsible_persons",
      name: "container.sidebar.responsible_persons",
      path: PAGE_DOM_RESPONSIBLE_PERSONS,
      activeRoutes: [PAGE_DOM_EDIT_RESPONSIBLE_PERSON, PAGE_DOM_NEW_RESPONSIBLE_PERSON],
      gates: PERMISSION_VIEW_DOM_RESPONSIBLE_PERSONS
    },
    {
      key: "dom.templates",
      name: "container.sidebar.templates",
      path: PAGE_DOM_TEMPLATES,
      activeRoutes: [PAGE_DOM_VIEW_TEMPLATE_HISTORY, PAGE_DOM_EDIT_HISTORY_TEMPLATE],
      gates: PERMISSION_VIEW_DOM_TEMPLATES
    }
  ]
};

export const NAV_QAM = {
  key: "qam",
  name: "container.sidebar.qam",
  iconClassName: "ti-check-box",
  gates: [
    PERMISSION_VIEW_QAM_DASHBOARD,
    PERMISSION_VIEW_QAM_AUDITS,
    PERMISSION_VIEW_QAM_ENTITIES,
    PERMISSION_VIEW_QAM_AUDITORS,
    PERMISSION_VIEW_QAM_AUDITEES,
    PERMISSION_VIEW_QAM_NORMS,
    PERMISSION_VIEW_QAM_CORRECTIVE_ACTIONS,
    PERMISSION_VIEW_QAM_ROOT_CAUSES
  ],
  submenu: [
    {
      key: "qam.dashboard",
      name: "container.sidebar.dashboard",
      path: PAGE_QAM_DASHBOARD,
      gates: PERMISSION_VIEW_QAM_DASHBOARD
    },
    {
      key: "qam.activities",
      name: "container.sidebar.activities",
      path: PAGE_QAM_ACTIVITIES,
      activeRoutes: [
        PAGE_QAM_ACTIVITIES_CALENDAR,
        PAGE_QAM_ACTIVITY_ACTIONS,
        PAGE_QAM_ACTIVITY_CHECKLIST,
        PAGE_QAM_ACTIVITY_PROGRAM
      ],
      gates: PERMISSION_VIEW_QAM_AUDITS
    },
    {
      key: "qam.entities",
      name: "container.sidebar.entities",
      path: PAGE_QAM_ENTITIES,
      gates: PERMISSION_VIEW_QAM_ENTITIES
    },
    {
      key: "qam.auditors",
      name: "container.sidebar.auditors",
      path: PAGE_QAM_AUDITORS,
      activeRoutes: [PAGE_QAM_EDIT_AUDITOR, PAGE_QAM_NEW_AUDITOR],
      gates: PERMISSION_VIEW_QAM_AUDITORS
    },
    {
      key: "qam.auditees",
      name: "container.sidebar.auditees",
      path: PAGE_QAM_AUDITEES,
      activeRoutes: [PAGE_QAM_EDIT_AUDITEE, PAGE_QAM_NEW_AUDITEE],
      gates: PERMISSION_VIEW_QAM_AUDITEES
    },
    {
      key: "qam.catalogues",
      name: "container.sidebar.catalogues",
      path: PAGE_QAM_CATALOGUES,
      gates: PERMISSION_VIEW_QAM_NORMS
    },
    {
      key: "qam.corrective_actions",
      name: "container.sidebar.corrective_actions",
      path: PAGE_QAM_CORRECTIVE_ACTIONS,
      activeRoutes: [PAGE_QAM_CORRECTIVE_ACTION],
      gates: PERMISSION_VIEW_QAM_CORRECTIVE_ACTIONS
    }
  ]
};

export const NAV_TRG = {
  key: "trg",
  name: "container.sidebar.trg",
  iconClassName: "ti-medall-alt",
  gates: [PERMISSION_VIEW_TRG_PLANNING, PERMISSION_MANAGE_TRG],
  submenu: [
    {
      key: "trg.trg_dashboard",
      name: "container.sidebar.trg_dashboard",
      path: PAGE_TRG_DASHBOARD,
      gates: PERMISSION_MANAGE_TRG
    },
    {
      key: "trg.trainings",
      name: "container.sidebar.trainings",
      path: PAGE_TRG_TRAININGS,
      gates: PERMISSION_MANAGE_TRG
    },
    {
      key: "trg.training_matrix",
      name: "container.sidebar.training_matrix",
      path: PAGE_TRG_TRAINING_MATRIX,
      gates: PERMISSION_MANAGE_TRG
    },
    {
      key: "trg.training_program",
      name: "container.sidebar.training_program",
      path: PAGE_TRG_TRAINING_PROGRAM,
      gates: PERMISSION_MANAGE_TRG
    },
    {
      key: "trg.planning",
      name: "container.sidebar.planning",
      path: PAGE_TRG_PLANNING,
      gates: PERMISSION_VIEW_TRG_PLANNING,
      activeRoutes: [PAGE_TRG_NEW_PLANNING, PAGE_TRG_EDIT_PLANNING, PAGE_TRG_VIEW_PLANNING, PAGE_TRG_PLANNING_CALENDAR]
    },
    {
      key: "trg.qualifications",
      name: "container.sidebar.qualifications",
      path: PAGE_TRG_QUALIFICATIONS,
      gates: PERMISSION_MANAGE_TRG,
      activeRoutes: [PAGE_TRG_MASS_ASSIGNMENT]
    },
    {
      key: "trg.reporting",
      name: "container.sidebar.reporting",
      path: PAGE_TRG_REPORTING,
      gates: PERMISSION_MANAGE_TRG
    },
    {
      key: "trg.external_providers",
      name: "container.sidebar.external_providers",
      path: PAGE_TRG_EXTERNAL_PROVIDERS,
      gates: PERMISSION_MANAGE_TRG
    }
  ]
};

export const NAV_ADM = {
  key: "adm",
  name: "container.sidebar.adm",
  iconClassName: "ti-server",
  gates: [PERMISSION_VIEW_ADM, PERMISSION_MANAGE_ADM],
  submenu: [
    {
      key: "adm.adm_reports",
      name: "container.sidebar.adm_reports",
      path: PAGE_ADM_REPORTS,
      gates: PERMISSION_VIEW_ADM,
      activeRoutes: [PAGE_ADM_NEW_REPORT, PAGE_ADM_EDIT_REPORT, PAGE_ADM_REPORT]
    },
    {
      key: "adm.adm_drafts",
      name: "container.sidebar.adm_drafts",
      path: PAGE_ADM_DRAFTS,
      gates: PERMISSION_MANAGE_ADM
    },
    {
      key: "adm.adm_exports_in_queue",
      name: "container.sidebar.adm_exports_in_queue",
      path: PAGE_ADM_EXPORTS,
      gates: PERMISSION_VIEW_ADM
    }
  ]
};

export const NAV_USERS_GROUPS = {
  key: "users_groups",
  name: "container.sidebar.users_and_groups",
  iconClassName: "ti-lock",
  gates: [PERMISSION_VIEW_CORE_USERS, PERMISSION_VIEW_CORE_GROUPS],
  submenu: [
    {
      key: "users_groups.users",
      name: "container.sidebar.users",
      path: PAGE_USERS,
      gates: PERMISSION_VIEW_CORE_USERS,
      activeRoutes: [PAGE_NEW_USER, PAGE_EDIT_USER, PAGE_VIEW_USER]
    },
    {
      key: "users_groups.groups",
      name: "container.sidebar.groups",
      path: PAGE_GROUPS,
      gates: PERMISSION_VIEW_CORE_GROUPS,
      activeRoutes: [PAGE_NEW_GROUP, PAGE_EDIT_GROUP]
    }
  ]
};

export const NAV_SETTINGS = {
  key: "settings",
  name: "container.sidebar.settings",
  iconClassName: "ti-settings",
  gates: [
    PERMISSION_MANAGE_CORE_SETTINGS,
    PERMISSION_MANAGE_QAM_SETTINGS,
    PERMISSION_VIEW_QAM_SETTINGS,
    PERMISSION_MANAGE_ORG_SETTINGS,
    PERMISSION_VIEW_ORG_SETTINGS,
    PERMISSION_MANAGE_TRG_SETTINGS,
    PERMISSION_VIEW_TRG_SETTINGS,
    PERMISSION_VIEW_RSM_SETTINGS,
    PERMISSION_MANAGE_RSM_SETTINGS,
    PERMISSION_MANAGE_IOR_SETTINGS,
    PERMISSION_VIEW_IOR_SETTINGS,
    PERMISSION_VIEW_DOM_SETTINGS
  ],
  submenu: [
    {
      key: "settings.main_settings",
      name: "container.sidebar.main_settings",
      path: PAGE_MAIN_SETTINGS,
      gates: PERMISSION_MANAGE_CORE_SETTINGS
    },
    {
      key: "settings.dom_settings",
      name: "container.sidebar.dom_settings",
      path: PAGE_DOM_SETTINGS,
      gates: [PERMISSION_MANAGE_DOM_SETTINGS, PERMISSION_VIEW_DOM_SETTINGS]
    },
    {
      key: "settings.qam_settings",
      name: "container.sidebar.qam_settings",
      path: PAGE_QAM_SETTINGS,
      gates: [PERMISSION_MANAGE_QAM_SETTINGS, PERMISSION_VIEW_QAM_SETTINGS]
    },
    {
      key: "settings.org_settings",
      name: "container.sidebar.org_settings",
      path: PAGE_ORGANIZATION_SETTINGS,
      gates: [PERMISSION_MANAGE_ORG_SETTINGS, PERMISSION_VIEW_ORG_SETTINGS]
    },
    {
      key: "settings.trg_settings",
      name: "container.sidebar.trg_settings",
      path: PAGE_TRG_SETTINGS,
      gates: [PERMISSION_MANAGE_TRG_SETTINGS, PERMISSION_VIEW_TRG_SETTINGS]
    },
    {
      key: "settings.rsm_settings",
      name: "container.sidebar.rsm_settings",
      path: PAGE_RSM_SETTINGS,
      gates: PERMISSION_VIEW_RSM_SETTINGS
    },
    {
      key: "settings.ior_settings",
      name: "container.sidebar.ior_settings",
      path: PAGE_IOR_SETTINGS,
      gates: [PERMISSION_MANAGE_IOR_SETTINGS, PERMISSION_VIEW_IOR_SETTINGS]
    }
  ]
};

export const NAV_NOTIFICATIONS = {
  key: "notifications",
  name: "container.sidebar.notifications",
  path: PAGE_NOTIFICATIONS,
  iconClassName: "ti-bell",
  gates: PERMISSION_VIEW_CORE_NOTIFICATIONS
};

export const NAV_AUDIT_LOGS = {
  key: "audit_log",
  name: "container.sidebar.audit_log",
  path: PAGE_AUDIT_LOG,
  iconClassName: "ti-book",
  gates: PERMISSION_VIEW_CORE_AUDIT_LOG
};
