import { Layout } from "antd";
import NavigationMenu from "./NavigationMenu";
import Logo from "./Logo";
/**
 * Sidebar
 * @function Sidebar
 * @description Sidebar component
 */
const Sidebar = ({ collapsed, handleCollapse }) => {
  return (
    <Layout.Sider
      className="layout-sidebar"
      trigger={null}
      collapsible
      onCollapse={handleCollapse}
      collapsed={collapsed}
      width={240}
      collapsedWidth={60}
    >
      <div className="layout-sidebar-navigation-container">
        <div className="app-logo">
          <Logo />
        </div>
        <NavigationMenu collapsed={collapsed} />
      </div>
    </Layout.Sider>
  );
};

export default Sidebar;
