import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  ACTIVITY_CLOSED,
  ACTIVITY_EXECUTION,
  ACTIVITY_PLANNING,
  ACTIVITY_PREPARATION,
  ACTIVITY_REPORTING,
  ACTIVITY_SURVEILLANCE_FINDING
} from "../config";
import {
  PERMISSION_EXPORT_QAM_AUDITS,
  PERMISSION_MANAGE_QAM_ALL_AUDITS,
  PERMISSION_MANAGE_QAM_AUDITS
} from "../../../components/_auth/permissions/permissions";
import TableCell from "../../../components/SmartTable/TableCell";
import Tooltip from "../../../components/Tooltip";
import { PAGE_QAM_ACTIVITY_ACTIONS, PAGE_QAM_ACTIVITY_CHECKLIST, PAGE_QAM_ACTIVITY_PROGRAM } from "../routes";
import { openModalAction } from "../../../components/Modal/reducer";
import DeleteItemButton from "../../../components/SmartTable/TableFiltersActions/DeleteItemButton";
import { usePermission } from "../../../components/_auth/permissions/usePermission";
import { downloadActivityReportPdf, TABLE_QAM_ACTIVITIES } from "./methods";
import { MODAL_QAM_ACTIVITY_FORM } from "./index";

const ActivityActionColumn = ({ row }) => {
  const dispatch = useDispatch();
  const permission = usePermission();
  const { t } = useTranslation();
  const history = useHistory();

  const programAvailable = row.stage_name === ACTIVITY_PLANNING || row.stage_name === ACTIVITY_PREPARATION;
  const checklistAvailable = row.stage_name === ACTIVITY_EXECUTION;
  const actionsAvailable =
    row.stage_name === ACTIVITY_REPORTING ||
    row.stage_name === ACTIVITY_SURVEILLANCE_FINDING ||
    row.stage_name === ACTIVITY_CLOSED;

  const auditCanBeExported = permission.canDo(PERMISSION_EXPORT_QAM_AUDITS);
  const activityIsEditableOrRemovable = permission.canDo(PERMISSION_MANAGE_QAM_ALL_AUDITS) || !!row.canManage;
  const activityCanBeRemoved =
    activityIsEditableOrRemovable &&
    (row.stage_name === ACTIVITY_PLANNING ||
      row.stage_name === ACTIVITY_PREPARATION ||
      row.stage_name === ACTIVITY_EXECUTION);
  const activityCanBeEdited =
    activityIsEditableOrRemovable &&
    (row.stage_name === ACTIVITY_PLANNING ||
      row.stage_name === ACTIVITY_PREPARATION ||
      row.stage_name === ACTIVITY_EXECUTION);
  const canManageActivities = permission.canDo([
    PERMISSION_MANAGE_QAM_AUDITS,
    PERMISSION_MANAGE_QAM_ALL_AUDITS,
    PERMISSION_EXPORT_QAM_AUDITS
  ]);

  let exportType = "Program";
  let exportTooltipTransKey = "qam.activities.export_program_pdf";
  if (row.stage_name === ACTIVITY_EXECUTION) {
    exportType = "Checklist";
    exportTooltipTransKey = "qam.activities.export_checklist_pdf";
  }
  if (
    row.stage_name === ACTIVITY_REPORTING ||
    row.stage_name === ACTIVITY_SURVEILLANCE_FINDING ||
    row.stage_name === ACTIVITY_CLOSED
  ) {
    exportType = "Report";
    exportTooltipTransKey = "qam.activities.export_report_pdf";
  }

  return (
    <TableCell minWidth={canManageActivities ? 135 : 62} className="table-actions-column">
      <Tooltip title={t("qam.activities.program_popover")} placement="top">
        <a
          disabled={!programAvailable}
          className="mr-10"
          href="/"
          onClick={(e) => {
            e.preventDefault();
            if (programAvailable) {
              history.push(`${PAGE_QAM_ACTIVITY_PROGRAM}/${row.id}`);
            }
          }}
        >
          <i className="ti-list" />
        </a>
      </Tooltip>
      <Tooltip title={t("qam.activities.checklist_popover")} placement="top">
        <a
          disabled={!checklistAvailable}
          className="mr-10"
          href="/"
          onClick={(e) => {
            e.preventDefault();
            if (checklistAvailable) {
              history.push(`${PAGE_QAM_ACTIVITY_CHECKLIST}/${row.id}`);
            }
          }}
        >
          <i className="ti-clipboard" />
        </a>
      </Tooltip>
      <Tooltip title={t("qam.activities.actions_popover")} placement="top">
        <a
          disabled={!actionsAvailable}
          href="/"
          onClick={(e) => {
            e.preventDefault();
            if (actionsAvailable) {
              history.push(`${PAGE_QAM_ACTIVITY_ACTIONS}/${row.id}`);
            }
          }}
        >
          <i className="ti-layout-list-thumb" />
        </a>
      </Tooltip>
      {canManageActivities && (
        <>
          {auditCanBeExported && (
            <Tooltip title={t(exportTooltipTransKey)} placement="top" className="text-muted">
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  downloadActivityReportPdf(t, dispatch, row, exportType);
                }}
              >
                <i className="ti-download" />
              </a>
            </Tooltip>
          )}
          <Tooltip title={t("qam.activities.edit_activity")} placement="top" className="text-primary">
            <a
              disabled={!activityCanBeEdited}
              href="/"
              onClick={(e) => {
                e.preventDefault();
                if (activityCanBeEdited) {
                  dispatch(openModalAction({ name: MODAL_QAM_ACTIVITY_FORM, data: row }));
                }
              }}
            >
              <i className="ti-pencil-alt" />
            </a>
          </Tooltip>
          <DeleteItemButton
            disabled={!activityCanBeRemoved}
            tableName={TABLE_QAM_ACTIVITIES}
            route={`qam/audits/${row.id}`}
            content={
              <div>
                <div>{t("container.confirm_modal.form_subtitle")}</div>
              </div>
            }
          />
        </>
      )}
    </TableCell>
  );
};

export default ActivityActionColumn;
