import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import ResourceSelectLazyLoading from "../../../../../../components/ResourceSelectLazyLoading";
import Button from "../../../../../../components/Button";
import Tooltip from "../../../../../../components/Tooltip";
import { INVESTIGATOR_AUDITOR } from "../config";
import axios from "../../../../../../axios";
import { openNotification } from "../../../../../../components/Notification";
import useReportData from "../../../useReportData";
import { useClearInvestigatorSelect } from "../methods";
import { usePermission } from "../../../../../../components/_auth/permissions/usePermission";
import { PERMISSION_MANAGE_IOR_CORRECTIVE_ACTIONS } from "../../../../../../components/_auth/permissions/permissions";
import { updateReportData } from "../../../reducer";
import InvestigatorCard from "../InvestigatorCard";
import { REPORT_FORM_TYPE } from "../../..";

const ReportLeadAuditor = ({ investigators, loading, type, basicDataSaved }) => {
  const { t } = useTranslation();
  const permission = usePermission();
  const dispatch = useDispatch();
  const [auditor, setAuditor] = useState();
  const report = useReportData();
  const reportAuditor = report.lead;
  const [loadingSave, setLoadingSave] = useState(false);

  useClearInvestigatorSelect(auditor, setAuditor);

  const addLeadAuditor = () => {
    if (!auditor || loadingSave) return;
    setLoadingSave(true);

    const reportId = report?.basic_data?.id ? report.basic_data.id : basicDataSaved.basic_data.id;
    axios.put(`ior/report/set-investigator/${reportId}`, { lead_auditor: auditor.id }).then(
      () => {
        dispatch(
          updateReportData({
            lead: { ...auditor }
          })
        );
        setLoadingSave(false);
        setAuditor(undefined);
        openNotification({
          message: t("qam.activity_program.notifications.lead_auditor_saved"),
          type: "success",
          placement: "bottom"
        });
      },
      () => {
        setLoadingSave(false);
        openNotification({
          message: t("qam.activity_program.notifications.lead_auditor_could_not_be_saved"),
          type: "error",
          placement: "bottom"
        });
      }
    );
  };

  return (
    <div>
      {permission.canDo(PERMISSION_MANAGE_IOR_CORRECTIVE_ACTIONS) && type !== REPORT_FORM_TYPE.VIEW ? (
        <div className="activity-field-input">
          <ResourceSelectLazyLoading
            overwritePreviousOptions
            size="middle"
            placeholder={t("ior.reports.select_lead_investigator_placeholder")}
            searchIn={["name", "company"]}
            disabled={loading}
            staticOptions={investigators}
            onChange={(id, entityObj) => setAuditor(entityObj)}
            value={auditor?.id || undefined}
          />
          <Tooltip
            title={!auditor ? t("qam.activity_program.participants_lead_auditor_disabled_message_popover") : null}
          >
            <Button type="ghost-icon" className={!auditor ? "disabled" : ""} onClick={addLeadAuditor}>
              <i className="ti-check" />
            </Button>
          </Tooltip>
        </div>
      ) : (
        <></>
      )}
      {reportAuditor && (
        <div className="activity-field-value">
          <InvestigatorCard type={type} user={{ ...reportAuditor, type: INVESTIGATOR_AUDITOR }} />
        </div>
      )}
    </div>
  );
};

export default ReportLeadAuditor;
