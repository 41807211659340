import React, { useCallback } from "react";
import { Layout } from "antd";
import classNames from "classnames";
import DropdownNotifications from "../DropdownNotifications";
import DropdownNewItem from "../DropdownNewItem";
import GeneralSearch from "../GeneralSearch";
import DropdownUser from "../DropdownUser";
import DropdownLanguages from "../DropdownLanguages";
import { useLoggedUser } from "../../utils/hooks/useLoggedUser";
import ProtectedComponent from "../_auth/ProtectedComponent";
import { PERMISSION_VIEW_CORE_NOTIFICATIONS } from "../_auth/permissions/permissions";
import { getCompanyLogo } from "./methods";

/**
 * Header
 * @function Header
 * @description Header component
 */
const Header = ({ collapsed, handleCollapse }) => {
  const collapse = useCallback(
    (e) => {
      e.preventDefault();
      handleCollapse(!collapsed);
    },
    [collapsed, handleCollapse]
  );

  const loggedUser = useLoggedUser();
  const { smallLogo } = getCompanyLogo(loggedUser);

  return (
    <Layout.Header className="layout-header">
      <div className="flex flex-items-center header-left-part">
        <div className="header-small-logo">
          <img src={smallLogo} alt="logo" />
        </div>
        <a href="/#" onClick={collapse} className="icon-link white trigger-sidebar ml-5">
          <i className={classNames(collapsed ? "ti-layout-sidebar-left" : "ti-layout-sidebar-left")} />
          <i className={classNames(collapsed ? "ti-menu" : "ti-close")} />
        </a>
        <ProtectedComponent gates={PERMISSION_VIEW_CORE_NOTIFICATIONS}>
          <DropdownNotifications />
        </ProtectedComponent>
        <DropdownNewItem />
        <GeneralSearch />
      </div>
      <div className="ml-a flex flex-items-center mr-20">
        <DropdownLanguages />
        <DropdownUser />
      </div>
    </Layout.Header>
  );
};

export default Header;
