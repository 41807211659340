import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Button, Tooltip } from "antd";
import { ClearOutlined } from "@ant-design/icons";
import PageMetaTagTitle from "../../../components/PageMetaTagTitle";
import SmartTable from "../../../components/SmartTable";
import TableService from "../../../components/SmartTable/TableService";
import { usePermission } from "../../../components/_auth/permissions/usePermission";
import { PERMISSION_EXPORT_QAM_CORRECTIVE_ACTIONS } from "../../../components/_auth/permissions/permissions";
import { useLoggedUser } from "../../../utils/hooks/useLoggedUser";
import Modal from "../../../components/Modal";
import { useDateFormat } from "../../../utils/hooks/useDateFormat";
import { reloadTable } from "../../../components/SmartTable/reducer";
import ResourceSelectLazyLoading from "../../../components/ResourceSelectLazyLoading";
import { generateColumns, TABLE_QAM_CORRECTIVE_ACTIONS } from "./methods";
import CorrectiveActionForm from "./CorrectiveActionForm";

export const MODAL_QAM_CORRECTIVE_ACTION = "MODAL_QAM_CORRECTIVE_ACTION";

const QamCorrectiveActionsPage = () => {
  const dispatch = useDispatch();
  const permission = usePermission();
  const loggedUser = useLoggedUser();
  const { t } = useTranslation();
  const { format } = useDateFormat();
  const tableKeyName = `${TABLE_QAM_CORRECTIVE_ACTIONS}_${loggedUser.id}`;
  const localStorageTableFilters = JSON.parse(localStorage.getItem(tableKeyName) || "{}");
  const [tableService] = useState(
    new TableService({
      defaultWith: ["info", "qamAudit", "qamAudit.stage"],
      defaultOrderBy: [
        { column: "status_id", type: "asc" },
        { column: "was_updated", type: "desc" }
      ],
      searchColumns: ["title", "number", "lead_auditor_name"],
      searchValue: "",
      ...localStorageTableFilters
    })
  );

  const [shouldReset, setShouldReset] = useState();

  const handleClearFilters = () => {
    setShouldReset(new Date().getTime());
    dispatch(reloadTable({ name: TABLE_QAM_CORRECTIVE_ACTIONS, resetFilters: true }));
  };

  const externalScopeFilter = localStorageTableFilters?.anotherFilters?.filter(
    (item) => item.nameKey === "scope_id"
  )[0];

  return (
    <>
      <PageMetaTagTitle title="QAM corrective actions" />
      <Modal name={MODAL_QAM_CORRECTIVE_ACTION}>
        <CorrectiveActionForm />
      </Modal>
      <SmartTable
        externalFilters={
          <div className="flex">
            <ResourceSelectLazyLoading
              shouldReset={shouldReset}
              className="mr-10"
              showArrow
              showSearch
              allowClear
              initialValue={externalScopeFilter}
              value={externalScopeFilter?.value}
              placeholder={t("qam.corrective_actions.filter_by_scope")}
              apiRoute="qam/scopes?order_by_column%5B%5D=name&order_by_type%5B%5D=asc"
              onChange={(value, objValue) => {
                tableService.removeAnotherFilter("scope_id");
                if (value) {
                  tableService.addAnotherFilter({
                    nameKey: "scope_id",
                    ...objValue
                  });
                }
                dispatch(reloadTable({ name: TABLE_QAM_CORRECTIVE_ACTIONS }));
              }}
            />
          </div>
        }
        scroll={{ x: 1600 }}
        name={TABLE_QAM_CORRECTIVE_ACTIONS}
        tableService={tableService}
        columns={generateColumns(permission, dispatch, t, format)}
        route="qam/correctiveactions"
        tableLocalStorageKeyName={tableKeyName}
        rowSelection={false}
        exportProps={{
          access: permission.canDo(PERMISSION_EXPORT_QAM_CORRECTIVE_ACTIONS),
          route: "qam/correctiveactions/export",
          columns: [
            { column: "status", trans: t("qam.corrective_actions.status") },
            { column: "number", trans: t("qam.new_entity.number") },
            { column: "title", trans: t("container.general_words.title") },
            { column: "due_date", trans: t("qam.corrective_actions.due_date") },
            { column: "responsible_name", trans: t("qam.corrective_actions.responsibility") },
            { column: "activity_title", trans: t("qam.corrective_actions.activity_title") },
            { column: "activity_number", trans: t("qam.corrective_actions.activity_number") },
            { column: "lead_auditor_name", trans: t("qam.corrective_actions.lead_auditor") }
          ]
        }}
        clearFiltersActions={
          <Tooltip placement="topLeft" title={t("container.general_words.clear_filters")}>
            <Button onClick={handleClearFilters} icon={<ClearOutlined />}>
              {t("container.general_words.clear_filters")}
            </Button>
          </Tooltip>
        }
      />
    </>
  );
};

export default QamCorrectiveActionsPage;
