import { useLoggedUser } from "../../utils/hooks/useLoggedUser";

const TABLE_HEIGHT_WITH_FOOTER = window.innerHeight - 327;
const TABLE_HEIGHT = window.innerHeight - 285;

export const useTableFullHeight = () => {
  const loggedUser = useLoggedUser();
  const companyFooter = loggedUser?.company?.footer;

  return companyFooter ? TABLE_HEIGHT_WITH_FOOTER : TABLE_HEIGHT;
};
