import React from "react";
import { NavLink } from "react-router-dom";
import {
  FILTER_TYPE_DATE_RANGE,
  FILTER_TYPE_SELECT_MODE_1,
  FILTER_TYPE_SELECT_MODE_3,
  FILTER_TYPE_STRING
} from "../../../components/SmartTable/methods";
import TableCell from "../../../components/SmartTable/TableCell";
import Tooltip from "../../../components/Tooltip";
import { openModalAction } from "../../../components/Modal/reducer";
import Tag from "../../../components/Tag";
import { PAGE_IOR_CORRECTIVE_ACTION } from "../routes";
import { formatString } from "../../../utils/common";
import { PERMISSION_MANAGE_IOR_CORRECTIVE_ACTIONS } from "../../../components/_auth/permissions/permissions";
import { PAGE_IOR_VIEW_REPORT } from "../routes";
import { correctiveActionStatuses } from "./config";
import { MODAL_IOR_CORRECTIVE_ACTION } from "./index";

export const TABLE_IOR_CORRECTIVE_ACTIONS = "table-ior-corrective-actions";

export const generateColumns = (permission, dispatch, trans, format) => {
  return [
    {
      title: "ior.corrective_actions.status",
      dataIndex: "status",
      sorter: true,
      filterType: FILTER_TYPE_SELECT_MODE_1,
      filterNameKey: "status",
      filterProps: {
        staticOptions: [
          ...correctiveActionStatuses.map((item) => ({ ...item, text: trans(item.name) })),
          { value: "assigned_overdue", text: trans("ior.ior_corrective_actions_stages.assigned_overdue") }
        ]
      },
      width: 100,
      render: (text, row) => {
        const status = getCorrectiveActionStatusLangKey(row.status, true);
        return (
          <TableCell>
            <Tag style={{ backgroundColor: status.color, color: "#fff", borderColor: "transparent" }}>
              {trans(status.name)}
            </Tag>
          </TableCell>
        );
      }
    },
    {
      title: "ior.corrective_actions.id",
      dataIndex: "number",
      filterType: FILTER_TYPE_STRING,
      sorter: true,
      width: 130,
      render: (text, row) => {
        return (
          <TableCell className="flex flex-items-center">
            {row.has_notification && <Tag className="tag-primary mr-10">{trans("container.new")}</Tag>}
            {row.number}
          </TableCell>
        );
      }
    },
    {
      title: "container.general_words.title",
      dataIndex: "title",
      filterType: FILTER_TYPE_STRING,
      sorter: true,
      render: (text, row) => {
        return <TableCell minWidth={130}>{row.title}</TableCell>;
      }
    },
    {
      title: "ior.corrective_actions.due_date",
      dataIndex: "due_date",
      sorter: true,
      filterType: FILTER_TYPE_DATE_RANGE,
      width: 170,
      render: (text, row) => <TableCell>{format({ date: row.due_date })}</TableCell>
    },
    {
      title: "ior.corrective_actions.responsibility",
      dataIndex: "responsible_name",
      filterType: FILTER_TYPE_STRING,
      sorter: true,
      width: 190,
      render: (text, row) => {
        return (
          <TableCell>
            {row.responsible_name}
            {row.responsible_type === "external" && (
              <Tooltip title={trans("ior.corrective_actions.heart-broken-icon-popover")}>
                <i className="ti-heart-broken text-darken text-left-margin" />
              </Tooltip>
            )}
          </TableCell>
        );
      }
    },
    {
      title: "ior.corrective_actions.report_title",
      dataIndex: "report_title",
      filterType: FILTER_TYPE_SELECT_MODE_3,
      sorter: true,
      filterNameKey: "reportIds",
      render: (text, row) => {
        return <TableCell minWidth={150}>{row.report.basic_data.title}</TableCell>;
      }
    },
    {
      title: "ior.corrective_actions.report_custom_id",
      dataIndex: "report_custom_id",
      filterType: FILTER_TYPE_STRING,
      sorter: true,
      width: 180,
      render: (text, row) => {
        return (
          <TableCell>
            <NavLink to={`${PAGE_IOR_VIEW_REPORT}/${row.report_id}`}>{row.report_number}</NavLink>
          </TableCell>
        );
      }
    },
    {
      title: "ior.corrective_actions.lead_auditor",
      dataIndex: "lead_auditor_name",
      filterType: FILTER_TYPE_SELECT_MODE_3,
      sorter: true,
      filterNameKey: "leadAuditorIds",
      width: 220,
      render: (text, row) => {
        return <TableCell minWidth={150}>{formatString(row.lead_auditor_name, "-")}</TableCell>;
      }
    },
    {
      title: trans("container.general_words.actions"),
      fixed: "right",
      width: 80,
      render: (row = {}) => (
        <div className="table-actions-column">
          <Tooltip title={trans("container.general_words.view")}>
            <NavLink to={`${PAGE_IOR_CORRECTIVE_ACTION}/${row.id}`} className="text-secondary">
              <i className="ti-eye" />
            </NavLink>
          </Tooltip>
          {permission.canDo(PERMISSION_MANAGE_IOR_CORRECTIVE_ACTIONS) && (
            <Tooltip title={trans("container.general_words.edit")} placement="top" className="text-primary">
              <a
                href="/"
                disabled={
                  row.status === correctiveActionStatuses[3].value ||
                  (permission.isAuditee() && !permission.isAuditor())
                }
                onClick={(e) => {
                  e.preventDefault();
                  if (row.status === correctiveActionStatuses[3].value) return;
                  dispatch(
                    openModalAction({ name: MODAL_IOR_CORRECTIVE_ACTION, data: { ...row, hideObservation: true } })
                  );
                }}
              >
                <i className="ti-pencil-alt" />
              </a>
            </Tooltip>
          )}
        </div>
      )
    }
  ];
};

export const getCorrectiveActionStatusLangKey = (status, withObjProperties = false) => {
  if (!status) {
    return null;
  }

  const statusResult = correctiveActionStatuses.filter((item) => item.value === status)[0];

  if (withObjProperties) {
    return statusResult;
  }

  return statusResult?.name;
};
