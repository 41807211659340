import React from "react";
import TableCell from "../../../../../components/SmartTable/TableCell";
import FileBox from "../../../../Qam/CorrectiveActionView/FileBox";
import { EditButton } from "../../../../../components/EditButton";
import { DownloadButton } from "../../../../../components/DownloadButton";
import { closeModalAction, openModalAction } from "../../../../../components/Modal/reducer";
import { downloadFile } from "../../../../../utils/downloadFile";
import { MODAL_EXPORT_LOADING } from "../../../../../components/SmartTable/ExportDropdown/LoadingModal";
import { openGeneralErrorNotification } from "../../../../../components/Notification";
import DeleteItemButton from "../../../../../components/SmartTable/TableFiltersActions/DeleteItemButton";
import { REPORT_FORM_TYPE } from "../../index";
import { MODAL_IOR_DOCUMENT_FORM } from "./DocumentsTable";

export const generateColumns = (dispatch, t, type, attachments, setAttachments) => {
  return [
    {
      title: "No",
      render: (text, row, index) => <TableCell>{index + 1}</TableCell>
    },
    {
      title: "crm.actual_contact.edit_note.title",
      render: (text, row) => (
        <TableCell>
          <FileBox
            className="mb-5"
            key={row.id}
            filename={row.name}
            fileUrl={row.image?.fullPath}
            fileExt={row.image?.name?.split(".")[1]}
          />
        </TableCell>
      )
    },
    {
      title: t("container.general_words.actions"),
      fixed: "right",
      width: 100,
      align: "center",
      render: (row = {}) => (
        <TableCell minWidth={50} className="table-actions-column flex flex-justify-center">
          <DownloadButton
            onClick={() => {
              downloadFile({
                fileUrl: row.fullPath,
                fileName: row.name,
                onStart: () => dispatch(openModalAction({ name: MODAL_EXPORT_LOADING })),
                onSuccess: () => dispatch(closeModalAction({ name: MODAL_EXPORT_LOADING })),
                onFailed: (error) => {
                  dispatch(closeModalAction({ name: MODAL_EXPORT_LOADING }));
                  openGeneralErrorNotification({
                    error,
                    defaultError: t("container.form_errors.internal_server_error")
                  });
                }
              });
            }}
          ></DownloadButton>
          {type !== REPORT_FORM_TYPE.VIEW && (
            <>
              <EditButton
                onClick={() => {
                  dispatch(openModalAction({ name: MODAL_IOR_DOCUMENT_FORM, data: row }));
                }}
              ></EditButton>
              <DeleteItemButton
                onOk={() => {
                  setAttachments(attachments.filter((item) => item.id !== row.id));
                }}
                content={
                  <div>
                    <div>{t("container.confirm_modal.form_subtitle")}</div>
                  </div>
                }
              ></DeleteItemButton>
            </>
          )}
        </TableCell>
      )
    }
  ];
};
