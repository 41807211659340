import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useLoggedUser } from "../../../../../utils/hooks/useLoggedUser";
import TableService from "../../../../../components/SmartTable/TableService";
import SmartTable from "../../../../../components/SmartTable";
import { useDateFormat } from "../../../../../utils/hooks/useDateFormat";
import Modal from "../../../../../components/Modal";
import { openModalAction } from "../../../../../components/Modal/reducer";
import { useTableFullHeight } from "../../../../../components/SmartTable/useTableFullHeight";
import LoadingModal, { MODAL_EXPORT_LOADING } from "../../../../../components/SmartTable/ExportDropdown/LoadingModal";
import { TABLE_USER_VIEW_TRG_QUALIFICATIONS_NAME, generateColumns } from "./methods";
import UserViewQualificationForm from "./UserViewQualificationForm";

export const MODAL_USER_VIEW_QUALIFICATION = "MODAL_USER_VIEW_QUALIFICATION";

const UserViewTrgQualifications = ({ data }) => {
  const location = useLocation();
  const tableHeight = useTableFullHeight();
  const dispatch = useDispatch();
  const language = i18next.language;
  const loggedUser = useLoggedUser();
  const { t } = useTranslation();
  const { format } = useDateFormat();
  const tableKeyName = `${TABLE_USER_VIEW_TRG_QUALIFICATIONS_NAME}_${loggedUser.id}_${data.id}`;
  const tableLocalStorage = JSON.parse(localStorage.getItem(tableKeyName) || "{}");
  tableLocalStorage.filters = location.training
    ? [
        {
          column: "qualification_id",
          op: "eq",
          value: location.training.qualificationId,
          id: location.training.id,
          text: `${location.training.name} (${location.training.code})`
        }
      ]
    : tableLocalStorage.filters;
  const [tableService] = useState(
    new TableService({
      defaultWith: ["instructor", "files", "qualification.training"],
      defaultOrderBy: [{ column: "id", type: "desc" }],
      ...tableLocalStorage
    })
  );

  return (
    <>
      <Modal name={MODAL_USER_VIEW_QUALIFICATION}>
        <UserViewQualificationForm userId={data.id} />
      </Modal>
      <LoadingModal name={MODAL_EXPORT_LOADING} message={t("qam.loading_pdf_report_message")} />
      <SmartTable
        scroll={{ x: 1100, y: tableHeight - 70 }}
        name={TABLE_USER_VIEW_TRG_QUALIFICATIONS_NAME}
        tableService={tableService}
        columns={generateColumns(format, t, data.id, language, dispatch)}
        route={`trg/qualification-histories?userId=${data.id}`}
        tableLocalStorageKeyName={tableKeyName}
        rowSelection={false}
        newItemProps={{
          access: true,
          text: t("container.user_profile.training_history.new_entry_button"),
          onClick: () => dispatch(openModalAction({ name: MODAL_USER_VIEW_QUALIFICATION }))
        }}
      />
    </>
  );
};

export default UserViewTrgQualifications;
