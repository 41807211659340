import React from "react";
import { Dropdown as AntdDropdown } from "antd";

const Dropdown = ({ children, overlay = <div></div>, ...rest }) => {
  return (
    <AntdDropdown overlay={<div className="general-dropdown-overlay">{overlay}</div>} trigger={["click"]} {...rest}>
      {children}
    </AntdDropdown>
  );
};

export default Dropdown;
