const AUTO_REFRESH_TOKEN_VALIDITY_DURATION = process.env.REACT_APP_AUTO_REFRESH_TOKEN_DURATION_SECONDS || 900; // 900s = 15min

class AuthService {
  init({ beforeExpiry = () => {}, afterExpiry = () => {} }) {
    if (this.isInitialized) {
      return;
    }
    this.beforeExpiryToken = beforeExpiry;
    this.expiredToken = afterExpiry;
    this.isInitialized = true;
  }

  destroy() {
    if (!this.isInitialized) {
      return;
    }

    this.isInitialized = false;
    clearTimeout(this.afterTokenExpiry);
    clearTimeout(this.beforeTokenExpiry);
  }

  trackTokenValidityTime() {
    clearTimeout(this.afterTokenExpiry);
    clearTimeout(this.beforeTokenExpiry);

    if (!this.isInitialized) {
      return;
    }

    this.afterTokenExpiry = setTimeout(() => {
      this.expiredToken();
    }, AUTO_REFRESH_TOKEN_VALIDITY_DURATION * 1000);

    this.beforeTokenExpiry = setTimeout(() => {
      this.beforeExpiryToken();
    }, (AUTO_REFRESH_TOKEN_VALIDITY_DURATION - 60) * 1000);
  }

  initBroadCast(onBroadCastMessage = () => {}) {
    if (this.broadCastIsInitialized) {
      return;
    }

    this.broadCastIsInitialized = true;
    const eventListener = onBroadCastMessage;
    this.broadCast = new BroadcastChannel("teampulse");
    this.broadCast.removeEventListener("message", eventListener);
    this.broadCast.addEventListener("message", eventListener);
  }

  sendBroadCastMessage(name, data) {
    this.broadCast.postMessage({ name, data });
  }
}

export default new AuthService();
