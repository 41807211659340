import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import useFormSubmit from "../../../components/Form/useFormSubmit";
import Form, { FORM_HORIZONTAL_LAYOUT } from "../../../components/Form";
import { closeModalAction } from "../../../components/Modal/reducer";
import Button from "../../../components/Button";
import { renderDynamicallyFields } from "../../../components/Form/methods";
import { FORM_ITEM_RESOURCE_SELECT_LAZY_LOADING } from "../../../components/Form/FormItems/FormItemResourceSelectLazyLoading";
import axios from "../../../axios";
import { openGeneralErrorNotification } from "../../../components/Notification";
import { MODAL_QAM_CORRECTIVE_ACTION_ROOT_CAUSE } from "./CorrectiveActionRootCause";
import { prepareRootCausesOptions } from "./methods";

const AddRootCauseForm = ({ name, routeUrl }) => {
  const data = useSelector((state) => state?.modal[name || MODAL_QAM_CORRECTIVE_ACTION_ROOT_CAUSE]?.data) || {};
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isNewRecord = !data.id;
  const [areas, setAreas] = useState([]);
  const [selectedArea, setSelectedArea] = useState(data.area);
  const [selectedCategory, setSelectedCategory] = useState(data.category);
  const [applyInitialValue, setApplyInitialValue] = useState(!isNewRecord);
  const { loading, submit, generalError } = useFormSubmit({
    route: isNewRecord ? routeUrl : `${routeUrl}/${data.id}`,
    method: isNewRecord ? "post" : "put",
    form,
    transformer: (values) => {
      if (!data.report_id && !data.corrective_action_id) {
        return {
          ...values
        };
      }
      return {
        ...values,
        ...(data.report_id && { report_id: data.report_id }),
        ...(data.corrective_action_id && { corrective_action_id: data.corrective_action_id })
      };
    },
    onSuccess: (res) => {
      data.onSuccess && data.onSuccess(res);
      dispatch(closeModalAction({ name: name || MODAL_QAM_CORRECTIVE_ACTION_ROOT_CAUSE }));
    }
  });

  useEffect(() => {
    axios
      .get(
        "rootcauseareas?order_by_column%5B%5D=name&order_by_type%5B%5D=asc&with%5B%5D=rootcausecategories.rootcausesubcategories",
        {
          params: {
            "filter_active": true
          }
        }
      )
      .then(
        (res) => {
          const preparedRootCauses = prepareRootCausesOptions(
            data.existentRootCauses,
            res.data.data.map((item) => ({ ...item, text: item.name, value: item.id })),
            data.id
          );
          setAreas(preparedRootCauses);

          if (data.root_cause_area_id && data.root_cause_category_id) {
            const selectedInitialArea = preparedRootCauses.filter((item) => item.id === data.root_cause_area_id)[0];
            const selectedInitialCategory = selectedInitialArea?.rootcausecategories?.filter(
              (item) => item.id === data.root_cause_category_id
            )[0];
            setSelectedArea(selectedInitialArea);
            setSelectedCategory(selectedInitialCategory);
          }
        },
        (error) => {
          openGeneralErrorNotification({ error, defaultError: t("container.form_errors.internal_server_error") });
        }
      );

    if (!isNewRecord) {
      form.setFieldsValue({
        root_cause_area_id: data.root_cause_area_id,
        root_cause_category_id: data.root_cause_category_id,
        root_cause_subcategory_id: data.root_cause_subcategory_id
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Form
      onSubmit={submit}
      form={form}
      errors={generalError}
      loading={loading}
      layout={FORM_HORIZONTAL_LAYOUT}
      header={
        <>
          <div className="h3">
            {t(
              isNewRecord
                ? "qam.corrective_action_view.add_root_cause_modal.form_title"
                : "qam.corrective_action_view.add_root_cause_modal.form_title"
            )}
          </div>
          <div className="mb-30 mt-10 h6 font-weight-300 text-muted">{t("container.general_words.form_subtitle")}</div>
        </>
      }
    >
      {renderDynamicallyFields([
        {
          required: true,
          type: FORM_ITEM_RESOURCE_SELECT_LAZY_LOADING,
          name: "root_cause_area_id",
          label: "qam.corrective_action_view.add_root_cause_modal.area",
          placeholder: "qam.corrective_action_view.add_root_cause_modal.area_placeholder",
          initialValue:
            applyInitialValue && data.area ? { ...data.area, text: data.area.name, value: data.area.id } : {},
          fieldProps: {
            staticOptions: areas,
            onChange: (val, obj) => {
              setSelectedArea(obj);
              setSelectedCategory(null);
              setApplyInitialValue(false);
              form.resetFields(["root_cause_category_id", "root_cause_subcategory_id"]);
            }
          }
        },
        {
          required: true,
          type: FORM_ITEM_RESOURCE_SELECT_LAZY_LOADING,
          name: "root_cause_category_id",
          label: "qam.corrective_action_view.add_root_cause_modal.category",
          placeholder: "qam.corrective_action_view.add_root_cause_modal.category_placeholder",
          initialValue:
            applyInitialValue && data.category
              ? { ...data.category, text: data.category.name, value: data.category.id }
              : {},
          fieldProps: {
            disabled: !selectedArea,
            staticOptions: selectedArea?.rootcausecategories?.map((item) => ({
              ...item,
              value: item.id,
              text: item.name
            })),
            onChange: (val, obj) => {
              setSelectedCategory(obj);
              form.resetFields(["root_cause_subcategory_id"]);
              setApplyInitialValue(false);
            }
          }
        },
        {
          required: true,
          type: FORM_ITEM_RESOURCE_SELECT_LAZY_LOADING,
          name: "root_cause_subcategory_id",
          label: "qam.corrective_action_view.add_root_cause_modal.sub_category",
          placeholder: "qam.corrective_action_view.add_root_cause_modal.sub_category_placeholder",
          initialValue:
            applyInitialValue && data.subcategory
              ? { ...data.subcategory, text: data.subcategory.name, value: data.subcategory.id }
              : {},
          fieldProps: {
            disabled: !selectedCategory,
            onChange: () => {
              setApplyInitialValue(false);
            },
            staticOptions: selectedCategory?.rootcausesubcategories?.map((item) => ({
              ...item,
              value: item.id,
              text: item.name
            }))
          }
        }
      ])}
      <div className="flex mt-25 flex-justify-end">
        <Button
          className="min-w-150"
          type="primary"
          htmlType="submit"
          disabled={loading}
          loading={loading}
          icon={<i className="ti-check" />}
        >
          {t("container.general_words.save")}
        </Button>
      </div>
    </Form>
  );
};

export default AddRootCauseForm;
