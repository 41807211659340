import React, { useState } from "react";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import classNames from "classnames";
import Dropdown from "../../Dropdown";
import axios from "../../../axios";
import { closeModalAction, openModalAction } from "../../Modal/reducer";
import { openNotification } from "../../Notification";
import { downloadFile } from "../../../utils/downloadFile";
import LoadingModal, { MODAL_EXPORT_LOADING } from "./LoadingModal";
import { prepareExportRequestParams } from "./methods";
import ExportOverlay, { EXPORT_TYPE_CSV, EXPORT_TYPE_PDF, EXPORT_TYPE_XLS } from "./ExportOverlay";

const ExportDropdown = (props) => {
  const { exportProps = {}, columns, tableService, selectedRows, className } = props;
  const allowedExportTypes = exportProps.exportTypes || [EXPORT_TYPE_XLS, EXPORT_TYPE_CSV, EXPORT_TYPE_PDF];
  const exportColumns = exportProps.columns;
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onExportClick = (type) => () => {
    const exportParams = prepareExportRequestParams(
      type === EXPORT_TYPE_XLS ? exportProps.xlsColumns || exportColumns : exportColumns,
      columns,
      selectedRows
    );
    const filename = `${exportProps.filename || type}_export`;
    const params = {
      ...(tableService ? tableService.getFilters() : {}),
      ...exportParams,
      type,
      filename,
      ...(exportProps?.params || {})
    };

    dispatch(openModalAction({ name: MODAL_EXPORT_LOADING }));
    axios
      .get(exportProps.route, {
        params
      })
      .then(
        (res) => {
          const fileName = res.data.data.url.substring(
            res.data.data.url.lastIndexOf("/") + 1,
            res.data.data.url.length
          );

          downloadFile({
            fileUrl: res.data.data.url,
            fileName: fileName,
            onSuccess: () => {
              dispatch(closeModalAction({ name: MODAL_EXPORT_LOADING }));
            },
            onFailed: () => {
              openNotification({ type: "error", message: t("container.form_errors.internal_server_error") });
              dispatch(closeModalAction({ name: MODAL_EXPORT_LOADING }));
            }
          });
        },
        () => {
          openNotification({ type: "error", message: t("container.form_errors.internal_server_error") });
          dispatch(closeModalAction({ name: MODAL_EXPORT_LOADING }));
        }
      );
    setVisible(false);
  };

  return (
    <>
      <LoadingModal name={MODAL_EXPORT_LOADING} message={t("qam.loading_pdf_report_message")} />
      <Dropdown
        visible={visible}
        onVisibleChange={(vis) => setVisible(vis)}
        trigger={["click"]}
        overlay={<ExportOverlay allowedExportTypes={allowedExportTypes} onExportClick={onExportClick} />}
        className={classNames("action-btn", className)}
      >
        <Button type="secondary" icon={<i className="ti-more" />}>
          {t("container.general_words.export")}
        </Button>
      </Dropdown>
    </>
  );
};

export default ExportDropdown;
