import React from "react";
import classNames from "classnames";

const TableCell = (props) => {
  const { className, minWidth, maxWidth, width, style, children, wrap = true } = props;

  return (
    <div
      className={classNames("table-cell", { nowrap: !wrap }, className)}
      style={{ minWidth, maxWidth, width, ...style }}
    >
      {children}
    </div>
  );
};

export default TableCell;
