import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import Button from "../../Button";
import { useCountdown } from "../../../utils/hooks/useCountdown";
import useLogout from "../useLogout";
import { getStorageAuth, saveStorageAuth } from "../methods";
import axios from "../../../axios";
import { closeModalAction } from "../../Modal/reducer";
import PageMetaTagTitle from "../../PageMetaTagTitle";
import { MODAL_SESSION_NAME } from "./index";

const TIME_TO_CONTINUE_YOUR_SESSION = 30;

const ModalSessionContent = ({ onModalClose }) => {
  const { t } = useTranslation();
  const countdownValue = useCountdown(TIME_TO_CONTINUE_YOUR_SESSION);
  const { submit, loading } = useLogout(() => {
    setLoggedOut(true);
  });
  const [loadingContinueSession, setLoadingContinueSession] = useState(false);
  const [loggedOut, setLoggedOut] = useState(false);
  const dispatch = useDispatch();
  const continueSession = () => {
    if (loadingContinueSession) {
      return;
    }

    const authStorage = getStorageAuth();
    setLoadingContinueSession(true);
    axios.post("auth/refresh", { token: authStorage.refresh_token }).then(
      (res) => {
        saveStorageAuth({ ...res.data, google2fa: authStorage.google2fa, token_timestamp: new Date().toString() });
        onModalClose && onModalClose("continue");
        dispatch(closeModalAction({ name: MODAL_SESSION_NAME }));
      },
      () => {
        setLoadingContinueSession(false);
      }
    );
  };

  const onLogoutClick = () => {
    onModalClose && onModalClose("logout");
    dispatch(closeModalAction({ name: MODAL_SESSION_NAME }));
    submit();
  };

  useEffect(() => {
    if (countdownValue === 0) {
      onModalClose && onModalClose("logout");
      submit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countdownValue]);

  return loggedOut ? (
    <>
      <PageMetaTagTitle defer={false} appName="" title={t("container.auth_modal.expired_session")} />
      <div className="h3">{t("container.auth_modal.expired_session")}</div>
      <div className="mt-30 flex">
        <Button onClick={() => dispatch(closeModalAction({ name: MODAL_SESSION_NAME }))}>
          {t("container.general_words.close")}
        </Button>
      </div>
    </>
  ) : (
    <>
      <PageMetaTagTitle defer={false} appName="" title={`${countdownValue} seconds until session expiry`} />
      <div className="h3">
        {t("container.auth_modal.form_title_part1")} {countdownValue} {t("container.auth_modal.form_title_part2")}
      </div>
      <div className="text-inline-small fs-14">{t("container.auth_modal.form_subtitle")}</div>
      <div className="mt-30 flex flex-justify-between">
        <Button loading={loading} disabled={loading} onClick={onLogoutClick} className="close-session-modal">
          {t("container.sidebar.logout")}
        </Button>
        <Button
          className="close-session-modal"
          type="primary"
          disabled={loading || loadingContinueSession}
          loading={loadingContinueSession}
          onClick={continueSession}
        >
          {t("container.auth_modal.continue_session")}
        </Button>
      </div>
    </>
  );
};

export default ModalSessionContent;
