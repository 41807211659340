import { useTranslation } from "react-i18next";
import { renderDynamicallyFields } from "../../../../../components/Form/methods";
import { FORM_ITEM_TEXT_AREA } from "../../../../../components/Form/FormItems/FormItemTextArea";
import { FORM_ITEM_DATE_PICKER } from "../../../../../components/Form/FormItems/FormItemDatePicker";
import moment from "../../../../../moment";
import { DATE_SHORT_FORMAT } from "../../../../../components/DatePicker";
import { FORM_ITEM_RESOURCE_SELECT_LAZY_LOADING } from "../../../../../components/Form/FormItems/FormItemResourceSelectLazyLoading";
import { FORM_ITEM_TEXT } from "../../../../../components/Form/FormItems/FormItemText";
import { prepareFields } from "../methods";
import config from "../../../../../config";

export const CorrectiveActionFields = ({ form, type }) => {
  const { t } = useTranslation();
  const typeOptions = [
    { value: "internal", text: t("ior.new_report.responsible.internal") },
    { value: "external", text: t("ior.new_report.responsible.external") }
  ];

  const fields = [
    {
      type: FORM_ITEM_TEXT_AREA,
      max: 4000,
      required: true,
      name: ["first_risk_analysis", "first_analysis_corrective_action"],
      label: "ior.new_report.corrective_action",
      placeholder: "ior.new_report.corrective_action_placeholder"
    },
    {
      type: FORM_ITEM_DATE_PICKER,
      name: ["first_risk_analysis", "first_analysis_deadline"],
      label: "ior.new_report.deadline",
      placeholder: "ior.new_report.deadline",
      disabledDate: (current) => {
        if (!form.getFieldValue(["basic_data", "event_date"])) {
          return false;
        }
        const fullDate = `${moment(form.getFieldValue(["basic_data", "event_date"])).format(
          config.defaultDateFormat
        )} ${moment(form.getFieldValue(["basic_data", "event_time"])).format("HH:mm")}`;

        return moment(current, DATE_SHORT_FORMAT).startOf("day") < moment(fullDate);
      }
    },
    {
      type: FORM_ITEM_RESOURCE_SELECT_LAZY_LOADING,
      required: true,
      fieldProps: {
        staticOptions: typeOptions,
        onChange: () => {
          const firstRiskAnalysis = form.getFieldValue("first_risk_analysis");
          form.setFieldsValue({
            first_risk_analysis: {
              ...firstRiskAnalysis,
              first_analysis_responsible_name: undefined,
              first_analysis_responsible_id: undefined
            }
          });
        }
      },
      name: ["first_risk_analysis", "first_analysis_responsible_type"],
      label: "ior.new_report.responsible.type",
      placeholder: "ior.new_report.responsible.type"
    },
    {
      type: FORM_ITEM_TEXT,
      max: 200,
      required: true,
      visible: (formInstance) =>
        formInstance.getFieldValue(["first_risk_analysis", "first_analysis_responsible_type"]) === "external",
      name: ["first_risk_analysis", "first_analysis_responsible_name"],
      label: "ior.new_report.responsible.name",
      placeholder: "ior.new_report.responsible.name"
    },
    {
      required: true,
      type: FORM_ITEM_RESOURCE_SELECT_LAZY_LOADING,
      visible: (formInstance) =>
        formInstance.getFieldValue(["first_risk_analysis", "first_analysis_responsible_type"]) === "internal",
      name: ["first_risk_analysis", "first_analysis_responsible_id"],
      label: "ior.new_report.responsible.name",
      fieldProps: {
        apiRoute:
          "users/all?field%5B%5D=user_is_in_organisation&filters%5BvalidContract%5D=1&op%5B%5D=eq&order_by_column%5B%5D=name&order_by_type%5B%5D=asc&term%5B%5D=1",
        requestOnMount: true,
        searchColumns: ["name"]
      },
      placeholder: "ior.new_report.responsible.name"
    }
  ];
  return <>{renderDynamicallyFields(prepareFields(fields, type))}</>;
};
