import React from "react";
import { useTranslation } from "react-i18next";
import isFunction from "lodash/isFunction";
import isBoolean from "lodash/isBoolean";
import { fieldRules } from "../methods";
import Form from "../index";
import DatePicker from "../../DatePicker";

export const FORM_ITEM_DATE_PICKER = "form-datepicker";

const FormItemDatePicker = (props) => {
  const {
    name,
    label,
    required,
    placeholder,
    rules = [],
    disabledDate,
    fieldProps = {},
    tooltip,
    visible,
    ...rest
  } = props;
  const { t } = useTranslation();

  const formField = (
    <Form.Item
      name={name}
      label={t(label)}
      rules={fieldRules(
        {
          required: required
            ? {
                message: t("container.form_errors.required")
              }
            : false
        },
        rules
      )}
      {...rest}
    >
      <DatePicker placeholder={t(placeholder)} disabledDate={disabledDate} children={tooltip} {...fieldProps} />
    </Form.Item>
  );

  if (isBoolean(visible) && !visible) {
    return null;
  }

  if (isFunction(visible)) {
    return (
      <Form.Item noStyle shouldUpdate>
        {(formInstance) => {
          const isVisible = visible(formInstance);
          if (isVisible) {
            return isVisible ? formField : null;
          }
        }}
      </Form.Item>
    );
  }

  return formField;
};

export default FormItemDatePicker;
