import React from "react";
import { NavLink } from "react-router-dom";
import Button from "../../Button";

const NewItemButton = ({ newItemProps = {} }) => {
  const { onClick = () => {}, disabled } = newItemProps;
  return (
    <>
      {newItemProps.href ? (
        <NavLink to={newItemProps.href} className="action-btn" onClick={onClick}>
          <Button type="primary" icon={<i className="ti-plus" />}>
            {newItemProps.text}
          </Button>
        </NavLink>
      ) : (
        <Button
          type="primary"
          icon={<i className="ti-plus" />}
          className="action-btn"
          onClick={onClick}
          disabled={disabled}
        >
          {newItemProps.text}
        </Button>
      )}
    </>
  );
};

export default NewItemButton;
