import { useState } from "react";
import { useDispatch } from "react-redux";
import axios from "../../axios";
import { deleteStorageAuth, getStorageAuth } from "./methods";
import { userUnauthenticatedAction } from "./reducer";
import AuthService from "./AuthService";

const useLogout = (onSuccess = () => {}) => {
  const dispatch = useDispatch();
  const [localState, setLocalState] = useState({
    loading: false
  });

  const submit = () => {
    if (localState.loading) {
      return;
    }

    const accessToken = getStorageAuth().access_token;

    if (accessToken) {
      setLocalState({ ...localState, loading: true });
      axios.post("auth/logout").then(
        () => {
          AuthService.sendBroadCastMessage("logout");
          deleteStorageAuth();
          setLocalState({ ...localState, loading: false });
          dispatch(userUnauthenticatedAction());
          onSuccess();
        },
        () => {
          AuthService.sendBroadCastMessage("logout");
          deleteStorageAuth();
          setLocalState({ ...localState, loading: false });
          dispatch(userUnauthenticatedAction());
          onSuccess();
        }
      );
    } else {
      dispatch(userUnauthenticatedAction());
    }
  };

  return {
    loading: localState.loading,
    submit
  };
};

export default useLogout;
