import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import classNames from "classnames";
import SmartTable from "../../../../../components/SmartTable";
import TableService from "../../../../../components/SmartTable/TableService";
import { useDateFormat } from "../../../../../utils/hooks/useDateFormat";
import { ReportCollapse } from "../ReportCollapse";
import PageMetaTagTitle from "../../../../../components/PageMetaTagTitle";
import { usePermission } from "../../../../../components/_auth/permissions/usePermission";
import { MODAL_IOR_CORRECTIVE_ACTION } from "../../../CorrectiveActions/AddCorrectiveActionForm";
import Button from "../../../../../components/Button";
import { openModalAction } from "../../../../../components/Modal/reducer";
import Form from "../../../../../components/Form";
import { REPORT_FORM_TYPE } from "../..";
import { PERMISSION_MANAGE_IOR_CORRECTIVE_ACTIONS } from "../../../../../components/_auth/permissions/permissions";
import { generateColumns, TABLE_IOR_CORRECTIVE_ACTIONS } from "./methods";

export const ReportCorrectiveActions = ({ form, data, type, basicDataSaved }) => {
  const { t } = useTranslation();
  const permission = usePermission();
  const dispatch = useDispatch();
  const { format } = useDateFormat();
  const tableKeyName = `${TABLE_IOR_CORRECTIVE_ACTIONS}`;
  const [tableService] = useState(
    new TableService({
      defaultWith: ["responsible"]
    })
  );
  const firstAnalysis = Form.useWatch("riskAnalysis", form) || {};

  return (
    <div className={classNames("activity-view-content mb-20")}>
      <ReportCollapse
        className="mb-15 mt-10"
        isSectionDisabled={!basicDataSaved?.basic_data}
        collapsed={false}
        header={{
          title: t("qam.activity_actions.corrective_actions"),
          tooltip: permission.canDo(PERMISSION_MANAGE_IOR_CORRECTIVE_ACTIONS)
            ? t("qam.activity_actions.corrective_actions_management")
            : t("qam.general_words.block_can_not_be_changed_here")
        }}
      >
        <>
          <PageMetaTagTitle title="IOR corrective actions" />
          <SmartTable
            scroll={{ x: 1600 }}
            name={TABLE_IOR_CORRECTIVE_ACTIONS}
            tableService={tableService}
            columns={generateColumns(permission, dispatch, t, format, data)}
            route={
              basicDataSaved?.basic_data?.id
                ? "ior/corrective-actions?field%5B%5D=report_id&op%5B%5D=eq&term%5B%5D=" +
                  basicDataSaved?.basic_data?.id
                : ""
            }
            tableLocalStorageKeyName={tableKeyName}
            rowSelection={false}
            externalActions={
              <Button
                type="primary"
                icon={<i className="ti-plus" />}
                className="action-btn"
                disabled={!firstAnalysis?.riskLevel?.corrective_action_is_required || type === REPORT_FORM_TYPE.VIEW}
                onClick={() => {
                  const riskAnalysis = form.getFieldValue(["riskAnalysis", "riskLevel"]);
                  dispatch(
                    openModalAction({
                      name: MODAL_IOR_CORRECTIVE_ACTION,
                      data: {
                        report_id: basicDataSaved.basic_data?.id,
                        risk_level_id: riskAnalysis.id
                      }
                    })
                  );
                }}
              >
                {t("qam.new_corrective_action.form_title")}
              </Button>
            }
          />
        </>
      </ReportCollapse>
    </div>
  );
};
