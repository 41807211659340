import React from "react";

const DropdownLanguagesOverlay = (props) => {
  const { selectedLanguage = {}, onSelectLanguage, availableLanguages = [] } = props;
  const languages = availableLanguages.filter((lang) => lang.code !== selectedLanguage.code);
  const onSelect = (lang) => () => onSelectLanguage(lang);

  return (
    <ul>
      {languages.map((lang) => (
        <li key={lang.code} onClick={onSelect(lang)}>
          <img src={lang.icon} alt="language-flag" />
          <span>{lang.title}</span>
        </li>
      ))}
    </ul>
  );
};

export default DropdownLanguagesOverlay;
