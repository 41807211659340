import React from "react";
import { useTranslation } from "react-i18next";
import { fieldRules } from "../methods";
import ResourceSelect from "../../ResourceSelect";
import Form from "../index";

export const FORM_ITEM_RESOURCE_SELECT = "form-resource-select";

const FormItemResourceSelect = (props) => {
  const { name, label, required, placeholder, options = [], rules = [], fieldProps = {} } = props;
  const { t } = useTranslation();

  return (
    <Form.Item
      name={name}
      label={t(label)}
      rules={fieldRules(
        {
          required: required
            ? {
                message: t("container.form_errors.required")
              }
            : false
        },
        rules
      )}
    >
      <ResourceSelect allowClear placeholder={t(placeholder)} data={options} {...fieldProps} />
    </Form.Item>
  );
};

export default FormItemResourceSelect;
