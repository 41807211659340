import pickBy from "lodash/pickBy";
import forEach from "lodash/forEach";
import isNull from "lodash/isNull";
import isUndefined from "lodash/isUndefined";
import isArray from "lodash/isArray";
import moment from "../../moment";
import config from "../../config";
import {
  FILTER_TYPE_DATE_RANGE,
  FILTER_TYPE_SELECT_MODE_1,
  FILTER_TYPE_SELECT_MODE_2,
  FILTER_TYPE_SELECT_MODE_3,
  FILTER_TYPE_STATIC_SELECT,
  FILTER_TYPE_STRING
} from "./methods";

class TableService {
  constructor(initialParams = {}) {
    this.initialParams = initialParams;
    this.params = initialParams.params;
    this.searchColumns = initialParams.searchColumns;
    this.searchValue = initialParams.searchValue || "";
    this.filters = initialParams.filters || [];
    this.anotherFilters = initialParams.anotherFilters || [];
    this.filterKeyTransformer = initialParams.filterKeyTransformer;
    this.orderBy = initialParams.orderBy || [];
    this.perPage = initialParams.perPage || 10;
    this.defaultPageSize = initialParams.defaultPageSize || 10;
    this.page = initialParams.page || 1;
    this.with = initialParams.with || [];
    this.total = null;
    this.onFilterChange = initialParams.onFilterChange;
    this.langKeys = [];
    this.period = {
      start: null,
      end: null
    };
  }

  addCustomParams(params = {}) {
    this.params = { ...params };
    return this;
  }

  getSearchColumns() {
    return this.searchColumns;
  }

  getSearchValue() {
    return this.searchValue;
  }

  setSearchValue(searchValue) {
    this.searchValue = searchValue;
  }

  manageColumnFilters(filters = {}, columns = []) {
    forEach(filters, (value, columnKey) => {
      const columnProps = columns.filter((item) => item.dataIndex === columnKey)[0];

      if (columnProps.filterType === FILTER_TYPE_STRING) {
        this.removeFilter(columnKey);
        if (value || value === 0) {
          this.addFilter({
            column: columnKey,
            op: "like",
            value: value
          });
        }
      }

      if (
        columnProps.filterType === FILTER_TYPE_STATIC_SELECT ||
        columnProps.filterType === FILTER_TYPE_SELECT_MODE_2
      ) {
        this.removeFilter(columnKey);
        if (value || value === 0) {
          this.addFilter({
            column: columnKey,
            op: "eq",
            value: columnProps.filterType === FILTER_TYPE_SELECT_MODE_2 ? value[0].value : value,
            ...(columnProps.filterType === FILTER_TYPE_SELECT_MODE_2 ? value[0] : {})
          });
        }
      }

      if (
        columnProps.filterType === FILTER_TYPE_SELECT_MODE_1 ||
        columnProps.filterType === FILTER_TYPE_SELECT_MODE_3
      ) {
        this.removeAnotherFilter(columnProps.filterNameKey);

        if (value || value === 0) {
          const valueToSave =
            columnProps.filterType === FILTER_TYPE_SELECT_MODE_3
              ? { value: isArray(value[0].value) ? value[0].value : [value[0].value] }
              : {};
          this.addAnotherFilter({
            nameKey: columnProps.filterNameKey,
            ...value[0],
            ...valueToSave
          });
        }
      }

      if (columnProps.filterType === FILTER_TYPE_DATE_RANGE) {
        this.removeFilter(columnKey);
        if (value) {
          this.addFilter({
            column: columnKey,
            op: "gte",
            value: moment(value[0]).format(config.defaultDateFormat)
          });
          this.addFilter({
            column: columnKey,
            op: "lte",
            value: moment(value[1]).format(config.defaultDateFormat)
          });
        }
      }
    });

    return this;
  }

  removeAnotherFilter(nameKey) {
    this.anotherFilters = this.anotherFilters.filter((filter) => filter.nameKey !== nameKey);
    this.onFilterChange && this.onFilterChange([...this.filters, ...this.anotherFilters]);
    return this;
  }

  removeAllFilters() {
    this.filters = [];
    this.searchValue = "";
    this.anotherFilters = [];
    this.page = 1;

    return this;
  }

  addAnotherFilter({ nameKey, value, text, column }) {
    const filter = {
      nameKey,
      value,
      text,
      column
    };
    this.anotherFilters.push(filter);
    this.onFilterChange && this.onFilterChange([...this.filters, ...this.anotherFilters]);

    return this;
  }

  addFilter({ column, op, value, ...rest }) {
    const filter = {
      column,
      op,
      value,
      ...rest
    };
    this.filters.push(filter);
    this.onFilterChange && this.onFilterChange([...this.filters, ...this.anotherFilters]);

    return this;
  }

  removeFilter(columnKey) {
    this.filters = this.filters.filter((filter) => filter.column !== columnKey);
    this.onFilterChange && this.onFilterChange([...this.filters, ...this.anotherFilters]);
    return this;
  }

  manageOrderBy({ column, type }) {
    const existingOrder = this.orderBy?.filter((item) => item.column === column).length > 0;
    if (existingOrder) {
      this.orderBy.removeOrderBy(column);
    }

    if (type) {
      let formattedType = type === "ascend" ? "asc" : type === "descend" ? "desc" : type;
      this.orderBy.push({ column, type: formattedType });
    }

    // For the case where defaultOrderBy type is "desc" and on click, the type will pe undefined
    const existingDefaultOrder =
      (this.initialParams?.defaultOrderBy || []).filter((item) => item.column === column).length > 0;
    if (!type && existingDefaultOrder) {
      this.orderBy.push({ column, type: "asc" });
    }

    return this;
  }

  removeOrderBy(column) {
    this.orderBy = this.orderBy.filter((item) => item.column !== column);
    return this;
  }

  removeAllOrderBy() {
    this.orderBy = [];
    return this;
  }

  setPerPage(perPage, checkInitial = false) {
    if (checkInitial) {
      this.perPage = this.initialParams.perPage || perPage || 10;
    } else {
      this.perPage = perPage || 10;
    }

    return this;
  }

  setDefaultPerPage(defaultPerPage) {
    this.defaultPageSize = defaultPerPage || 10;

    return this;
  }

  getPerPage() {
    return this.perPage;
  }

  setPage(page) {
    this.page = page || 1;

    return this;
  }

  getPage() {
    return this.page;
  }

  setTotal(total) {
    this.total = total || 0;

    return this;
  }

  getTotal() {
    return this.total;
  }

  getArrayValues(arrayValue = []) {
    if (arrayValue?.length === 0) {
      return undefined;
    }

    return arrayValue.filter((item) => item !== undefined);
  }

  applyDefaultParams(currentValue, defaultValue) {
    return currentValue || defaultValue;
  }

  prepareAnotherFilters() {
    const result = {};
    this.anotherFilters.forEach((item) => {
      const filterKeyTransformer = this.filterKeyTransformer;
      if (filterKeyTransformer && filterKeyTransformer.keys.includes(item.nameKey)) {
        result[filterKeyTransformer.transformer(item)] = item.value;
      } else {
        result[`filters[${item.nameKey}]`] = item.value;
      }
    });
    return result;
  }

  applyPermanentFilters() {
    if (this.initialParams?.permanentFilters) {
      this.initialParams.permanentFilters.forEach((item) => {
        this.removeFilter(item.column);
        this.addFilter(item);
      });
    }
  }

  getFilters() {
    this.applyPermanentFilters();

    return pickBy(
      {
        period_start: this.period.start,
        period_end: this.period.end,
        per_page: this.perPage,
        defaultPageSize: this.defaultPageSize,
        page: this.page,
        total: this.total,
        with: this.applyDefaultParams(this.getArrayValues(this.with), this.initialParams.defaultWith),
        order_by_column: this.applyDefaultParams(
          this.getArrayValues(this.orderBy.map((item) => item.column)),
          this.getArrayValues(this.initialParams.defaultOrderBy?.map((item) => item.column))
        ),
        order_by_type: this.applyDefaultParams(
          this.getArrayValues(this.orderBy.map((item) => item.type)),
          this.getArrayValues(this.initialParams.defaultOrderBy?.map((item) => item.type))
        ),
        field: this.getArrayValues(this.filters.map((item) => item.column)),
        op: this.getArrayValues(this.filters.map((item) => item.op)),
        term: this.getArrayValues(this.filters.map((item) => item.value)),
        searchColumns: this.searchValue ? this.searchColumns : undefined,
        searchValue: this.searchValue,
        ...(this.params || {}),
        ...this.prepareAnotherFilters()
      },
      (value) => !(isNull(value) || isUndefined(value) || (!value && value !== 0))
    );
  }

  getAllParameters() {
    return {
      params: this.params,
      searchColumns: this.searchColumns,
      searchValue: this.searchValue,
      filters: this.filters,
      anotherFilters: this.anotherFilters,
      orderBy: this.orderBy,
      with: this.with,
      defaultOrderBy: this.initialParams.defaultOrderBy
    };
  }
}

export default TableService;
