import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Button, Tooltip } from "antd";
import { ClearOutlined } from "@ant-design/icons";
import PageMetaTagTitle from "../../../components/PageMetaTagTitle";
import SmartTable from "../../../components/SmartTable";
import TableService from "../../../components/SmartTable/TableService";
import { usePermission } from "../../../components/_auth/permissions/usePermission";
import { PERMISSION_EXPORT_IOR_CORRECTIVE_ACTIONS } from "../../../components/_auth/permissions/permissions";
import { useLoggedUser } from "../../../utils/hooks/useLoggedUser";
import Modal from "../../../components/Modal";
import { useDateFormat } from "../../../utils/hooks/useDateFormat";
import { reloadTable } from "../../../components/SmartTable/reducer";
import { generateColumns, TABLE_IOR_CORRECTIVE_ACTIONS } from "./methods";
import { AddCorrectiveActionForm } from "./AddCorrectiveActionForm";

export const MODAL_IOR_CORRECTIVE_ACTION = "MODAL_IOR_CORRECTIVE_ACTION";

const IorCorrectiveActionsPage = () => {
  const dispatch = useDispatch();
  const permission = usePermission();
  const loggedUser = useLoggedUser();
  const { t } = useTranslation();
  const { format } = useDateFormat();
  const tableKeyName = `${TABLE_IOR_CORRECTIVE_ACTIONS}_${loggedUser.id}`;
  const localStorageTableFilters = JSON.parse(localStorage.getItem(tableKeyName) || "{}");
  const [tableService] = useState(
    new TableService({
      defaultWith: ["info", "report"],
      defaultOrderBy: [{ column: "was_updated", type: "desc" }],
      searchColumns: ["title", "number", "lead_auditor_name"],
      searchValue: "",
      ...localStorageTableFilters
    })
  );

  const handleClearFilters = () => {
    dispatch(reloadTable({ name: TABLE_IOR_CORRECTIVE_ACTIONS, resetFilters: true }));
  };

  return (
    <>
      <PageMetaTagTitle title="IOR corrective actions" />
      <Modal name={MODAL_IOR_CORRECTIVE_ACTION}>
        <AddCorrectiveActionForm />
      </Modal>
      <SmartTable
        scroll={{ x: 1600 }}
        name={TABLE_IOR_CORRECTIVE_ACTIONS}
        tableService={tableService}
        columns={generateColumns(permission, dispatch, t, format)}
        route="ior/corrective-actions"
        tableLocalStorageKeyName={tableKeyName}
        rowSelection={false}
        exportProps={{
          access: permission.canDo(PERMISSION_EXPORT_IOR_CORRECTIVE_ACTIONS),
          route: "ior/corrective-actions/export",
          columns: [
            { column: "status", trans: t("ior.corrective_actions.status") },
            { column: "number", trans: t("ior.corrective_actions.number") },
            { column: "title", trans: t("container.general_words.title") },
            { column: "due_date", trans: t("ior.corrective_actions.due_date") },
            { column: "responsible_name", trans: t("ior.corrective_actions.responsibility") },
            { column: "report_title", trans: t("ior.corrective_actions.report_title") },
            { column: "report_number", trans: t("ior.corrective_actions.report_custom_id") },
            { column: "lead_auditor_name", trans: t("ior.corrective_actions.lead_auditor") }
          ]
        }}
        clearFiltersActions={
          <Tooltip placement="topLeft" title={t("container.general_words.clear_filters")}>
            <Button onClick={handleClearFilters} icon={<ClearOutlined />}>
              {t("container.general_words.clear_filters")}
            </Button>
          </Tooltip>
        }
      />
    </>
  );
};

export default IorCorrectiveActionsPage;
