import React from "react";
import { NavLink } from "react-router-dom";
import {
  FILTER_TYPE_DATE_RANGE,
  FILTER_TYPE_SELECT_MODE_1,
  FILTER_TYPE_SELECT_MODE_3,
  FILTER_TYPE_STRING
} from "../../../components/SmartTable/methods";
import TableCell from "../../../components/SmartTable/TableCell";
import Tooltip from "../../../components/Tooltip";
import { openModalAction } from "../../../components/Modal/reducer";
import Tag from "../../../components/Tag";
import { PAGE_QAM_ACTIVITY_ACTIONS, PAGE_QAM_ACTIVITY_CHECKLIST, PAGE_QAM_CORRECTIVE_ACTION } from "../routes";
import { formatString } from "../../../utils/common";
import { PERMISSION_MANAGE_QAM_CORRECTIVE_ACTIONS } from "../../../components/_auth/permissions/permissions";
import { ACTIVITY_EXECUTION } from "../config";
import { correctiveActionStatuses } from "./config";
import { MODAL_QAM_CORRECTIVE_ACTION } from "./index";

export const TABLE_QAM_CORRECTIVE_ACTIONS = "table-qam-corrective-actions";

export const generateColumns = (permission, dispatch, trans, format) => {
  return [
    {
      title: "qam.corrective_actions.status",
      dataIndex: "status",
      sorter: true,
      filterType: FILTER_TYPE_SELECT_MODE_1,
      filterNameKey: "status",
      filterProps: {
        staticOptions: [
          ...correctiveActionStatuses.map((item) => ({ ...item, text: trans(item.name) })),
          { value: "assigned_overdue", text: trans("qam.general_words.qam_corrective_actions_stages.assigned_overdue") }
        ]
      },
      width: 100,
      render: (text, row) => {
        const status = getCorrectiveActionStatusLangKey(row.status, true);
        return (
          <TableCell>
            <Tag style={{ backgroundColor: status.color, color: "#fff", borderColor: "transparent" }}>
              {trans(status.name)}
            </Tag>
          </TableCell>
        );
      }
    },
    {
      title: "qam.corrective_actions.id",
      dataIndex: "number",
      filterType: FILTER_TYPE_STRING,
      sorter: true,
      width: 130,
      render: (text, row) => {
        return (
          <TableCell className="flex flex-items-center">
            {row.has_notification && <Tag className="tag-primary mr-10">{trans("container.new")}</Tag>}
            {row.number}
          </TableCell>
        );
      }
    },
    {
      title: "container.general_words.title",
      dataIndex: "title",
      filterType: FILTER_TYPE_STRING,
      sorter: true,
      render: (text, row) => {
        return <TableCell minWidth={130}>{row.title}</TableCell>;
      }
    },
    {
      title: "qam.corrective_actions.due_date",
      dataIndex: "due_date",
      sorter: true,
      filterType: FILTER_TYPE_DATE_RANGE,
      width: 170,
      render: (text, row) => <TableCell>{format({ date: row.due_date })}</TableCell>
    },
    {
      title: "qam.corrective_actions.entity",
      dataIndex: "entity_name",
      sorter: true,
      filterType: FILTER_TYPE_SELECT_MODE_1,
      filterNameKey: "entity",
      filterProps: {
        apiRoute: "qam/entities?order_by_column%5B%5D=name&order_by_type%5B%5D=asc"
      },
      render: (text, row) => <TableCell minWidth={130}>{row.entity_name}</TableCell>
    },
    {
      title: "qam.corrective_actions.responsibility",
      dataIndex: "responsible_name",
      filterType: FILTER_TYPE_STRING,
      sorter: true,
      width: 190,
      render: (text, row) => {
        return <TableCell>{row.responsible_name}</TableCell>;
      }
    },
    {
      title: "qam.corrective_actions.activity_title",
      dataIndex: "activity_title",
      filterType: FILTER_TYPE_SELECT_MODE_3,
      sorter: true,
      filterNameKey: "auditIds",
      filterProps: {
        apiRoute: "qam/audits-view?order_by_column%5B%5D=title&order_by_type%5B%5D=asc",
        transformer: (data) => data.map((item) => ({ ...item, name: item.title }))
      },
      render: (text, row) => {
        return <TableCell minWidth={150}>{row.activity_title}</TableCell>;
      }
    },
    {
      title: "qam.corrective_actions.activity_number",
      dataIndex: "activity_number",
      filterType: FILTER_TYPE_STRING,
      sorter: true,
      width: 180,
      render: (text, row) => {
        return (
          <TableCell>
            <NavLink
              to={`${
                row?.qamAudit?.stage?.name === ACTIVITY_EXECUTION
                  ? PAGE_QAM_ACTIVITY_CHECKLIST
                  : PAGE_QAM_ACTIVITY_ACTIONS
              }/${row.audit_id}`}
            >
              {row.activity_number}
            </NavLink>
          </TableCell>
        );
      }
    },
    {
      title: "qam.corrective_actions.lead_auditor",
      dataIndex: "lead_auditor_name",
      filterType: FILTER_TYPE_SELECT_MODE_3,
      sorter: true,
      filterNameKey: "leadAuditorIds",
      filterProps: {
        apiRoute: "qam/corrective-action-auditors?order_by_column%5B%5D=name&order_by_type%5B%5D=asc"
      },
      width: 220,
      render: (text, row) => {
        return <TableCell minWidth={150}>{formatString(row.lead_auditor_name, "-")}</TableCell>;
      }
    },
    {
      title: trans("container.general_words.actions"),
      fixed: "right",
      width: 80,
      render: (row = {}) => (
        <div className="table-actions-column">
          <Tooltip title={trans("container.general_words.view")}>
            <NavLink to={`${PAGE_QAM_CORRECTIVE_ACTION}/${row.id}`} className="text-secondary">
              <i className="ti-eye" />
            </NavLink>
          </Tooltip>
          {permission.canDo(PERMISSION_MANAGE_QAM_CORRECTIVE_ACTIONS) && (
            <Tooltip title={trans("container.general_words.edit")} placement="top" className="text-primary">
              <a
                href="/"
                disabled={row.status === correctiveActionStatuses[3].value}
                onClick={(e) => {
                  e.preventDefault();
                  if (row.status === correctiveActionStatuses[3].value) return;
                  dispatch(
                    openModalAction({ name: MODAL_QAM_CORRECTIVE_ACTION, data: { ...row, hideObservation: true } })
                  );
                }}
              >
                <i className="ti-pencil-alt" />
              </a>
            </Tooltip>
          )}
        </div>
      )
    }
  ];
};

export const getCorrectiveActionStatusLangKey = (status, withObjProperties = false) => {
  if (!status) {
    return null;
  }

  const statusResult = correctiveActionStatuses.filter((item) => item.value === status)[0];

  if (withObjProperties) {
    return statusResult;
  }

  return statusResult?.name;
};
