import { useEffect, useState } from "react";

export const useHtmlSize = (element) => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  useEffect(() => {
    const handleResize = () => {
      if (windowSize.width !== window.innerWidth || windowSize.height !== window.innerHeight) {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight
        });
      }
    };

    (element || window).addEventListener("resize", handleResize);
    handleResize();
    return () => (element || window).removeEventListener("resize", handleResize);
  }, [windowSize]); // eslint-disable-line

  return windowSize;
};
