import axios from "../../../axios";

export const getCriteriaData = (data) => {
  return axios.get("qam/criterias", {
    params: {
      "field": ["audit_id", "classification_option_id", "corrective_action_id"],
      "op": ["eq", "eq", "eq"],
      "term": [data.audit_id, data.classification_option_id, data.id],
      "with": ["evidences"]
    }
  });
};

export const prepareRootCausesOptions = (existentRootCauses = [], areasData = [], dataId) => {
  let areas = [...areasData];
  if (existentRootCauses.length > 0) {
    existentRootCauses
      .filter((item) => item.id !== dataId)
      .forEach((alreadyHave) => {
        areas = areas.filter((a) => {
          a.rootcausecategories = a.rootcausecategories.map((item) => {
            item.rootcausesubcategories = item.rootcausesubcategories.filter((subcategory) => {
              return subcategory.id !== alreadyHave.root_cause_subcategory_id;
            });

            return item;
          });

          return a;
        });

        areas = areas.filter((a) => {
          a.rootcausecategories = a.rootcausecategories.filter((item) => {
            return item.id !== alreadyHave.root_cause_category_id || item.rootcausesubcategories.length > 0;
          });

          return a;
        });

        areas = areas.filter((item) => item.rootcausecategories.length > 0);
      });
  }
  return areas;
};

export const getSelectedClassificationOption = (classificationOptions, classificationOptionId) => {
  if (!classificationOptions || !classificationOptionId) {
    return null;
  }

  return classificationOptions.filter((option) => option.id === classificationOptionId)[0];
};
