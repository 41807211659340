import React, { useCallback, useEffect, useState } from "react";
import { Badge, Dropdown } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useAxiosRequest } from "../../utils/hooks/useAxios";
import axios from "../../axios";
import { useLoggedUser } from "../../utils/hooks/useLoggedUser";
import { updateNotifications } from "../Notification/reducer";
import { TABLE_NOTIFICATIONS_NAME } from "../../pages/Core/Notifications/methods";
import { reloadTable } from "../SmartTable/reducer";
import DropdownOverlayNotifications from "./DropdownOverlayNotifications";
import { headerNotificationsParams, notificationsTransformer } from "./methods";

const DropdownNotifications = () => {
  const notificationsReduxState = useSelector((state) => state.notifications);
  const [visible, setVisible] = useState(false);
  const [refetch, setRefetch] = useState(null);
  const dispatch = useDispatch();
  const [total, setTotal] = useState(0);
  const [notificationIdLoading, setNotificationIdLoading] = useState(null);
  const loggedUser = useLoggedUser();
  const notificationState = useAxiosRequest({
    refetch,
    route: "notifications",
    params: headerNotificationsParams,
    config: {
      headers: {
        notTracking: true
      }
    },
    transformer: (res) => {
      setTotal(res.data.data.pager.totalItems);
      return notificationsTransformer(res.data.data.data, loggedUser?.company?.date_format);
    }
  });

  const markAsSeen = useCallback(
    (notification) => {
      if (notificationIdLoading) {
        return;
      }

      setNotificationIdLoading(notification.id);
      axios.put(`notifications/mark-as-seen/${notification.id}`).then(
        () => {
          setRefetch(!refetch);
          setNotificationIdLoading(null);
          dispatch(reloadTable({ name: TABLE_NOTIFICATIONS_NAME }));
        },
        () => {
          setNotificationIdLoading(null);
        }
      );
    },
    [notificationIdLoading, refetch, dispatch]
  );

  useEffect(() => {
    const refetchOnIntervalTime = setInterval(() => {
      setRefetch(!refetch);
    }, 10 * 60 * 1000);

    return () => {
      clearInterval(refetchOnIntervalTime);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);

  useEffect(() => {
    if (notificationsReduxState.triggerHeader) {
      setRefetch(!refetch);
      dispatch(
        updateNotifications({
          ...notificationsReduxState,
          triggerHeader: false
        })
      );
    }
  }, [dispatch, notificationsReduxState, refetch]);

  return (
    <Dropdown
      visible={visible}
      onVisibleChange={(vis) => setVisible(vis)}
      overlayClassName="notifications-dropdown-overlay"
      trigger={["click"]}
      getPopupContainer={(el) => el}
      overlay={
        <DropdownOverlayNotifications
          data={notificationState.data || []}
          loading={notificationState.loading}
          markAsSeen={markAsSeen}
          notificationIdLoading={notificationIdLoading}
          setVisible={setVisible}
        />
      }
    >
      <div className="link icon-link white notifications-trigger">
        <Badge count={total} size="small" className="notifications-badge">
          <i className="ti-bell" />
        </Badge>
      </div>
    </Dropdown>
  );
};

export default DropdownNotifications;
