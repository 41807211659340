import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useHistory } from "react-router-dom";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { usePermission } from "../_auth/permissions/usePermission";
import { openModalAction } from "../Modal/reducer";
import { PAGE_IOR_NEW_REPORT } from "../../pages/Ior/routes";
import { IOR_REPORT_PAGE } from "../../pages/Ior/ReportPage/ReportForm/ReportForm";
import { groupedNewItems } from "./methods";

const DropdownNewItemOverlay = ({ setVisible }) => {
  const { t } = useTranslation();
  const permission = usePermission();
  const dispatch = useDispatch();
  const history = useHistory();
  const openModal = (item) => (e) => {
    e.preventDefault();
    setVisible(false);
    if (item.modal) {
      dispatch(openModalAction({ name: item.modal }));
    }
  };
  const handleClickLink = (e) => {
    setVisible(false);
    if (e.target.name === "create-ior-report") {
      e.preventDefault();
      history.push(PAGE_IOR_NEW_REPORT, {
        page: window.location.pathname === "/ior/reports" ? IOR_REPORT_PAGE.REPORTS : IOR_REPORT_PAGE.MY_REPORTS
      });
    }
  };

  return (
    <div className="inner">
      {groupedNewItems.map(
        (groupedItem) =>
          permission.canDo(groupedItem.gates) && (
            <div className="new-items-group" key={groupedItem.title}>
              <div className="h4 mb-20 flex flex-wrap flex-items-center">
                <i className={classNames(groupedItem.iconClassName, "mr-10")} />
                <span>{groupedItem.title}</span>
              </div>
              <ul>
                {groupedItem.items.map(
                  (item) =>
                    permission.canDo(item.gates) && (
                      <li key={item.name}>
                        {item.link ? (
                          <NavLink to={item.link} onClick={(e) => handleClickLink(e)} name={`${groupedItem.gates}`}>
                            <i className="ti-plus" />
                            {t(item.name)}
                          </NavLink>
                        ) : (
                          <a href="/" onClick={openModal(item)}>
                            <i className="ti-plus" />
                            {t(item.name)}
                          </a>
                        )}
                      </li>
                    )
                )}
              </ul>
            </div>
          )
      )}
    </div>
  );
};

export default DropdownNewItemOverlay;
