import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import forEach from "lodash/forEach";
import { useHistory } from "react-router-dom";
import Button from "../../../../components/Button";
import { REPORT_FORM_TYPE } from "../index";
import Tooltip from "../../../../components/Tooltip";
import { openConfirm } from "../../../../components/Modal/Confirm";
import axios from "../../../../axios";
import { PAGE_IOR_EDIT_REPORT, PAGE_IOR_REPORTS } from "../../routes";
import useFormSubmit from "../../../../components/Form/useFormSubmit";
import { useDateFormat } from "../../../../utils/hooks/useDateFormat";
import { prepareFormData, showCollapsedBoxErrors } from "./methods";

export const ReportButtons = ({
  onClose,
  submit,
  type,
  data,
  setSuccess,
  form,
  collapseErrors,
  setCollapseErrors,
  canBeReopened,
  submitLoading,
  basicDataSaved
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { formatLocaleUTC, format } = useDateFormat();

  const closeReport = () => {
    openConfirm({
      content: <div>{t("ior.new_report.close_report_confirmation_message")}</div>,
      onOk: () => {
        setLoading(true);
        axios
          .put(`ior/report/close/${data?.basic_data?.id}`)
          .then(() => {
            setSuccess(true);
            history.push(PAGE_IOR_REPORTS);
          })
          .catch((err) => {
            const formError = [];
            forEach(err.response?.data?.errors, (value, key) => {
              formError.push({ name: key, errors: value });
            });
            showCollapsedBoxErrors(form, formError, collapseErrors, setCollapseErrors);
          })
          .finally(() => setLoading(false));
      },
      zIndex: 99999
    });
  };

  const { submit: localSubmit } = useFormSubmit({
    route:
      data?.basic_data?.id || basicDataSaved?.basic_data?.id
        ? `ior/report/${data?.basic_data?.id ? data.basic_data.id : basicDataSaved.basic_data.id}`
        : "ior/report",
    method: data?.basic_data?.id || basicDataSaved?.basic_data?.id ? "put" : "post",
    form,
    transformer: (values) => {
      return prepareFormData(values, formatLocaleUTC, format);
    },
    onSuccess: () => {
      closeReport();
    },
    onFailed: (err, generalErrors, formErrors) => {
      showCollapsedBoxErrors(form, formErrors, collapseErrors, setCollapseErrors);
    }
  });

  const reopenReport = () => {
    openConfirm({
      onOk: () => {
        setLoading(true);
        axios
          .put(`ior/report/reopen-report/${data?.basic_data?.id}`)
          .then(() => {
            history.push(`${PAGE_IOR_EDIT_REPORT}/${data?.basic_data?.id}`);
            history.go(0);
          })
          .catch((err) => {
            const formError = [];
            forEach(err.response?.data?.errors, (value, key) => {
              formError.push({ name: key, errors: value });
            });
            showCollapsedBoxErrors(form, formError, collapseErrors, setCollapseErrors);
          })
          .finally(() => setLoading(false));
      },
      zIndex: 99999
    });
  };

  return (
    <div className="flex flex-items-center">
      <Button
        loading={loading || submitLoading}
        disabled={loading || submitLoading}
        htmlType="submit"
        icon={<i className="ti-back-left" />}
        onClick={onClose}
      >
        {t("container.confirm_modal.cancel")}
      </Button>
      {type !== REPORT_FORM_TYPE.VIEW && (
        <Button
          loading={loading || submitLoading}
          disabled={loading || submitLoading}
          iconRight
          className="min-w-150 ml-10"
          type="primary"
          htmlType="button"
          icon={<i className="ti-check" />}
          onClick={submit}
        >
          {t("container.general_words.save")}
        </Button>
      )}
      {data?.canBeClosed && type === REPORT_FORM_TYPE.EDIT && (
        <Tooltip title={t("ior.new_report.stage_open_popover")} placement="top" onClick={localSubmit}>
          <Button
            loading={loading || submitLoading}
            disabled={loading || submitLoading}
            className="ml-10 icon-center large"
            type="primary"
            htmlType="button"
            icon={<i className="ti-unlock" />}
          />
        </Tooltip>
      )}
      {data?.closer_id && canBeReopened && data?.basic_data.stage !== "archived" && (
        <Tooltip title={t("ior.new_report.stage_closed_popover")} placement="top" onClick={reopenReport}>
          <Button
            loading={loading || submitLoading}
            disabled={loading || submitLoading}
            className="ml-10 icon-center large"
            type="primary"
            htmlType="button"
            icon={<i className="ti-unlock" />}
          />
        </Tooltip>
      )}
    </div>
  );
};
