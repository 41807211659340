import { createAction, createReducer } from "@reduxjs/toolkit";

const initialState = {};

export const changeActiveStep = createAction("steps/change-active-step");
export const resetStepsState = createAction("steps/reset-steps-state");

export default createReducer(initialState, (builder) => {
  builder
    .addCase(changeActiveStep, (state, action) => {
      return {
        ...state,
        [action.payload.name]: {
          activeStep: action.payload.activeStep
        }
      };
    })
    .addCase(resetStepsState, (state, action) => {
      return {
        ...state,
        [action.payload.name]: {}
      };
    });
});
