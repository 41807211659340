import React from "react";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Tooltip } from "antd";
import Tag from "../../../../components/Tag";
import { closeModalAction, openModalAction } from "../../../../components/Modal/reducer";
import { MODAL_EXPORT_LOADING } from "../../../../components/SmartTable/ExportDropdown/LoadingModal";
import { downloadFile } from "../../../../utils/downloadFile";
import { openNotification } from "../../../../components/Notification";
import { computeFileNameExtension, getExtensionLabelClassName } from "./methods";

const FileBox = ({ className, filename, fileUrl, fileExt, downloadIcon, onRemove }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const fileExtension = fileExt || (filename ? filename.substr(filename.lastIndexOf(".") + 1) : null);
  const extUppercase = fileExtension ? fileExtension.toUpperCase() : null;
  const extClass = getExtensionLabelClassName(extUppercase);

  const download = () => {
    if (!fileUrl) return;
    dispatch(openModalAction({ name: MODAL_EXPORT_LOADING }));
    downloadFile({
      fileUrl: fileUrl,
      fileName: computeFileNameExtension(filename, extUppercase),
      onSuccess: () => {
        dispatch(closeModalAction({ name: MODAL_EXPORT_LOADING }));
      },
      onFailed: () => {
        openNotification({ type: "error", message: t("container.form_errors.internal_server_error") });
        dispatch(closeModalAction({ name: MODAL_EXPORT_LOADING }));
      }
    });
  };

  return (
    <div
      className={classNames("file-box", className, {
        clickable: !!fileUrl && !downloadIcon,
        "with-download": downloadIcon,
        "with-delete": onRemove
      })}
      onClick={downloadIcon ? null : download}
    >
      <div className="file-box-name-ext">
        {extUppercase && <Tag className={classNames("file-extension mr-5", extClass)}>{extUppercase}</Tag>}
        {filename && <span className="file-name">{filename}</span>}
      </div>
      {(downloadIcon || onRemove) && (
        <div className="actions">
          {downloadIcon && fileUrl && (
            <Tooltip title={t("container.general_words.download")}>
              <div className="action-item" onClick={download}>
                {downloadIcon}
              </div>
            </Tooltip>
          )}
          {onRemove && (
            <Tooltip title={t("container.general_words.delete")} className="text-danger">
              <div className="action-item" onClick={onRemove}>
                <i className="ti-close" />
              </div>
            </Tooltip>
          )}
        </div>
      )}
    </div>
  );
};

export default FileBox;
