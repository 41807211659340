import { FileExcelOutlined, FilePdfOutlined, FileTextOutlined } from "@ant-design/icons";
import React from "react";
import { preventDefaultWithCallback } from "../../../utils/common";

export const EXPORT_TYPE_XLS = "xls";
export const EXPORT_TYPE_CSV = "csv";
export const EXPORT_TYPE_PDF = "pdf";
export const EXPORT_TYPE_ZIP = "zip";

const ExportOverlay = ({ onExportClick, allowedExportTypes = [] }) => {
  return (
    <div className="export-overlay">
      {allowedExportTypes.map((exportType) => (
        <a href="/" key={exportType} onClick={preventDefaultWithCallback(onExportClick(exportType))}>
          {exportType === EXPORT_TYPE_XLS && (
            <>
              <FileExcelOutlined />
              Excel
            </>
          )}
          {exportType === EXPORT_TYPE_CSV && (
            <>
              <FileTextOutlined />
              CSV
            </>
          )}
          {exportType === EXPORT_TYPE_PDF && (
            <>
              <FilePdfOutlined />
              PDF
            </>
          )}
        </a>
      ))}
    </div>
  );
};

export default ExportOverlay;
