import axiosPackage from "axios";
import { saveAs } from "file-saver";
import { getStorageAuth } from "../components/_auth/methods";

export const downloadFile = ({ fileUrl, fileName, onStart, onSuccess, onFailed }) => {
  const authStorage = getStorageAuth();
  const headers = authStorage?.access_token
    ? {
        "Authorization": `Bearer ${authStorage.access_token}`,
        google2fa: authStorage.google2fa
      }
    : {};
  onStart && onStart();
  axiosPackage
    .get(fileUrl, {
      headers,
      responseType: "blob"
    })
    .then(
      (response) => {
        saveAs(response.data, fileName);
        onSuccess && onSuccess(response);
      },
      (err) => {
        onFailed && onFailed(err);
      }
    );
};
