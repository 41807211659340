import {
  PERMISSION_CREATE_IOR_REPORTS,
  PERMISSION_CREATE_QAM_AUDITS,
  PERMISSION_MANAGE_CORE_GROUPS,
  PERMISSION_MANAGE_CORE_USERS,
  PERMISSION_MANAGE_DOM_TEMPLATES,
  PERMISSION_MANAGE_ORG_SETTINGS,
  PERMISSION_MANAGE_ORG_STAFF,
  PERMISSION_MANAGE_QAM_AUDITS,
  PERMISSION_MANAGE_QAM_ENTITIES,
  PERMISSION_MANAGE_QAM_NORMS,
  PERMISSION_MANAGE_RSM_PUBLICATIONS,
  PERMISSION_MANAGE_TRG
} from "../_auth/permissions/permissions";
import { PAGE_NEW_GROUP, PAGE_NEW_USER } from "../../pages/Core/routes";
import { PAGE_NEW_ORGANIZATION_STAFF } from "../../pages/Organization/routes";
import { MODAL_ORG_SETTINGS_FUNCTIONS } from "../../pages/Organization/OrgSettings/Functions";
import { MODAL_NORM_FORM } from "../../pages/Qam/Catalogues/Norms/NormForm";
import { MODAL_TRG_TRAINING } from "../../pages/Trg/Trainings";
import { MODAL_QUALIFICATION } from "../../pages/Trg/TrgQualifications";
import { PAGE_TRG_NEW_PLANNING } from "../../pages/Trg/routes";
import { PAGE_IOR_NEW_REPORT } from "../../pages/Ior/routes";
import { PAGE_RSM_NEW_PUBLICATION } from "../../pages/Rsm/routes";
import { MODAL_TEMPLATE_FORM } from "../../pages/Dom/DocumentTemplates/Templates/TemplateForm";
import { MODAL_QAM_ACTIVITY_FORM_HEADER_NEW_ITEM, MODAL_QAM_ENTITY_FORM_HEADER_NEW_ITEM } from "./config";

export const CORE_NEW_ITEMS = [
  {
    name: "container.users.new_user",
    link: PAGE_NEW_USER,
    gates: PERMISSION_MANAGE_CORE_USERS
  },
  {
    name: "container.groups.new_group.form_title",
    link: PAGE_NEW_GROUP,
    gates: PERMISSION_MANAGE_CORE_GROUPS
  }
];

export const ORG_NEW_ITEMS = [
  {
    name: "org.staff.new_staff",
    link: PAGE_NEW_ORGANIZATION_STAFF,
    gates: PERMISSION_MANAGE_ORG_STAFF
  },
  {
    name: "container.org_settings.functions_tab.new_function.form_title",
    modal: MODAL_ORG_SETTINGS_FUNCTIONS,
    gates: PERMISSION_MANAGE_ORG_SETTINGS
  }
];

export const QAM_NEW_ITEMS = [
  {
    name: "qam.new_entity.form_title",
    modal: MODAL_QAM_ENTITY_FORM_HEADER_NEW_ITEM,
    gates: PERMISSION_MANAGE_QAM_ENTITIES
  },
  {
    name: "qam.new_activity.form_title",
    modal: MODAL_QAM_ACTIVITY_FORM_HEADER_NEW_ITEM,
    gates: PERMISSION_CREATE_QAM_AUDITS
  },
  {
    name: "qam.new_norm.form_title",
    modal: MODAL_NORM_FORM,
    gates: PERMISSION_MANAGE_QAM_NORMS
  }
];

export const TRG_NEW_ITEMS = [
  {
    name: "trg.new_training.form_title",
    modal: MODAL_TRG_TRAINING
  },
  {
    name: "trg.add_qualification_modal.form_title",
    modal: MODAL_QUALIFICATION
  },
  {
    name: "trg.planning.new_course",
    link: PAGE_TRG_NEW_PLANNING
  }
];

export const IOR_NEW_ITEMS = [
  {
    name: "ior.reports.new_report",
    link: PAGE_IOR_NEW_REPORT
  }
];

export const RSM_NEW_ITEMS = [
  {
    name: "rsm.publications.new_publication",
    link: PAGE_RSM_NEW_PUBLICATION
  }
];

export const DOM_NEW_ITEMS = [
  {
    name: "qam.new_entity.form_title",
    modal: MODAL_TEMPLATE_FORM,
    gates: PERMISSION_MANAGE_DOM_TEMPLATES
  }
];

export const groupedNewItems = [
  {
    title: "CORE",
    iconClassName: "ti-server",
    gates: [PERMISSION_MANAGE_CORE_USERS, PERMISSION_MANAGE_CORE_GROUPS],
    items: CORE_NEW_ITEMS
  },
  {
    title: "ORG",
    iconClassName: "ti-pulse",
    items: ORG_NEW_ITEMS,
    gates: [PERMISSION_MANAGE_ORG_STAFF, PERMISSION_MANAGE_ORG_SETTINGS]
  },
  {
    title: "QAM",
    iconClassName: "ti-check-box",
    items: QAM_NEW_ITEMS,
    gates: [PERMISSION_MANAGE_QAM_ENTITIES, PERMISSION_MANAGE_QAM_AUDITS, PERMISSION_MANAGE_QAM_NORMS]
  },
  {
    title: "TRG",
    iconClassName: "ti-medall-alt",
    items: TRG_NEW_ITEMS,
    gates: PERMISSION_MANAGE_TRG
  },
  {
    title: "IOR",
    iconClassName: "ti-bolt",
    items: IOR_NEW_ITEMS,
    gates: PERMISSION_CREATE_IOR_REPORTS
  },
  {
    title: "RSM",
    iconClassName: "ti-write",
    items: RSM_NEW_ITEMS,
    gates: PERMISSION_MANAGE_RSM_PUBLICATIONS
  },
  {
    title: "DOM",
    iconClassName: "ti-folder",
    items: DOM_NEW_ITEMS,
    gates: [PERMISSION_MANAGE_DOM_TEMPLATES]
  }
];
