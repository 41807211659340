import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";
import Form, { FORM_HORIZONTAL_LAYOUT } from "../../../../components/Form";
import useFormSubmit from "../../../../components/Form/useFormSubmit";
import { reloadTable } from "../../../../components/SmartTable/reducer";
import { closeModalAction } from "../../../../components/Modal/reducer";
import { FORM_ITEM_TEXT } from "../../../../components/Form/FormItems/FormItemText";
import Button from "../../../../components/Button";
import { FORM_ITEM_CHECKBOX } from "../../../../components/Form/FormItems/FormItemCheckbox";
import { renderDynamicallyFields } from "../../../../components/Form/methods";
import { TABLE_ORG_SETTINGS_FUNCTIONS_NAME } from "./methods";
import { MODAL_ORG_SETTINGS_FUNCTIONS } from "./index";

const FunctionForm = () => {
  const data = useSelector((state) => state?.modal[MODAL_ORG_SETTINGS_FUNCTIONS]?.data) || {};
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isNewRecord = isEmpty(data);
  const { loading, submit, generalError } = useFormSubmit({
    route: isNewRecord ? "job-titles" : `job-titles/${data.id}`,
    method: isNewRecord ? "post" : "put",
    form,
    onSuccess: () => {
      dispatch(reloadTable({ name: TABLE_ORG_SETTINGS_FUNCTIONS_NAME, resetFilters: isNewRecord }));
      dispatch(closeModalAction({ name: MODAL_ORG_SETTINGS_FUNCTIONS }));
    }
  });

  return (
    <Form
      onSubmit={submit}
      form={form}
      errors={generalError}
      loading={loading}
      initialValues={{ active: true, name: data?.name }}
      layout={FORM_HORIZONTAL_LAYOUT}
      header={
        <>
          <div className="h3">
            {t(
              isNewRecord
                ? "container.org_settings.functions_tab.new_function.form_title"
                : "container.org_settings.functions_tab.edit_function.form_title"
            )}
          </div>
          <div className="mb-30 mt-10 h6 font-weight-300 text-muted">{t("container.general_words.form_subtitle")}</div>
        </>
      }
    >
      {renderDynamicallyFields([
        {
          required: true,
          type: FORM_ITEM_TEXT,
          max: 200,
          name: "name",
          label: "container.org_settings.functions_tab.new_function.function_name",
          placeholder: "container.org_settings.functions_tab.new_function.function_name"
        },
        {
          type: FORM_ITEM_CHECKBOX,
          visible: isNewRecord,
          name: "active",
          label: "container.org_settings.functions_tab.active"
        }
      ])}
      <div className="flex mt-25 flex-justify-end">
        <Button
          className="min-w-150"
          type="primary"
          htmlType="submit"
          disabled={loading}
          loading={loading}
          icon={<i className="ti-check" />}
        >
          {t("container.general_words.save")}
        </Button>
      </div>
    </Form>
  );
};

export default FunctionForm;
