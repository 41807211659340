import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import ResourceSelectLazyLoading from "../../../../../../components/ResourceSelectLazyLoading";
import Button from "../../../../../../components/Button";
import Tooltip from "../../../../../../components/Tooltip";
import { INVESTIGATOR_CO_AUDITOR } from "../config";
import axios from "../../../../../../axios";
import useReportData from "../../../useReportData";
import { openNotification } from "../../../../../../components/Notification";
import { useClearInvestigatorSelect } from "../methods";
import { usePermission } from "../../../../../../components/_auth/permissions/usePermission";
import { PERMISSION_MANAGE_IOR_CORRECTIVE_ACTIONS } from "../../../../../../components/_auth/permissions/permissions";
import { updateReportData } from "../../../reducer";
import InvestigatorCard from "../InvestigatorCard";
import { REPORT_FORM_TYPE } from "../../..";

const ReportCoAuditors = ({ investigators, loading, type, basicDataSaved }) => {
  const { t } = useTranslation();
  const permission = usePermission();
  const [coAuditor, setCoAuditor] = useState();
  const report = useReportData();
  const dispatch = useDispatch();
  const reportCoAuditors = report?.coAuditors;
  const [loadingSave, setLoadingSave] = useState(false);
  useClearInvestigatorSelect(coAuditor, setCoAuditor);

  const addCoAuditor = () => {
    if (!coAuditor || loadingSave) return;
    setLoadingSave(true);

    const coAuditorsIds = (reportCoAuditors || []).map((item) => item.id);
    const reportId = report?.basic_data?.id ? report.basic_data.id : basicDataSaved.basic_data.id;
    axios
      .put(`ior/report/set-investigator/${reportId}`, {
        coAuditors: [...coAuditorsIds, coAuditor.id]
      })
      .then(
        () => {
          dispatch(
            updateReportData({
              coAuditors: [...(reportCoAuditors || []), coAuditor]
            })
          );
          setLoadingSave(false);
          setCoAuditor(undefined);

          openNotification({
            message: t("qam.activity_program.notifications.co-auditor_saved"),
            type: "success",
            placement: "bottom"
          });
        },
        () => {
          setLoadingSave(false);
          openNotification({
            message: t("qam.activity_program.notifications.co-auditor_could_not_be_saved"),
            type: "error",
            placement: "bottom"
          });
        }
      );
  };

  return (
    <div>
      {permission.canDo(PERMISSION_MANAGE_IOR_CORRECTIVE_ACTIONS) && type !== REPORT_FORM_TYPE.VIEW ? (
        <div className="activity-field-input">
          <ResourceSelectLazyLoading
            overwritePreviousOptions
            size="middle"
            placeholder={t("ior.reports.select_co_investigator_placeholder")}
            searchIn={["name", "company"]}
            disabled={loading}
            staticOptions={investigators}
            onChange={(id, itemObj) => setCoAuditor(itemObj)}
            value={coAuditor?.id || undefined}
          />
          <Tooltip
            title={!coAuditor ? t("qam.activity_program.participants_co_auditor_disabled_message_popover") : null}
          >
            <Button type="ghost-icon" className={!coAuditor ? "disabled" : ""} onClick={addCoAuditor}>
              <i className="ti-check" />
            </Button>
          </Tooltip>
        </div>
      ) : (
        <></>
      )}
      {reportCoAuditors?.length > 0 && (
        <div className="activity-field-value">
          {reportCoAuditors.map((coAuditor) => (
            <InvestigatorCard key={coAuditor.id} type={type} user={{ ...coAuditor, type: INVESTIGATOR_CO_AUDITOR }} />
          ))}
        </div>
      )}
    </div>
  );
};

export default ReportCoAuditors;
