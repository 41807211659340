import React from "react";
import { useTranslation } from "react-i18next";
import { fieldRules } from "../methods";
import Form from "../index";
import Radio from "../../Radio";

export const FORM_ITEM_RADIO_GROUP = "form-radio-group";

const FormItemRadioGroup = (props) => {
  const { name, label, required, rules = [], fieldProps = {}, radioItems = [], visible = true, ...rest } = props;
  const { t } = useTranslation();

  if (!visible) {
    return null;
  }

  return (
    <Form.Item
      name={name}
      label={t(label)}
      rules={fieldRules(
        {
          required: required
            ? {
                message: t("container.form_errors.required")
              }
            : false
        },
        rules
      )}
      {...rest}
    >
      <Radio.Group {...fieldProps}>
        {radioItems.map(({ value, label }) => (
          <Radio key={value} value={value}>
            {label}
          </Radio>
        ))}
      </Radio.Group>
    </Form.Item>
  );
};

export default FormItemRadioGroup;
