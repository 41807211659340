import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import uniqBy from "lodash/uniqBy";
import { useDispatch } from "react-redux";
import Form, { FORM_HORIZONTAL_LAYOUT } from "../../../../../components/Form";
import Button from "../../../../../components/Button";
import InputUploadFile from "../../../../../components/Upload/InputUploadFile";
import { Input } from "../../../../../components/Input";
import { useModalData } from "../../../../../components/Modal/useModalData";
import { closeModalAction } from "../../../../../components/Modal/reducer";
import { MODAL_IOR_DOCUMENT_FORM } from "./DocumentsTable";

export const DocumentForm = () => {
  const data = useModalData(MODAL_IOR_DOCUMENT_FORM);
  const { attachments, setAttachments } = data;
  const dispatch = useDispatch();
  const [currentFile, setCurrentFile] = useState(data);
  const isEdit = !!data.id;
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const onSave = () => {
    setAttachments(uniqBy([{ ...currentFile, name: form.getFieldValue("title") }, ...attachments], "id"));
    dispatch(closeModalAction({ name: MODAL_IOR_DOCUMENT_FORM }));
  };

  return (
    <Form
      form={form}
      layout={FORM_HORIZONTAL_LAYOUT}
      initialValues={{ name: data, title: data.name }}
      header={
        <>
          <div className="h3">
            {isEdit ? t("ior.new_report.edit_attachment.form_title") : t("ior.new_report.new_attachment.form_title")}
          </div>
          <div className="mb-30 mt-10 h6 font-weight-300 text-muted">{t("container.general_words.form_subtitle")}</div>
        </>
      }
    >
      <Form.Item name="name" required label={t("ior.new_report.new_attachment.attachment")}>
        <InputUploadFile
          purpose="document"
          entity="IorReport"
          type="document"
          onUpload={(file) => {
            setCurrentFile(file);
            if (!form.getFieldValue("title")) {
              form.setFieldsValue({ "title": file.name });
            }
          }}
          placeholder={t("crm.new_company.picture_placeholder")}
        />
      </Form.Item>
      <Form.Item name="title" required label={t("container.general_words.title")}>
        <Input placeholder={t("container.general_words.title")} />
      </Form.Item>
      <div className="flex mt-25 flex-justify-end">
        <Button onClick={onSave} className="min-w-150" type="primary" icon={<i className="ti-check" />}>
          {t("container.general_words.save")}
        </Button>
      </div>
    </Form>
  );
};
