import React from "react";
import Modal from "../../Modal";
import ModalSessionContent from "./ModalContent";

export const MODAL_SESSION_NAME = "modal-session";

const ModalSession = ({ onModalClose }) => {
  return (
    <Modal name={MODAL_SESSION_NAME} size="small" closable={false}>
      <ModalSessionContent onModalClose={onModalClose} />
    </Modal>
  );
};

export default ModalSession;
