import { useSelector } from "react-redux";
import { useCallback } from "react";
import isString from "lodash/isString";
import isArray from "lodash/isArray";
import { formatAccessList } from "./methods";

export const usePermission = () => {
  const userState = useSelector((state) => state.auth?.user);
  const { accessList, moduleList } = formatAccessList(userState);

  const canDo = useCallback(
    (actions) => {
      if (!actions) {
        return true;
      }

      if (isString(actions)) {
        return accessList.filter((item) => item.slug === actions).length > 0;
      }

      if (isArray(actions)) {
        const match = accessList.find((item) => {
          return actions.includes(item.slug);
        });

        return !!match;
      }
    },
    [accessList]
  );

  const hasModule = useCallback(
    (module) => {
      if (!module) {
        return true;
      }

      return moduleList.indexOf(module) > -1;
    },
    [moduleList]
  );

  const isAuditee = useCallback(() => {
    return userState?.user_is_in_auditees;
  }, [userState]);

  const isAuditor = useCallback(() => {
    return userState?.user_is_in_auditors;
  }, [userState]);

  const isResponsiblePerson = useCallback(() => {
    return !!userState?.user_is_in_responsible_persons;
  }, [userState]);

  const isApprover = useCallback(() => {
    return !!userState?.user_is_in_approvers;
  }, [userState]);

  return {
    canDo,
    hasModule,
    isAuditee,
    isAuditor,
    isResponsiblePerson,
    isApprover
  };
};
