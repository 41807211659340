export const PAGE_TRG_SETTINGS = "/trg-settings";
export const PAGE_TRG_EXTERNAL_PROVIDERS = "/trg/external-providers";
export const PAGE_TRG_TRAININGS = "/trg/trainings";
export const PAGE_TRG_TRAINING_MATRIX = "/trg/training-matrix";
export const PAGE_TRG_TRAINING_PROGRAM = "/trg/training-program";
export const PAGE_TRG_REPORTING = "/trg/reporting";
export const PAGE_TRG_PLANNING = "/trg/planning";
export const PAGE_TRG_PLANNING_CALENDAR = "/trg/planning/calendar";
export const PAGE_TRG_VIEW_PLANNING = "/trg/planning/view";
export const PAGE_TRG_NEW_PLANNING = "/trg/planning/new";
export const PAGE_TRG_EDIT_PLANNING = "/trg/planning/edit";
export const PAGE_TRG_QUALIFICATIONS = "/trg/qualifications";
export const PAGE_TRG_MASS_ASSIGNMENT = "/trg/mass-assignment";
export const PAGE_TRG_DASHBOARD = "/trg/dashboard";
