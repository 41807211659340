import React from "react";
import { useLoggedUser } from "../../utils/hooks/useLoggedUser";
import { getCompanyLogo } from "./methods";

const Logo = () => {
  const loggedUser = useLoggedUser();
  const { logo, smallLogo } = getCompanyLogo(loggedUser);

  return (
    <>
      <img src={logo} alt="logo" className="big-logo" />
      <img src={smallLogo} alt="logo" className="small-logo" />
    </>
  );
};

export default Logo;
