import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useModalData } from "../../../components/Modal/useModalData";
import Form, { FORM_HORIZONTAL_LAYOUT } from "../../../components/Form";
import useFormSubmit from "../../../components/Form/useFormSubmit";
import { closeModalAction } from "../../../components/Modal/reducer";
import Button from "../../../components/Button";
import { renderDynamicallyFields } from "../../../components/Form/methods";
import { FORM_ITEM_RESOURCE_SELECT_LAZY_LOADING } from "../../../components/Form/FormItems/FormItemResourceSelectLazyLoading";
import { reloadTable } from "../../../components/SmartTable/reducer";
import { openNotification } from "../../../components/Notification";
import { TABLE_TRG_TRAININGS } from "./methods";

export const MODAL_SHARE_TRAINING = "MODAL_SHARE_TRAINING";

const ShareTrainingForm = () => {
  const data = useModalData(MODAL_SHARE_TRAINING);
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { loading, submit, generalError } = useFormSubmit({
    form,
    route: "trg/shared-trainings",
    transformer: (values) => ({ shared_companies: values.shared_companies || [], training_id: data.id }),
    onSuccess: () => {
      dispatch(closeModalAction({ name: MODAL_SHARE_TRAINING }));
      dispatch(reloadTable({ name: TABLE_TRG_TRAININGS }));
    },
    onFailed: (err) => {
      openNotification({
        type: "error",
        message: t(
          err?.response?.status === 422 ? "qam.invalid_shared_companies" : "container.form_errors.internal_server_error"
        )
      });
    }
  });

  return (
    <Form
      onSubmit={submit}
      form={form}
      errors={generalError}
      loading={loading}
      initialValues={{
        shared_companies: data.shared_companies?.length > 0 ? data.shared_companies.map((item) => item.id) : undefined
      }}
      layout={FORM_HORIZONTAL_LAYOUT}
      header={
        <>
          <div className="h3">{t("trg.share_training")}</div>
          <div className="mb-30 mt-10 h6 font-weight-300 text-muted" style={{ whiteSpace: "pre-wrap" }}>
            {t("trg.share_training_description")}
          </div>
        </>
      }
    >
      {renderDynamicallyFields([
        {
          type: FORM_ITEM_RESOURCE_SELECT_LAZY_LOADING,
          name: "shared_companies",
          label: "qam.share_with",
          placeholder: "qam.select_companies",
          apiRoute: "get-linked-companies?order_by_column%5B%5D=name&order_by_type%5B%5D=asc",
          initialValue: data.shared_companies?.length > 0 ? data.shared_companies : undefined,
          fieldProps: {
            mode: "multiple",
            requestOnMount: true
          }
        }
      ])}
      <div className="flex mt-25 flex-justify-end">
        <Button
          className="min-w-150"
          type="primary"
          htmlType="submit"
          disabled={loading}
          loading={loading}
          icon={<i className="ti-check" />}
        >
          {t("container.general_words.save")}
        </Button>
      </div>
    </Form>
  );
};

export default ShareTrainingForm;
