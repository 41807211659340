import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import useFormSubmit from "../../../components/Form/useFormSubmit";
import Form, { FORM_HORIZONTAL_LAYOUT } from "../../../components/Form";
import { reloadTable } from "../../../components/SmartTable/reducer";
import { closeModalAction } from "../../../components/Modal/reducer";
import Button from "../../../components/Button";
import { renderDynamicallyFields } from "../../../components/Form/methods";
import { FORM_ITEM_TEXT } from "../../../components/Form/FormItems/FormItemText";
import { FORM_ITEM_DATE_PICKER } from "../../../components/Form/FormItems/FormItemDatePicker";
import { FORM_ITEM_RESOURCE_SELECT_LAZY_LOADING } from "../../../components/Form/FormItems/FormItemResourceSelectLazyLoading";
import { FORM_ITEM_TEXT_AREA } from "../../../components/Form/FormItems/FormItemTextArea";
import { formatMomentDateForAPI } from "../../../components/DatePicker";
import { TABLE_QAM_ACTIVITIES } from "./methods";
import { MODAL_QAM_ACTIVITY_FORM } from "./index";

const ActivityForm = ({ name }) => {
  const data = useSelector((state) => state?.modal[name || MODAL_QAM_ACTIVITY_FORM]?.data) || {};
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isNewRecord = !data.id;
  const { loading, submit, generalError } = useFormSubmit({
    route: isNewRecord ? "qam/audits" : `qam/audits/${data.id}`,
    method: isNewRecord ? "post" : "put",
    form,
    transformer: (values) => {
      return {
        ...values,
        date: formatMomentDateForAPI(values.date)
      };
    },
    onSuccess: () => {
      data.onSuccess && data.onSuccess();
      dispatch(reloadTable({ name: TABLE_QAM_ACTIVITIES, resetFilters: isNewRecord }));
      dispatch(closeModalAction({ name: name || MODAL_QAM_ACTIVITY_FORM }));
    }
  });

  return (
    <Form
      onSubmit={submit}
      form={form}
      errors={generalError}
      loading={loading}
      initialValues={{
        ...data,
        name: data.title,
        date: data.timeline_date
      }}
      layout={FORM_HORIZONTAL_LAYOUT}
      header={
        <>
          <div className="h3">{t(isNewRecord ? "qam.new_activity.form_title" : "qam.edit_activity.form_title")}</div>
          <div className="mb-30 mt-10 h6 font-weight-300 text-muted">{t("container.general_words.form_subtitle")}</div>
        </>
      }
    >
      {renderDynamicallyFields([
        {
          required: true,
          type: FORM_ITEM_TEXT,
          max: 200,
          name: "name",
          label: "container.general_words.title",
          placeholder: "container.general_words.title"
        },
        {
          type: FORM_ITEM_DATE_PICKER,
          name: "date",
          label: "qam.new_activity.date",
          placeholder: "qam.new_activity.date"
        },
        {
          required: true,
          type: FORM_ITEM_RESOURCE_SELECT_LAZY_LOADING,
          name: "entity_id",
          label: "qam.new_activity.entity",
          placeholder: "qam.new_activity.entity",
          fieldProps: {
            searchColumns: ["name"]
          },
          initialValue: data?.entity
            ? {
                id: data.entity_id,
                text: data.entity
              }
            : undefined,
          apiRoute:
            "qam/entities?field%5B%5D=active&op%5B%5D=eq&order_by_column%5B%5D=name&order_by_type%5B%5D=asc&term%5B%5D=1"
        },
        {
          type: FORM_ITEM_TEXT_AREA,
          name: "objective",
          label: "qam.new_activity.objective",
          placeholder: "qam.new_activity.objective",
          autoSize: { minRows: 2, maxRows: 6 }
        },
        {
          type: FORM_ITEM_TEXT_AREA,
          name: "internal_remarks",
          label: "qam.activity_program.internal_remarks",
          placeholder: "qam.activity_program.internal_remarks",
          autoSize: { minRows: 2, maxRows: 6 }
        }
      ])}
      <div className="flex mt-25 flex-justify-end">
        <Button
          className="min-w-150"
          type="primary"
          htmlType="submit"
          disabled={loading}
          loading={loading}
          icon={<i className="ti-check" />}
        >
          {t("container.general_words.save")}
        </Button>
      </div>
    </Form>
  );
};

export default ActivityForm;
