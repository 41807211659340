import React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useModalData } from "../../../../components/Modal/useModalData";
import Form, { FORM_HORIZONTAL_LAYOUT } from "../../../../components/Form";
import useFormSubmit from "../../../../components/Form/useFormSubmit";
import { closeModalAction } from "../../../../components/Modal/reducer";
import Button from "../../../../components/Button";
import { FORM_ITEM_TEXT } from "../../../../components/Form/FormItems/FormItemText";
import { renderDynamicallyFields } from "../../../../components/Form/methods";
import { FORM_ITEM_CHECKBOX } from "../../../../components/Form/FormItems/FormItemCheckbox";
import { updateTemplatesState } from "../reducer";
import Tooltip from "../../../../components/Tooltip";
import { PAGE_DOM_SETTINGS } from "../../routes";
import { usePermission } from "../../../../components/_auth/permissions/usePermission";
import { PERMISSION_MANAGE_DOM_HEADERS_FOOTERS_COVERS } from "../../../../components/_auth/permissions/permissions";
import { FORM_ITEM_RESOURCE_SELECT_LAZY_LOADING } from "../../../../components/Form/FormItems/FormItemResourceSelectLazyLoading";

export const MODAL_TEMPLATE_FORM = "MODAL_TEMPLATE_FORM";

const TemplateForm = ({ modalName }) => {
  const permissions = usePermission();
  const canManageHeadersFooters = permissions.canDo(PERMISSION_MANAGE_DOM_HEADERS_FOOTERS_COVERS);
  const data = useModalData(modalName || MODAL_TEMPLATE_FORM);
  const isEdit = !!data?.historyId;
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { loading, submit, generalError } = useFormSubmit({
    form,
    route: isEdit ? `dom/template-histories/${data.historyId}` : "dom/templates",
    method: isEdit ? "put" : "post",
    onSuccess: () => {
      if (data?.onSuccess) {
        data.onSuccess();
      } else {
        dispatch(updateTemplatesState({ reload: true, keepFilters: isEdit }));
        dispatch(closeModalAction({ name: MODAL_TEMPLATE_FORM }));
      }
    }
  });

  return (
    <Form
      onSubmit={submit}
      form={form}
      errors={generalError}
      loading={loading}
      initialValues={{
        /**
         *!! The header input name is "header_id" (it works for new template), so when the edit template is open
         * the current value is not displayed because it comes through validVersionHistory: {header_id: value}
         * When isEdit I need to pass to the header_id the value that comes through validVersionHistory{}
         */
        ...(isEdit
          ? {
              ...data,
              header_id: data?.validVersionHistory?.header_id,
              footer_id: data?.validVersionHistory?.footer_id,
              cover_id: data?.validVersionHistory?.cover_id
            }
          : { ...data }),
        active: isEdit ? data?.active : true
      }}
      layout={FORM_HORIZONTAL_LAYOUT}
      header={
        <>
          <div className="h3">{t(isEdit ? "dom.templates.edit_template" : "dom.templates.new_template")}</div>
          <div className="mb-30 mt-10 h6 font-weight-300 text-muted">{t("container.general_words.form_subtitle")}</div>
        </>
      }
    >
      {renderDynamicallyFields([
        {
          required: true,
          type: FORM_ITEM_TEXT,
          name: "template_title",
          label: "qam.new_section.title",
          placeholder: "qam.new_section.title"
        },
        {
          required: true,
          type: FORM_ITEM_TEXT,
          name: "template_version",
          label: "container.general_words.version",
          placeholder: "container.general_words.version"
        },
        {
          type: FORM_ITEM_RESOURCE_SELECT_LAZY_LOADING,
          name: "cover_id",
          label: "dom.settings.cover",
          placeholder: "dom.settings.cover",
          initialValue: data?.validVersionHistory?.cover
            ? {
                id: data.validVersionHistory.cover.id,
                text: data.validVersionHistory.cover.name
              }
            : undefined,
          apiRoute: "dom/covers",
          fieldProps: {
            customParams: {
              order_by_column: ["name"],
              order_by_type: ["asc"],
              field: ["active"],
              op: ["eq"],
              term: [1]
            }
          }
        },
        {
          className: "hide-required-mark",
          required: true,
          type: FORM_ITEM_RESOURCE_SELECT_LAZY_LOADING,
          name: "header_id",
          placeholder: "qam.catalogues.header",
          initialValue: data?.validVersionHistory?.header
            ? {
                id: data.validVersionHistory.header.id,
                text: data.validVersionHistory.header.name
              }
            : undefined,
          apiRoute: "dom/headers",
          label: (
            <div>
              {t("qam.catalogues.header")} *
              {canManageHeadersFooters && (
                <Tooltip title={t("dom.settings.new_header")} className="ml-10 text-primary">
                  <NavLink
                    to={{
                      pathname: PAGE_DOM_SETTINGS,
                      tab: t("dom.settings.headers")
                    }}
                    onClick={() => dispatch(closeModalAction({ name: MODAL_TEMPLATE_FORM }))}
                  >
                    <i className="ti-plus" />
                  </NavLink>
                </Tooltip>
              )}
            </div>
          ),
          fieldProps: {
            overwritePreviousOptions: true,
            searchIn: ["name"],
            requestOnMount: true,
            customParams: {
              order_by_column: ["name"],
              order_by_type: ["asc"],
              field: ["active"],
              op: ["eq"],
              term: [1]
            },
            onGetOptions: (options) => {
              if (!form.getFieldValue("header_id")) {
                const header = options.find((header) => header.defaultCurrentId) || {};
                form.setFieldsValue({ header_id: header?.defaultCurrentId });
              }
              return options;
            }
          }
        },
        {
          className: "hide-required-mark",
          required: true,
          type: FORM_ITEM_RESOURCE_SELECT_LAZY_LOADING,
          name: "footer_id",
          placeholder: "dom.settings.footer",
          initialValue: data?.validVersionHistory?.footer
            ? {
                id: data.validVersionHistory.footer.id,
                text: data.validVersionHistory.footer.name
              }
            : undefined,
          apiRoute: "dom/footers",
          label: (
            <div>
              {t("dom.settings.footer")} *
              {canManageHeadersFooters && (
                <Tooltip title={t("dom.settings.new_footer")} className="ml-10 text-primary">
                  <NavLink
                    to={{
                      pathname: PAGE_DOM_SETTINGS,
                      tab: t("dom.settings.footers")
                    }}
                    onClick={() => dispatch(closeModalAction({ name: MODAL_TEMPLATE_FORM }))}
                  >
                    <i className="ti-plus" />
                  </NavLink>
                </Tooltip>
              )}
            </div>
          ),
          fieldProps: {
            overwritePreviousOptions: true,
            searchIn: ["name"],
            requestOnMount: true,
            customParams: {
              order_by_column: ["name"],
              order_by_type: ["asc"],
              field: ["active"],
              op: ["eq"],
              term: [1]
            },
            onGetOptions: (options) => {
              if (!form.getFieldValue("footer_id")) {
                const footer = options.find((footer) => footer.defaultCurrentId) || {};
                form.setFieldsValue({ footer_id: footer?.defaultCurrentId });
              }
              return options;
            }
          }
        },
        {
          type: FORM_ITEM_CHECKBOX,
          visible: !isEdit,
          name: "active",
          label: "container.general_words.active"
        }
      ])}
      <div className="flex mt-25 flex-justify-end">
        <Button
          className="min-w-150"
          type="primary"
          htmlType="submit"
          disabled={loading}
          loading={loading}
          icon={<i className="ti-check" />}
        >
          {t("container.general_words.save")}
        </Button>
      </div>
    </Form>
  );
};

export default TemplateForm;
