import React from "react";
import TableCell from "../../../components/SmartTable/TableCell";
import { PERMISSION_VIEW_CORE_NOTIFICATIONS } from "../../../components/_auth/permissions/permissions";
import DeleteItemButton from "../../../components/SmartTable/TableFiltersActions/DeleteItemButton";
import NotificationsMessageColumn from "./NotificationsMessageColumn";
import MarkAsSeen from "./MarkAsSeen";

export const TABLE_NOTIFICATIONS_NAME = "table-notifications";

export const generateColumns = (permission, trans, updateNotificationsState) => {
  return [
    {
      title: "container.notifications_page.message",
      dataIndex: "message",
      render: (text, row) => (
        <TableCell>
          <NotificationsMessageColumn data={row} />
        </TableCell>
      )
    },
    ...(permission.canDo(PERMISSION_VIEW_CORE_NOTIFICATIONS)
      ? [
          {
            title: trans("container.notifications_page.generation_date"),
            fixed: "right",
            width: 150,
            render: (row = {}) => <div className="table-actions-column">{row.generation_date}</div>
          },
          {
            title: trans("container.general_words.actions"),
            fixed: "right",
            width: 100,
            render: (row = {}) => (
              <div className="table-actions-column">
                <MarkAsSeen notification={row} onSuccess={updateNotificationsState} />
                <DeleteItemButton
                  onDelete={updateNotificationsState}
                  tableName={TABLE_NOTIFICATIONS_NAME}
                  route={`notifications/${row.id}`}
                  content={
                    <div>
                      <div>{trans("container.confirm_modal.form_subtitle")}</div>
                    </div>
                  }
                />
              </div>
            )
          }
        ]
      : [])
  ];
};
