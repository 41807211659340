import { Menu } from "antd";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import isArray from "lodash/isArray";
import isEqual from "lodash/isEqual";
import { useEffect, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { usePermission } from "../_auth/permissions/usePermission";
import { composeNavigationMenuItems, generateNavigationMenuItems, getSelectedMenuKey } from "./methods";

const NavigationMenu = ({ collapsed }) => {
  const permission = usePermission();
  const options = composeNavigationMenuItems(permission);
  const { t } = useTranslation();
  const location = useLocation();
  const initialSelectedKeys = getSelectedMenuKey(location, options);
  const [openKeys, setOpenKeys] = useState(initialSelectedKeys);
  const [selectedKey, setSelectedKey] = useState(initialSelectedKeys);

  const onOpenChange = (e) => {
    setOpenKeys(e);
  };

  const updateOpenKeys = (e) => {
    setSelectedKey(e.key);

    if (e.keyPath.length === 1) {
      setOpenKeys(e.keyPath);
    }
  };

  useEffect(() => {
    if (!isEqual(selectedKey, initialSelectedKeys)) {
      setSelectedKey(initialSelectedKeys);
      setOpenKeys(initialSelectedKeys);
    }
  }, [initialSelectedKeys, selectedKey, openKeys]);

  return (
    <Scrollbars
      autoHide
      autoHideTimeout={1000}
      hideTracksWhenNotNeeded={true}
      renderTrackVertical={(props) => <div className="scrollbar-track-vertical" {...props} />}
      className="navigation-scroll-container"
    >
      <Menu
        className="layout-sidebar-navigation"
        theme="dark"
        mode="inline"
        selectedKeys={selectedKey}
        openKeys={!collapsed && isArray(openKeys) ? openKeys : undefined}
        onOpenChange={onOpenChange}
        onClick={updateOpenKeys}
        items={generateNavigationMenuItems(options, permission, t)}
      />
    </Scrollbars>
  );
};

export default NavigationMenu;
