import { Redirect, useHistory, useParams } from "react-router-dom";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import PageMetaTagTitle from "../../../components/PageMetaTagTitle";
import { useAxiosRequest } from "../../../utils/hooks/useAxios";
import Preloader from "../../../components/Preloader";
import { PAGE_NOT_FOUND } from "../../Core/routes";
import { usePermission } from "../../../components/_auth/permissions/usePermission";
import { PERMISSION_MANAGE_IOR_REPORTS } from "../../../components/_auth/permissions/permissions";
import { useLoggedUser } from "../../../utils/hooks/useLoggedUser";
import { cleanUpData } from "./reducer";
import { updateReportData } from "./reducer";
import { Report } from "./ReportForm/ReportForm";

export const REPORT_FORM_TYPE = {
  VIEW: "view",
  EDIT: "edit",
  NEW: "new"
};

const ReportPage = ({ type }) => {
  const params = useParams();
  const dispatch = useDispatch();
  const permission = usePermission();
  const noAccess = !permission.canDo(PERMISSION_MANAGE_IOR_REPORTS);
  const loggedUser = useLoggedUser();
  const history = useHistory();

  const reportState = useAxiosRequest({
    route: `ior/report/${params.id}`,
    disabled: !params.id,
    params: {
      "with": [
        "reporter",
        "basicReportFiles",
        "carrier",
        "category",
        "issue",
        "liability",
        "rootCause",
        "responsible",
        "type",
        "tags",
        "riskAnalysis.files",
        "riskAnalysis.firstAnalysisResponsible",
        "riskAnalysis.riskLevel",
        "lead",
        "coAuditors"
      ]
    },
    onSuccess: (res) => {
      if (loggedUser.id !== res.data.data.basic_data.reporter_id && noAccess) {
        history.push(PAGE_NOT_FOUND);
      }
      dispatch(updateReportData({ ...res.data.data, rewrite: true }));
    }
  });

  useEffect(() => {
    return () => {
      dispatch(cleanUpData());
    };
  }, [dispatch]);
  const generalSettings = useAxiosRequest({
    route: "ior/general-settings"
  });

  if (reportState.error) {
    return <Redirect to={PAGE_NOT_FOUND} />;
  }

  if (generalSettings.error) {
    return <Redirect to={PAGE_NOT_FOUND} />;
  }

  const loading = reportState.loading && generalSettings.loading;

  const reportStateData = reportState?.data?.data?.data;

  return (
    <>
      <PageMetaTagTitle title={`IOR ${type} report`} />
      <div className="box min-h-100">
        {loading && <Preloader className="text-center mt-50" size={30} />}
        {!loading && (reportStateData || !params.id) && (
          <Report
            generalSettings={generalSettings?.data?.data?.data || {}}
            data={reportStateData}
            type={type}
            editOnlyBasicSection={
              (loggedUser.id === reportStateData?.basic_data?.reporter_id && noAccess) ||
              (noAccess && type === REPORT_FORM_TYPE.NEW)
            }
          />
        )}
      </div>
    </>
  );
};

export default ReportPage;
