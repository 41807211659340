import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import React from "react";
import Form, { FORM_HORIZONTAL_LAYOUT } from "../../../../../components/Form";
import useFormSubmit from "../../../../../components/Form/useFormSubmit";
import { reloadTable } from "../../../../../components/SmartTable/reducer";
import { closeModalAction } from "../../../../../components/Modal/reducer";
import Button from "../../../../../components/Button";
import { renderDynamicallyFields } from "../../../../../components/Form/methods";
import { FORM_ITEM_TEXT_AREA } from "../../../../../components/Form/FormItems/FormItemTextArea";
import { TABLE_IOR_INTERNAL_NOTES } from "./methods";

export const MODAL_IOR_INTERNAL_NOTE = "MODAL_IOR_INTERNAL_NOTE";

export const InternalNoteForm = ({ basicDataSaved }) => {
  const data = useSelector((state) => {
    return state?.modal[MODAL_IOR_INTERNAL_NOTE]?.data || {};
  });
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { loading, submit, generalError } = useFormSubmit({
    route: "ior/internal-notes",
    method: "post",
    form,
    transformer: (values) => {
      return {
        ...values,
        report_id: data.report_id ? data.report_id : basicDataSaved.basic_data?.report_id
      };
    },
    onSuccess: (res) => {
      data.onSuccess && data.onSuccess(res);
      dispatch(reloadTable({ name: TABLE_IOR_INTERNAL_NOTES }));
      dispatch(closeModalAction({ name: MODAL_IOR_INTERNAL_NOTE }));
    }
  });

  return (
    <Form
      onSubmit={submit}
      form={form}
      errors={generalError}
      loading={loading}
      initialValues={data}
      layout={FORM_HORIZONTAL_LAYOUT}
      header={
        <>
          <div className="h3">{t("ior.internal_notes.new_internal_note")}</div>
          <div className="mb-30 mt-10 h6 font-weight-300 text-muted">{t("container.general_words.form_subtitle")}</div>
        </>
      }
    >
      {renderDynamicallyFields([
        {
          required: true,
          type: FORM_ITEM_TEXT_AREA,
          max: 500,
          name: "note",
          label: "ior.internal_notes.note",
          placeholder: "ior.internal_notes.note"
        }
      ])}
      <div className="flex mt-25 flex-justify-end">
        <Button
          className="min-w-150"
          type="primary"
          htmlType="submit"
          disabled={loading}
          loading={loading}
          icon={<i className="ti-check" />}
        >
          {t("container.general_words.save")}
        </Button>
      </div>
    </Form>
  );
};
