import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { openModalAction } from "../../../components/Modal/reducer";
import Tooltip from "../../../components/Tooltip";
import { MODAL_TRG_PLANNING_SELECT_EXPORT } from "./PlanningSelectExport";

const DownloadPlanning = ({ row, className }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <Tooltip title={t("trg.planning.export_excel")}>
      <a
        href="/"
        className={className}
        onClick={(e) => {
          e.preventDefault();
          dispatch(openModalAction({ name: MODAL_TRG_PLANNING_SELECT_EXPORT, data: row }));
        }}
      >
        <i className="ti-download" />
      </a>
    </Tooltip>
  );
};

export default DownloadPlanning;
