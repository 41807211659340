import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import Tooltip from "../Tooltip";
import { downloadFile } from "../../utils/downloadFile";
import { openGeneralErrorNotification } from "../Notification";
import { closeModalAction, openModalAction } from "../Modal/reducer";
import { MODAL_EXPORT_LOADING } from "../SmartTable/ExportDropdown/LoadingModal";

const DownloadFile = ({ title, file }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const download = (e) => {
    e.preventDefault();
    if (!file) {
      return;
    }

    downloadFile({
      fileUrl: file.fullPath,
      fileName: file.name,
      onStart: () => dispatch(openModalAction({ name: MODAL_EXPORT_LOADING })),
      onSuccess: () => dispatch(closeModalAction({ name: MODAL_EXPORT_LOADING })),
      onFailed: (error) => {
        dispatch(closeModalAction({ name: MODAL_EXPORT_LOADING }));
        openGeneralErrorNotification({ error, defaultError: t("container.form_errors.internal_server_error") });
      }
    });
  };

  return (
    <>
      <Tooltip title={title}>
        <a href="/" disabled={!file} onClick={download}>
          <i className="ti-download" />
        </a>
      </Tooltip>
    </>
  );
};

export default DownloadFile;
