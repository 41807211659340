import React from "react";
import { NavLink } from "react-router-dom";
import orderBy from "lodash/orderBy";
import classNames from "classnames";
import TableCell from "../../../components/SmartTable/TableCell";
import DeleteItemButton from "../../../components/SmartTable/TableFiltersActions/DeleteItemButton";
import { FILTER_TYPE_SELECT_MODE_1, FILTER_TYPE_SELECT_MODE_2 } from "../../../components/SmartTable/methods";
import { formatString } from "../../../utils/common";
import { openModalAction } from "../../../components/Modal/reducer";
import { EditButton } from "../../../components/EditButton";
import Tooltip from "../../../components/Tooltip";
import moment from "../../../moment";
import { PAGE_VIEW_USER } from "../../Core/routes";
import EmployeeCell from "./EmployeeCell";
import { MODAL_QUALIFICATION } from "./index";

export const TABLE_QUALIFICATIONS = "TABLE_QUALIFICATIONS";

export const generateColumns = (trans, dispatch) => {
  return [
    {
      title: "trg.qualifications.employee",
      dataIndex: "id",
      filterType: FILTER_TYPE_SELECT_MODE_2,
      filterProps: {
        apiRoute: "users/all",
        searchColumns: ["name"],
        customParams: {
          order_by_column: ["last_name", "first_name"],
          order_by_type: ["asc", "asc"],
          field: ["user_is_in_organisation"],
          op: ["eq"],
          term: [1]
        },
        transformer: (employees) =>
          employees.map((item) => ({ ...item, text: `${item.name} (${item.userDetail.staff_number})` }))
      },
      onCell: (row) => {
        return { rowSpan: row?.qualification?.rowSpan || 0 };
      },
      render: (text, row) => (
        <TableCell minWidth={200}>
          <EmployeeCell row={row} />
        </TableCell>
      )
    },
    {
      title: "trg.qualifications.training",
      dataIndex: "trainingId",
      filterType: FILTER_TYPE_SELECT_MODE_1,
      filterNameKey: "trainingId",
      filterProps: {
        apiRoute: "trg/trainings?order_by_column",
        searchColumns: ["name", "code"],
        customParams: {
          order_by_column: ["name"],
          order_by_type: ["asc"],
          field: ["active"],
          op: ["eq"],
          term: [1]
        },
        transformer: (res = []) => {
          return res?.map((item) => ({ ...item, text: `${item.name} (${item.code})` })) || [];
        }
      },
      render: (text, row) => (
        <TableCell minWidth={200}>
          {formatString(
            row.qualification.training
              ? `${row.qualification.training.name} (${row.qualification.training.code})`
              : null,
            "-"
          )}
        </TableCell>
      )
    },
    {
      title: "trg.qualifications.expiry_date",
      width: 200,
      render: (row) => {
        const plannedText = trans("trg.reporting.planned");
        const fromPreviousFunctionText = trans("trg.qualifications.qualification_status_filter.from_previous_function");
        return (
          <TableCell minWidth={150}>
            <span
              className={classNames({
                "training-not-done-yet": row.qualification.notDoneYet,
                "date-in-past": row.qualification.date_in_past
              })}
            >
              {row.qualification.expiry_date}
            </span>
            {row.qualification.isPlanned && (
              <Tooltip title={plannedText}>
                <span className="planned-circle">{plannedText.split("")[0]}</span>
              </Tooltip>
            )}
            {row.qualification.from_previous_function && (
              <Tooltip title={fromPreviousFunctionText}>
                <span className="planned-circle">Q</span>
              </Tooltip>
            )}
            {!row.qualification.expiry_notification &&
              !row.qualification.notDoneYet &&
              !row.qualification.training.validity.no_expiry && (
                <Tooltip title={trans("trg.disabled_notification")}>
                  <span className="ml-5">(*)</span>
                </Tooltip>
              )}
          </TableCell>
        );
      }
    },
    {
      title: trans("container.general_words.actions"),
      fixed: "right",
      width: 100,
      render: (row = {}) => (
        <div className="table-actions-column">
          {!row.qualification.notDoneYet && (
            <>
              {row.userDetail && (
                <Tooltip title={trans("trg.qualifications.view_history")}>
                  <NavLink
                    to={{
                      pathname: `${PAGE_VIEW_USER}/${row.userDetail.user_id}`,
                      training: {
                        name: row.qualification.training.name,
                        id: row.qualification.training.id,
                        code: row.qualification.training.code,
                        qualificationId: row.qualification.id
                      }
                    }}
                    className="text-primary"
                  >
                    <i className="ti-eye" />
                  </NavLink>
                </Tooltip>
              )}
              <EditButton
                onClick={() => {
                  dispatch(openModalAction({ name: MODAL_QUALIFICATION, data: row }));
                }}
              />
              <DeleteItemButton
                tableName={TABLE_QUALIFICATIONS}
                route={`trg/qualifications/${row.qualification.id}`}
                content={
                  <div>
                    <div>{trans("container.confirm_modal.form_subtitle")}</div>
                  </div>
                }
              />
            </>
          )}
        </div>
      )
    }
  ];
};

const dateInPast = (firstDate, secondDate) => {
  return firstDate.setHours(0, 0, 0, 0) <= secondDate.setHours(0, 0, 0, 0);
};

const transformerForExpiryDate = (expiryDate, trans, format) => {
  if (expiryDate) {
    return format({ date: expiryDate });
  }
  return trans("trg.validity_field.no_expiry");
};

const isUserPlannedForTraining = (row, training) => {
  const uncompletedCourses = training.uncompletedCourses;
  const participants =
    uncompletedCourses.length > 0
      ? uncompletedCourses.flatMap((item) => item.participants).map((item) => item.id)
      : null;

  if (participants) {
    const isUserInParticipants = participants.filter((item) => item === row.id);
    return !!isUserInParticipants.length;
  }
  return false;
};

const isUserPlannedFor = (row, qualification) => {
  if (row.qualifications.length) {
    const courses = row.qualifications
      .flatMap((item) => {
        if (item.id) {
          return item.training.uncompletedCourses;
        }
        return null;
      })
      .filter((item) => item !== null);

    const isInUncompletedCourses = courses.filter((item) => item.training_id === qualification.training_id);

    const participants =
      isInUncompletedCourses.length > 0
        ? isInUncompletedCourses.flatMap((item) => item.participants).map((item) => item.id)
        : [];

    const isUserInParticipants = participants.filter((item) => item === row.id);

    return !!isUserInParticipants.length && !!isInUncompletedCourses.length;
  }

  return false;
};

const isPlanned = (expiryDate, qualification, row) => {
  if (isUserPlannedFor(row, qualification) && qualification.id) {
    const diff = moment(expiryDate).diff(moment(), "milliseconds");
    const notificationPeriodInMs = moment.duration(qualification.training.notification_period, "day").asMilliseconds();

    const isExpired = diff <= 0;
    const isExpiringSoon = diff > 0 && diff <= notificationPeriodInMs;

    return isExpiringSoon || isExpired;
  }
  return false;
};

const formatDateForReportingAndQualification = (row, format) => {
  const result = {};
  if (row.userDetail.contract_started) {
    result.contractStartedFormatted = format({ date: row.userDetail.contract_started });
  }

  if (row.userDetail.contract_ended) {
    result.contractEndedFormatted = format({ date: row.userDetail.contract_ended });
  }

  return result;
};

const mapUserFunctions = (functions = [], doneTrainings = [], firstCompletedDates = [], trans) => {
  return functions
    .filter((func) => !!(func.staffFunctions && func.staffFunctions.trainingPrograms))
    .flatMap((func) => {
      func.missingTrainingsPerFunction = func.staffFunctions.trainingPrograms.activeJobTitleTrainings
        .filter((item) => doneTrainings.indexOf(item.training_id) === -1)
        .sort((a, b) => b.required - a.required)
        .map((item, index, arr) => {
          if (item.training.linked_training_id) {
            const linkedTraining = arr.filter((training) => training.training_id === item.training.linked_training_id);

            const linkedTrainingIsDoneIndex = doneTrainings.findIndex(
              (element) => element === item.training.linked_training_id
            );

            if (linkedTrainingIsDoneIndex !== -1) {
              const firstCompletedDate = moment(firstCompletedDates[linkedTrainingIsDoneIndex])
                .add(item.training.linked_training_notification, "day")
                .format();

              return moment().diff(firstCompletedDate, "milliseconds") >= 0 ? item : null;
            } else {
              if (linkedTraining.length && linkedTraining[0].required) {
                return null;
              }

              return item.required ? item : null;
            }
          }

          if (item.required) {
            return item;
          }

          return null;
        })
        .filter((item) => item !== null)
        .map((item) => {
          item.expiry_date = trans("trg.qualifications.training_not_done");
          item.isPending = true;
          item.notDoneYet = true;
          return item;
        });

      return func.missingTrainingsPerFunction;
    });
};

export const prepareQualificationsData = (rows, trans, format, filters) => {
  let result = rows
    .map((row) => {
      row.missingTrainings = [];
      row.functions = [];
      row.qualifications = row.qualifications.map((qualification) => {
        const expiryDate = qualification.expiry_date;

        if (expiryDate) {
          qualification.date_in_past = dateInPast(new Date(expiryDate), new Date());
        }

        qualification.expiry_date = transformerForExpiryDate(qualification.expiry_date, trans, format);
        qualification.isPlanned = isPlanned(expiryDate, qualification, row);

        return qualification;
      });

      if (row.userDetail) {
        row = { ...row, ...formatDateForReportingAndQualification(row, format) };
      }

      const doneTrainings = row.qualifications.map((item) => item.training.id);
      const firstCompletedDates = row.qualifications.map((item) => item.first_completed_date);

      if (row.activeStaffJobFunctions) {
        row.functions = row.activeStaffJobFunctions.map((item) => {
          item.name = item.staffFunctions.name;
          return item;
        });

        if (row.functions && row.functions.length) {
          row.missingTrainings = mapUserFunctions(row.functions, doneTrainings, firstCompletedDates, trans);

          /**
           * Order missing trainings by training name (alphabetically)
           */
          if (row.missingTrainings.length > 0) {
            row.missingTrainings.map((item) => (item.isPlanned = isUserPlannedForTraining(row, item.training)));
            row.missingTrainings = [...orderBy(row.missingTrainings, ["training.name"], ["asc"])];
          }
        }
      }

      // eslint-disable-next-line no-prototype-builtins
      if (!filters.hasOwnProperty("filters[qualificationStatus]")) {
        row.qualifications = [...row.qualifications, ...row.missingTrainings].filter(
          (item, index, arr) => index === arr.findIndex((i) => i.training_id === item.training_id)
        );
      }

      if (filters["filters[qualificationStatus]"] === "pending") {
        row.qualifications = [...row.qualifications, ...row.missingTrainings]
          .filter((item, index, arr) => index === arr.findIndex((i) => i.training_id === item.training_id))
          .filter((item) => {
            return item.isPending;
          });
      }

      if (filters["filters[qualificationStatus]"] === "from_previous_function") {
        row.qualifications = [...row.qualifications, ...row.missingTrainings]
          .filter((item, index, arr) => index === arr.findIndex((i) => i.training_id === item.training_id))
          .filter((item) => {
            return item.from_previous_function;
          });
      }

      if (filters["filters[qualificationStatus]"] === "all_without_previous_function") {
        row.qualifications = [...row.qualifications, ...row.missingTrainings]
          .filter((item, index, arr) => index === arr.findIndex((i) => i.training_id === item.training_id))
          .filter((item) => {
            return !item.from_previous_function;
          });
      }

      // eslint-disable-next-line no-prototype-builtins
      if (filters.hasOwnProperty("filters[trainingId]")) {
        row.qualifications = row.qualifications.filter((item) => item.training_id === filters["filters[trainingId]"]);
      }

      if (row.missingTrainings.length === 0 && row.qualifications.length === 0) {
        return null;
      }

      /**
       * Change order for the qualification from previous function
       */
      row.qualificationsPreviousFunction = row.qualifications.filter((item) => {
        return item.from_previous_function;
      });
      row.qualifications = row.qualifications.filter((item) => {
        return !item.from_previous_function;
      });
      row.qualifications = [...row.qualifications, ...row.qualificationsPreviousFunction];

      return row;
    })
    .filter((item) => item !== null);

  result = result.reduce((acc, item) => {
    item.qualifications.forEach((qualificationItem, idx) => {
      acc.push({
        ...item,
        id: qualificationItem.id,
        qualification: { ...qualificationItem, ...(idx === 0 ? { rowSpan: item.qualifications?.length || 1 } : {}) }
      });
    });
    return acc;
  }, []);

  return result;
};
