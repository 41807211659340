import React from "react";
import { Collapse as AntdCollapse } from "antd";

const Collapse = (props) => {
  return <AntdCollapse {...props} />;
};

Collapse.Panel = AntdCollapse.Panel;

export default Collapse;
