import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import isBoolean from "lodash/isBoolean";
import moment from "../../../../../moment";
import config from "../../../../../config";
import { usePrevious } from "../../../../../utils/hooks/usePrevious";
import { INVESTIGATOR_AUDITOR, INVESTIGATOR_CO_AUDITOR } from "./config";

const SubText = ({ company }) => {
  const { t } = useTranslation();
  return `${t("container.general_words.company")}: ${company || "-"}`;
};

export const checkActiveAuditorPeriod = (startDate, endDate) => {
  const start = moment(startDate, config.defaultDateLongFormat);
  if (endDate) {
    const end = moment(endDate, config.defaultDateLongFormat);
    return moment().isBetween(start, end, "days", "[]");
  }
  return moment().isBetween(start, moment().format(config.defaultDateLongFormat), "days", "[]");
};

export const prepareUserProperties = (user) => {
  let company = user.auditor ? user.auditor.company || "-" : user.company ? user.company.name : "-";
  let auditeeCompany = user?.auditeeCompany?.name;

  return {
    ...user,
    company,
    value: user.id,
    text: user.name,
    auditee_company: auditeeCompany,
    subText: <SubText company={company} />,
    subTextAuditee: <SubText company={auditeeCompany} />
  };
};

export const prepareInvestigatorData = (investigator) => {
  let desc = investigator.company_id;
  let systemAccess = !!investigator.auditor || !!investigator.user_id;

  if (investigator.type === INVESTIGATOR_CO_AUDITOR || investigator.type === INVESTIGATOR_AUDITOR) {
    desc = investigator.company_id;
  }

  return { ...investigator, desc, systemAccess };
};

export const getAvailableInvestigators = (users, report) => {
  const { lead = {}, coAuditors = [] } = report;
  const excludedIds = [lead?.id, ...(coAuditors || []).map((item) => item.id)];
  return (
    users?.filter((user) => {
      return !excludedIds.includes(user.id);
    }) || []
  );
};

export const useClearInvestigatorSelect = (value, callback) => {
  const activity = {};
  const previousShouldResetinvestigatorSelect = usePrevious(activity.shouldResetinvestigatorSelect);

  useEffect(() => {
    if (
      value &&
      isBoolean(activity.shouldResetinvestigatorSelect) &&
      activity.shouldResetinvestigatorSelect !== previousShouldResetinvestigatorSelect
    ) {
      callback(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activity.shouldResetinvestigatorSelect]);
};
