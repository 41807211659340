import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { renderDynamicallyFields } from "../../../../../components/Form/methods";
import { FORM_ITEM_RESOURCE_SELECT_LAZY_LOADING } from "../../../../../components/Form/FormItems/FormItemResourceSelectLazyLoading";
import { FORM_ITEM_NUMBER } from "../../../../../components/Form/FormItems/FormItemNumber";
import { FORM_ITEM_TEXT } from "../../../../../components/Form/FormItems/FormItemText";
import { DocumentsTable } from "../ReportBasicData/DocumentsTable";
import Form from "../../../../../components/Form";
import { prepareFields, saveSectionData } from "../methods";
import { Input } from "../../../../../components/Input";
import { ReportCollapse } from "../ReportCollapse";
import { openModalAction } from "../../../../../components/Modal/reducer";
import { MODAL_IOR_CORRECTIVE_ACTION } from "../../../CorrectiveActions/AddCorrectiveActionForm";
import Button from "../../../../../components/Button";
import { useDateFormat } from "../../../../../utils/hooks/useDateFormat";
import { REPORT_ARCHIVED } from "../../../config";
import { CorrectiveActionFields } from "./CorrectiveActionFields";

const recurrenceOptions = (t) => [
  { value: true, text: t("container.ior_settings.general_tab.yes") },
  { value: false, text: t("container.ior_settings.general_tab.no") }
];

export const severityOptions = [
  { value: 5, text: "A" },
  { value: 4, text: "B" },
  { value: 3, text: "C" },
  { value: 2, text: "D" },
  { value: 1, text: "E" }
];

export const ReportFirstRiskAnalysis = ({ form, data, type, hasError, basicDataSaved }) => {
  const { t } = useTranslation();
  const [attachments, setAttachments] = useState(data.riskAnalysis?.files || []);
  const firstAnalysis = Form.useWatch("first_risk_analysis", form) || {};
  let hasData = Object.values(firstAnalysis)?.some((item) => item !== undefined && item !== null);
  const dispatch = useDispatch();
  const { formatLocaleUTC, format } = useDateFormat();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (attachments.length !== 0) {
      form.setFieldsValue({ first_files: attachments });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attachments]);

  const fields = [
    {
      type: FORM_ITEM_RESOURCE_SELECT_LAZY_LOADING,
      required: hasData,
      fieldProps: {
        staticOptions: recurrenceOptions(t)
      },
      name: ["first_risk_analysis", "is_recurrent"],
      label: "ior.new_report.recurrence.label",
      placeholder: "ior.new_report.recurrence.label"
    },
    {
      type: FORM_ITEM_RESOURCE_SELECT_LAZY_LOADING,
      required: hasData,
      fieldProps: {
        staticOptions: severityOptions,
        onChange: (value) => {
          const firstRiskAnalysis = form.getFieldValue("first_risk_analysis");
          const probability = firstRiskAnalysis.first_analysis_probability;
          probability &&
            firstRiskAnalysis &&
            form.setFieldsValue({
              first_risk_analysis: { ...firstRiskAnalysis, first_analysis_conclusion: probability * value }
            });
        }
      },
      name: ["first_risk_analysis", "first_analysis_severity"],
      label: "ior.new_report.severity",
      placeholder: "ior.new_report.severity"
    },
    {
      required: hasData,
      type: FORM_ITEM_NUMBER,
      name: ["first_risk_analysis", "first_analysis_probability"],
      label: "ior.new_report.probability",
      placeholder: "ior.new_report.probability",
      fieldProps: {
        min: 0,
        max: 999,
        onChange: (value) => {
          const firstRiskAnalysis = form.getFieldValue("first_risk_analysis");
          const severity = firstRiskAnalysis.first_analysis_severity;
          severity &&
            firstRiskAnalysis &&
            form.setFieldsValue({
              first_risk_analysis: { ...firstRiskAnalysis, first_analysis_conclusion: severity * value }
            });
        }
      }
    },
    {
      dependencies: ["first_risk_analysis", "first_analysis_probability"],
      type: FORM_ITEM_TEXT,
      fieldProps: {
        disabled: true
      },
      name: ["first_risk_analysis", "first_analysis_conclusion"],
      label: "ior.new_report.conclusion",
      placeholder: "ior.new_report.conclusion"
    },
    {
      customField: (
        <Form.Item className="d-none" name={["riskAnalysis", "riskLevel"]}>
          <Input />
        </Form.Item>
      ),
      name: "risk_level"
    },
    {
      customField: (
        <Form.Item className="d-none" name="first_files">
          <Input />
        </Form.Item>
      ),
      name: "first_files"
    },
    {
      type: FORM_ITEM_RESOURCE_SELECT_LAZY_LOADING,
      required: hasData,
      shouldUpdate: true,
      fieldProps: {
        requestOnMount: true,
        onChange: (value, obj) => {
          const riskAnalysis = form.getFieldsValue("riskAnalysis");
          form.setFieldsValue({ riskAnalysis: { ...riskAnalysis, riskLevel: obj } });
          if (obj.corrective_action_is_required) {
            dispatch(
              openModalAction({
                name: MODAL_IOR_CORRECTIVE_ACTION,
                data: {
                  report_id: basicDataSaved.basic_data?.id || undefined,
                  risk_level_id: obj.id,
                  risk_level_changed: true
                }
              })
            );
          }
        }
      },
      apiRoute:
        "ior/risk-levels?field%5B%5D=active&op%5B%5D=eq&order_by_column%5B%5D=name&order_by_type%5B%5D=asc&term%5B%5D=1",
      name: ["first_risk_analysis", "risk_level_id"],
      label: "ior.new_report.risk_level",
      placeholder: "ior.new_report.risk_level"
    },
    {
      customField: data?.basic_data?.stage === REPORT_ARCHIVED && <CorrectiveActionFields form={form} type={type} />,
      name: "corrective_action_fields"
    },
    {
      customField: <DocumentsTable attachments={attachments} setAttachments={setAttachments} type={type} />,
      name: "documents_basic_table"
    }
  ];

  const saveData = () => {
    const usedData = data?.basic_data?.id ? data : basicDataSaved;
    saveSectionData(
      form,
      usedData,
      setLoading,
      formatLocaleUTC,
      format,
      t("ior.new_report.notifications.1st_risk_analysis_saved"),
      t("ior.new_report.notifications.1st_risk_analysis_not_saved")
    );
  };

  return (
    <div className={classNames("activity-view-content mb-20", { error: hasError })}>
      <ReportCollapse
        collapsed={false}
        isSectionDisabled={!basicDataSaved?.basic_data}
        header={{
          title: t("ior.new_report.section_first_risk_analysis"),
          tooltip: !basicDataSaved?.basic_data?.id
            ? t("ior.new_report.disabled_feedback")
            : t("ior.new_report.section_first_risk_analysis_popover")
        }}
      >
        {renderDynamicallyFields(prepareFields(fields, type))}
        <div className="flex flex-justify-end mt-15">
          <Button
            className="min-w-110 ant-btn-sm"
            type="primary"
            icon={<i className="ti-check" />}
            onClick={saveData}
            disabled={loading || type === "view"}
            loading={loading}
          >
            {t("container.general_words.save")}
          </Button>
        </div>
      </ReportCollapse>
    </div>
  );
};
