import { Button, Space } from "antd";
import React from "react";
import isArray from "lodash/isArray";
import ResourceSelectLazyLoading from "../../ResourceSelectLazyLoading";
import i18n from "../../../i18n";
import { FILTER_TYPE_SELECT_MODE_1, FILTER_TYPE_SELECT_MODE_2, FILTER_TYPE_SELECT_MODE_3 } from "../methods";

export const getColumnResourceSelectFilterProps = ({ tableParams = {}, column = {} }) => {
  const { apiRoute, ...rest } = column?.filterProps || {};

  let filterColumn;
  if (column.filterType === FILTER_TYPE_SELECT_MODE_1 || column.filterType === FILTER_TYPE_SELECT_MODE_3) {
    filterColumn = tableParams.anotherFilters?.filter((item) => item.column === column.dataIndex)[0];
  }

  if (column.filterType === FILTER_TYPE_SELECT_MODE_2) {
    filterColumn = tableParams.filters?.filter((item) => item.column === column.dataIndex)[0];
  }

  return {
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div className="table-filter-dropdown select-filter-dropdown">
        <ResourceSelectLazyLoading
          size="normal"
          showArrow
          showSearch
          allowClear
          apiRoute={apiRoute}
          value={selectedKeys[0]?.value}
          initialValue={
            filterColumn
              ? {
                  id: isArray(filterColumn.value) ? filterColumn.value[0] : filterColumn.value,
                  text: filterColumn.text,
                  transKey: filterColumn.transKey
                }
              : null
          }
          onChange={(value, objValue) => {
            setSelectedKeys(value ? [{ ...objValue, column: column.dataIndex }] : []);
          }}
          {...rest}
        />
        <Space>
          <Button type="primary" onClick={() => confirm()} size="small" style={{ width: 80 }}>
            {i18n.t("container.general_words.apply")}
          </Button>
          <Button
            style={{ width: 80 }}
            size="small"
            type="secondary"
            onClick={() => {
              confirm({ closeDropdown: false });
            }}
          >
            {i18n.t("container.general_words.preview")}
          </Button>
        </Space>
      </div>
    ),
    filteredValue: filterColumn ? [filterColumn] : null
  };
};
