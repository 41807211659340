import { createAction, createReducer } from "@reduxjs/toolkit";

const initialState = {
  reload: null
};

export const updateActivityData = createAction("activity/update");
export const reloadActivityData = createAction("activity/reload");

export default createReducer(initialState, (builder) => {
  builder
    .addCase(updateActivityData, (state, { payload = {} }) => {
      if (payload.rewrite) {
        return { ...payload, rewrite: null, ...(state.keepOnReload || {}), keepOnReload: null };
      }

      return {
        ...state,
        ...payload,
        ...(state.keepOnReload ? { ...state.keepOnReload, keepOnReload: null } : {})
      };
    })
    .addCase(reloadActivityData, (state, { payload }) => {
      return {
        ...state,
        keepOnReload: payload,
        reload: !state.reload
      };
    });
});
