import { Button, Select, Space } from "antd";
import React from "react";
import i18n from "../../../i18n";

export const getColumnStaticSelectFilterProps = ({ tableParams, column = {} }) => {
  const { options, ...rest } = column?.filterProps || {};
  const filterColumn = tableParams.filters?.filter((item) => item.column === column.dataIndex)[0];
  const filterValue = filterColumn?.value || null;
  return {
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div className="table-filter-dropdown select-filter-dropdown">
        <Select
          getPopupContainer={(el) => el}
          allowClear
          value={selectedKeys}
          onChange={(value) => setSelectedKeys(value)}
          {...rest}
        >
          {options.map((option) => (
            <Select.Option key={option.value} value={option.value}>
              {option.name}
            </Select.Option>
          ))}
        </Select>
        <Space>
          <Button type="primary" onClick={() => confirm()} size="small" style={{ width: 80 }}>
            {i18n.t("container.general_words.apply")}
          </Button>
          <Button
            style={{ width: 80 }}
            size="small"
            type="secondary"
            onClick={() => {
              confirm({ closeDropdown: false });
            }}
          >
            {i18n.t("container.general_words.preview")}
          </Button>
        </Space>
      </div>
    ),
    filteredValue: filterValue
  };
};
