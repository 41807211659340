import React from "react";
import isObject from "lodash/isObject";
import forEach from "lodash/forEach";
import TableCell from "../../../components/SmartTable/TableCell";
import DeleteItemButton from "../../../components/SmartTable/TableFiltersActions/DeleteItemButton";
import { FILTER_TYPE_STATIC_SELECT, FILTER_TYPE_STRING } from "../../../components/SmartTable/methods";
import { formatString } from "../../../utils/common";
import { closeModalAction, openModalAction } from "../../../components/Modal/reducer";
import ColumnActiveSwitch from "../../Organization/OrgSettings/Functions/ColumnActiveSwitch";
import { EditButton } from "../../../components/EditButton";
import Tag from "../../../components/Tag";
import Tooltip from "../../../components/Tooltip";
import moment from "../../../moment";
import { EXPORT_TYPE_PDF } from "../../../components/SmartTable/ExportDropdown/ExportOverlay";
import config from "../../../config";
import { MODAL_EXPORT_LOADING } from "../../../components/SmartTable/ExportDropdown/LoadingModal";
import axios from "../../../axios";
import { downloadFile } from "../../../utils/downloadFile";
import { openNotification } from "../../../components/Notification";
import { getHTMLWords } from "../../../components/TinyMCE/methods";
import { validityOptions } from "./config";
import { MODAL_SHARE_TRAINING } from "./ShareTrainingForm";
import { MODAL_TRG_TRAINING } from "./index";

export const TABLE_TRG_TRAININGS = "TABLE_TRG_TRAININGS";

export const generateColumns = (isCompanyLinked, canManage, trans, dispatch, format) => {
  return [
    ...(isCompanyLinked
      ? [
          {
            title: "trg.shared",
            render: (row) => {
              return (
                <TableCell minWidth={150} maxWidth={210}>
                  {!!row.shared_by_company_name && (
                    <Tag className="tag-primary">
                      {trans("qam.shared_by")} {row.shared_by_company_name}
                    </Tag>
                  )}
                  {!!row.is_shared && <Tag className="tag-secondary">{trans("qam.shared_with_other_companies")}</Tag>}
                  {!row.is_shared && !row.shared_by_company_name && <Tag>{trans("trg.not_shared")}</Tag>}
                </TableCell>
              );
            }
          }
        ]
      : []),
    {
      title: "trg.trainings.code",
      dataIndex: "code",
      sorter: true,
      filterType: FILTER_TYPE_STRING,
      render: (text, row) => <TableCell minWidth={100}>{formatString(row.code, "-")}</TableCell>
    },
    {
      title: "trg.trainings.name",
      dataIndex: "name",
      sorter: true,
      filterType: FILTER_TYPE_STRING,
      render: (text, row) => <TableCell minWidth={150}>{formatString(row.name, "-")}</TableCell>
    },
    {
      title: "trg.trainings.validity",
      render: (row) => {
        const { dataForExpiryDate, expiryDateText } = expiryDateTransformer(row, format);
        return (
          <TableCell minWidth={150}>
            {dataForExpiryDate && `${dataForExpiryDate} `}
            {expiryDateText && trans(expiryDateText, { years: row.validity.year_number })}
          </TableCell>
        );
      }
    },
    {
      title: "trg.new_training.provider",
      dataIndex: "provider_name",
      sorter: true,
      filterType: FILTER_TYPE_STRING,
      render: (text, row) => {
        const formattedProvider = row.provider_name || trans("trg.new_training.internal");
        return <TableCell minWidth={150}>{formattedProvider}</TableCell>;
      }
    },
    {
      title: "trg.external_providers.active",
      dataIndex: "active",
      sorter: true,
      filterType: FILTER_TYPE_STATIC_SELECT,
      filterProps: {
        options: [
          {
            value: "1",
            name: trans("container.general_words.active")
          },
          {
            value: "0",
            name: trans("container.general_words.inactive")
          }
        ]
      },
      render: (text, row) => (
        <TableCell width={100}>
          {trans(row.active ? "container.general_words.active" : "container.general_words.inactive")}
        </TableCell>
      )
    },
    ...(canManage
      ? [
          {
            title: trans("container.general_words.actions"),
            fixed: "right",
            width: 100,
            render: (row = {}) => (
              <div className="table-actions-column">
                <Tooltip title={trans("container.general_words.export")} placement="top" className="text-muted">
                  <a
                    href="/"
                    onClick={(e) => {
                      e.preventDefault();
                      downloadTrgSingleTraining(trans, dispatch, row, "trg/training/export");
                    }}
                  >
                    <i className="ti-download" />
                  </a>
                </Tooltip>
                <ColumnActiveSwitch
                  disabled={!row.can_be_activated}
                  className="mr-10"
                  isActive={row.active}
                  route={`trg/trainings/change-state/${row.id}`}
                  tableName={TABLE_TRG_TRAININGS}
                  tooltip={{
                    active: !row.can_be_activated
                      ? "trg.training_cannot_be_activated"
                      : "trg.trainings.deactivate_training_tooltip",
                    inactive: !row.can_be_activated
                      ? "trg.training_cannot_be_activated"
                      : "trg.trainings.activate_training_tooltip"
                  }}
                />
                {isCompanyLinked && (
                  <Tooltip title={trans("trg.share")}>
                    <a
                      className="text-primary mr-15"
                      disabled={!row.active || !!row.shared_by_company_name}
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        if (!row.active || !!row.shared_by_company_name) return;
                        dispatch(openModalAction({ name: MODAL_SHARE_TRAINING, data: row }));
                      }}
                    >
                      <i className="ti-share" />
                    </a>
                  </Tooltip>
                )}
                <EditButton
                  onClick={() => {
                    dispatch(openModalAction({ name: MODAL_TRG_TRAINING, data: row }));
                  }}
                />
                <DeleteItemButton
                  disabled={!row.canBeDeleted || row.is_shared || !!row.shared_by_company_name}
                  tableName={TABLE_TRG_TRAININGS}
                  route={`trg/trainings/${row.id}`}
                  content={
                    <div>
                      <div>{trans("container.confirm_modal.form_subtitle")}</div>
                    </div>
                  }
                />
              </div>
            )
          }
        ]
      : [])
  ];
};

export const expiryDateTransformer = (item, format) => {
  const result = {
    dataForExpiryDate: null,
    expiryDateText: null
  };

  if (item.validity?.no_expiry) {
    result.expiryDateText = "trg.validity_field.no_expiry";
  } else {
    const keys = Object.keys(item.validity).filter((key) => key !== "no_expiry");
    keys.forEach((key) => {
      if (item.validity[key]) {
        switch (key) {
          case "day_number":
            if (item.validity[key] === 1) {
              result.dataForExpiryDate = 1;
              result.expiryDateText = "trg.validity_field.day";
            } else {
              result.dataForExpiryDate = item.validity[key];
              result.expiryDateText = "trg.validity_field.days";
            }
            break;
          case "year_number":
            if (item.validity[key] === 1) {
              result.dataForExpiryDate = 1;
              result.expiryDateText = "trg.validity_field.year";
            } else {
              result.dataForExpiryDate = item.validity[key];
              result.expiryDateText = "trg.validity_field.years";
            }
            break;
          case "current_year":
            result.expiryDateText = "trg.validity_field.current_year";
            break;
          case "next_year":
            result.expiryDateText = "trg.validity_field.next_year";
            break;
          case "specific_date":
            result.dataForExpiryDate = format({ date: item.validity[key] });
            break;
          case "december_in_x_years":
            result.dataForExpiryDate = "";
            result.expiryDateText = "trg.validity_field.table_data_december_in_x_years";
            break;
          default:
            break;
        }
      }
    });
  }

  return result;
};

export const prepareTrainingDataToSend = (values) => {
  const { range_of_validity, internal_provider, validity, specific_date } = values;
  const all_branches = isObject(range_of_validity ? range_of_validity[0] : null)
    ? range_of_validity[0].all_branches
    : false;

  return {
    ...values,
    all_branches,
    internal_provider: !!internal_provider,
    no_expiry: validity === "no_expiry",
    description_length: getHTMLWords(values.description).charactersLength,
    ...(validity === "current_year" ? { current_year: true } : {}),
    ...(validity === "next_year" ? { next_year: true } : {}),
    ...(validity === "december_in_x_years" ? { december_in_x_years: true } : {}),
    ...(specific_date ? { specific_date: moment(specific_date).format(config.defaultDateFormat) } : {}),
    ...(!all_branches ? { branches: range_of_validity } : {})
  };
};

export const getInitialValidity = (training) => {
  let result = {};
  forEach(training.validity, (value, key) => {
    if (value) {
      result = validityOptions.filter((item) => item.value === key)[0];
    }
  });
  return result.value;
};

export const downloadTrgSingleTraining = (trans, dispatch, row = {}, baseRoute) => {
  dispatch(openModalAction({ name: MODAL_EXPORT_LOADING }));
  axios
    .get(`${baseRoute}`, {
      params: {
        filename: row.title,
        "type": EXPORT_TYPE_PDF,
        "training_id": row.id
      }
    })
    .then(
      (res) => {
        const fileName = res.data.data.url.substring(res.data.data.url.lastIndexOf("/") + 1, res.data.data.url.length);

        downloadFile({
          fileUrl: res.data.data.url,
          fileName: fileName,
          onSuccess: () => {
            dispatch(closeModalAction({ name: MODAL_EXPORT_LOADING }));
          },
          onFailed: () => {
            openNotification({
              type: "error",
              message: trans("container.form_errors.internal_server_error")
            });
            dispatch(closeModalAction({ name: MODAL_EXPORT_LOADING }));
          }
        });
      },
      () => {
        openNotification({
          type: "error",
          message: trans("container.form_errors.internal_server_error")
        });
        dispatch(closeModalAction({ name: MODAL_EXPORT_LOADING }));
      }
    );
};
