import { useState, useEffect, useCallback } from "react";
import CropperJs from "cropperjs";
import { getBase64, getFileType } from "./methods";

const defaultCropperJsValues = {
  viewMode: 1,
  dragMode: "move",
  initialAspectRatio: 1,
  guides: false,
  autoCropArea: 1,
  cropBoxMovable: true,
  cropBoxResizable: true,
  background: false,
  scalable: false
};

const useCropper = ({ cropperRef, cropperJsOptions = {}, image, onCrop }) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let cropperRefCurrent = null;

    if (cropperRef?.current) {
      new CropperJs(cropperRef.current, {
        ...defaultCropperJsValues,
        ...cropperJsOptions,
        ready: (e) => {
          cropperJsOptions.ready?.(e);
        }
      });

      cropperRefCurrent = cropperRef.current;
    }

    return () => {
      if (cropperRefCurrent) {
        cropperRefCurrent.cropper.destroy();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cropperRef]);

  useEffect(() => {
    (async () => {
      if (image && cropperRef.current?.cropper) {
        setLoading(true);

        let src = image;

        if (image?.size) {
          src = await getBase64(image);
        }

        cropperRef.current.cropper.reset().clear().replace(src);

        setLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cropperRef, image]);

  const handleCrop = useCallback(() => {
    let encodeType = image?.type;

    if (!encodeType) {
      const type = getFileType(cropperRef.current?.src);
      encodeType = type ? `image/${type}` : "";
    }

    const canvasDimensions = {
      maxWidth: 4096,
      maxHeight: 4096
    };

    cropperRef?.current?.cropper.getCroppedCanvas(canvasDimensions).toBlob((blob) => {
      onCrop?.({
        blob,
        base64: cropperRef?.current?.cropper.getCroppedCanvas(canvasDimensions).toDataURL(encodeType)
      });
    }, encodeType);
  }, [cropperRef, onCrop, image]);

  return {
    loading,
    handleCrop
  };
};

export { useCropper };
