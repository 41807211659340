import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ChromePicker } from "react-color";
import { Input } from "../Input";
import Popover from "../Popover";

const ColorPicker = (props) => {
  const { placeholder, onChange, value, ...rest } = props;
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);

  const handleColorChange = (color) => {
    onChange && onChange(color);
  };

  return (
    <div className="color-picker-input">
      <Popover
        visible={visible}
        trigger={["click"]}
        placement="topLeft"
        onVisibleChange={(vis) => setVisible(vis)}
        overlayClassName="popover-color-picker"
        content={
          <ChromePicker disableAlpha={true} {...rest} onChange={({ hex }) => handleColorChange(hex)} color={value} />
        }
      >
        <Input placeholder={t(placeholder)} onChange={(e) => handleColorChange(e.target.value)} value={value} />
      </Popover>
      {value && <div className="square" style={{ backgroundColor: value }} />}
    </div>
  );
};

export default ColorPicker;
