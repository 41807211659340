import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import isEmpty from "lodash/isEmpty";
import Form, { FORM_HORIZONTAL_LAYOUT } from "../../../components/Form";
import useFormSubmit from "../../../components/Form/useFormSubmit";
import { reloadTable } from "../../../components/SmartTable/reducer";
import { closeModalAction } from "../../../components/Modal/reducer";
import { FORM_ITEM_TEXT } from "../../../components/Form/FormItems/FormItemText";
import Button from "../../../components/Button";
import { FORM_ITEM_CHECKBOX } from "../../../components/Form/FormItems/FormItemCheckbox";
import { renderDynamicallyFields } from "../../../components/Form/methods";
import { useModalData } from "../../../components/Modal/useModalData";
import { FORM_ITEM_RESOURCE_SELECT_LAZY_LOADING } from "../../../components/Form/FormItems/FormItemResourceSelectLazyLoading";
import { FORM_ITEM_TEXT_AREA } from "../../../components/Form/FormItems/FormItemTextArea";
import { FORM_ITEM_NUMBER } from "../../../components/Form/FormItems/FormItemNumber";
import { FORM_ITEM_DATE_PICKER } from "../../../components/Form/FormItems/FormItemDatePicker";
import axios from "../../../axios";
import { openConfirm } from "../../../components/Modal/Confirm";
import moment from "../../../moment";
import config from "../../../config";
import { FORM_ITEM_TINY_MCE } from "../../../components/Form/FormItems/FormItemTinyMCE";
import { getInitialValidity, prepareTrainingDataToSend, TABLE_TRG_TRAININGS } from "./methods";
import { trainingLanguages, validityOptions } from "./config";
import { MODAL_TRG_TRAINING } from "./index";

const TrainingForm = () => {
  const data = useModalData(MODAL_TRG_TRAINING);
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isNewRecord = isEmpty(data);
  const [branchesVisible, setBranchesVisible] = useState(false);
  const [language, setLanguage] = useState();
  const [validity, setValidity] = useState();
  const [linkedTraining, setLinkedTraining] = useState();
  const [internalProvider, setInternalProvider] = useState();
  const [editLoading, setEditLoading] = useState(false);
  const { loading, submit, generalError } = useFormSubmit({
    form,
    route: isNewRecord ? "trg/trainings" : `trg/trainings/${data.id}`,
    method: isNewRecord ? "post" : "put",
    transformer: (values) => prepareTrainingDataToSend(values),
    onSuccess: () => {
      dispatch(reloadTable({ name: TABLE_TRG_TRAININGS, resetFilters: isNewRecord }));
      dispatch(closeModalAction({ name: MODAL_TRG_TRAINING }));
    }
  });

  useEffect(() => {
    if (data.id) {
      setEditLoading(true);
      axios
        .get(`trg/trainings/${data.id}`, {
          params: { with: ["area", "branches", "provider", "prerequisites", "validity", "tags", "label"] }
        })
        .then(
          (res) => {
            const training = res.data.data;
            const range_of_validity = res.data.data.all_branches
              ? [{ value: t("trg.new_training.all_branches"), all_branches: true }]
              : training.branches?.map((item) => item.id) || undefined;
            const validity = getInitialValidity(training);
            const internal_provider = training.internal_provider ? 1 : 0;
            setValidity(validity);
            setInternalProvider(internal_provider);
            setLinkedTraining(training.linked_training_id);
            setLanguage(training.language);

            form.setFieldsValue({
              code: training.code,
              area_id: training.area_id,
              method_id: training.method_id,
              tags: training.tags?.map((item) => item.id) || undefined,
              range_of_validity: range_of_validity,
              name: training.name,
              version: training.version,
              description: training.description,
              category: training.category,
              duration: training.duration,
              language: training.language,
              alternative_languages: training.alternative_languages,
              prerequisites: training.prerequisites?.map((item) => item.id) || undefined,
              validity: validity,
              specific_date: training.validity?.specific_date,
              day_number: training.validity?.day_number,
              year_number: training.validity?.year_number,
              notification_period: training.notification_period,
              linked_training_id: training.linked_training_id,
              linked_training_notification: training.linked_training_notification,
              internal_provider: internal_provider,
              responsible_id: training.responsible_id,
              external_provider_id: training.external_provider_id,
              internal_remarks: training.internal_remarks,
              training_label_id: training.label?.id
            });
            setEditLoading(false);
          },
          () => {
            setEditLoading(false);
          }
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const beforeSubmit = () => {
    if (isNewRecord) {
      return submit();
    }
    // Check if validity of the training was changed
    let validityIsChanged = 0;
    const actualValidity = form.getFieldValue("validity");
    const numberDaysValidity = form.getFieldValue("day_number");
    const numberYearsValidity = form.getFieldValue("year_number");
    const specificDateValidity = form.getFieldValue("specific_date")
      ? moment(form.getFieldValue("specific_date")).format(config.defaultDateLongFormat)
      : null;
    if (
      getInitialValidity(data) !== actualValidity ||
      numberDaysValidity !== data.validity?.day_number ||
      numberYearsValidity !== data.validity?.year_number ||
      specificDateValidity !== data.validity?.specific_date
    ) {
      validityIsChanged = 1;
    }
    if (validityIsChanged) {
      openConfirm({
        content: (
          <div>
            <div>{t("trg.new_training.edit_on_confirm_body")}</div>
          </div>
        ),
        onOk: () => {
          return submit();
        }
      });
    } else {
      return submit();
    }
  };

  return (
    <Form
      disabled={editLoading}
      onSubmit={beforeSubmit}
      form={form}
      errors={generalError}
      loading={loading}
      initialValues={{ active: isNewRecord ? true : data?.active, training_label_id: data?.label?.id }}
      layout={FORM_HORIZONTAL_LAYOUT}
      header={
        <>
          <div className="h3">{t(isNewRecord ? "trg.new_training.form_title" : "trg.edit_training.form_title")}</div>
          <div className="mb-30 mt-10 h6 font-weight-300 text-muted">{t("container.general_words.form_subtitle")}</div>
        </>
      }
    >
      {renderDynamicallyFields([
        {
          required: true,
          type: FORM_ITEM_TEXT,
          max: 200,
          name: "code",
          label: "trg.new_training.code",
          placeholder: "trg.new_training.code",
          fieldProps: {
            disabled: !!data.shared_by_company_name
          }
        },
        {
          required: true,
          type: FORM_ITEM_RESOURCE_SELECT_LAZY_LOADING,
          name: "area_id",
          label: "trg.new_training.area",
          placeholder: "trg.new_training.area",
          apiRoute:
            "trg/areas?field%5B%5D=active&op%5B%5D=eq&order_by_column%5B%5D=name&order_by_type%5B%5D=asc&term%5B%5D=1",
          fieldProps: {
            requestOnMount: !isNewRecord
          }
        },
        {
          type: FORM_ITEM_RESOURCE_SELECT_LAZY_LOADING,
          name: "method_id",
          label: "qam.activity_program.method",
          placeholder: "qam.activity_program.method",
          apiRoute:
            "trg/methods?field%5B%5D=active&op%5B%5D=eq&order_by_column%5B%5D=name&order_by_type%5B%5D=asc&term%5B%5D=1",
          fieldProps: {
            requestOnMount: !isNewRecord
          }
        },
        {
          type: FORM_ITEM_RESOURCE_SELECT_LAZY_LOADING,
          name: "tags",
          mode: "multiple",
          label: "trg.new_training.tags",
          placeholder: "trg.new_training.tags",
          apiRoute:
            "trg/tags?field%5B%5D=active&op%5B%5D=eq&order_by_column%5B%5D=name&order_by_type%5B%5D=asc&term%5B%5D=1",
          fieldProps: {
            disabled: !!data.shared_by_company_name,
            requestOnMount: !isNewRecord
          }
        },
        {
          required: true,
          type: FORM_ITEM_RESOURCE_SELECT_LAZY_LOADING,
          name: "range_of_validity",
          mode: "multiple",
          label: "trg.new_training.range_of_validity",
          placeholder: "trg.new_training.range_of_validity",
          apiRoute: "organization-company-branches?order_by_column%5B%5D=name&order_by_type%5B%5D=asc",
          fieldProps: {
            requestOnMount: !isNewRecord,
            dropdownRender: (menu) => {
              return (
                <>
                  <div className="ml-10 mb-10 mt-10">
                    <Button
                      size="small"
                      type="secondary"
                      onClick={() => {
                        setBranchesVisible(false);
                        form.setFieldsValue({
                          range_of_validity: [{ value: t("trg.new_training.all_branches"), all_branches: true }]
                        });
                      }}
                    >
                      {t("trg.new_training.all_branches")}
                    </Button>
                  </div>
                  {menu}
                </>
              );
            },
            onDropdownVisibleChange: (visible) => {
              if (visible) {
                const validityValue = form.getFieldValue("range_of_validity");

                if (validityValue && validityValue[0]?.all_branches) {
                  return;
                }
              }
              setBranchesVisible(visible);
            },
            open: branchesVisible
          }
        },
        {
          required: true,
          type: FORM_ITEM_TEXT,
          max: 200,
          name: "name",
          label: "trg.new_training.name",
          placeholder: "trg.new_training.name",
          fieldProps: {
            disabled: !!data.shared_by_company_name
          }
        },
        {
          type: FORM_ITEM_TEXT,
          name: "version",
          label: "trg.new_training.version",
          placeholder: "trg.new_training.version",
          fieldProps: {
            disabled: !!data.shared_by_company_name
          }
        },
        {
          type: FORM_ITEM_TINY_MCE,
          name: "description",
          label: "trg.new_training.description",
          placeholder: "trg.new_training.description",
          fieldProps: {
            disabled: !!data.shared_by_company_name,
            "showMenuBar": false
          }
        },
        {
          type: FORM_ITEM_RESOURCE_SELECT_LAZY_LOADING,
          name: "training_label_id",
          label: "trg.training_program.training_label",
          placeholder: "trg.training_program.training_label",
          apiRoute: "trg/training-labels?order_by_column%5B%5D=name&order_by_type%5B%5D=asc",
          fieldProps: {
            initialValue: data.label ? { text: data.label.name, value: data.label.id } : undefined,
            requestOnMount: !isNewRecord
          }
        },
        {
          required: true,
          type: FORM_ITEM_RESOURCE_SELECT_LAZY_LOADING,
          name: "category",
          label: "trg.new_training.category",
          placeholder: "trg.new_training.category",
          fieldProps: {
            showSearch: false,
            disabled: !!data.shared_by_company_name,
            staticOptions: [
              {
                transKey: "trg.new_training.initial",
                value: "trg.new_training.initial"
              },
              {
                transKey: "trg.new_training.recurrent",
                value: "trg.new_training.recurrent"
              }
            ]
          }
        },
        {
          required: true,
          type: FORM_ITEM_NUMBER,
          name: "duration",
          label: "trg.new_training.duration",
          placeholder: "trg.new_training.duration",
          fieldProps: {
            disabled: !!data.shared_by_company_name
          }
        },
        {
          required: true,
          type: FORM_ITEM_RESOURCE_SELECT_LAZY_LOADING,
          name: "language",
          label: "trg.new_training.language",
          placeholder: "trg.new_training.language",
          fieldProps: {
            showSearch: false,
            disabled: !!data.shared_by_company_name,
            staticOptions: trainingLanguages,
            onChange: (value) => {
              setLanguage(value);
              const alternativeLanguages = form.getFieldValue("alternative_languages");

              if (value && alternativeLanguages && alternativeLanguages.length > 0) {
                form.setFieldsValue({
                  alternative_languages: alternativeLanguages.filter((altLang) => altLang !== value)
                });
              }
            }
          }
        },
        {
          mode: "multiple",
          type: FORM_ITEM_RESOURCE_SELECT_LAZY_LOADING,
          name: "alternative_languages",
          label: "trg.new_training.alternative_languages",
          placeholder: "trg.new_training.alternative_languages",
          fieldProps: {
            overwritePreviousOptions: true,
            disabled: !!data.shared_by_company_name,
            staticOptions: trainingLanguages.filter((item) => item.value !== language)
          }
        },
        {
          type: FORM_ITEM_RESOURCE_SELECT_LAZY_LOADING,
          name: "prerequisites",
          mode: "multiple",
          label: "trg.new_training.prerequisites",
          placeholder: "trg.new_training.prerequisites",
          apiRoute:
            "trg/trainings?field%5B%5D=active&op%5B%5D=eq&order_by_column%5B%5D=name&order_by_type%5B%5D=asc&term%5B%5D=1",
          fieldProps: {
            searchColumns: ["name", "code"],
            requestOnMount: !isNewRecord
          }
        },
        {
          required: true,
          type: FORM_ITEM_RESOURCE_SELECT_LAZY_LOADING,
          name: "validity",
          label: "trg.new_training.validity",
          placeholder: "trg.new_training.validity",
          fieldProps: {
            staticOptions: validityOptions,
            onChange: (value) => setValidity(value)
          }
        },
        {
          required: true,
          type: FORM_ITEM_NUMBER,
          name: "day_number",
          label: "trg.new_training.number_of_days",
          placeholder: "trg.new_training.number_of_days",
          visible: validity === "day_number"
        },
        {
          required: true,
          type: FORM_ITEM_NUMBER,
          name: "year_number",
          label: "trg.new_training.number_of_years",
          placeholder: "trg.new_training.number_of_years",
          visible: validity === "year_number" || validity === "december_in_x_years"
        },
        {
          type: FORM_ITEM_DATE_PICKER,
          required: true,
          name: "specific_date",
          label: "trg.new_training.specific_date",
          placeholder: "trg.new_training.specific_date",
          visible: validity === "specific_date"
        },
        {
          required: true,
          type: FORM_ITEM_NUMBER,
          name: "notification_period",
          label: "trg.new_training.notification_period",
          placeholder: "trg.new_training.notification_period",
          visible: validity && validity !== "no_expiry",
          fieldProps: {
            disabled: !!data.shared_by_company_name
          }
        },
        {
          type: FORM_ITEM_RESOURCE_SELECT_LAZY_LOADING,
          name: "linked_training_id",
          label: "trg.new_training.link_this_training_to",
          placeholder: "trg.new_training.link_this_training_to",
          apiRoute:
            "trg/trainings?field%5B%5D=active&op%5B%5D=eq&order_by_column%5B%5D=name&order_by_type%5B%5D=asc&term%5B%5D=1",
          fieldProps: {
            searchColumns: ["name", "code"],
            requestOnMount: !isNewRecord,
            onChange: (value) => setLinkedTraining(value),
            disabled: !!data.shared_by_company_name,
            transformer: (options) =>
              options.map((option) => ({
                ...option,
                text: `${option.name} (${option.code})`
              }))
          }
        },
        {
          required: true,
          type: FORM_ITEM_NUMBER,
          name: "linked_training_notification",
          label: "trg.new_training.number_of_days_for_linked_training",
          placeholder: "trg.new_training.number_of_days_for_linked_training",
          visible: !!linkedTraining,
          fieldProps: {
            disabled: !!data.shared_by_company_name
          }
        },
        {
          required: true,
          type: FORM_ITEM_RESOURCE_SELECT_LAZY_LOADING,
          name: "internal_provider",
          label: "trg.new_training.provider",
          placeholder: "trg.new_training.provider",
          fieldProps: {
            onChange: (value) => setInternalProvider(value),
            staticOptions: [
              {
                transKey: "trg.new_training.internal",
                value: 1
              },
              {
                transKey: "trg.new_training.external",
                value: 0
              }
            ]
          }
        },
        {
          required: true,
          type: FORM_ITEM_RESOURCE_SELECT_LAZY_LOADING,
          name: "external_provider_id",
          label: "trg.new_training.provider_name",
          placeholder: "trg.new_training.provider_name",
          visible: internalProvider === 0,
          apiRoute:
            "trg/external-providers?field%5B%5D=active&op%5B%5D=eq&order_by_column%5B%5D=name&order_by_type%5B%5D=asc&term%5B%5D=1",
          fieldProps: {
            requestOnMount: !isNewRecord
          }
        },
        {
          required: true,
          type: FORM_ITEM_RESOURCE_SELECT_LAZY_LOADING,
          name: "responsible_id",
          label: "trg.new_training.responsible_person",
          placeholder: "trg.new_training.responsible_person",
          visible: !!internalProvider,
          apiRoute:
            "users/all?field%5B%5D=user_is_in_organisation&filters%5BvalidContract%5D=1&op%5B%5D=eq&order_by_column%5B%5D=name&order_by_type%5B%5D=asc&term%5B%5D=1",
          fieldProps: {
            requestOnMount: !isNewRecord,
            searchColumns: ["name"]
          }
        },
        {
          type: FORM_ITEM_TEXT_AREA,
          name: "internal_remarks",
          label: "trg.new_training.internal_remarks",
          placeholder: "trg.new_training.internal_remarks",
          autoSize: { minRows: 2, maxRows: 6 },
          fieldProps: {
            disabled: !!data.shared_by_company_name
          }
        },
        {
          type: FORM_ITEM_CHECKBOX,
          visible: isNewRecord,
          name: "active",
          label: "trg.external_providers.active"
        }
      ])}
      <div className="flex mt-25 flex-justify-end">
        <Button
          className="min-w-150"
          type="primary"
          htmlType="submit"
          disabled={loading}
          loading={loading}
          icon={<i className="ti-check" />}
        >
          {t("container.general_words.save")}
        </Button>
      </div>
    </Form>
  );
};

export default TrainingForm;
