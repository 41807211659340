import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import classNames from "classnames";
import Preloader from "../../../../../components/Preloader";
import { ReportCollapse } from "../ReportCollapse";
import { MODAL_QAM_CORRECTIVE_ACTION_ROOT_CAUSE } from "../../../../Qam/CorrectiveActionView/CorrectiveActionRootCause";
import Tooltip from "../../../../../components/Tooltip";
import Modal from "../../../../../components/Modal";
import { closeModalAction, openModalAction } from "../../../../../components/Modal/reducer";
import { formatString } from "../../../../../utils/common";
import AddRootCauseForm from "../../../../Qam/CorrectiveActionView/AddRootCauseForm";
import DeleteItemButton from "../../../../../components/SmartTable/TableFiltersActions/DeleteItemButton";

export const ReportRootCause = ({
  data,
  loading,
  setIsRootCause,
  isCollapsed,
  hasError,
  onUpdateRootCauses,
  basicDataSaved,
  isClosedReport
}) => {
  const { t } = useTranslation();
  const [rootCauses, setRootCauses] = useState(data.rootCause || []);

  useEffect(() => {
    if (rootCauses.length > 0) {
      setRootCauses(rootCauses);
    }
  }, [rootCauses]);
  const dispatch = useDispatch();
  const [localRootCauses, setLocalRootCauses] = useState(data.rootCause || []);

  const openRootCauseModal = (e, editData = {}) => {
    e.preventDefault();
    dispatch(
      openModalAction({
        name: MODAL_QAM_CORRECTIVE_ACTION_ROOT_CAUSE,
        data: {
          ...editData,
          report_id: basicDataSaved.basic_data?.id,
          existentRootCauses: localRootCauses,
          onSuccess: (res) => {
            const updatedRootCause = isEmpty(editData)
              ? [...localRootCauses, res.data]
              : localRootCauses.map((rootCauseItem) => {
                  if (rootCauseItem.id === res.data.id) {
                    return res.data;
                  }
                  return rootCauseItem;
                });
            setLocalRootCauses(updatedRootCause);
            onUpdateRootCauses(updatedRootCause);
            setIsRootCause(!!updatedRootCause.length);
            dispatch(closeModalAction({ MODAL_QAM_CORRECTIVE_ACTION_ROOT_CAUSE }));
          }
        }
      })
    );
  };

  return (
    <div className={`report-root-cause activity-view-content mb-20 ${hasError ? classNames({ error: true }) : ""}`}>
      <ReportCollapse
        collapsed={isCollapsed}
        isSectionDisabled={!basicDataSaved?.basic_data}
        header={{
          title: t("ior.new_report.section_root_causes"),
          tooltip: !data?.basic_data?.id
            ? t("ior.new_report.disabled_feedback")
            : t("ior.new_report.section_root_causes_popover")
        }}
      >
        {loading && <Preloader className="text-center mt-50" size={30} />}
        <>
          <Modal name={MODAL_QAM_CORRECTIVE_ACTION_ROOT_CAUSE}>
            <AddRootCauseForm routeUrl={"ior/iorRootCause"} />
          </Modal>
          <div className="flex flex-justify-between flex-items-center mb-10">
            <div className="flex flex-items-center">
              <h6 className="font-weight-300 text-muted mb-0">{t("qam.corrective_action_view.root_cause")}</h6>
              {
                <Tooltip title={t("qam.corrective_action_view.add_root_cause")}>
                  {!isClosedReport && (
                    <a href="#/" className="text-secondary ml-10" onClick={(e) => openRootCauseModal(e)}>
                      <i className="ti-plus" />
                    </a>
                  )}
                </Tooltip>
              }
            </div>
          </div>
          {localRootCauses.length > 0 ? (
            <div className="root-causes-list">
              {localRootCauses.map((rootCauseItem) => (
                <div key={rootCauseItem.id} className="root-cause-item">
                  <div className="left">
                    <div className="text-muted fs-12">
                      {t("qam.corrective_action_view.root_cause_area_category_sub_category")}
                    </div>
                    <div className="fs-14">
                      {formatString(rootCauseItem.area.name, "-")} / {formatString(rootCauseItem.category.name, "-")} /{" "}
                      {formatString(rootCauseItem.subcategory.name, "-")}
                    </div>
                  </div>
                  {!isClosedReport && (
                    <div className="right">
                      <Tooltip title={t("container.general_words.edit")} className="text-primary">
                        <a href="/" onClick={(e) => openRootCauseModal(e, rootCauseItem)}>
                          <i className="ti-pencil-alt" />
                        </a>
                      </Tooltip>
                      <DeleteItemButton
                        onDelete={(res) => {
                          const updatedRootCause = localRootCauses.filter((item) => item.id !== res.data.data.id);
                          setLocalRootCauses(updatedRootCause);
                          onUpdateRootCauses(updatedRootCause);
                          setIsRootCause(!!updatedRootCause.length);
                        }}
                        route={`ior/iorRootCause/${rootCauseItem.id}`}
                        content={
                          <div>
                            <div>{t("container.confirm_modal.form_subtitle")}</div>
                          </div>
                        }
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>
          ) : (
            <div className="fs-14">{t("qam.corrective_action_view.root_cause_no_data_message")}</div>
          )}
        </>
      </ReportCollapse>
    </div>
  );
};
