import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import axios from "../../../axios";
import useFormSubmit from "../../../components/Form/useFormSubmit";
import Form, { FORM_HORIZONTAL_LAYOUT } from "../../../components/Form";
import { reloadTable } from "../../../components/SmartTable/reducer";
import { closeModalAction } from "../../../components/Modal/reducer";
import Button from "../../../components/Button";
import { renderDynamicallyFields } from "../../../components/Form/methods";
import { prepareFormData } from "../ReportPage/ReportForm/methods";
import { FORM_ITEM_TEXT } from "../../../components/Form/FormItems/FormItemText";
import { FORM_ITEM_TINY_MCE } from "../../../components/Form/FormItems/FormItemTinyMCE";
import { FORM_ITEM_DATE_PICKER } from "../../../components/Form/FormItems/FormItemDatePicker";
import { FORM_ITEM_RESOURCE_SELECT_LAZY_LOADING } from "../../../components/Form/FormItems/FormItemResourceSelectLazyLoading";
import { formatMomentDateForAPI } from "../../../components/DatePicker";
import { TABLE_IOR_CORRECTIVE_ACTIONS } from "../ReportPage/ReportForm/ReportCorrectiveActions/methods";
import { useDateFormat } from "../../../utils/hooks/useDateFormat";
import { typeOptions } from "./config";

export const MODAL_IOR_CORRECTIVE_ACTION = "MODAL_IOR_CORRECTIVE_ACTION";

export const AddCorrectiveActionForm = ({ name, reportForm, basicDataSaved }) => {
  const data = useSelector((state) => {
    return state?.modal[name || MODAL_IOR_CORRECTIVE_ACTION]?.data || {};
  });
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { formatLocaleUTC, format } = useDateFormat();

  const isNewRecord = !data.id;

  const { loading, submit, generalError } = useFormSubmit({
    route: isNewRecord ? "ior/corrective-actions" : `ior/corrective-actions/${data.id}`,
    method: isNewRecord ? "post" : "put",
    form,
    transformer: (values) => {
      if (isNewRecord) {
        return {
          ...data,
          ...values,
          report_id: data.id ? data.id : basicDataSaved.basic_data?.id,
          due_date: formatMomentDateForAPI(values.due_date)
        };
      } else {
        return {
          ...values,
          report_id: data.id ? data.id : basicDataSaved.basic_data?.id,
          risk_level_id: data.risk_level_id,
          due_date: formatMomentDateForAPI(values.due_date)
        };
      }
    },
    onSuccess: (res) => {
      data.onSuccess && data.onSuccess(res);
      dispatch(reloadTable({ name: TABLE_IOR_CORRECTIVE_ACTIONS, resetFilters: isNewRecord }));
      dispatch(closeModalAction({ name: name || MODAL_IOR_CORRECTIVE_ACTION }));
      if (data.risk_level_changed) {
        const values = reportForm.getFieldsValue([
          "basic_data",
          "first_risk_analysis",
          "investigation",
          "management_summary"
        ]);
        axios.put(
          `ior/report/${data.id ? data.id : basicDataSaved.basic_data?.id}`,
          prepareFormData(values, formatLocaleUTC, format)
        );
      }
    }
  });

  return (
    <Form
      onSubmit={submit}
      form={form}
      errors={generalError}
      loading={loading}
      initialValues={data}
      layout={FORM_HORIZONTAL_LAYOUT}
      header={
        <>
          <div className="h3">
            {t(isNewRecord ? "ior.new_corrective_action.form_title" : "ior.edit_corrective_action.form_title")}
          </div>
          <div className="mb-30 mt-10 h6 font-weight-300 text-muted">{t("container.general_words.form_subtitle")}</div>
        </>
      }
    >
      {renderDynamicallyFields([
        {
          required: true,
          type: FORM_ITEM_TEXT,
          max: 200,
          name: "title",
          label: "container.general_words.title",
          placeholder: "container.general_words.title"
        },
        {
          required: false,
          type: FORM_ITEM_TINY_MCE,
          name: "observation",
          label: "ior.corrective_actions.observation",
          placeholder: "ior.corrective_actions.observation"
        },
        {
          required: true,
          type: FORM_ITEM_TINY_MCE,
          name: "details",
          label: "ior.new_corrective_action.details",
          placeholder: "ior.new_corrective_action.details"
        },
        {
          required: true,
          type: FORM_ITEM_DATE_PICKER,
          name: "due_date",
          label: "ior.corrective_actions.due_date",
          placeholder: "ior.corrective_actions.due_date"
        },
        {
          type: FORM_ITEM_RESOURCE_SELECT_LAZY_LOADING,
          required: true,
          fieldProps: {
            staticOptions: typeOptions.map((item) => ({ ...item, text: t(item.text) })),
            onChange: (value) => {
              if (value === "external") {
                form.setFieldsValue({
                  "responsible_id": undefined,
                  "responsible_name": undefined
                });
              }
            }
          },
          name: ["responsible_type"],
          label: "ior.new_report.responsible.type",
          placeholder: "ior.new_report.responsible.type"
        },
        {
          required: true,
          type: FORM_ITEM_RESOURCE_SELECT_LAZY_LOADING,
          name: "responsible_id",
          visible: (formInstance) => formInstance.getFieldValue(["responsible_type"]) === typeOptions[0].value,
          label: "ior.new_report.responsible.name",
          placeholder: "ior.new_report.responsible.name",
          initialValue: data?.responsible_id
            ? {
                id: data.responsible_id,
                text: data.responsible_name || data.responsible?.name
              }
            : undefined,
          apiRoute:
            "user-and-groups-and-user-details?per_page=10&defaultPageSize=10&page=1&with%5B%5D=files&order_by_column%5B%5D=name&order_by_type%5B%5D=asc&field%5B%5D=user_is_in_auditees&op%5B%5D=eq&term%5B%5D=1&filters%5BhasIorPermission%5D%5B%5D=1"
        },
        {
          type: FORM_ITEM_TEXT,
          max: 200,
          required: true,
          visible: (formInstance) => formInstance.getFieldValue(["responsible_type"]) === typeOptions[1].value,
          name: ["responsible_name"],
          label: "ior.new_report.responsible.name",
          placeholder: "ior.new_report.responsible.name"
        }
      ])}
      <div className="flex mt-25 flex-justify-end">
        <Button
          className="min-w-150"
          type="primary"
          htmlType="submit"
          disabled={loading}
          loading={loading}
          icon={<i className="ti-check" />}
        >
          {t("container.general_words.save")}
        </Button>
      </div>
    </Form>
  );
};
