import { createAction, createReducer } from "@reduxjs/toolkit";

const initialState = {
  reload: undefined,
  searchValue: "",
  rootCauses: [],
  filters: {
    "filters[active]": 1
  },
  keepFilters: true,
  pagination: {
    page: 1
  }
};

export const updateRootCausesState = createAction("root-causes/update");

export default createReducer(initialState, (builder) => {
  builder.addCase(updateRootCausesState, (state, { payload = {} }) => {
    return {
      ...state,
      ...payload
    };
  });
});
