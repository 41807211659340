import React from "react";
import { useTranslation } from "react-i18next";
import { fieldRules } from "../methods";
import Form from "../index";
import InputUploadImage from "../../Upload/InputUploadImage";

export const FORM_TYPE_UPLOAD_IMAGE = "form-upload-image";

const FormItemUploadImage = (props) => {
  const { name, label, required, placeholder, rules = [], fieldProps = {}, ...rest } = props;
  const { t } = useTranslation();

  return (
    <Form.Item
      name={name}
      label={t(label)}
      rules={fieldRules(
        {
          required: required
            ? {
                message: t("container.form_errors.required")
              }
            : false
        },
        rules
      )}
      {...rest}
    >
      <InputUploadImage
        purpose="picture"
        entity="user"
        type="image"
        placeholder={t(placeholder)}
        cardImageStyle={{ width: 80, height: 80 }}
        {...fieldProps}
      />
    </Form.Item>
  );
};

export default FormItemUploadImage;
