import React, { useEffect, useState } from "react";
import { Upload } from "antd";
import { CloseCircleFilled, DeleteOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import classNames from "classnames";
import axios from "../../axios";
import { openGeneralErrorNotification } from "../Notification";
import { usePrevious } from "../../utils/hooks/usePrevious";
import Preloader from "../Preloader";
import { validateFile } from "./config";

const InputUploadFile = (props) => {
  const {
    id,
    placeholder,
    purpose,
    entity,
    type,
    value = {},
    disabled = false,
    onChange = (e) => e,
    onUpload,
    onClickDelete,
    extensions = []
  } = props;
  const [fileName, setFileName] = useState(value.name);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const previousValue = usePrevious(value);

  useEffect(() => {
    if (value?.id && isEqual(previousValue, value)) {
      onChange([value.id]);
    }

    if (!isEqual(previousValue, value)) {
      if (value?.id) {
        onChange([value.id]);
      }

      if (isEmpty(value)) {
        onChange([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const beforeUpload = (file) => {
    if (validateFile(file, t, extensions)) {
      upload(file);
    }

    return false;
  };

  const upload = (file) => {
    setLoading(true);
    const fd = new FormData();
    purpose && fd.append("purpose", purpose);
    entity && fd.append("entity", entity);
    type && fd.append("type", type);
    fd.append("file", file, file.name);

    axios.post("files", fd, { headers: { "Content-Type": "multipart/form-data" } }).then(
      (response) => {
        onChange([response.data.data.id]);
        onUpload && onUpload(response.data.data);
        setFileName(file.name);
        setLoading(false);
      },
      (error) => {
        openGeneralErrorNotification({
          error,
          defaultError: error.response?.data?.errors?.file[0] || t("container.form_errors.internal_server_error")
        });
        setLoading(false);
      }
    );
  };

  const clearUpload = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onChange([]);
    setFileName(null);
  };

  return (
    <>
      <div className={classNames("input-upload", { "disabled": loading || disabled })}>
        <Upload name="file" className="file-uploader" showUploadList={false} beforeUpload={beforeUpload} id={id}>
          {loading && <Preloader />}
          {fileName ? (
            <>
              <div className="file-name">
                <i className="ti-file mr-5" />
                {fileName}
              </div>
              <div className="clear-upload" onClick={clearUpload}>
                <CloseCircleFilled className="clear" />
                {onClickDelete && <DeleteOutlined onClick={onClickDelete} className="delete" />}
              </div>
            </>
          ) : (
            <div className="placeholder">{placeholder}</div>
          )}
        </Upload>
      </div>
    </>
  );
};

export default InputUploadFile;
