import forEach from "lodash/forEach";
import isString from "lodash/isString";
import { getColumnSearchFilterProps } from "./filterTypes/columnSearch";
import { getColumnResourceSelectFilterProps } from "./filterTypes/columnResourceSelect";
import { getColumnStaticSelectFilterProps } from "./filterTypes/columnStaticSelect";
import { getColumnDateFilterProps } from "./filterTypes/columnDate";

/***
 * @description: FILTER_TYPE_DATE_RANGE return filter format:
 * period_start: 2022-06-28
 * period_end: 2022-06-30
 */
export const FILTER_TYPE_DATE_RANGE = "date-range";

/***
 * @description: FILTER_TYPE_STRING return filter format:
 * field[]: last_name
 * op[]: like
 * term[]: 123
 */
export const FILTER_TYPE_STRING = "string";

/***
 * @description: FILTER_TYPE_SELECT_MODE_1 return filter format:
 * filters[qualification_id]: 862
 */
export const FILTER_TYPE_SELECT_MODE_1 = "select_1";

/***
 * @description: FILTER_TYPE_SELECT_MODE_2 return filter format:
 * field[]: qualification_id
 * op[]: eq
 * term[]: 63308
 */
export const FILTER_TYPE_SELECT_MODE_2 = "select_2";

/***
 * @description: FILTER_TYPE_SELECT_MODE_3 return filter format:
 * filters[scopes][]: 862
 * filters[scopes][]: 845
 */
export const FILTER_TYPE_SELECT_MODE_3 = "select_3";

/***
 * @description: FILTER_TYPE_STATIC_SELECT is used with static options
 * @description: FILTER_TYPE_STATIC_SELECT return filter format:
 * field[]: active
 * op[]: eq
 * term[]: 1
 */
export const FILTER_TYPE_STATIC_SELECT = "static_select";

export const translateColumnsTitles = (columns, t) => {
  return columns.map((item) => ({
    ...item,
    title: isString(item.title) ? t(item.title) : item.title
  }));
};

export const applyColumnsFilterSort = (columns, tableService) => {
  const formattedColumns = [...columns];
  const tableParams = tableService.getAllParameters();
  const sort = (tableParams?.orderBy || [])[0] || {};
  const defaultSort = (tableParams?.defaultOrderBy || [])[0] || {};
  const sorterColumn = sort.column || defaultSort.column;
  const sorterType = sort.type || defaultSort.type;

  forEach(formattedColumns, (col, idx) => {
    if (sorterColumn && sorterType && col.dataIndex === sorterColumn) {
      formattedColumns[idx].sortOrder = sorterType === "asc" ? "ascend" : "descend";
    }

    if (col.filterType === FILTER_TYPE_STRING) {
      formattedColumns[idx] = {
        ...col,
        ...getColumnSearchFilterProps({ tableParams, column: col })
      };
    }

    if (col.filterType === FILTER_TYPE_DATE_RANGE) {
      formattedColumns[idx] = {
        ...col,
        ...getColumnDateFilterProps({ tableParams, column: col })
      };
    }

    if (col.filterType === FILTER_TYPE_STATIC_SELECT) {
      formattedColumns[idx] = {
        ...col,
        ...getColumnStaticSelectFilterProps({ tableParams, column: col })
      };
    }

    if (
      col.filterType === FILTER_TYPE_SELECT_MODE_1 ||
      col.filterType === FILTER_TYPE_SELECT_MODE_2 ||
      col.filterType === FILTER_TYPE_SELECT_MODE_3
    ) {
      formattedColumns[idx] = {
        ...col,
        ...getColumnResourceSelectFilterProps({ tableParams, column: col })
      };
    }
  });

  return formattedColumns;
};

export const saveTableParams = (tableService, tableKeyName) => {
  const lastTableParams = JSON.stringify(tableService.getAllParameters());
  localStorage.setItem(tableKeyName, lastTableParams);
};
