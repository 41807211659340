import React, { lazy, Suspense } from "react";
import Preloader from "../components/Preloader";

const loadable = (importFunc, fallback) => {
  const LazyComponent = lazy(importFunc);

  return (props) => (
    <Suspense fallback={fallback || <Preloader className="center-on-body" fontSize={50} />}>
      <LazyComponent {...props} />
    </Suspense>
  );
};

export default loadable;
