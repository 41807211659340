import isEmpty from "lodash/isEmpty";
import forOwn from "lodash/forOwn";

export const formatAccessList = (user = {}) => {
  const { groupActions } = user;
  let accessList = [];
  let moduleList = [];

  if (!isEmpty(groupActions)) {
    forOwn(groupActions, (values, key) => {
      if (values && values.length > 0) {
        values.forEach((item) => {
          if (item.selected) {
            accessList.push(item);
            if (moduleList.indexOf(key) === -1) {
              moduleList.push(key);
            }
          }
        });
      }
    });
  }

  return {
    accessList,
    moduleList
  };
};
