import React from "react";
import TableCell from "../../../../components/SmartTable/TableCell";
import { PERMISSION_MANAGE_ORG_SETTINGS } from "../../../../components/_auth/permissions/permissions";
import DeleteItemButton from "../../../../components/SmartTable/TableFiltersActions/DeleteItemButton";
import { FILTER_TYPE_STATIC_SELECT, FILTER_TYPE_STRING } from "../../../../components/SmartTable/methods";
import { formatString } from "../../../../utils/common";
import { openModalAction } from "../../../../components/Modal/reducer";
import Tooltip from "../../../../components/Tooltip";
import ColumnActiveSwitch from "./ColumnActiveSwitch";
import { MODAL_ORG_SETTINGS_FUNCTIONS } from "./index";

export const TABLE_ORG_SETTINGS_FUNCTIONS_NAME = "table-settings-functions";

export const generateColumns = (permission, trans, dispatch) => {
  return [
    {
      title: "container.org_settings.functions_tab.name",
      dataIndex: "name",
      sorter: true,
      filterType: FILTER_TYPE_STRING,
      width: "50%",
      render: (text, row) => <TableCell>{formatString(row.name, "-")}</TableCell>
    },
    {
      title: "container.org_settings.functions_tab.active",
      dataIndex: "active",
      sorter: true,
      filterType: FILTER_TYPE_STATIC_SELECT,
      filterProps: {
        options: [
          {
            value: "1",
            name: trans("container.general_words.active")
          },
          {
            value: "0",
            name: trans("container.general_words.inactive")
          }
        ]
      },
      render: (text, row) => (
        <TableCell>
          {trans(row.active ? "container.general_words.active" : "container.general_words.inactive")}
        </TableCell>
      )
    },
    ...(permission.canDo(PERMISSION_MANAGE_ORG_SETTINGS)
      ? [
          {
            title: trans("container.general_words.actions"),
            fixed: "right",
            width: 100,
            render: (row = {}) => (
              <div className="table-actions-column">
                <ColumnActiveSwitch
                  className="mr-15"
                  isActive={row.active}
                  route={`job-titles/change-state/${row.id}`}
                  tableName={TABLE_ORG_SETTINGS_FUNCTIONS_NAME}
                  tooltip={{
                    active: "container.org_settings.functions_tab.undo_activate_popover",
                    inactive: "container.org_settings.functions_tab.active_popover"
                  }}
                />
                <Tooltip title={trans("container.general_words.edit")} placement="top" className="text-primary">
                  <a
                    href="/"
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(openModalAction({ name: MODAL_ORG_SETTINGS_FUNCTIONS, data: row }));
                    }}
                  >
                    <i className="ti-pencil-alt" />
                  </a>
                </Tooltip>
                <DeleteItemButton
                  disabled={!row.canBeDeleted}
                  tableName={TABLE_ORG_SETTINGS_FUNCTIONS_NAME}
                  route={`job-titles/${row.id}`}
                  content={
                    <div>
                      <div>{trans("container.confirm_modal.form_subtitle")}</div>
                    </div>
                  }
                />
              </div>
            )
          }
        ]
      : [])
  ];
};
