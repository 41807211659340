import { Avatar as AntdAvatar } from "antd";

const Avatar = (props) => {
  const { title, icon, size, style, ...rest } = props;
  return (
    <AntdAvatar icon={!title && icon} size={size} style={{ fontSize: size ? `${size}px` : "15px", ...style }} {...rest}>
      {!!title && title.charAt(0)}
    </AntdAvatar>
  );
};

export default Avatar;
