export const getExtensionLabelClassName = (ext) => {
  if (!ext) return;
  const extUppercase = ext.toUpperCase();

  switch (extUppercase) {
    case "PDF":
      return "tag-danger";
    case "DOC":
    case "DOCX":
    case "DOT":
    case "DOTM":
    case "DOTB":
    case "DOTX":
      return "tag-secondary";
    case "CSV":
    case "XLSX":
    case "XLS":
    case "XLSM":
    case "XLSB":
    case "XLT":
      return "tag-primary";
    case "JPG":
    case "PNG":
    case "GIF":
      return "tag-warning";
    default:
      return "tag-warning";
  }
};

export const computeFileNameExtension = (fileName, ext) => {
  const fileNameExt = fileName ? fileName.substr(fileName.lastIndexOf(".") + 1) : null;

  if (fileNameExt && ext && fileNameExt.toUpperCase() === ext.toUpperCase()) {
    return fileName;
  }

  return ext ? `${fileName}.${ext.toLowerCase()}` : fileName;
};
