import React, { Suspense } from "react";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import App from "./pages/App";
import GlobalPreloader from "./components/_auth/GlobalPreloader";
import "./i18n";
import "./styles/index.scss";
import { clearTablesFiltersLocalStorage } from "./utils/common";

Sentry.init({
  allowUrls: ["teampulse.it", "teampulse.co"],
  dsn: "https://9f89b764fdf24a6dadcecfebedc1d072@o556342.ingest.sentry.io/4504026002948097",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0
});

const root = createRoot(document.getElementById("root"));

root.render(
  <Suspense fallback={<GlobalPreloader />}>
    <Provider store={store}>
      <App />
    </Provider>
  </Suspense>
);

// clear tables filters from localStorage
clearTablesFiltersLocalStorage();
