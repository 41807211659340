import React, { forwardRef, useRef } from "react";
import classNames from "classnames";
import Button from "../Button";
import { useCombinedRefs } from "./useCombinedRefs";
import { useCropper } from "./useCropper";
import "./style.scss";

export const Shape = Object.freeze({
  ROUND: "round",
  RECT: "rect"
});

const Cropper = forwardRef(({ ...props }, ref) => {
  const { cropperJsOptions = {}, shape = Shape.RECT, image, onCrop, preloader, className, loadingUpload } = props;

  const innerRef = useRef(null);
  const cropperRef = useCombinedRefs(ref, innerRef);

  const { loading: isLoading, handleCrop } = useCropper({
    cropperRef,
    cropperJsOptions,
    image,
    onCrop
  });

  const loading = isLoading && <div className="cropper-loading-image">{preloader || "Loading image..."}</div>;

  const opacity = isLoading ? 0 : 1;

  return (
    <div className="cropper-outer-container">
      {loading}
      <div style={{ opacity }} className={classNames("cropper-container", className, shape)}>
        <div className="cropper-image-container">
          <img ref={cropperRef} src="" alt="cropper" className="image-to-crop" />
        </div>
        <div className="cropper-toolbar">
          <Button type="primary" onClick={handleCrop} loading={loadingUpload} disabled={loadingUpload}>
            Crop
          </Button>
        </div>
      </div>
    </div>
  );
});

export default Cropper;
