export const PAGE_IOR_SETTINGS = "/ior-settings";
export const PAGE_IOR_MY_REPORTS = "/ior/my-reports";
export const PAGE_IOR_REPORTS = "/ior/reports";
export const PAGE_IOR_VIEW_REPORT = "/ior/reports/view-report";
export const PAGE_IOR_EDIT_REPORT = "/ior/reports/edit";
export const PAGE_IOR_NEW_REPORT = "/ior/reports/new";
export const PAGE_IOR_DASHBOARD = "/ior/dashboard";

export const PAGE_IOR_ASSIGNEES = "/ior/assignees";
export const PAGE_IOR_NEW_ASSIGNEE = "/ior/assignees/new";
export const PAGE_IOR_EDIT_ASSIGNEE = "/ior/assignees/edit";

export const PAGE_IOR_INVESTIGATORS = "/ior/investigators";
export const PAGE_IOR_NEW_INVESTIGATOR = "/ior/investigators/new";
export const PAGE_IOR_EDIT_INVESTIGATOR = "/ior/investigators/edit";

export const PAGE_IOR_CORRECTIVE_ACTIONS = "/ior/corrective-actions";
export const PAGE_IOR_CORRECTIVE_ACTION = "/ior/corrective-action";
