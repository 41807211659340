import { usePermission } from "./permissions/usePermission";

const ProtectedComponent = ({ children, gates, module }) => {
  const permission = usePermission();

  if (!permission.hasModule(module) || !permission.canDo(gates)) {
    return null;
  }

  return children;
};

export default ProtectedComponent;
