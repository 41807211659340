import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import UserAvatarOrLetter from "../../../../Core/Users/UserAvatarOrLetter";
import Tooltip from "../../../../../components/Tooltip";
import { openNotification } from "../../../../../components/Notification";
import axios from "../../../../../axios";
import { usePermission } from "../../../../../components/_auth/permissions/usePermission";
import useReportData from "../../useReportData";
import { updateReportData } from "../../reducer";
import { REPORT_FORM_TYPE } from "../..";
import { PERMISSION_MANAGE_IOR_CORRECTIVE_ACTIONS } from "../../../../../components/_auth/permissions/permissions";
import { prepareInvestigatorData } from "./methods";
import { INVESTIGATOR_AUDITOR, INVESTIGATOR_CO_AUDITOR } from "./config";

const InvestigatorCard = ({ user, type }) => {
  const { t } = useTranslation();
  const permission = usePermission();
  const dispatch = useDispatch();
  const report = useReportData();
  const investigator = prepareInvestigatorData(user);
  const [loading, setLoading] = useState(false);

  const onRemoveInvestigator = () => {
    if (loading) return;
    setLoading(true);

    if (investigator.type === INVESTIGATOR_AUDITOR) {
      axios.put(`ior/report/set-investigator/${report.basic_data.id}`, { lead_auditor: null }).then(
        () => {
          setLoading(false);
          dispatch(updateReportData({ lead: null }));
          openNotification({
            message: t("qam.activity_program.notifications.lead_auditor_removed"),
            type: "success",
            placement: "bottom"
          });
        },
        () => {
          setLoading(false);
          openNotification({
            message: t("qam.activity_program.notifications.lead_auditor_could_not_be_removed"),
            type: "error",
            placement: "bottom"
          });
        }
      );
    }

    if (investigator.type === INVESTIGATOR_CO_AUDITOR) {
      const updatedCoAuditors = (report.coAuditors || []).filter((item) => item.id !== investigator.id);
      axios
        .put(`ior/report/set-investigator/${report.basic_data.id}`, {
          coAuditors: updatedCoAuditors.map((item) => item.id)
        })
        .then(
          () => {
            setLoading(false);
            dispatch(updateReportData({ coAuditors: updatedCoAuditors }));

            openNotification({
              message: t("qam.activity_program.notifications.co-auditor_removed"),
              type: "success",
              placement: "bottom"
            });
          },
          () => {
            setLoading(false);
            openNotification({
              message: t("qam.activity_program.notifications.co-auditor_could_not_be_removed"),
              type: "error",
              placement: "bottom"
            });
          }
        );
    }
  };

  return (
    <div className="participant-card">
      {permission.canDo(PERMISSION_MANAGE_IOR_CORRECTIVE_ACTIONS) && (
        <div className="participant-status-bar">
          {investigator.systemAccess && (
            <Tooltip title={t("qam.activity_program.lock_icon_popover")}>
              <i className="ti-heart text-primary" />
            </Tooltip>
          )}
          {!investigator.systemAccess && (
            <Tooltip title={t("qam.activity_program.heart-broken-icon-popover")}>
              <i className="ti-heart-broken text-primary" />
            </Tooltip>
          )}
          {!investigator.confirmed && investigator.confirmed !== false && (
            <Tooltip title={t("qam.activity_program.participation_outstanding")}>
              <i className="ti-help text-primary" />
            </Tooltip>
          )}
          {investigator.confirmed && (
            <Tooltip title={t("qam.activity_program.participation_confirmed")}>
              <i className="ti-comments text-primary" />
            </Tooltip>
          )}
          {investigator.confirmed === false && (
            <Tooltip title={t("qam.activity_program.participation_declined")}>
              <i className="ti-alert text-primary" />
            </Tooltip>
          )}
          {permission.canDo(PERMISSION_MANAGE_IOR_CORRECTIVE_ACTIONS) && type !== REPORT_FORM_TYPE.VIEW && (
            <Tooltip title={t("container.general_words.remove")} onClick={onRemoveInvestigator}>
              <i className="ti-close text-danger" />
            </Tooltip>
          )}
        </div>
      )}
      <div className="left">
        <UserAvatarOrLetter name={investigator.name} imageSrc={investigator.image} size={30} />
      </div>
      <div className="right">
        <div className="mb-10">
          {investigator.name && <div className="line-height-12 name">{investigator.name}</div>}
          <div className="line-height-12">{investigator.desc || "-"}</div>
        </div>
        {investigator.phone && (
          <a className="black" href={`tel:${investigator.phone}`}>
            {investigator.phone}
          </a>
        )}
        {investigator.phone2 && (
          <a className="black" href={`tel:${investigator.phone2}`}>
            {investigator.phone2}
          </a>
        )}
        {investigator.email && (
          <a className="black" target="_blank" href={`mailto:${investigator.email}`} rel="noreferrer">
            {investigator.email}
          </a>
        )}
      </div>
    </div>
  );
};

export default InvestigatorCard;
