import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";
import Form, { FORM_HORIZONTAL_LAYOUT } from "../../../../../components/Form";
import useFormSubmit from "../../../../../components/Form/useFormSubmit";
import Button from "../../../../../components/Button";
import { useDateFormat } from "../../../../../utils/hooks/useDateFormat";
import { closeModalAction } from "../../../../../components/Modal/reducer";
import { reloadTable } from "../../../../../components/SmartTable/reducer";
import { renderDynamicallyFields } from "../../../../../components/Form/methods";
import { formTransformer, generateFormFields, TABLE_USER_VIEW_TRG_QUALIFICATIONS_NAME } from "./methods";
import { MODAL_USER_VIEW_QUALIFICATION } from "./index";

const UserQualificationForm = ({ userId }) => {
  const { format } = useDateFormat();
  const data = useSelector((state) => state?.modal[MODAL_USER_VIEW_QUALIFICATION]?.data) || {};
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isNewRecord = isEmpty(data);
  const { loading, submit, generalError } = useFormSubmit({
    route: isNewRecord ? "trg/qualification-histories" : `trg/qualification-histories/${data.id}`,
    method: isNewRecord ? "post" : "put",
    form,
    transformer: (values) => formTransformer(values, format),
    onSuccess: () => {
      dispatch(reloadTable({ name: TABLE_USER_VIEW_TRG_QUALIFICATIONS_NAME, resetFilters: isNewRecord }));
      dispatch(closeModalAction({ name: MODAL_USER_VIEW_QUALIFICATION }));
    }
  });

  return (
    <Form
      onSubmit={submit}
      form={form}
      errors={generalError}
      loading={loading}
      layout={FORM_HORIZONTAL_LAYOUT}
      initialValues={{
        qualification_id: data.qualification?.id,
        instructor_type: !isEmpty(data) ? (data.external_provider_id ? 0 : 1) : undefined,
        certificate: data.files?.filter((item) => item.purpose === "certificate")[0],
        evidence: data.files?.filter((item) => item.purpose !== "certificate")[0],
        ...data
      }}
      header={
        <>
          <div className="h3">
            {t(
              isNewRecord
                ? "container.user_profile.training_history.modal.form_title"
                : "container.user_profile.training_history.modal.form_title_edit"
            )}
          </div>
          <div className="mb-30 mt-10 h6 font-weight-300 text-muted">{t("container.general_words.form_subtitle")}</div>
        </>
      }
    >
      {renderDynamicallyFields(generateFormFields(data, userId, form, t))}
      <div className="flex mt-25 flex-justify-end">
        <Button
          className="min-w-150"
          type="primary"
          htmlType="submit"
          disabled={loading}
          loading={loading}
          icon={<i className="ti-check" />}
        >
          {t("container.general_words.save")}
        </Button>
      </div>
    </Form>
  );
};

export default UserQualificationForm;
