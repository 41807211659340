import React from "react";
import useLocale from "../DatePicker/useLocale";
import DatePicker from "../DatePicker";

const DateRangePicker = (props) => {
  const locale = useLocale();
  return <DatePicker.RangePicker {...props} locale={locale} />;
};

export default DateRangePicker;
