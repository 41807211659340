import React from "react";
import { useTranslation } from "react-i18next";
import isFunction from "lodash/isFunction";
import isString from "lodash/isString";
import { fieldRules } from "../methods";
import Form from "../index";
import ResourceSelectLazyLoading from "../../ResourceSelectLazyLoading";

export const FORM_ITEM_RESOURCE_SELECT_LAZY_LOADING = "form-resource-select-lazy-loading";

const FormItemResourceSelectLazyLoading = (props) => {
  const {
    name,
    label,
    required,
    placeholder,
    initialValue,
    mode,
    apiRoute,
    rules = [],
    fieldProps = {},
    visible = true,
    shouldReset,
    maxTagCount,
    ...rest
  } = props;
  const { t } = useTranslation();

  if (!visible) {
    return null;
  }

  const formField = (
    <Form.Item
      name={name}
      label={isString(label) ? t(label) : label}
      rules={fieldRules(
        {
          required: required
            ? {
                message: t("container.form_errors.required")
              }
            : false
        },
        rules
      )}
      {...rest}
    >
      <ResourceSelectLazyLoading
        initialValue={initialValue}
        placeholder={t(placeholder)}
        apiRoute={apiRoute}
        mode={mode}
        shouldReset={shouldReset}
        maxTagCount={maxTagCount}
        {...fieldProps}
      />
    </Form.Item>
  );

  if (isFunction(visible)) {
    return (
      <Form.Item noStyle shouldUpdate>
        {(formInstance) => {
          const isVisible = visible(formInstance);
          if (isVisible) {
            return isVisible ? formField : null;
          }
        }}
      </Form.Item>
    );
  }

  return formField;
};

export default FormItemResourceSelectLazyLoading;
