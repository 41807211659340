export const prepareExportRequestParams = (exportColumns = [], columns = [], selectedRows = []) => {
  const langKeys = [];
  const filters = selectedRows.map((item) => item.id);

  exportColumns.forEach((item) => {
    const associatedColumn = columns.filter((col) => col.dataIndex === item.column)[0] || {};
    if (item.trans || associatedColumn.title) {
      langKeys.push({
        column: item.column,
        trans: item.trans || associatedColumn.title || ""
      });
    }
  });

  return {
    langKeys,
    columns: exportColumns.filter((item) => item.onlyLang !== true).map((item) => item.column),
    ...(filters.length > 0 ? { "filters[identifiers]": filters } : {})
  };
};
