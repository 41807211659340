import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

const ShowMoreList = ({
  data = [],
  initialShow = 1,
  visible = false,
  defaultReturn = "-",
  className,
  showMoreTrans,
  showLessTrans
}) => {
  const [isVisible, setVisible] = useState(visible);
  const { t } = useTranslation();

  return (
    <div className={classNames("show-more-list", className)}>
      {data.length === 0 && defaultReturn}
      {data.map((item, idx) => (
        <div className={idx < initialShow || isVisible ? "item active" : "item"} key={idx}>
          {item}
        </div>
      ))}
      {initialShow < data.length && (
        <div onClick={() => setVisible(!isVisible)} className="show-more-list-trigger">
          {isVisible ? (
            <span>
              <i className="ti-angle-up mr-5" />
              {t(showLessTrans || "container.general_words.hide")}
            </span>
          ) : (
            <span>
              <i className="ti-angle-down mr-5" />
              {t(showMoreTrans || "container.general_words.show_more")}
            </span>
          )}
        </div>
      )}
    </div>
  );
};

export default ShowMoreList;
