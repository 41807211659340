import { useEffect, useState } from "react";
import axios from "axios";
import { getStorageAuth } from "../_auth/methods";

export const useImage = ({ src, retina }) => {
  const [imgSrc, setImgSrc] = useState(null);
  const [loading, setLoading] = useState(!!src);
  const [imgRetinaSrc, setImgRetinaSrc] = useState(null);
  const authStorage = getStorageAuth();
  const headers = authStorage?.access_token
    ? {
        "Authorization": `Bearer ${authStorage.access_token}`,
        google2fa: authStorage.google2fa
      }
    : {};

  const generateBlobURL = (url, callback) => {
    setLoading(true);
    if (url && url.indexOf("data:") === 0) {
      if (callback) {
        callback(url);
        setLoading(false);
      }
    } else if (url) {
      getImage(url).then(
        (response) => {
          const blob = new Blob([response.data], { type: response.headers["content-type"] });

          if (callback) {
            callback(URL.createObjectURL(blob));
          }

          setLoading(false);
        },
        () => {
          callback(null);
          setLoading(false);
        }
      );
    }
  };

  const getImage = (imgSource) => {
    return axios.get(imgSource, {
      headers,
      responseType: "arraybuffer"
    });
  };

  useEffect(() => {
    if (src) {
      generateBlobURL(src, (objURL) => {
        setImgSrc(objURL);
      });
    } else {
      setImgSrc(null);
    }

    if (retina) {
      generateBlobURL(retina, (objURL) => {
        setImgRetinaSrc(objURL);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src, retina]);

  return {
    src: imgSrc,
    retina: imgRetinaSrc,
    loading
  };
};
