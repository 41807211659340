import { createAction, createReducer } from "@reduxjs/toolkit";

export const initialState = {};

export const changeGlobalState = createAction("global/change");

export default createReducer(initialState, (builder) => {
  builder.addCase(changeGlobalState, (state, action) => {
    return {
      ...state,
      ...action.payload
    };
  });
});
